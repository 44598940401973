import React from 'react';
import Container from 'react-bootstrap/Container';

import { Helmet } from "react-helmet";

const SolidifiesNorthIndia = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Solidifies Position in North India | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Vistas is one of the leading brand in producing cement, ready mix concrete and modern building materials and rated as fifth largest cement group in India" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/financial-result-q4-fy22"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
          <h2 className='grad-heading'>Press Release: NUVOCO VISTAS SOLIDIFIES ITS POSITION IN NORTH INDIA</h2>
          <p>Witnesses substantial increase in Dealer Network with the launch of Double Bull Cement in the Region</p>
          
          <p className='onetxt'>
            <strong>Mumbai, June 2022:</strong>  India’s fifth-largest cement group and the leading cement company in the east, Nuvoco Vistas Corp Ltd has expanded its presence in the North region with the introduction of its premium cement portfolio. Revenue in the north region specifically Haryana, Western UP, Punjab, Delhi, and Madhya Pradesh focus on trade channels and brand building through a strong dealer network. </p>
          <p>To address to growing needs of the infrastructure sector, Nuvoco Vistas Corp Ltd plans to set up a grinding unit with a 1.2 million tonnes per annum capacity at its existing Plant at Bhiwani, Haryana. This is the first step toward the company’s expansion in the North region. This grinding unit will aid the full utilization of the clinker production in the region. </p>
          <p>Currently, Nuvoco’s product portfolio addresses the complete spectrum of customers with Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC), which are among the best in the industry based on BIS standards and premium raw material quality. Its flagship brands Concreto, Duraguard and Double Bull offer a right mix across the price points. Double Bull is the fastest growing cement brand in the country that has been indigenously developed and has achieved a sale of over 5 million tons in a short span of five years. Concreto, a premium product offering remains a frontrunner in Eastern India and has a specialized variant for Northern India. Duraguard offers a vast range of products that are technologically advanced and meet modern construction requirements.</p>
          <p>Nuvoco is committed to creating value for all its stakeholders through a portfolio of innovative and sustainable products that are backed by robust research and are primary growth drivers filling latent market gaps. The company’s premium offering Concreto has seen 17% Y-o-Y growth in the sales volume in FY 22. Duraguard is one of the most popular cement brands in the Northern and Eastern markets of India and has become a symbol of trust and longevity. Its range comprising Duraguard WaterSeal Cement received the first-ever Patent Certificate for its Water-Resistant Cement Composition.</p>
         <p>The company stays committed to its sustainability initiatives by focusing on increasing the consumption of alternative fuel, promoting a healthy share of blended cement, and conserving natural resources through efficient Waste Heat Recovery systems, making a positive impact on the environment, and maximising the value creation for its stakeholders. As India's long-term growth drivers remain intact, the company is wellpositioned for growth to build a safer, smarter, and sustainable world.</p>
        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a cement manufacturing company with a vision to build a safer, smarter and sustainable world. Nuvoco started its operations in 1999, and since then it has emerged as one of the major players in the building materials industry in India. Today, Nuvoco offers a diversified business portfolio under three business segments, namely, Cement, Ready-Mix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco is a leading cement player in India and the largest cement company in East India in terms of capacity, following the acquisition of Nu Vista Limited (formerly Emami Cement Limited), offering various brands of cement such as ‘Concreto’, ‘Duraguard’, ‘Nirmax’, ‘Double Bull’, and ‘Infracem’. The MBM product portfolio, under the ‘Zero M’ and ‘Instamix’ brands, comprises construction chemicals, wall putty and cover blocks. The RMX business has a comprehensive pan-India presence offering value-added products under various brands such as ‘Concreto’, ‘Artiste’, ‘InstaMix’ and ‘X-Con’. Nuvoco is a proud contributor to landmark projects such as World One building at Mumbai, and the metro railway projects at Mumbai, Jaipur and Noida. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet the customer requirements. <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
        </Container>
      </div>
    </div >
  )
}

export default SolidifiesNorthIndia