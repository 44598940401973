import { INVESTORCALLTRANSCRIPTS } from "./type";

const initialState ={investercalltrans: ''};

const investorcalltransR = (state = initialState, action) =>{
    switch(action.type){
        case INVESTORCALLTRANSCRIPTS:
            return {...state, investercalltrans: action.payload};

        default:
            return state;
    }
}

export default investorcalltransR;