import { PERFORMANCE, PERFORMANCESEARCH } from "./type";
import { API_URL } from "../config";
import axios from "axios";

const performanceFn = (data) =>{
    return{
        type: PERFORMANCE,
        payload: data
    };
}


export const performanceApi = (type) =>{
    let data ={};
    const title = type === 1 ? 'FinancialInformationPrelist' : 'FinancialInformationPostlist';
    return (dispatch) =>{
        axios.post(API_URL + title, data).then((response) =>{
            dispatch(performanceFn(response));
        });
    };
}

const performancesearchFn = (data) =>{
    const dt = {...data, IsSuccess:1};
    return{
        type: PERFORMANCESEARCH,
        payload: dt
    };
}

export const performancesearchApi = (cid,yid,id,cpType) =>{
    let data ={category_id:cid, year_id:yid, listStatus:id, companyType:cpType };
    return (dispatch) =>{
        axios.post(API_URL + 'FinancialInformationSearch', data).then((response) =>{
            dispatch(performancesearchFn(response));
        });
    };
}