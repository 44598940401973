import { CORPORATE, CORPORATESEARCH } from "./type";

const initialState ={corporate: '', corporatesearch: ''};

const corporateR = (state = initialState, action) =>{
    switch(action.type){
        case CORPORATE:
            return {...state, corporate: action.payload};
            
        case CORPORATESEARCH:
            return {...state, corporatesearch: action.payload};

        default:
            return state;
    }
}

export default corporateR;