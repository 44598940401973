import { PRESENTATION, PRESENTATIONSEARCH, AUDIOVIDEO } from "./type";
import { API_URL } from "../config";
import axios from "axios";

const presentationFn = (data) =>{
    const dt = {...data, IsSuccess:1};
    return{
        type: PRESENTATION,
        payload: dt
    };
}

export const presentationApi = (type) =>{
    let data ={};
    const title = type === 1 ? 'FinancialInformationPresentationPostlist' : 'FinancialInformationPresentationPostlist';
    return (dispatch) =>{
        axios.post(API_URL + title, data).then((response) =>{
            dispatch(presentationFn(response));
        });
    };
}

const presentationsearchFn = (data) =>{
    const dt = {...data, IsSuccess:1};
    return{
        type: PRESENTATIONSEARCH,
        payload: dt
    };
}

export const presentationsearchsearchApi = (cid,yid) =>{
    let data ={category_id:cid, year_id:yid};
    return (dispatch) =>{
        axios.post(API_URL + 'FinancialInformationPresentationSearch', data).then((response) =>{
            dispatch(presentationsearchFn(response));
        });
    };
}

const audioVideoFn = (data) =>{
    const dt = {...data, IsSuccess:1};
    return{
        type: AUDIOVIDEO,
        payload: dt
    };
}

export const audioVideoApi = (yid) =>{
    let data ={year_id:yid};
    return (dispatch) =>{
        axios.post(API_URL + 'VideoSearch', data).then((response) =>{
            dispatch(audioVideoFn(response));
        });
    };
}

