import React from "react";
import {Card, Col} from 'react-bootstrap';
import pdfIcon from '../assets/images/pdf_icon.png';

const PdfCard = ({bd, uri, ct}) => {
    var pdf = JSON.parse(bd.pdfs);
    var title = JSON.parse(bd.pdfs_title);    
    return (
        <>
            {pdf.map((val, i)=>{
                return(
                    <Col  className={'col-xl-4 ' + ct} md={5} key={i}>
                        <a href={uri + val} target="_blank" rel="noreferrer">
                            <Card className="wow fadeInUp" data-wow-delay="0.5s">
                                <Card.Title>{title[i]}</Card.Title>
                                <Card.Img src={pdfIcon} />
                            </Card>
                        </a>
                    </Col>
                )
            })}
        </>
    )
}

export default PdfCard;