import React, {useState} from "react";
import {Container, Breadcrumb, Row, Col, Card, Button, Modal} from 'react-bootstrap';
import { API_URL } from "../redux/config";
import axios from "axios";
import { useForm } from "react-hook-form";
import banner from '../assets/images/contact_us.png';
import bannermobile from '../assets/images/contact-us-mobile.jpg'
import aboutBannerLogo from '../assets/images/barndlogo.png';
import contact_icon from '../assets/images/contact_icon.png';
import media from '../assets/images/media.png';
import hr from '../assets/images/hr.png';
import shareholder from '../assets/images/shareholder.png';
import partner from '../assets/images/partner.png';
import clogo01 from '../assets/images/clogo01.png';
import clogo02 from '../assets/images/clogo02.png';
import clogo03 from '../assets/images/clogo03.png';
import clogo04 from '../assets/images/clogo04.png';
import { Helmet } from "react-helmet";

const ContactUs = ({data}) =>{
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
     const handleShow = () => setShow(true);
    const {
		register,
		handleSubmit,
		formState: { errors },
        reset
	} = useForm();

    const onSubmit = (data) => {
        const {
            district,
            email,
            full_name,
            message,
            phone_number,
            pin_code,
            product_details,
            products,
            state,
        } = data;

        axios
        .post(API_URL + "ContactFormSubmit", {
            full_name: full_name,
            email: email,
            phone_number: phone_number,
            state: state,
            district: district,
            pin_code: pin_code,
            products: products,
            product_details: product_details,
            message: message,
        })
        .then((response) => {
            if (response.status === 200) {                
                handleShow();
                reset();
            } else {
                //Something went wrong!
            }
        });
    }
    return(
        <div className="contact_inner">
            <Helmet>
                <title>Contact Us | Best Cement & RMX Concrete Company In India</title>
                <meta name="keywords" description="" />
                <meta name="description" content="Nuvoco is India s foremost cement concrete manufacturer with its nationwide reach If you have any queries, you can reach us here or contact our head office in Mumbai" />
                <link rel="canonical" href="https://nuvoco.com/contact-us"/>
            </Helmet>
            <div className="banner text-center position-relative">
            <img className='desktopbannerinner' src={banner} alt="" />
            <img className='mobilebannerinner' src={bannermobile} alt="" />
                <article className='position-absolute text-center'>
                    <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
                    <h1 className="text-uppercase">Contact Us</h1>
                </article>
                <div className='breadouter'>
                    <div className='container'>
                        <Breadcrumb className='mb-0 py-2'>
                            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>
                            Contact Us
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
            <div className="card_round pt-5">
                <Container>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form_sce">
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Full Name*</label>
                                        <input type="text" {...register("full_name", {required: true})} placeholder="Full Name" className="form-control name"></input>
                                        <span className="error">{errors['full_name'] && errors['full_name'].type === "required" && "Required*"}</span>
                                    </div>                                    
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>Email Id*</label>
                                        <input type="text" 
                                        {...register("email", 
                                            {required: true,
                                                pattern:/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                        })} 
                                        placeholder="Email Id" className="form-control email"></input>
                                        <span className="error">{errors['email'] && errors['email'].type === "required" && "Required*"}</span>
                                        <span className="error">{errors['email'] && errors['email'].type === "pattern" && "Invalid Email*"}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Phone Number*</label>
                                        <input type="number" {...register("phone_number", {required: true, maxLength: 10, minLength:10})} placeholder="Phone Number" className="form-control phone"></input>
                                        <span className="error">{errors['phone_number'] && errors['phone_number'].type === "required" && "Required*"}</span>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group select_b">
                                        <label>State*</label>

                                        <select
                                            className="form-control state"
                                            {...register("state")}
                                        >
                                            <option value="Andhra Pradesh">
                                                Andhra Pradesh
                                            </option>
                                            <option value="Andaman and Nicobar Islands">
                                                Andaman and Nicobar Islands
                                            </option>
                                            <option value="Arunachal Pradesh">
                                                Arunachal Pradesh
                                            </option>
                                            <option value="Assam">Assam</option>
                                            <option value="Bihar">Bihar</option>
                                            <option value="Chandigarh">Chandigarh</option>
                                            <option value="Chhattisgarh">
                                                Chhattisgarh
                                            </option>
                                            <option value="Dadar and Nagar Haveli">
                                                Dadar and Nagar Haveli
                                            </option>
                                            <option value="Daman and Diu">
                                                Daman and Diu
                                            </option>
                                            <option value="Delhi">Delhi</option>
                                            <option value="Lakshadweep">
                                                Lakshadweep
                                            </option>
                                            <option value="Puducherry">Puducherry</option>
                                            <option value="Goa">Goa</option>
                                            <option value="Gujarat">Gujarat</option>
                                            <option value="Haryana">Haryana</option>
                                            <option value="Himachal Pradesh">
                                                Himachal Pradesh
                                            </option>
                                            <option value="Jammu and Kashmir">
                                                Jammu and Kashmir
                                            </option>
                                            <option value="Jharkhand">Jharkhand</option>
                                            <option value="Karnataka">Karnataka</option>
                                            <option value="Kerala">Kerala</option>
                                            <option value="Madhya Pradesh">
                                                Madhya Pradesh
                                            </option>
                                            <option value="Maharashtra">
                                                Maharashtra
                                            </option>
                                            <option value="Manipur">Manipur</option>
                                            <option value="Meghalaya">Meghalaya</option>
                                            <option value="Mizoram">Mizoram</option>
                                            <option value="Nagaland">Nagaland</option>
                                            <option value="Odisha">Odisha</option>
                                            <option value="Punjab">Punjab</option>
                                            <option value="Rajasthan">Rajasthan</option>
                                            <option value="Sikkim">Sikkim</option>
                                            <option value="Tamil Nadu">Tamil Nadu</option>
                                            <option value="Telangana">Telangana</option>
                                            <option value="Tripura">Tripura</option>
                                            <option value="Uttar Pradesh">
                                                Uttar Pradesh
                                            </option>
                                            <option value="Uttarakhand">
                                                Uttarakhand
                                            </option>
                                            <option value="West Bengal">
                                                West Bengal
                                            </option>
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>District*</label>
                                        <input type="text" {...register("district", {required: true})} placeholder="District" className="form-control state"></input>
                                        <span className="error">{errors['district'] && errors['district'].type === "required" && "Required*"}</span>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>Pin Code*</label>
                                        <input type="number" {...register("pin_code", {required: true})} placeholder="Pin Code" className="form-control pincode"></input>
                                        <span className="error">{errors['pin_code'] && errors['pin_code'].type === "required" && "Required*"}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group select_b">
                                        <label>Portfolio**</label>
                                        <select
                                            className="form-control cementIcon"
                                            {...register("products")}
                                        >
                                            <option value="Cement">Cement</option>
                                            <option value="Concrete">Concrete</option>
                                            <option value="MBM">MBM</option>
                                            <option value="Corporate">Corporate</option>
                                        </select>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group select_b">
                                        <label>Product Details**</label>
                                        <select
                                            className="form-control cementIcon"
                                            {...register("product_details")}
                                        >
                                            <option value="Purchase">Purchase</option>
                                            <option value="Dealership">Dealership</option>
                                            <option value="CFA">CFA</option>
                                            <option value="Non Trade">Non Trade</option>
                                            <option value="Business Proposal">
                                                Business Proposal
                                            </option>
                                            <option value="Others">Others</option>
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Message**</label>
                                        <textarea type="text" {...register("message")} row="8" placeholder="Message" className="form-control message"></textarea>
                                    </div>
                                </Col>
                            </Row>
                        </div>         
                        <div className="text-center">
                            <Button variant="success" className="read_more" type="submit">Submit </Button>    
                        </div>    
                    </form>

                    <div className="ct_nuvoco card_round">
                        <Card className="ct_01">
                            <div className="card_header">
                              <img src={contact_icon} />  Nuvoco Vistas Corporation Limited
                            </div>
                            <div className="in"></div>
                            <Card.Body>
                                <p>Registered and Corporate Office</p>
                                <p><strong>Address:</strong> Equinox Business Park, Tower-3, East Wing,
                                    4th floor, Off Bandra-Kurla Complex, LBS Marg, 
                                    Kurla (West), Mumbai 400 070</p>   
                                    <p><strong>Contact:</strong> <a href="tel:+912267692595">+91 (0) 22 6769 2500</a></p>
                                    <p><strong>Email:</strong> <a href="mailto:secretarial.desk@nuvoco.com">secretarial.desk@nuvoco.com</a></p>
                                    <p><strong>CIN:</strong> L26940MH1999PLC118229</p>
                            </Card.Body>
                        </Card>

                        <Card className="ct_01">
                            <div className="card_header">
                              <img src={contact_icon} alt="" />  NU Vista Limited
                            </div>
                            <div className="in"></div>
                            <Row>
                            <Col md={6}>
                            <Card.Body>
                                <p>Registered Office</p>
                                <p><strong>Address:</strong> Equinox Business Park, Tower-3, East Wing,
                                                4th floor, Off Bandra-Kurla Complex, LBS Marg, 
                                                Kurla (West), Mumbai 400 070</p>   
                                            <p><strong>Contact:</strong> <a href="tel:+912267692595">+91 (0) 22 6769 2500</a></p>
                                            <p><strong>CIN:</strong> U26940MH2007PLC353160</p>
                            </Card.Body>
                            </Col>
                            <Col md={6} className='mm_z'>
                            <Card.Body>
                                <p>Corporate Office</p>
                                <p><strong>Address:</strong> DLF IT Park, Tower C, 10th Floor, Premise no. 08, Major Arterial Road,
                                Block-AF, New Town, Kolkata – 700156</p>   
                                            <p><strong>Contact:</strong> <a href="tel:+913340923100">+91 (0) 33 4092 3100</a></p>
                            </Card.Body>
                            </Col>
                            </Row>
                        </Card>

                        <Card className="ct_02 d-none">
                            <div className="card_header">
                              <img src={contact_icon} /> NU Vista Limited
                            </div>
                            <div className="in"></div>
                            <Row>
                                <Col md={6}>
                                    <Card.Body>
                                        <p>Registered Office</p>
                                        <p><strong>Address:</strong> Equinox Business Park, Tower-3, East Wing,
                                                4th floor, Off Bandra-Kurla Complex, LBS Marg, 
                                                Kurla (West), Mumbai 400 070</p>   
                                            <p><strong>Contact:</strong> <a href="tel:+912267692595">+91 (0) 22 6769 2595</a></p>
                                            <p><strong>CIN:</strong> U26940MH2007PLC353160</p>
                                    </Card.Body>
                                </Col>
                                <Col md={6} className='mm_z'>
                                    <Card.Body>
                                        <p>Corporate Office</p>
                                        <p><strong>Address:</strong> DLF IT Park, Tower C, 10th Floor, 
                                            Premise no. 08, Major Arterial Road, Block-AF, 
                                            New Town, Kolkata – 700156</p>   
                                            <p><strong>Contact:</strong> <a href="tel:+913340923100">+91 (0) 33 4092 3100</a></p>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card>
                        <Row className="ct_03">
                            <Col md={6}>
                                <Card>
                                    <div className="card_header">
                                    <img src={media} /> Media
                                    </div>
                                    <div className="in"></div>
                                    <Card.Body> 
                                        <p><strong>Contact:</strong> <a href="tel:+912261202683">+91 (0) 22 6120 2683</a></p>
                                        <p><strong>Email:</strong> <a href="mailto:india.communications@nuvoco.com">india.communications@nuvoco.com</a></p>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={6} className='mm_z'>
                                <Card>
                                    <div className="card_header">
                                    <img src={hr} /> HR
                                    </div>
                                    <div className="in"></div>
                                    <Card.Body>
                                        <p><strong>Contact:</strong> <a href="tel:+912267692500">+91 (0) 22 6769 2500</a></p>
                                        <p><strong>Email:</strong> <a href="mailto:talent.acquisition-india@nuvoco.com">talent.acquisition-india@nuvoco.com</a></p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Card className="ct_04">
                            <div className="card_header">
                              <img src={shareholder} /> Shareholder’s Matters
                            </div>
                            <div className="in"></div>
                            <Row>
                                <Col>
                                    <Card.Body>
                                        <p>For Institutional Investors </p>
                                        <p><strong>Name:</strong> Ms. Madhumita Basu</p>   
                                            <p><strong>Contact:</strong> <a href="tel:+912267692500">+91 (0) 22 6769 2500</a></p>
                                            <p><strong>Email:</strong> <a href="mailto:investor.relations@nuvoco.com">investor.relations@nuvoco.com</a></p>
                                    </Card.Body>
                                </Col>
                                <Col className='mm_z'>
                                    <Card.Body>
                                        <p>For Shares/Dividends/Compliances</p>
                                        <p><strong>Name:</strong> Ms. Shruta Sanghavi, SVP and Company Secretary</p>   
                                            <p><strong>Contact:</strong> <a href="tel:+912267692500">+91 (0) 22 6769 2500</a></p>
                                            <p><strong>Email:</strong> <a href="mailto:investor.relations@nuvoco.com">investor.relations@nuvoco.com</a></p>
                                    </Card.Body>
                                </Col>
                            </Row>


                            <Row>
                                <h3 className="headingstyle"><b>Nuvoco Vistas Corp. Ltd.</b></h3>
                                <Col>
                                <div className="address">
                                    <Card.Body>
                                        <p><b>Debenture Trustee</b></p>
                                        <p>IDBI Trusteeship Services Ltd.</p>
                                        <p><strong>Address: </strong>Universal Insurance Building, Ground Floor, Sir P.M. Road, Fort, Mumbai - 400 001, Maharashtra</p>   
                                            <p><strong>Contact:</strong> <a href="tel:+912240807000">+91 (0) 22 4080 7000</a></p>
                                            
                                            <p><strong>Email:</strong> <a href="mailto:itsl@idbitrustee.com">itsl@idbitrustee.com</a></p>
                                            <p><a class="text-decoration-none text-dark" target="blank" href="https://www.google.com/maps/place/IDBI+Trusteeship+Services+Limited/@18.9350932,72.8371259,17z/data=!3m1!4b1!4m5!3m4!1s0x3be7d1da7c07a6fb:0xefb78989f2d08525!8m2!3d18.9351078!4d72.8392487"><span class="border-bottom border-2 border-dark">Get Directions</span></a></p>
                                    </Card.Body>
                                    </div>
                                </Col>
                                <Col>
                                <div className="address">
<Card.Body>
<p><b>Registrar and Transfer Agent</b></p>
<p>Link Intime India Private Limited</p>
<p><strong>Address: </strong>101, 1st Floor, 247 Park, L.B.S. Marg, Vikhroli West Mumbai – 400 083, Maharashtra</p>   
<p><strong>Contact:</strong> <a href="tel:+912249186200">+91 (0) 22 4918 6200</a></p>
<p><strong>Email:</strong> 
For NCDs - <a href="mailto:bonds.helpdesk@linkintime.co.in">bonds.helpdesk@linkintime.co.in </a> 
For Equity Shares - <a href="mailto:rnt.helpdesk@linkintime.co.in">rnt.helpdesk@linkintime.co.in</a></p>
<p><strong>Website:</strong> <a href="">www.linkintime.co.in</a></p>

<p><a class="text-decoration-none text-dark" target="blank" href="https://www.google.com/maps/place/Link+Intime+India+Private+Limited/@19.153754,72.9355373,17z/data=!3m1!4b1!4m5!3m4!1s0x3be7b8642923d2b9:0x8481a08466c624bd!8m2!3d19.153754!4d72.937726"><span class="border-bottom border-2 border-dark">Get Directions</span></a></p>
</Card.Body>
</div>


</Col>
{/* <Col>
<div className="address">
<Card.Body>
<p><b>Company Contact</b></p>
<p>Ms. Shruta Sanghavi - SVP and Company Secretary</p>
<p><strong>Address: </strong>Equinox Business Park, Tower-3, East Wing, 4th floor, Off Bandra-Kurla Complex, LBS Marg, Kurla (West), Mumbai 400 070</p>   
<p><strong>Contact:</strong> <a href="tel:+912267692500">+91 (0) 22 6769 2500</a></p>
<p><strong>Email:</strong><a href="mailto:investor.relations@nuvoco.com">investor.relations@nuvoco.com </a></p>

<p><a class="text-decoration-none text-dark" target="blank" href="https://www.google.com/maps/search/Equinox+Business+Park,+Ambedkar+Nagar,+Kurla+West,+Kurla,+Mumbai,+Maharashtra+400070/@19.0708782,72.8740444,17z/data=!3m1!4b1"><span class="border-bottom border-2 border-dark">Get Directions</span></a></p>
</Card.Body>
</div>
</Col> */}

</Row>

<Row>
                                <h3 className="headingstyle"><b>NU Vista Ltd.</b></h3>
                                <Col>
                                <div className="address">
                                    <Card.Body>
                                        <p><b>Company Contact</b></p>
                                        <p>Ms. Neha Pokhrana, Company Secretary</p>
                                        <p><strong>Address: </strong>Equinox Business Park, Tower-3, East Wing, 4th floor, Off Bandra-Kurla Complex, LBS Marg, Kurla (West), Mumbai 400 070</p>   
<p><strong>Contact:</strong> <a href="tel:+912267692500">+91 (0) 6769 2500</a></p>
                                            
                                            <p><strong>Email:</strong> <a href="mailto:secretarial.desk@nuvoco.com">secretarial.desk@nuvoco.com</a></p>
<p><a class="text-decoration-none text-dark" target="blank" href="https://www.google.com/maps/place/Equinox+Business+Park/@19.0705795,72.8742324,17z/data=!3m1!4b1!4m5!3m4!1s0x3be7c893209d27a1:0xbd8e525b6e6889!8m2!3d19.0705795!4d72.8764211"><span class="border-bottom border-2 border-dark">Get Directions</span></a></p>
                                    </Card.Body>
                                    </div>
                                </Col>
                                <Col>
                                <div className="address">
<Card.Body>
<p><b>Registrar and Transfer Agent</b></p>
<p>KFin Technologies Ltd,</p>
<p><strong>Address: </strong>Selenium Tower B, Plot 31 & 32, Financial District, Nanakramguda, Serilingampally Mandal, Hyderabad, Telangana-500032</p>   
<p><strong>Contact:</strong> <a href="tel:1800-309-4001">1800-309-4001</a></p>
<p><strong>Email:</strong><a href="mailto:einward.ris@kfintech.com">einward.ris@kfintech.com</a></p>
<p><strong>Website:</strong> <a href="">www.kfintech.com</a></p>

<p><a class="text-decoration-none text-dark" target="blank" href="https://www.google.com/maps/place/KFintech+Pvt+Ltd/@17.4213558,78.3309726,17z/data=!3m1!4b1!4m5!3m4!1s0x3bcb937dde7fafb3:0x3ab58aee0b12590f!8m2!3d17.4213802!4d78.3331771?shorturl=1"><span class="border-bottom border-2 border-dark">Get Directions</span></a></p>
</Card.Body>
</div>
</Col>

</Row>
</Card>

                        <Card className="ct_05">
                            <div className="card_header">
                              <img src={partner} /> Partner Relations
                            </div>
                            <div className="in"></div>
                            <Row>
                                <Col md={6}>
                                    <Card.Body>
                                        <img src={clogo01} className='mb-1' alt=""/>
                                        <p>A customer loyalty program for Dealers of Nuvoco Vistas Corporation Limited. </p>                                          
                                            <p><strong>Email:</strong> <a href="mailto:helpdesk@maitree.co.in">helpdesk@maitree.co.in</a></p>
                                            <p><strong>Log on to: </strong> <a href='https://www.maitree.co.in/' target="_blank">www.maitree.co.in</a></p>
                                            <p><strong>Register on: </strong> <a href='https://play.google.com/store/apps/details?id=lafarge.maitree.loyalty.lafarge' target="_blank">Maitree App</a></p>
                                    </Card.Body>
                                </Col>
                                <Col md={6} className='mm_z'>
                                    <Card.Body>
                                        <img src={clogo02} alt=""/>
                                        <p>A loyalty program exclusively for retailers of Nuvoco Vistas Corporation Limited. </p>                                          
                                            <p><strong>Email:</strong> <a href="mailto:helpdesk@milanrewards.co.in">helpdesk@milanrewards.co.in</a></p>
                                            <p><strong>Log on to: </strong> <a href='https://www.milanrewards.co.in/' target="_blank">www.milanrewards.co.in</a></p>
                                            <p><strong>Register on: </strong> <a href="https://play.google.com/store/apps/details?id=com.mjunction.lafarge.milan" target="_blank">Milan App</a></p>
                                    </Card.Body>
                                </Col>
                                <Col md={6}>
                                    <Card.Body className="pt-1">
                                        <img src={clogo03} alt=""/>
                                        <p>A loyalty program to serve influencers of Nuvoco Vistas Corporation Limited.</p>                                          
                                            <p><strong>Email:</strong> <a href="mailto:helpdesk@vriddhirewards.co.in">helpdesk@vriddhirewards.co.in</a></p>
                                            <p><strong>Log on to: </strong> <a href='https://www.vriddhirewards.co.in/' target="_blank">www.vriddhirewards.co.in </a></p>
                                            <p><strong>Register on: </strong> <a href="https://play.google.com/store/apps/details?id=com.nuvoco.vriddhi" target="_blank">Vriddhi App</a></p>
                                    </Card.Body>
                                </Col>
                                <Col md={6}>
                                    <Card.Body className="pt-1">
                                        <img src={clogo04} alt=""/>
                                        <p>A Loyalty Program designed exclusively for the Contractors of NU Vista Limited.</p>                                          
                                            
                                            <p><strong>Log on to: </strong> <a href='https://www.nipun.doublebullcement.com/' target="_blank">nipun.doublebullcement.com</a></p>
                                            <p><strong>Contact:</strong> <a href="tel:18001212828" target="_blank"> 1800 121 2828</a></p>
                                    </Card.Body>
                                </Col>
                            </Row>
                            <p><strong>Contact:</strong> <a href="tel:18004199098">1800 419 9098</a> (Toll-Free)</p>
                            <p><strong>To avail multi-specialty technical services (off-site and on-site) <br/>contact - 1800 345 6666 (Toll-free)</strong></p>
                        </Card>
                    </div>        
                </Container>
            </div>
            <Modal className="success_mb" show={show} onHide={handleClose}>
                <Modal.Body>
                    <h5>Thank you. We have received your details</h5>
                    <Button variant="success" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Body> 
            </Modal>
    </div >
    )
}

export default ContactUs;