import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from "react-helmet";

const BerjisDesai = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Berjis Desai | Independent Director | Nuvoco Vistas Corp. Ltd., NU Vista Limited</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Mr. Berjis Desai has been a member of the Board of Directors since January 3, 2017." />
    <link rel="canonical" href="https://nuvoco.com/Berjis-Desai"/>
    </Helmet>

      <div className='midpart bodPages'>
        <Container>
            <Row className="mb-5">
                 <Col md={4}>
                    <img style={{borderRadius:'15px'}} src="https://admin.nuvoco.com/public/BoardOfDirectorsDetails/Berjis.png" />
                </Col>
                <Col md={8}>
                    <h2 className='grad-heading'>Berjis Desai<br/><small>Independent Director</small></h2>
                    <p className='onetxt'>
                    Mr. Berjis Desai has been a member of the Board of Directors since January 3, 2017. He graduated from the University of Mumbai with a bachelor's degree in Law and the University of Cambridge with a master's degree in Law. Mr. Desai has a background in private client law, business law, and transactional and dispute resolution. He has been previously associated with J. Sagar Associates, Advocates, and Solicitors as a Managing Partner.</p>
                </Col>
            </Row>

        </Container>
      </div>
    </div >
  )
}

export default BerjisDesai