import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const FootprintCuttingEdge = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco Vistas Expands Footprint in Hyderabad with Cutting-Edge Ready-Mix Concrete Plant in Medchal | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Vistas Expands Footprint in Hyderabad with Cutting-Edge Ready-Mix Concrete Plant in Medchal" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/footprint-in-hyderabad-cutting-edge"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
            <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Nuvoco%20Vistas%20Expands%20Footprint%20in%20Hyderabad.pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco Vistas Expands Footprint in Hyderabad with Cutting-Edge Ready-Mix Concrete Plant in Medchal</h2>
          
          <p className='onetxt'><strong>Mumbai, January 29, 2024 : </strong>Nuvoco Vistas Corp. Ltd., India’s fifth-largest cement group, takes another significant step towards reinforcing its commitment to quality and efficiency with theinauguration of its brand new Ready-Mix Concrete Plant in the bustling metropolis of Hyderabad. The new plant is 5th in the region and is conveniently located at Medchal, expands Nuvoco's ability to meet the region's expanding need for construction materials. </p>

          <p>By establishing the Medchal plant, the company solidifies its market-leading position. This adds to its existing facilities located in Miyapur and Jeedimetla, which are within 35 km, and Uppal & Patencheru, which are 40 to 60 km away. Located near industrial, commercial, and residential developments, Medchal offers high potential for growth. The plant's strategic location ensures quick market access thereby reducing traffic disruptions and fulfilling Nuvoco's commitment to operational excellence. </p>

          <p>This state-of-the-art plant is fully equipped to meet a wide range of customer requirements, providing top-quality concrete solutions for various development projects across the region. The plant will cater to several localities within a 10-15 km radius, including Medchal, Kallakal, Shamirpet, Gundlapochampally, Kompally, Dulapally, and Kandlakoya. With a capacity of 60 Cum/Hour (M1 Grade), the facility can produce all grades of concrete, including Value Added Products (VAP).</p>
          
          <p><strong>Mr. Prashant Jha, Chief of the Ready-Mix (RMX) Concrete and Modern Building Materials business at Nuvoco Vistas</strong> expressed his excitement at the inauguration of this latest plant, stating, "The newly opened facility strengthens the company's position in Hyderabad. It also plays a vital role in shaping the dynamic construction ecosystem of Medchal and its neighbouring areas. This expansion affirms the company's commitment to meeting the changing demands of the construction sector. </p>
          
          <p>Nuvoco adheres to the highest quality and environmental standards and focuses on innovation, sustainability, and customer satisfaction. The company remains committed to contributing to the construction industry's growth and development in India.</p>

        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a cement manufacturing company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999 and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest Cement Group in terms of capacity (23.8 MMTPA) with an INR 10,586 crore total revenue from operations in FY23 and is among the leading players in East India. Nuvoco offers a diversified business portfolio under three business segments - Cement, Ready-Mix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses PAN India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (ready-to-use bagged concrete - the  first-of-itskind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to the landmark projects like Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium, Rourkela; Aquatic Gallery Science City, Ahmedabad; and Metro Railway (Delhi, Jaipur, Noida and Mumbai) among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ and ‘InstaMix’ brands, comprises construction chemicals, wall putty and cover blocks. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements.</p>
          <p>Know more about the Company on <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
          <p>For more information contact the Company at  <a href="mailto:india.communications@nuvoco.com">India.communications@nuvoco.com</a></p>
      </Container>
      </div>
    </div >
  )
}

export default FootprintCuttingEdge