import { CORPORATE, CORPORATESEARCH } from "./type";
import { API_URL } from "../config";
import axios from "axios";

const corporateFn = (data) =>{
    return{
        type: CORPORATE,
        payload: data
    };
}

export const corporateApi = (type) =>{
    let data ={};
    const title = type === 1 ? 'CorporateGovernancePrelist' : 'CorporateGovernancePostlist';
    return (dispatch) =>{
        axios.post(API_URL + title, data).then((response) =>{
            dispatch(corporateFn(response));
        });
    };
}

const corporatesearchFn = (data) =>{
    const dt = {...data, IsSuccess:1};
    return{
        type: CORPORATESEARCH,
        payload: dt
    };
}

export const corporatesearchApi = (cid, yid, mid, pid, listType) =>{
    let data ={
        category_id:cid,
        year_id: yid,
        meeting_id: mid,
        policies_id: pid,
        list_type:listType
    };
    return (dispatch) =>{
        axios.post(API_URL + 'CorporateGovernanceSearch', data).then((response) =>{
            dispatch(corporatesearchFn(response));
        });
    };
}