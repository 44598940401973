import { PAGEDATA1 } from "./type";

const initialState ={teamdata: ''};

const commonNewR = (state = initialState, action) =>{
    switch(action.type){
        case PAGEDATA1:
            return {...state, teamdata: action.payload};

        default:
            return state;
    }
}

export default commonNewR;