import React from "react";
import { Row, Col } from "react-bootstrap";
import Slider from "react-slick";

const settings = {
    autoplay:true,
    autoplaySpeed: 2000,
    dots: false,
    arrow:true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

const BodSlider = ({slideList, uri}) =>{
    return (
        <Slider {...settings}>
            {slideList.map((slide, i)=>{
                return(
                    <div key={i}>
                        <Row>
                            <Col className="pr-0">
                                <div className="bob">
                                    <img src={uri + slide.person_photo} alt='' />
                                </div>
                            </Col>
                            <Col md={7} className="pl-0">
                                <div className="bob_content">
                                    <h1>{slide.person_name}</h1>
                                    <p>{slide.person_designation}</p>
                                    <article>
                                        {slide.person_description}
                                    </article>
                                </div>
                            </Col>
                        </Row>
                    </div>
                );
            })}                    
        </Slider>
    )
}

export default BodSlider;