import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const AnniversaryJojoberaPlant = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco celebrates 30th Anniversary of Jojobera Cement Plant, Jamshedpur | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco celebrates 30th Anniversary of Jojobera Cement Plant, Jamshedpur" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/anniversary-jojobera-cement-plant"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
            <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Nuvoco%20celebrates%2030th%20Anniversary%20of%20Jojobera%20Cement%20Plant,%20Jamshedpur.pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco celebrates 30th Anniversary of Jojobera Cement Plant, Jamshedpur</h2>
          
          <p className='onetxt'><strong>Jamshedpur, March 30, 2024: </strong>Nuvoco Vistas Corp. Ltd., India’s fifth-largest cement group and a leading player in East India, celebrated <b>30th Anniversary of its Jojobera Cement Plant (JCP)</b>. Since its inception in 1994, JCP has grown exponentially and holds a significant share in Nuvoco's success story</p>

          <p>With a production capacity of 6.6 MMTPA, JCP has evolved into a state-of-the-art manufacturing facility, making it one of the largest in the Indian cement industry. JCP holds a reputation of operating as one of the model plants that showcases high operational efficiencies. Furthermore, JCP plays a crucial role in enabling Nuvoco to achieve one of the industry's best cement-to-clinker ratios of 1.8. Additionally, it reinforces its commitment to enhancing green energy usage, reflecting its dedication to the Company’s environmental agenda — <b>PROTECT OUR PLANET</b>.</p>

          <p><strong>On this momentous occasion, Jayakumar Krishnaswamy, Managing Director, Nuvoco Vistas Corp Ltd.</strong> stated, "Celebrating the 30th Anniversary of the Jojobera Cement Plant marks our continuous commitment to excellence and innovation. As a leading building materials player in East India, JCP is pivotal in strengthening Nuvoco's position across key eastern states. The plant also manufactures a wide spectrum of premium and high-quality brands such as Concreto, Concreto UNO, Duraguard, and Double Bull, serving diverse markets." </p>
          
          <p>As Nuvoco Vistas commemorates this significant milestone, the company remains committed to embracing new technologies, diversifying its product portfolio, and continuing its journey of growth and innovation</p>

         
        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a building material company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999 and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest Cement Group in terms of capacity (23.8 MMTPA) with an INR 10,586 crore total revenue from operations in FY23 and is among the leading players in East India. Nuvoco offers a diversified business portfolio under three business segments - Cement, ReadyMix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses PAN India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (ready-to-use bagged concrete - the first-of-its-kind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to the landmark projects like Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium, Rourkela; Aquatic Gallery Science City, Ahmedabad; and Metro Railway (Delhi, Jaipur, Noida and Mumbai) among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ and ‘InstaMix’ brands, comprises construction chemicals, wall putty and cover blocks. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements.</p>
          <p>Know more about the Company on <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
          <p>For more information contact the Company at  <a href="mailto:india.communications@nuvoco.com">India.communications@nuvoco.com</a></p>
      </Container>
      </div>
    </div >
  )
}

export default AnniversaryJojoberaPlant