import { PAGEDATA1 } from "./type";
import { API_URL } from "../config";
import axios from "axios";

const commonNewFn = (data) =>{
    return{
        type: PAGEDATA1,
        payload: data
    };
}

export const commonNewApi = (page_type) =>{   
    return (dispatch) =>{
        axios.get(API_URL + page_type).then((response) =>{
            dispatch(commonNewFn(response));
        });
    };
}