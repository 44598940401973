import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const HydrophobicConcreteConcretoUno = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco Vistas Introduces India’s First Ever Hydrophobic Concrete - Concreto Uno with innovative Damp Lock Formula | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Vistas Introduces India’s First Ever Hydrophobic Concrete - Concreto Uno with innovative Damp Lock Formula" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/hydrophobic-concrete-concreto-uno"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
        <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Final%20Release%20-%20Concreto%20UNo%20Hydrophobic.pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco Vistas Introduces India’s First Ever Hydrophobic Concrete - Concreto Uno with innovative Damp Lock Formula</h2>
       
          <p className='onetxt'><strong>Mumbai, July 23, 2024:</strong> Nuvoco Vistas Corp. Ltd., India's fifth-largest cement group, launched Concreto Uno - Hydrophobic Concrete, a breakthrough in the building materials industry. With its advanced hydrophobic properties combined with the innovative Damp-lock Formula, this unique concrete redefines construction standards.</p>

          <p>Concreto Uno – Hydrophobic Concrete features a unique blend of admixtures and a specialized mix design that prevents the formation of capillaries within the concrete, resulting in significantly lower permeability compared to standard-grade concrete. This waterproof concrete enhances the overall strength and durability of structures, significantly extending their lifespan.</p>

          <p>In addition, Concreto Uno's Damplock formula reduces costs in construction projects by minimizing the need for costly waterproofing membranes and coatings. Furthermore, it prevents dampness, which preserves the aesthetic quality of buildings. This product is ideal for structural consultants, architects, contractors, engineers, and project management companies (PMCs). It is widely available across India through Nuvoco’s RMX plants.</p>

          <p><strong>Prashant Jha, Chief Ready-Mix Concrete and Modern Building Materials Officer, Nuvoco Vistas Corp Ltd.,</strong>, said, “Concreto Uno Hydrophobic Concrete is a game-changing hydrophobic concrete in the building materials industry. This means lower maintenance costs and greater peace of mind for our customers since their investments are better protected against water damage, enhancing the longevity and aesthetic appeal of structures. Also, this is a step toward sustainable building practices, making structures capable of withstanding harsh weather conditions.</p>

          <p><strong>Chirag Shah, Head of Marketing, Innovation and Sales Excellence, Nuvoco Vistas Corp. Ltd., stated:</strong> Concreto Uno Hydrophobic Concrete is a testament to our drive for innovation and excellence in building materials. By integrating cutting-edge hydrophobic technology with Damp-lock Formula, we are not only addressing the challenges of water permeability but also elevating construction standards. We are excited to bring this revolutionary concrete to the market, reaffirming our leadership in the industry.”</p>
        
        <p><strong>About Nuvoco</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a building materials company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999 and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest cement group in terms of capacity (25 MMTPA), with INR 10,733 crore in total revenue from operations in FY24. It is among the leading players in East India. Nuvoco offers a diversified business portfolio in three business segments: Cement, Ready-Mix Concrete (RMX), and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes - Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses a pan-India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (ready-to-use bagged concrete - the first-of-its-kind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to landmark projects like the Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium (Rourkela), Aquatic Gallery Science City (Ahmedabad), Metro Railway (Delhi, Jaipur, Noida and Mumbai), and among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ brands, comprises construction chemicals, tile adhesives, wall putty and cover blocks. Through the NABLaccredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements.</p>
          <p>Know more about the Company on to <a href="https://www.nuvoco.com">www.nuvoco.com</a></p>
          <p>For more information, contact the Company at <a href="mailto:india.communications@nuvoco.com">India.communications@nuvoco.com</a></p>
        </Container>
      </div>
    </div >
  )
}

export default HydrophobicConcreteConcretoUno