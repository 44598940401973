import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const FinancialResultQ3FY24 = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Financial result Q3 FY24 | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="NUVOCO Vistas announces its financial results for Q3 FY24" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/financial-result-q3-fy24"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
        <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Final-Q3FY2023-24-Press-Release-29Jan24.pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>NUVOCO Vistas announces its financial results for Q3 FY24</h2>
          
          <ul>
            <li>Consolidated revenue at Rs. 2,421 </li>
            <li>Consolidated EBITDA increased 55% YoY to Rs. 421 crores</li>
            <li>Consolidated EBITDA per ton of Rs. 1,048, highest in the past 10 quarters</li>
            <li>Net debt reduced Rs. 632 crores YoY to Rs. 4,533 crores </li>
          </ul>
          <p className='onetxt'>
            <strong>Mumbai, January 29, 2024: </strong>Nuvoco Vistas Corp. Ltd., a leading building materials Company in India, announced its unaudited financial results for the quarter ended December 31, 2023. With 25 MMTPA of combined installed capacity, Nuvoco Vistas Corp. Ltd. is the fifth largest cement group in India and amongst the leading cement players in East India. </p>

          <p>The consolidated cement sales volume for the Company stood at 4.02 MMT in Q3 FY24. Consolidated revenue from operations during the same period stood at Rs. 2,421 crores. Consolidated EBITDA improved by 55% YoY to Rs. 421 crores.</p>

          <p>The Company has successfully commissioned a 1.2 MMTPA Cement Mill at Haryana Cement Plant, taking the overall cement capacity to 25 MMTPA. This expansion will boost production volumes in the Northern region and enhances the ability to provide a diverse range of unique product offering to customers.</p>

          <p>Premium products continue to remain a key focus area for the Company and have contributed significantly, with a 36% share of the company's cement trade volumes in Q3 FY24. Building on this leadership, we recently launched brand new marketing campaign for Duraguard Franchisee - 'Seedhi Baat Hai, Duraguard Khaas Hai', underscoring its unique features and reinforcing customer trust. Additionally, the Company also introduced engaging brand activation activity "Sabse Khaas Sarpanch", showcasing impactful stories of Sarpanches contributing to village development. These initiatives aim to boost market position and elevate the brand. </p>

          <p>The Company remains committed to its sustainability agenda – <b>Protect Our Planet</b>. The Company has amongst the lowest carbon emissions at 462 kg CO2 per ton of cementitious materials1. The Company achieved significant improvement of 5% YoY in alternate fuel rate (AFR) to 14% in Q3 FY24, amongst the best in the industry.  </p>

          <p>Commenting on the performance of the Company, Mr. Jayakumar Krishnaswamy, Managing Director, Nuvoco Vistas Corp. Ltd. stated, “Despite the challenging demand environment, the Company continues to deliver strong growth in EBITDA. We achieved the highest EBITDA per ton compared to the past ten quarters; a testament to our operational excellence, focusing on cost efficiency and value-led growth.” </p>

         <p>Furthermore, he added, “With additional capacity in the Haryana Cement Plant, we are set to seize the opportunities. We continue to emphasize on quality and innovation and secure a larger market share in the North while retaining our leadership position in the East. On the Ready-Mix Concrete business, we have commissioned five new plants in the current fiscal, bringing our total to 56 plants pan-India.”   </p>
        
        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a cement manufacturing company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999 and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest Cement Group in terms of capacity (25 MMTPA) with an INR 10,586 crore total revenue from operations in FY23 and is among the leading players in East India. Nuvoco offers a diversified business portfolio under three business segments - Cement, Ready-Mix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses PAN India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (ready-to-use bagged concrete - the first-of-its-kind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to the landmark projects like Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium, Rourkela; Aquatic Gallery Science City, Ahmedabad; and Metro Railway (Delhi, Jaipur, Noida and Mumbai) among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ and ‘InstaMix’ brands, comprises construction chemicals, wall putty and cover blocks. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements. </p> 
          <p>Know more about the Company on <a href="https://www.nuvoco.com">www.nuvoco.com</a></p>
          <p>For more information, contact the Company at <a href="mailto:investor.relations@nuvoco.com">investor.relations@nuvoco.com</a></p>
        </Container>
      </div>
    </div >
  )
}

export default FinancialResultQ3FY24