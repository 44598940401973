import React from 'react';
import Container from 'react-bootstrap/Container';
import banner from '../assets/images/aboutus-banner.png'
import bannermobile from '../assets/images/aboutus-banner-mobile.jpg'
import aboutBannerLogo from '../assets/images/aboutba-logo.png'
import abouticon1 from '../assets/images/about-icon1.jpg'
import abouticon2 from '../assets/images/abouticon2.jpg'
import abouticon3 from '../assets/images/abouticon3.jpg'
import abouticon4 from '../assets/images/abouticon4.jpg'
import abouticon5 from '../assets/images/abouticon5.jpg'
import buss1 from '../assets/images/business1.jpg'
import buss2 from '../assets/images/business2.jpg'
import buss3 from '../assets/images/business3.jpg'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Helmet } from "react-helmet";

const AboutUs = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>About Us | Nuvoco vistas Leading Cement Manufacturer in India</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Vistas is one of the leading brand in producing cement, ready mix concrete and modern building materials and rated as fifth largest cement group in India" />
    <link rel="canonical" href="https://nuvoco.com/about-us"/>
    </Helmet>

      <div className="banner text-center position-relative">
        <img className='desktopbannerinner' src={banner} alt="" />
        <img className='mobilebannerinner' src={bannermobile} alt="" />
        <article className='position-absolute text-center'>
          <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
          <h1 className="text-uppercase">about us</h1>
        </article>
        <div className='breadouter'>
          <div className='container'>
            <Breadcrumb className='mb-0 py-2'>
              <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
              <Breadcrumb.Item active href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                About Us
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div className='midpart'>
        <Container>
          <h2 className='grad-heading'>Nuvoco at a Glance</h2>
           {/*<p className='onetxt'>
            <strong>
            The fifth-largest cement group in India and the leading cement player in East India, in terms of capacity</strong></p>*/}
          <p>Nuvoco Vistas Corporation Limited is a building materials company with a vision to build a <b>Safer, Smarter, and Sustainable world</b> and with a mission to become a <b>Leading Building Materials Company delivering superior performance.</b> </p>
          <p>Nuvoco is India’s 5th largest cement group in terms of capacity (25 MMTPA) and is the leading player in East India. It offers a diversified business portfolio in three business segments:<br/><b>Cement, Ready-Mix Concrete (RMX), and Modern Building Materials (MBM).</b></p>
          <p>Nuvoco is currently present at 85 locations across the country, including <b>11 cement manufacturing plants, 58 RMX plants, and 16 offices</b> — including the Head Office, CDIC, and Regional Sales Offices. Our strategically positioned facilities enable us to provide a diverse range of premium products to clients across India.</p>
          <Row className='abt_mt topbx my-5'>
            <Col>
              <Card className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={abouticon1} />
                </div>
                <Card.Body>
                  <Card.Title>11 Cement Plants</Card.Title>
                  <Card.Text>
                  25 MMTPA
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={abouticon2} />
                </div>
                <Card.Body>
                  <Card.Title>Captive Power Plants</Card.Title>
                  <Card.Text>
                    150 MW 
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={abouticon3} />
                </div>
                <Card.Body>
                  <Card.Title>Waste Heat Recovery</Card.Title>
                  <Card.Text>
                    44.7 MW
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={abouticon4} />
                </div>
                <Card.Body>
                  <Card.Title>Solar Power Plants</Card.Title>
                  <Card.Text>
                    5.3 MWp
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="text-center">
                <div className='imgout'>
                  <Card.Img variant="top" src={abouticon5} />
                </div>
                <Card.Body>
                  <Card.Title>Ready-Mix Concrete Plants</Card.Title>
                  <Card.Text>
                    58
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <p>Nuvoco has a dedicated NABL-accredited Construction Development and Innovation Centre (CDIC) located in Mumbai. It serves as the incubation centre for innovative products and can conduct over 100 mechanical tests. It also offers third-party external testing services, offering products and solutions that have passed the highest standards and holds global validation.</p>
         
          
          <h2 className='grad-heading mt-5'>Our Businesses</h2>
          <p>Our product lineup comprises a diverse array of offerings including Cement, <b>Ready-Mix Concrete (“RMX”), and Modern Building Materials (“MBM”)</b>. These are meticulously crafted to meet the dynamic needs of our customers and evolving market trends. This year, we diversified our portfolio and unveiled a new look for our products bringing Nuvoco Logo in the front of our packaging, emphasising the strong bond between the mother brand and its sub-brands. </p>
          <p>
          <strong>Cement</strong><br></br>
          Renowned for its premium quality, our cement manufacturing portfolio encompasses 4 broad categories of products, namely Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC), and Portland Composite Cement (PCC) that adhere to BIS standards and each offering exceptional quality and performance. Nuvoco has a wide portfolio of brands like Concreto, Duraguard, DoubleBull, PSC, Nirmax and Infracem that provide a wide range of products.
</p><br></br>
<p>
          <strong>Ready Mix Concrete (RMX)</strong><br></br>
          Our Ready-Mix Concrete (“RMX”) offerings, comprising products under 5 franchise brands, cater to a wide range of consumers, ranging from commercial enterprises to individual home builders. As a leading RMX provider, we are the preferred partner for developers, contractors, architects, private clients as well as government projects, offering advanced concrete solutions that focus on high-grade construction. Our goal is to strengthen and deepen the trust of our customers through the quality and innovation of our products.
</p><br></br>

<p>
          <strong>Modern Building Materials (MBM)</strong><br></br>
          The Modern Building Materials business is a key differentiator for Nuvoco. ​Our products including  Water-proofing solutions, Surface preparation solutions, Fixing solutions, Repair solutions and Ancillary solutions are marketed and sold under the brand Zero M brands.</p><br></br>

          
          <Row className='bussbx my-5'>
            <Col md={4} className='mb-3'>
              <Card className="pt-0">
              <a href='/top-cement-company'><Card.Img variant="top" src={buss1} /></a>
                <Card.Body>
                  <a href='/top-cement-company'><Card.Title className='mb-0'>Cement</Card.Title></a>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className='mb-3'>
              <Card className="pt-0">
              <a href='/concrete'><Card.Img variant="top" src={buss2} /></a>
                <Card.Body>
                <a href='/concrete'><Card.Title className='mb-0'>Ready-Mix Concrete</Card.Title></a>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="pt-0">
              <a href='/modern-building-materials'><Card.Img variant="top" src={buss3} /></a>
                <Card.Body>
                <a href='/modern-building-materials'><Card.Title className='mb-0'>Modern Building Materials</Card.Title></a>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div >
  )
}

export default AboutUs