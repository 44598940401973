import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from "react-helmet";

const JayakumarKrishnaswamy = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Jayakumar Krishnaswamy | Managing Director | Nuvoco Vistas Corp. Ltd.</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Mr. Jayakumar Krishnaswamy is the Managing Director of our Company." />
    <link rel="canonical" href="https://nuvoco.com/Jayakumar-Krishnaswamy"/>
    </Helmet>

      <div className='midpart bodPages'>
        <Container>
            <Row className="mb-5">
                 <Col md={4}>
                    <img style={{borderRadius:'15px'}} src="https://admin.nuvoco.com/public/BoardOfDirectorsDetails/jaykumar.png" />
                </Col>
                <Col md={8}>
                    <h2 className='grad-heading'>Jayakumar Krishnaswamy<br/><small>Managing Director</small></h2>
                    <p className='onetxt'>
                    Mr. Jayakumar Krishnaswamy is the Managing Director of our Company. He has been on the Board since September 17, 2018. He holds a bachelor's degree in engineering (mechanical) from University of Delhi. He has experience across FMCG and paint and coating industry. He has previously been associated with Hindustan Unilever Limited and Akzo Nobel India Limited.</p>
                </Col>
            </Row>

        </Container>
      </div>
    </div >
  )
}

export default JayakumarKrishnaswamy