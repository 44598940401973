import React from "react";

const AreaOfApplication = ({title, desc, product, imgUrl, img}) =>{
    var imgList = JSON.parse(product.area_of_application_img);
    var titleList = JSON.parse(product.area_of_application_img_title);
    return(
        <div className="area_app">
            <h2 className='grad-heading mt-5 pt-4'>{title}</h2>
            <p className="con_pt">
                {desc}
            </p>

            <div className="concreto row newAreaCss">
                <div className="col-md-4 d-flex align-items-center">
                    <img className="pro_img" src={imgUrl + img} alt='' />
                </div>
                <div className="col-md-8 d-flex align-items-center">
                    <ul className="areaCss">
                        {imgList.map((item, index) => {
                            return(
                                <li key={item}>
                                    <span className="wow fadeInUpBig">{titleList[index]}</span> <img src={imgUrl+item} alt='' />
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
            {/* <div className="concreto minHeight500 text-center">
                <div className="productimginnerbox"><img className="pro_img" src={imgUrl + img} alt='' /></div>
                <ul className="areaCss">
                {imgList.map((item, index) => {
                    return(
                        <li key={item}>
                            <span className="wow fadeInUpBig">{titleList[index]}</span> <img src={imgUrl+item} alt='' />
                        </li>
                    );
                })}
                </ul>
            </div> */}
        </div>
    )
}

export default AreaOfApplication;