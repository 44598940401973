import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, Button, Form, Alert, Card } from 'react-bootstrap';
import { Helmet } from "react-helmet";

import banner from '../assets/images/CSRBanner.png';
import bannermobile from '../assets/images/CSRBannerMobile.png';

import aboutBannerLogo from '../assets/images/socialIcon.png';
import socialbicon1 from '../assets/images/socialbicon1.png'
import socialbicon2 from '../assets/images/socialbicon2.png'
import socialbicon3 from '../assets/images/socialbicon3.png'
import socialbicon4 from '../assets/images/socialbicon4.png'
import socialbicon5 from '../assets/images/socialbicon5.png'
import Eshiksha from '../assets/images/Eshiksha.png'
import ShikshitSunderhattu from '../assets/images/Shikshit-Sunderhattu.jpg'
import AanganTara from '../assets/images/Swasth-Matritva.jpg'
import ProjectTara from '../assets/images/Project-Tara.png'
import GirlsHealthGroup from '../assets/images/Girls-Health-Group.jpg'
import MobileMedicalSevices from '../assets/images/Mobile-Medical-Sevices.png'
import JalSanchay from '../assets/images/Jal-Sanchay.png'
import TreePlantation from '../assets/images/Tree-Plantation.png'
import akriti from '../assets/images/akriti.png'
import daksh from '../assets/images/daksh.png'
import nirmaanbahini from '../assets/images/nirmaanbahini.jpg'
import ProjectSamriddhi from '../assets/images/ProjectSamriddhi.png'
import samridhi from '../assets/images/samridhi.jpg'
import NuvoMason from '../assets/images/Nuvo-Mason.png'
import healthpolicy from '../assets/images/Health_and_Safety_Policy.pdf';
import smart1 from '../assets/images/smart.jpg';
import smart2 from '../assets/images/smart1.jpg';

// import BeforeAfterSlider from 'react-before-after-slider'
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import BeforeImg from '../assets/images/Jal-Sanchay-Before.png';
import AfterImg from '../assets/images/Jal-Sanchay-After.png';
import arroing from '../assets/images/arrofull.png';

import pdfIcon from '../assets/images/pdf_icon.png';
import YogaSocial from '../assets/images/yoga-social.jpg';
import SolarInHospital from '../assets/images/SolarBackupinhospitals.png';
import ImpactAssessmentReport from '../assets/pdf/Impact-assessment-Final-Report-Nuvoco-Deck-26Apr24.pdf'
import ImpactAssessmentReportImg from '../assets/images/ImpactAssessmentReport2024.png'
import Slider from "react-slick";
const settings = {
  //autoplay:true,
  autoplaySpeed: 2000,
  dots: false,
  arrow: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  touchMove:false,
};


const Social = () => {
  const [IsTab, setTabType] = useState(3);

  const setTab = (id) => {
    setTabType(id);
  }

const FIRST_IMAGE = {
  imageUrl: BeforeImg
};
const SECOND_IMAGE = {
  imageUrl: AfterImg
};
const delimiterIconStyles = {
  width: '40px',
  height: '40px',
  backgroundSize: 'cover',
  borderRadius: 'none',
  // backgroundImage: 'url(../../public/left-right-icon.png)'
}

  return (
    <div className="socialpage enviromentPage">
                <Helmet>
                  <title>Corporate Social Responsibility & Sustainability | Cement & Concrete Industry</title>
                  <meta name="keywords" description="" />
                 <meta name="description" content="Browse Nuvoco CSR achievements committed towards sustainable development of environment programs that fall under 5 pillars of Health, Safety, Education, Employability developing Infrastructure" />
                </Helmet>
      <div className="banner text-center position-relative">
        <img className='desktopbannerinner' src={banner} alt="" />
        <img className='mobilebannerinner w-100' src={bannermobile} alt="" />
        <article className='position-absolute text-center'>
          <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
          <h1 className="text-uppercase">Social</h1>
        </article>
        <div className='breadouter'>
          <div className='container'>
            <Breadcrumb className='mb-0 py-2'>
              <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>
                Social
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>


      <div className='midpart'>
        <Container>
        <Row className=''>
          <h2 className='grad-heading'>Corporate Social Responsibility</h2>
          <p>Our social engagement strategy integrates industrial expertise with respect for and inclusion of local communities, cultures, and environmental conservation. We aim to create meaningful change through partnerships with neighbouring communities, enhancing their quality of life and our shared value. Aligned with the United Nations Sustainable Development Goals, our CSR initiatives focus on improving the communities where we operate or those that influence our plant operations. Instead of continually adding new programs, we concentrate on strengthening existing ones, leveraging them to uplift society through our five thematic pillars.</p>
          <p>
          </p>
</Row>
          <Row className='py-t text-center'>
            <div className="md-6">
            <Button onClick={() => setTab(3)} variant="success" className={IsTab === 3 ? 'active btn_lg' : 'btn_lg'}>Saksham Bharat</Button>            
            <Button onClick={() => setTab(4)} variant="success" className={IsTab === 4 ? 'active btn_lg' : 'btn_lg'}>Sangrahit Bharat</Button>
            <Button onClick={() => setTab(1)} variant="success" className={IsTab === 1 ? 'active btn_lg' : 'btn_lg'}>Shikshit Bharat</Button>
            </div>
            <div className="md-6">
            <Button onClick={() => setTab(2)} variant="success" className={IsTab === 2 ? 'active btn_lg' : 'btn_lg'}>Swasth Bharat</Button>
            <Button onClick={() => setTab(5)} variant="success" className={IsTab === 5 ? 'active btn_lg' : 'btn_lg'}>Sanrachit Bharat</Button>
            </div>
            {(IsTab === 1 && (
              <Container>
                <div className="card_round mt-5">
                  <Row className="text-left-style">
                    <Col md={12}>
                      <h2 className='grad-heading'>Shikshit Bharat</h2>
                      <p>Our Shikshit Bharat initiatives aim to create a positive impact in education through improved infrastructure and technology. By supporting tribal schools and investing in digital learning, Nuvoco is working towards a future where education is accessible and transformative. These efforts empower individuals with the skills needed for success in a digital world, contributing to a brighter and more educated India. This initiative goes beyond traditional education by addressing fundamental needs such as access to clean drinking water, improved sanitation through better toilets, consistent electricity supply, and essential teaching aids. By focusing on these critical aspects, Nuvoco aims to create an environment conducive to effective student learning and holistic development.</p>

                      <div className="slidersection">
                        <Slider {...settings}>
                          {/* <Card>
                            <Card.Img variant="top" src={Eshiksha} />
                            <Card.Body>
                              <p><strong>E-Shiksha</strong> – An initiative to improve the overall quality of education and promote joyful learning by installing Smart Classes in Government schools.</p>
                            </Card.Body>
                          </Card>
                          <Card>
                            <Card.Img variant="top" src={ShikshitSunderhattu} />
                            <Card.Body>
                              <p><strong>Shikshit Sunderhattu</strong> – Through this initiative, we established ‘Birsa Pratik Vidyalaya’ to educate the tribal children of the Sunderhattu and Sarenbera villages from Nursery to Class 3. Currently, a total of 115 students are studying in the school and have shaped the lives of more than 500 tribal children by bringing them into mainstream education.
                              <strong> Shikshit Sunderhattu has been a Winner of FICCI CSR Jury Award 2018-19 for Education.</strong></p>
                            </Card.Body>
                          </Card> */}
                          <Card>
                            <Card.Img variant="top" src={Eshiksha} />
                            <Card.Body>
                              <p><strong>SMART Class Scheme</strong> – The SMART Class scheme is designed to support youth in higher education. With operations in <b>48 government schools</b> across four plant locations, this scheme has already significantly <b>impacted 12,000+ children</b>.</p>
                              {/* <ul style={{textAlign:'left'}}>
                                  <li>Students Benefitted – 12,000+</li>
                                 </ul> */}
                            </Card.Body>
                          </Card>
                       
                        </Slider>

                      </div>


                    </Col>
                  </Row>
                </div>
              </Container>
            )) || ''}



            {(IsTab === 2 && (
                   <Container>
                   <div className="card_round mt-5">
                     <Row className="text-left-style">
                       <Col md={12}>
                         <h2 className='grad-heading'>Swasth Bharat</h2>
                         <p>Through Swasth Bharat initiatives, our goal is to enrich people's lives by providing access to essential primary healthcare services, improved sanitation facilities, and clean drinking water for communities.</p>
   
                         <div className="slidersection">
                           <Slider {...settings}>
                              <Card>
                                <Card.Img variant="top" src={MobileMedicalSevices} />
                                <Card.Body>
                                  <p><b>Mobile Medical Services</b> – During the year, we started our Mobile Medical Services at the Jojobera and Arasmeta Cement Plants, which provided free consultations and medicines to <b>9,236 people from 31 villages</b>. Additionally, we have completely renovated a public health centre near the Risda Cement Plant.</p>
                                </Card.Body>
                              </Card>
                              <Card>
                               <Card.Img variant="top" src={ProjectTara} />
                               <Card.Body>
                                 <p><b>Project TARA</b> - IIn addition to school-based initiatives, Nuvoco’s commitment to education extends to early childhood development through the TARA project. This project aims to develop Anganwadi centres as preferred hubs for accessing Maternal and Child Health (MCH) services alongside early childhood education. Currently operational in <b>32 Anganwadi Centres</b> near Panagarh Cement Plant, the TARA project has positively impacted <b>1,300+ adolescent girls from 16 villages</b>.</p>
                                 {/* <ul style={{textAlign:'left'}}>
                                  <li>Adolescent Girls Impacted – 1,300+ </li>
                                  <li>People benefitted – 35,000+ </li>
                                 </ul> */}
                               </Card.Body>
                             </Card>
                             {/* <Card>
                               <Card.Img variant="top" src={GirlsHealthGroup} />
                               <Card.Body>
                                 <p><b>Girls Health Group</b> – Focusing on adolescent girls’ health under the aegis of Swasth Bharat, one of its major activities is to promote awareness on adolescent girls’ health and hygiene. Through this program we promote use of sanitary pads to maintain hygiene during menstruation and organize talk shows with doctors where girls can freely share their health problems and seek advice. Through this program we have reached to more than 2000 girls across 70 villages. Additionally, we were able to provide preventive healthcare facilities, free Medical and Health Check-up camps and spread awareness on Water Sanitation and Hygiene in schools.</p>
                               </Card.Body>
                             </Card> */}

                           </Slider>
   
                         </div>
   
   
                       </Col>
                     </Row>
                   </div>
                 </Container>
            )) || ''}

            {(IsTab === 3 && (
                   <Container>
                   <div className="card_round mt-5">
                     <Row className="text-left-style">
                       <Col md={12}>
                         <h2 className='grad-heading'>Saksham Bharat</h2>
                         <p>In India, where a significant portion of the population relies on manual labour and traditional skills, the need for skill development is crucial. The country’s demographic dividend, with a large young population, presents an opportunity to harness this workforce for economic growth. By focusing on skill development, especially in sectors like construction, Nuvoco is providing training and creating pathways to meaningful employment through various initiatives.</p>
   
                         <div className="slidersection">
                           <Slider {...settings}>
                             <Card>
                               <Card.Img variant="top" src={NuvoMason} />
                               <Card.Body>
                                 <p><b>Nuvo Mason</b> – Our Nuvo Mason programme stands at the epicentre of skill development within the building materials and construction sector. This programme is certified by NSDC (National Skill Development Corporation) and offers more than <b>300 hours (about 2 weeks)</b> of training, making it one of the few programmes in the industry with such extensive training. The programme is designed to uplift existing masons through the Recognition of Prior Learning (RPL) initiative and specialised programmes like mason tiling. It has witnessed remarkable growth, with an impressive <b>450+ trainees benefiting</b> from the programme during the year. This flagship initiative of Nuvoco offers extensive training and certification opportunities, equipping individuals with the necessary skills for the construction industry. The programme issues certificates with QR codes, facilitating employment opportunities in government jobs or other schemes, while also providing a track and trace mechanism for the trainees and the government. </p>
                                 {/* <ul style={{textAlign:'left'}}>
                                  <li>Trainees Benefitted – 450+</li>
                                  <li>Training Hours – 300+</li>
                                 </ul> */}
                               </Card.Body>
                             </Card>
                             <Card>
                               <Card.Img variant="top" src={akriti} />
                               <Card.Body>
                                 <p><b>Aakriti Women Empowerment Programme</b> - Akriti is our self-help group initiative to empower women in industrial stitching or encourage them to be self-employed. The programme aims to augment their income, enable them to become entrepreneurs, and integrate them into mainstream society. With a turnover reaching <b>25 lakhs in FY 2023-24</b>, the Aakriti programme has enabled artisans to earn between INR 5,000 to 7,000 per month.</p>
                               </Card.Body>
                             </Card>
                             <Card>
                               <Card.Img variant="top" src={daksh} />
                               <Card.Body>
                                 <p><b>Project Daksh</b> – Project Daksh, our collaborative effort with Shahi Exports, focuses on providing industrial stitching training to women in rural areas. It has successfully provided training in industrial stitching and enhanced soft and social skills, opening employment opportunities for participants. The project has contributed to changing attitudes towards women’s work, boosting confidence, and promoting self-reliance among its beneficiaries. Through Project Daksh, we have trained over<b>400 girls. 120+ are employed</b> at Shahi Exports unit in Bengaluru, and <b>200+</b> have become successful entrepreneurs. </p>
                                 {/* <ul style={{textAlign:'left'}}>
                                  <li>Girls Employed at Shahi Exports’ unit in Bangalore – 80+</li>
                                  <li>Girls turned into entrepreneurs – 200+</li>
                                 </ul> */}
                               </Card.Body>
                             </Card>
                             <Card>
                               <Card.Img variant="top" src={ProjectSamriddhi} />
                               <Card.Body>
                                 <p><b>Project Samriddhi</b> – Project Samriddhi has been a transformative programme supporting a farm producer company in West Bengal. Focused on agricultural activities such as mushroom and paddy cultivation, the programme has significantly improved women’s livelihoods in the plant areas. Through Project Samriddhi, women farmers have substantially increased their annual income by INR <b>60,000-80,000 per year</b>. </p>
                                 {/* <ul style={{textAlign:'left'}}>
                                  <li>Women Farmers Engaged in Agricultural Activities – 550+</li>
                                 </ul> */}
                               </Card.Body>
                             </Card>
                           </Slider>
   
                         </div>
   
   
                       </Col>
                     </Row>
                   </div>
                 </Container>
            )) || ''}


            {(IsTab === 4 && (
                   <Container>
                   <div className="card_round mt-5">
                     <Row className="text-left-style">
                       <Col md={12}>
                         <h2 className='grad-heading'>Sangrahit Bharat</h2>
                         <p>In an era where environmental sustainability is paramount, Nuvoco understands the critical importance of sustainable practices for the well-being of our planet and future generations. Through the Sangrahit Bharat Programme, we foster renewable energy, water conservation, waste management, and biodiversity, embodying responsible corporate citizenship.</p>
   
                         <div className="slidersection">
                           <Slider {...settings}>
                             <Card>
                               <Card.Img variant="top" src={TreePlantation} />
                               <Card.Body>
                                 <p><b>Promoting Rural Tree Plantations</b> - Our dedication to environmental stewardship extends to 1,300+ promoting greenery in rural areas by planting saplings. This effort goes beyond just planting trees; it represents a commitment to enhancing biodiversity, improving air quality, and fostering a healthier environment for local communities. In FY 2023-24 we have planted more than <b>6,500 saplings</b> under this initiative.</p>
                                 {/* <ul style={{textAlign:'left'}}>
                                  <li>Saplings planted under CSR - 6,500+</li>
                                 </ul> */}
                               </Card.Body>
                             </Card>
                            
                             <Card>
                               {/* <Card.Img variant="top" src={JalSanchay} /> */}
                             
                              <ReactBeforeSliderComponent
                                  firstImage={SECOND_IMAGE}
                                  secondImage={FIRST_IMAGE}
                                  delimiterColor='#fff'
                                  delimiterIconStyles={delimiterIconStyles}
                                  className="CustomArrow"
                              />
                               <Card.Body>
                                 <p><b>Project Jal Sanchay</b> - To address water scarcity and promote sustainable water management, we launched Project Jal Sanchay. Through this initiative, <b>68,700 cubic metres (CuM)</b> of pond-deepening activities were carried out in 9 villages. This endeavour resulted in benefiting local communities with increased access to water for agriculture, domestic use, and other essential needs.</p>
                                 {/* <ul style={{textAlign:'left'}}>
                                  <li>Cubic Meters of increased water capacity - 68,700</li>
                                 </ul> */}
                               </Card.Body>
                             </Card>
                            
                          
                           </Slider>
   
                         </div>
   
   
                       </Col>
                     </Row>
                   </div>
                 </Container>
            )) || ''}


            {(IsTab === 5 && (
                   <Container>
                   <div className="card_round mt-5">
                     <Row className="text-left-style">
                       <Col md={12}>
                         <h2 className='grad-heading'>Sanrachit Bharat</h2>
                         <p>In many rural areas, inadequate infrastructure hampers daily life and economic progress. Nuvoco recognizes these challenges and, through the Sanrachit Bharat Programme, has taken decisive steps to improve the quality of life by developing community infrastructure through the construction and repair of roads and providing safe drinking water through pond distillation and rainwater harvesting. We were also able to refurbish the school buildings, build community centers, improve drainage systems in villages and conduct tree plantations in communities and villages where we operate. In FY 2023-24 Nuvoco installed <b>101 solar lights, 18 LED lights, and 12 CCTV cameras</b> near various plants.</p>
                        {/* <ul style={{textAlign:'left'}}>
                          <li>Installed 101 solar lights, 18 LED lights, and 12 CCTV cameras near various plants</li>
                          <li>Constructed community building in Parsabhader and Risda to foster social cohesion </li>
                        </ul> */}
                         <div className="slidersection">
                         <Slider {...settings}>
                             {/* <Card>
                               <Card.Img variant="top" src={YogaSocial} />
                               <Card.Body>
                                 <p></p>
                               </Card.Body>
                             </Card>
                             */}
                             <Card>
                               <Card.Img variant="top" src={SolarInHospital} />
                               <Card.Body>
                                 <p><b>Solar Power Revolutionizes Village Hospital</b> - In a village near our Panagarh Cement Plant, a local hospital faced significant challenges due to unreliable electricity, hindering its ability to perform basic surgeries and deliver essential healthcare services. As part of our Swasth Bharat and Sanrachit Bharat Programmes, we addressed this issue by powering the hospital with a 5KVA off-grid solar power plant backup to provide uninterrupted power supply in case of emergencies. This initiative not only resolved the power challenges but also transformed the hospital’s operations, elevating medical standards in the village and enabling improved patient care and the ability to conduct surgeries.</p>
                               </Card.Body>
                             </Card>
                            
                          
                           </Slider>
                           </div>
                       
                       </Col>
                     </Row>
                   </div>
                 </Container>
            )) || ''}

          </Row>
          <Row>
            <h2 className='grad-heading'>CSR Impact Assessment Report 2024</h2>
            <div className="text-center"> 
              <a href={ImpactAssessmentReport} target="_blank">
                  <img style={{maxWidth:'600px', margin:'20px auto 40px auto', width:'100%'}} src={ImpactAssessmentReportImg} alt='CSR Impact Assessment Report 2024' />
              </a>
            </div>
          </Row>
          </Container>
          <Container>
          

          {/* <Row>
                        <h2 className='grad-heading'>Health and Safety</h2>
                        <p>
                        Nuvoco is committed to ensuring the well-being, health, and safety of its employees, associates, and all other stakeholders. To achieve the goal of 'ZERO HARM', we continue to invest in initiatives that will increase the safety of our workplace.
                        </p>
                        <p>Towards that end, Nuvoco has established a robust Health, Safety & Environment Management System (HSEMS) that covers all aspects of a strong H&S system and provides a framework for identifying and minimizing health, safety, and environmental risks in order to promote a safe and healthy working environment and a welcoming workplace. By implementing HSEMS, injury, occupational illness, and ecological damage risks are reduced, thus improving overall performance and compliance with legal, regulatory, and other requirements. </p>
                       <p>At Nuvoco, we adhere to 5S, the Japanese system of organizing spaces so that work can be performed efficiently, effectively, and safely in our manufacturing facilities and offices. Our manufacturing units conduct regular training on LOTOTO (Lock Out, Tag Out, Try Out), FLRA (Field Level Risk Assessment), Road Safety, SLDP (Safety Leadership Development Program), Working at height, Scaffolding Rescue and Machine Guarding to improve awareness of the H&S process.</p>
                   
                      </Row> */}

                      {/* <Row className='topbx'>
                        <div className="m5section">
                          <div className="sticakerm5">5S</div>
                          <div className="droplinemain">
                            <img className='arroing' src={arroing} alt="" /></div>
                        </div>

                        <div className="d-flex justify-content-center row">
                          <Col>
                            <Card className="text-center">
                              <div className='imgout'>
                                <Card.Img variant="top" src={socialbicon1} />
                              </div>
                              <Card.Body>
                                <Card.Title>Sort</Card.Title>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col>
                            <Card className="text-center">
                              <div className='imgout'>
                                <Card.Img variant="top" src={socialbicon2} />
                              </div>
                              <Card.Body>
                                <Card.Title>Set In Order</Card.Title>

                              </Card.Body>
                            </Card>
                          </Col>
                          <Col>
                            <Card className="text-center">
                              <div className='imgout'>
                                <Card.Img variant="top" src={socialbicon3} />
                              </div>
                              <Card.Body>
                                <Card.Title>Shine</Card.Title>

                              </Card.Body>
                            </Card>
                          </Col>

                          <Col>
                            <Card className="text-center">
                              <div className='imgout'>
                                <Card.Img variant="top" src={socialbicon4} />
                              </div>
                              <Card.Body>
                                <Card.Title>Standardize</Card.Title>

                              </Card.Body>
                            </Card>
                          </Col>

                          <Col>
                            <Card className="text-center">
                              <div className='imgout'>
                                <Card.Img variant="top" src={socialbicon5} />
                              </div>
                              <Card.Body>
                                <Card.Title>Sustain</Card.Title>

                              </Card.Body>
                            </Card>
                          </Col>
                        </div>
                      </Row> */}

                      {/* <Row className="mt-5">
                        <p>
                        To ensure all our stakeholders are safe and are well equipped to fight against the COVID-19 Pandemic, we took various initiatives for the well-being of the employees across locations and invested in the communities that we operate. Some of the initiatives include implementing a process to daily track the well-being of the employees across locations; the Emergency Response Procedure (24x7 help desk), frequent sanitization of offices and plants, educate employees and customers on the infection-related risks involved, free consultation with doctors, adding a top-up equivalent to individual base sums of the Mediclaim policies and partnering with several diagnostic labs and hospitals to expedite treatment. We supported communities near our operations by associating with local authorities to establish covid relief hospitals and supplyingmedication kits, hospital beds, oxygen cylinders, tabletop oximetersand automatic blood analyzers. Additionally, we organized vaccination camps in nearby villages to administer more than 10,000 vaccines and ensure 100% of our employees are fully immunized. </p>
                        <p>
                        All these initiatives together ensure a safe and enriching work environment. </p>
                      </Row> */}

                      {/* <Row>

                      <div className="per_hig mb-5">
                      <div className="card_round">
                      <div className="justify-content-center mb-3 row">
                        <Col className="col-xl-4" md={5}>

                        <a href={healthpolicy} target="_blank">
                            <Card className="wow fadeInUp" data-wow-delay="0.5s">
                              <Card.Title>Our Policy &amp; Commitment 
                         </Card.Title>
                              <Card.Img src={pdfIcon} />
                            </Card>
                          </a>
                        </Col>
                  
                    
</div></div></div>



                      </Row> */}

                      </Container>


      </div>



    </div>
  )
}

export default Social