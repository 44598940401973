import React, {useEffect, useState} from "react";
import {Container, Row, Col, Card, Breadcrumb, Button, Modal} from 'react-bootstrap';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import banner from '../assets/images/our-rmc-banner.png'
import rmxconcreto from '../assets/images/rmxconcreto.png'

import bannermobile from '../assets/images/our-rmc-banner-mobile.jpg';
import aboutBannerLogo from '../assets/images/rmcIcon.png'
import abouticon1 from '../assets/images/about-icon1.jpg'
import abouticon2 from '../assets/images/abouticon2.jpg'
import abouticon3 from '../assets/images/abouticon3.jpg'
import doubicon from '../assets/images/doubicon.png'
import abouticon4 from '../assets/images/abouticon4.jpg'
import abouticon5 from '../assets/images/abouticon5.jpg'
import buss1 from '../assets/images/business1.jpg'
import buss2 from '../assets/images/business2.jpg'
import buss3 from '../assets/images/business3.jpg'

import img1 from '../assets/images/vimi1.png'
import img2 from '../assets/images/vimi2.png'
import img3 from '../assets/images/vimi3.png'
import img4 from '../assets/images/vimi4.png'
import img5 from '../assets/images/vimi5.png'
import img6 from '../assets/images/vimi6.png'
import img7 from '../assets/images/vimi7.png'
import coffeeTable from '../assets/images/coffee-table-img.jpg'
import stand4icon from '../assets/images/stand4icon.png'
import stand5icon from '../assets/images/stand5icon.png'
import stand6icon from '../assets/images/stand6icon.png'
import cementLogo from '../assets/images/cement_logo.svg';
import dcement from '../assets/images/dcement.png';
import dbull from '../assets/images/dbull.png';
import nirmex from '../assets/images/nirmex.png';
import psc from '../assets/images/psc.png';
import infracem from '../assets/images/infracem.png';
import artiste from '../assets/images/artiste.png';
import concreto from '../assets/images/concreto.png';
import ecodure from '../assets/images/ecodure.png';
import instamix from '../assets/images/instamix.png';
import xcon from '../assets/images/xcon.png';
import zeroM from '../assets/images/zeroM.png';
import concreto01 from '../assets/images/concreto.jpg'
import landmark01 from '../assets/images/landmark01.png';
import landmark02 from '../assets/images/landmark02.png';
import landmark03 from '../assets/images/landmark03.png';
import landmark04 from '../assets/images/landmark04.png';
import landmark05 from '../assets/images/landmark05.png';
import landmark06 from '../assets/images/landmark06.png';
import landmark07 from '../assets/images/landmark07.png';
import landmark08 from '../assets/images/landmark08.png';
import landmarkcenter01 from '../assets/images/landmarkcenter01.jpg';
import landmarkcenter02 from '../assets/images/landmarkcenter02.jpg';
import landmarkcenter03 from '../assets/images/landmarkcenter03.jpg';
import landmarkcenter04 from '../assets/images/landmarkcenter04.jpg';
import landmarkcenter05 from '../assets/images/landmarkcenter05.jpg';
import landmarkcenter06 from '../assets/images/landmarkcenter06.jpg';
import landmarkcenter07 from '../assets/images/landmarkcenter07.jpg';
import landmarkcenter08 from '../assets/images/landmarkcenter08.jpg';
import landmarkcenter09 from '../assets/images/landmarkcenter09.jpg';
import landmarkcenter10 from '../assets/images/landmarkcenter10.jpg';
import concretoagileflow from '../assets/images/concreto-agile-flow.png';
import concretoagile from '../assets/images/concreto-agile.png';
import concretorobuste from '../assets/images/concreto-robuste.png';
import concretofluide from '../assets/images/concreto-fluide.png';
import concretoinstante from '../assets/images/concreto-instante.png';
import concretoregletherme from '../assets/images/concreto-regletherme.png';
import concretocorrosafeplus from '../assets/images/concreto-corrosafe-plus.png';
import concretopermadure from '../assets/images/concreto-permadure.png';
import concretosteelibre from '../assets/images/concreto-steelibre.png';
import concretoxlite from '../assets/images/concreto-xlite.png';
import concretoeasyfille from '../assets/images/concreto-easyfille.png';
import concretolente from '../assets/images/concreto-lente.png';
import concretocwtplus from '../assets/images/concreto-cwt-plus.png';
import concretoxlitestructure from '../assets/images/concreto-xlite-structure.png';
import concretoecodure from '../assets/images/concreto-ecodure.png';
import concretopolibre from '../assets/images/concreto-polibre.png';
import instamixbaggedconcrete from '../assets/images/instamixbaggedconcrete.png';
import instamixmicrone from '../assets/images/instamixmicrone.png';
import instamixmortareradymixmortar from '../assets/images/instamixmortareradymixmortar.png';
import artistelogo05 from '../assets/images/artistelogo05.png';
import xconlogo from '../assets/images/xconlogo.png';
import green from '../assets/images/green.jpg'
import concretoglydelogo from '../assets/images/concreto-glyde-logo.png';
import artistsengrave from '../assets/images/artists-engrave.png';
import artistshug from '../assets/images/artists-hug.png';
import artistbare from '../assets/images/artist-bare.png';
import artistscoarse from '../assets/images/artists-coarse.png';
import artistlumos from '../assets/images/artist-lumos.png';
import ecodureproduct from '../assets/images/ecodure-product.png';
import { Helmet } from 'react-helmet';

const settings = {
    //centerMode: true,
    //autoplay:true,
    autoplay:true,
    autoplaySpeed: 2000,
    dots: false,
    arrow:true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            },
        },
    ],
  };

const slideLandmark = {
  autoplay:true,
  autoplaySpeed: 2000,
  dots: false,
  arrow:true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
      {
          breakpoint: 992,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
          },
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
          },
      },
  ],
};

const slideLandmarkproject = {
    autoplay:true,
    autoplaySpeed: 2000,
    dots: false,
    arrow:true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            },
        },
    ],
  };



const OurRMC = () =>{

    const [one, setOne] = useState(false);
    const closeOne = () => setOne(false);

    const [two, setTwo] = useState(false);
    const closeTwo = () => setTwo(false);

    const [three, setThree] = useState(false);
    const closeThree = () => setThree(false);

    const [four, setFour] = useState(false);
    const closeFour = () => setFour(false);

    const [five, setFive] = useState(false);
    const closeFive = () => setFive(false);

    const [six, setSix] = useState(false);
    const closeSix = () => setSix(false);

    const [seven, setSeven] = useState(false);
    const closeSeven = () => setSeven(false);

    const [projectone, setProjectone] = useState(false);
    const closeProjectone = () => setProjectone(false);

    const [projecttwo, setProjecttwo] = useState(false);
    const closeProjecttwo = () => setProjecttwo(false);

    const [projectthree, setProjectthree] = useState(false);
    const closeProjectthree = () => setProjectthree(false);

    const [projectfour, setProjectfour] = useState(false);
    const closeProjectfour = () => setProjectfour(false);

    const [projectfive, setProjectfive] = useState(false);
    const closeProjectfive = () => setProjectfive(false);

    const [projectsix, setProjectsix] = useState(false);
    const closeProjectsix = () => setProjectsix(false);

    const [projectseven, setProjectseven] = useState(false);
    const closeProjectseven = () => setProjectseven(false);

    const [projecteight, setProjecteight] = useState(false);
    const closeProjecteight = () => setProjecteight(false);

    const [projectnine, setProjectnine] = useState(false);
    const closeProjectnine = () => setProjectnine(false);

    const [projectten, setProjectten] = useState(false);
    const closeProjectten = () => setProjectten(false);

    
    const showComponent=((varname) =>{    
        switch (varname) {
          case "one":
            setOne(true);
            break;
            case "two":
            setTwo(true);
            break;
            case "three":
                setThree(true);
                break;
                case "four":
                setFour(true);
                break;
                case "five":
                setFive(true);
                break;
                case "six":
                setSix(true);
                break;
                case "seven":
                setSeven(true);
                break;
                
                case "projectone":
                setProjectone(true);
                break;
                case "projecttwo":
                setProjecttwo(true);
                break;
                case "projectthree":
                setProjectthree(true);
                break;
                case "projectfour":
                setProjectfour(true);
                break;
                case "projectfive":
                setProjectfive(true);
                break;
                case "projectsix":
                setProjectsix(true);
                break;
                case "projectseven":
                setProjectseven(true);
                break;
                case "projecteight":
                setProjecteight(true);
                break;
                case "projectnine":
                setProjectnine(true);
                break;
                case "projectten":
                setProjectten(true);
                break;
                
          default: return;
        }
        
      })
      
    return(
<div className="aboutus-pge inner_page landmarkpages">

    <Helmet>
    <title>Ready Mix Concrete manufacturers and Suppliers | Nuvoco Vistas</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco vistas produce ready mix concrete using new age technology and it is one of the leading suppliers of ready mix concrete acroos the country" />
    <link rel="canonical" href="https://nuvoco.com/ready-mix-concrete"/>
    </Helmet>

        <div className="banner text-center position-relative">
        <img className='desktopbannerinner' src={banner} alt="" />
               <img className='mobilebannerinner' src={bannermobile} alt="" />
          <article className='position-absolute text-center'>
            <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
            <h1 className="text-uppercase">READY-MIX Concrete</h1>
          </article>
          <div className='breadouter'>
            <div className='container'>
              <Breadcrumb className='mb-0 py-2'>
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item active href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                Ready Mix Concrete
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
        <div className='midpart'>
          <Container>
            <h2 className='grad-heading'>Overview</h2>
          <p>
          With more than 50+ plants across India, we are one of the leading industry players in the Ready-mix Concrete (RMX) industry. As a preferred partner, we provide concrete solutions to developers, small contractors, builders, architects, government agencies, and individual home builders at large. Nuvoco is proud to have contributed to landmark projects – Nazrul Tirtha, Lodha World One, Amritsar Entry Gate, Aquatic Gallery, and several Metro Rail Projects.  
</p>
<p>So, if you are searching for ‘ready mix Concrete suppliers near me’, then stop your search with Nuvoco.</p>
<p>We being one of the prominent rmx suppliers provide assured quality for all our products, which sets them apart from the competition. Our business is driven by innovation. We adhere to the philosophy of developing products which are not only beneficial to mankind but also to the environment. In addition to sustainability and efficiency, our R&D team stresses innovation. It is our commitment to transparency that makes our customers trust us, and we are one of the ready mix concrete companies who always win our customers’ trust.</p>
           
          
            <div className="vimi-page mt-5">
            <h2 className='grad-heading'>What Makes Us Stand Out?</h2>
            <Row className='topbx stand-page my-5'>
            <div className="vimiicon pb-2 d-flex justify-content-center">
              <div className="imgbx d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <div className='imgout'>
                    <Card.Img variant="top" src={stand4icon} />
                  </div>
                  <Card.Body>
                    
                    <Card.Text>
                    Assured Quality
                    </Card.Text>
                  </Card.Body>
                </div>
              </div>
              <div className="imgbx d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <div className='imgout'>
                    <Card.Img variant="top" src={stand5icon} />
                  </div>
                  <Card.Body>
                    
                    <Card.Text>
                    Always Innovative
                    </Card.Text>
                  </Card.Body>
                </div>
              </div>
              <div className="imgbx d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <div className='imgout'>
                    <Card.Img variant="top" src={stand6icon} />
                  </div>
                  <Card.Body>
                   
                    <Card.Text>
                    Trust and Transparency
                    </Card.Text>
                  </Card.Body>
                </div>
              </div>
              </div>
            </Row>
        </div>
        <div className="our_brand_inner">
            <div className="card_round pt-5">
                <Container>
                    <h2 className='grad-heading pd-5 mb-5'>Our Brands</h2>
                    <Row className="pb-4 justify-content-center">
                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('one')}>
                            <Card.Img src={rmxconcreto} />
                                <Card.Body>
                                    <article>
                                    The Concreto range of products offers enhanced performance with variants like self-compacting concrete, thermal insulated concrete, low density concrete.
                                    </article>
                                    <Card.Title className="g_invert">
                                     Concreto
                                    </Card.Title>   
                                </Card.Body>
                            </Card>
                        </Col>
                        
                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('two')}>
                            <Card.Img src={instamix} />
                                <Card.Body>
                                    <article>
                                    Instamix range offers ready-to-use concrete and mortar that is available in 35 kg bags and delivered straight to the job site. It is easy to use and gives consistent quality with minimal wastage.
                                    </article>
                                    <Card.Title className="g_invert">
                                    InstaMix
                                    </Card.Title>   
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('three')}>
                            <Card.Img src={artiste} />
                                <Card.Body>
                                    <article>
                                    Artiste is a range of decorative concrete that combines freedom of design with low maintenance and superior durability. It offers wide variety of choices for the modern builder to match today’s trends in the world of new-age construction.
                                    </article>
                                    <Card.Title className="g_invert">
                                    Artiste
                                    </Card.Title>   
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('four')}>
                            <Card.Img src={xcon} />
                                <Card.Body>
                                    <article>
                                    X-CON is a widely preferred standard  concrete (Grade M5 to M95) that is produced in an automated batching plant using a mixture of cement, water, sand and aggregates (including 10 and 20 mm stones or gravels). This range is popular for its consistent quality, on-time delivery and high-quality service.
                                    </article>
                                    <Card.Title className="g_invert">
                                    X-CON
                                    </Card.Title>   
                                </Card.Body>
                            </Card>
                        </Col>
                        
                        <Col md={6} lg={4} className="mb-5 wow fadeInUp">
                            <Card onClick={() => showComponent('five')}>
                            <Card.Img src={ecodure} />
                                <Card.Body>
                                    <article>
                                    Ecodure is a revolutionary range of Low carbon concrete or green concrete that can reduce the carbon emissions up to 60% in comparison with standard OPC mix. Its range of concrete includes Ecodure, Ecodure
                                    Prime, and Ecodure Plus. 
                                    </article>
                                    <Card.Title className="g_invert">
                                    Ecodure
                                    </Card.Title>   
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
            </Container >
            </div>
            
        </div>
        
       <section>
            <Container>
                <h2 className='grad-heading pd-5 mb-5'>Coffee Table Book – Featuring Landmark Projects</h2>
                <Row>
                <Col className="text-center">
                    <a href="https://rmxprojects.nuvoco.com/" target="_blank">
                        <img src={coffeeTable} />
                    </a>
                    </Col>
                </Row>
            </Container>
        </section>

        <section className="landmark card_round">
           
            <Container className="landmarkpage" id="landmark">
                <h3 className='grad-heading pd-5 mb-5'>Other Projects</h3>
                
                        <Slider {...slideLandmark}>
                            <div className="wow fadeInLeft" data-wow-delay="0.5s">
                            <Card onClick={() => showComponent('projectone')}>
                                    <Card.Img variant="top" src={landmarkcenter01} />
                                    <Card.Body className="wow fadeInRight" data-wow-delay="0.7s">
                                        <Card.Title>Khodhaldham Temple</Card.Title>
                                        <p>Rajkot, Ahemdabad</p>
                                    </Card.Body>
                                </Card>
                            </div>                 
                            <div className="wow fadeInLeft" data-wow-delay="0.7s">
                            <Card onClick={() => showComponent('projecttwo')}>
                                    <Card.Img variant="top" src={landmarkcenter02} />
                                    <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                        <Card.Title>Sumadhura Shikharam</Card.Title>
                                        <p>Bangalore</p>
                                    </Card.Body>
                                </Card>
                            </div>              
                            <div className="wow fadeInLeft" data-wow-delay="0.5s">
                            <Card onClick={() => showComponent('projectthree')}>
                                    <Card.Img variant="top" src={landmarkcenter03} />
                                    <Card.Body className="wow fadeInRight" data-wow-delay="0.7s">
                                        <Card.Title>CEEBROS</Card.Title>
                                        <p>Chennai</p>
                                    </Card.Body>
                                </Card>
                            </div>                 
                            <div className="wow fadeInLeft" data-wow-delay="0.7s">
                            <Card onClick={() => showComponent('projectfour')}>
                                    <Card.Img variant="top" src={landmarkcenter04} />
                                    <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                        <Card.Title>Metro</Card.Title>
                                        <p>Delhi, Noida, Jaipur and Mumbai</p>
                                    </Card.Body>
                                </Card>
                            </div>       

                            <div className="wow fadeInLeft" data-wow-delay="0.7s">
                            <Card onClick={() => showComponent('projectfive')}>
                                    <Card.Img variant="top" src={landmarkcenter05} />
                                    <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                        <Card.Title>Sharda University</Card.Title>
                                        <p>Delhi</p>
                                    </Card.Body>
                                </Card>
                            </div>   

                            <div className="wow fadeInLeft" data-wow-delay="0.7s">
                            <Card onClick={() => showComponent('projectsix')}>
                                    <Card.Img variant="top" src={landmarkcenter06} />
                                    <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                        <Card.Title>Suven Life Sciences</Card.Title>
                                        <p>Hyderabad</p>
                                    </Card.Body>
                                </Card>
                            </div>   

                            <div className="wow fadeInLeft" data-wow-delay="0.7s">
                            <Card onClick={() => showComponent('projectseven')}>
                                    <Card.Img variant="top" src={landmarkcenter07} />
                                    <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                        <Card.Title>Dravyavati River Rejuvenation</Card.Title>
                                        <p>Jaipur</p>
                                    </Card.Body>
                                </Card>
                            </div>   

                            <div className="wow fadeInLeft" data-wow-delay="0.7s">
                            <Card onClick={() => showComponent('projecteight')}>
                                    <Card.Img variant="top" src={landmarkcenter08} />
                                    <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                        <Card.Title>Nazrul Tirtha</Card.Title>
                                        <p>Kolkata</p>
                                    </Card.Body>
                                </Card>
                            </div>   

                            <div className="wow fadeInLeft" data-wow-delay="0.7s">
                            <Card onClick={() => showComponent('projectnine')}>
                                    <Card.Img variant="top" src={landmarkcenter09} />
                                    <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                        <Card.Title>Lodha World One</Card.Title>
                                        <p>Mumbai</p>
                                    </Card.Body>
                                </Card>
                            </div>   
                            
                            <div className="wow fadeInLeft" data-wow-delay="0.7s">
                            <Card onClick={() => showComponent('projectten')}>
                                    <Card.Img variant="top" src={landmarkcenter10} />
                                    <Card.Body className="wow fadeInRight" data-wow-delay="0.9s">
                                        <Card.Title>Amritsar entry gate</Card.Title>
                                        <p>Punjab</p>
                                    </Card.Body>
                                </Card>
                            </div>   
                            
                        </Slider>
            </Container>
        </section>
        

          </Container>
        </div>


        <Modal  size="lg" centered="true"
        show={one} onHide={closeOne} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col>
            <Col md={12}>
                
                    <div className="d-flex align-item-center">
                        <img src={rmxconcreto} alt='' />
                        <article className="p-4">
                        The Concreto range of products offers enhanced performance with variants like self-compacting concrete, thermal insulated concrete, low density concrete.
                        </article>
                    </div>
                    
                
            </Col>
        </Row>

        <div className="mt-3 pb-4">
            <Row>
            <Slider {...settings}>
                <Col md={4}>
                    <Card>
                        <Card.Img src={concretoagile} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Concreto Agile
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/concreto-agile">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img src={concretorobuste} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Concreto Robuste
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/concreto-robuste">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card>
                        <Card.Img src={concretofluide} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Concreto Fluide
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/concreto-fluide%C2%A0">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img src={concretoinstante} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Concreto Instante
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/concreto-instante">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card>
                        <Card.Img src={concretoregletherme} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Concreto Regletherme
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/concreto-regletherme">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card>
                        <Card.Img src={concretocorrosafeplus} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Concreto Corrosafe
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/concreto-corrosafe-plus">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img src={concretopermadure} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Concreto Permadure
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/concreto-permadure">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img src={concretosteelibre} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                           Concreto Steelibre
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/concreto-steelibre">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card>
                        <Card.Img src={concretoagileflow} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Concreto Agile Flow
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/concreto-agile-flow">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img src={concretoxlite} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Concreto Xlite
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/concreto-xlite">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img src={concretoeasyfille} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Concreto Easyfille

                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/concreto-easyfille">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img src={concretolente} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Concreto Lente
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/concreto-lente">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card>
                        <Card.Img src={concretocwtplus} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Concreto CWT Plus
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/concreto-cwt-plus">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img src={concretoxlitestructure} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Concreto Xlite Structure
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/concreto-xlite-structure">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card>
                        <Card.Img src={concretoecodure} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                           Concreto Endura
                            </Card.Title>
                            <Button variant="success" className="read_more" href="/products/concreto-endura">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                
               <Col md={4}>
                    <Card>
                        <Card.Img src={concretopolibre} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Concreto Polibre
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/concreto-polibre">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                                    <Card>
                                        <Card.Img src={concretoglydelogo} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Concreto Glyde
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/concreto-glyde">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
          
                
                
   
          
                

                </Slider> 
            </Row>
        </div>
        </Modal.Body>           
    </Modal>

    <Modal  size="lg" centered="true"
        show={two} onHide={closeTwo} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col>
            <Col md={12}>
                
                    <div className="d-flex align-item-center">
                        <img src={instamix} alt='' />
                        <article className="p-4">
                        InstaMix range offers ready-to-use concrete and mortar that is available in 35 kg bags and delivered straight to the job site. It is easy to use and gives consistent quality with minimal wastage.
                        </article>
                    </div>
                    
                
            </Col>
        </Row>

        <div className="mt-3 pb-4">
            <Row>
            <Slider {...settings}>
                <Col md={4}>
                    <Card>
                        <Card.Img src={instamixbaggedconcrete} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Instamix
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/instamix">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img src={instamixmicrone} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Instamix Microne
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/instamix-microne">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card>
                        <Card.Img src={instamixmortareradymixmortar} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                            Concreto Green Cement
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="/products/instamix-mortare">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                

                </Slider> 
            </Row>
        </div>
        </Modal.Body>           
    </Modal>

    <Modal  size="lg" centered="true"
        show={three} onHide={closeThree} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col>
            <Col md={12}>
                
                    <div className="d-flex align-item-center">
                        <img src={artiste} alt='' />
                        <article className="p-4">
                        Artiste is a range of decorative concrete that combines freedom of design with low maintenance and superior durability. It offers wide variety of choices for the modern builder to match today’s trends in the world of new-age construction.
                        </article>
                    </div>
                    
                
            </Col>
        </Row>

        <div className="mt-3 pb-4">
            <Row>
            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={artistelogo05} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Artiste Signature
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/artiste">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={artistsengrave} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Artiste Engrave
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/artiste-engrave">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={artistshug} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Artiste Hue
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/artiste-hue">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={artistbare} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Artiste Bare
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/artiste-bare">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={artistscoarse} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Artiste Coarse
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/artiste-coarse">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={artistlumos} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Artiste Lumos
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/artiste-lumos">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>


                            </Slider>
            </Row>
        </div>
        </Modal.Body>           
    </Modal>

    <Modal  size="lg" centered="true"
        show={four} onHide={closeFour} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col>
            <Col md={12}>
                
                    <div className="d-flex align-item-center">
                        <img src={xcon} alt='' />
                        <article className="p-4">
                        X-CON is a widely preferred standard concrete (Grade M5 to M95) that is produced in an automated batching plant using a mixture of cement, water, sand and aggregates (including 10 and 20 mm stones or gravels). This range is popular for its consistent quality, on-time delivery and high-quality service.
                        </article>
                    </div>
                    
                
            </Col>
        </Row>

        <div className="mt-3 pb-4">
            <Row>
            <Slider {...settings}>
                <Col md={4}>
                    <Card>
                        <Card.Img src={xconlogo} />
                        <Card.Body>
                            <Card.Title className="g_invert">
                           X-CON
                            </Card.Title>  
                            <Button variant="success" className="read_more" href="products/x-con">Read More </Button> 
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                
                </Col>
                <Col md={4}>
                    
                </Col>
                

                </Slider> 
            </Row>
        </div>
        </Modal.Body>           
    </Modal>

    <Modal  size="lg" centered="true"
        show={five} onHide={closeFive} className="bob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col>
            <Col md={12}>
                
                    <div className="d-flex align-item-center">
                        <img src={ecodure} alt='' />
                        <article className="p-4">
                        Ecodure is a revolutionary range of Low carbon concrete or green concrete that can reduce the carbon emissions up to 60% in comparison with standard OPC mix. Its range of concrete includes Ecodure, Ecodure Prime, and Ecodure Plus.
                        </article>
                    </div>
                    
                
            </Col>
        </Row>

        <div className="mt-3 pb-4">
            <Row>
            <Slider {...settings}>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={ecodureproduct} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Ecodure
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/ecodure">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={ecodureproduct} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Ecodure Prime
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/ecodure-prime">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Img src={ecodureproduct} />
                                        <Card.Body>
                                            <Card.Title className="g_invert">
                                            Ecodure Plus
                                            </Card.Title>
                                            <Button variant="success" className="read_more" href="/products/ecodure-plus">Read More </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>


                            </Slider>
            </Row>
        </div>
        </Modal.Body>           
    </Modal>

{/* Landmark project popup */}

    <Modal  size="lg" centered="true"
        show={projectone} onHide={closeProjectone} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
            <Col md={12}>
           
                    <div className="align-item-center productlandmark">
                        <img src={landmarkcenter01} alt='' />
                        <article className="p-4">
                            <h4>Khodhaldham Temple</h4>
                            <p>One of the largest comprehensive Hindu Temples in India that is 299 feet tall. It is renowned for setting Guinness World Record in the category of the highest number of people (3.5 lakhs) singing the national anthem at a single venue. Nuvoco’s Artiste installed on the steps that cover a 39,600 sq. ft. area and has footfall as high as three lakhs.</p>
                        </article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal>
    <Modal  size="lg" centered="true"
        show={projecttwo} onHide={closeProjecttwo} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
            <Col md={12}>
           
                    <div className="align-item-center productlandmark">
                        <img src={landmarkcenter02} alt='' />
                        <article className="p-4">
                            <h4>Sumadhura Shikharam</h4>
                            <p>Sumadhura Infracon Pvt. Ltd. is a name that resonates among the prominent residential and commercial developers in Bengaluru. A posh project in pristine surroundings that boosts tranquillity; these high-rise apartments are studded with world-class amenities. Artiste’s subtle design provides vibrant colour and realistic texture.</p>
                        </article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal>
    <Modal  size="lg" centered="true"
        show={projectthree} onHide={closeProjectthree} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
            <Col md={12}>
           
                    <div className="align-item-center productlandmark">
                        <img src={landmarkcenter03} alt='' />
                        <article className="p-4">
                            <h4>CEEBROS</h4>
                            <p>With an uncompromising passion for the highest quality and detailing, exceptional design and craftsmanship, CEEBROS Property Development has been leading the luxury residential market in Chennai. They were the first ones to build a five-star Ecotel Hotel ‘The Rain Tree’ in South India. Nuvoco’s XLite was used to fill the complex driveway of their premium residential complex ONE 74.</p>
                        </article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal>
    <Modal  size="lg" centered="true"
        show={projectfour} onHide={closeProjectfour} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
            <Col md={12}>
           
                    <div className="align-item-center productlandmark">
                        <img src={landmarkcenter04} alt='' />
                        <article className="p-4">
                            <h4>Metro</h4>
                            <p>Nuvoco executed three underground metro stations with one launching shaft and one crossover for the Delhi Metro project. Delivered 1,40,000 cum in 20 months and erected two plants in the record time of 50 days to support the uninterrupted supply of concrete.</p>
                        </article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal>
    <Modal  size="lg" centered="true"
        show={projectfive} onHide={closeProjectfive} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
            <Col md={12}>
           
                    <div className="align-item-center productlandmark">
                        <img src={landmarkcenter05} alt='' />
                        <article className="p-4">
                            <h4>Sharda University</h4>
                            <p>A leading educational institution located in Greater Noida, Delhi NCR that spread across a sprawling campus of 63 acres and housing students from more than 74 countries other than India. Nuvoco’s Artiste offers beauty and durability to the construction.</p>
                        </article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal>
    <Modal  size="lg" centered="true"
        show={projectsix} onHide={closeProjectsix} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
            <Col md={12}>
           
                    <div className="align-item-center productlandmark">
                        <img src={landmarkcenter06} alt='' />
                        <article className="p-4">
                            <h4>Suven Life Sciences</h4>
                            <p>A biopharmaceutical company focused on discovering, developing and commercializing novel pharmaceutical products. Nuvoco’s Concreto Agile helped in the expansion process [building of five bulk chemical storage tanks] of their existing plant.</p>
                        </article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal>
    <Modal  size="lg" centered="true"
        show={projectseven} onHide={closeProjectseven} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
            <Col md={12}>
           
                    <div className="align-item-center productlandmark">
                        <img src={landmarkcenter07} alt='' />
                        <article className="p-4">
                            <h4>Dravyavati River Rejuvenation</h4>
                            <p>A project by TATA Projects Limited for restoring a 47.5 km (rain-fed) riverfront of untreated sewage nallah to a perennial river - fed by treated clean water and beatifying it. Nuvoco’s range of ready-mix concrete is helping in shaping this project and achieve uncompromised results.</p>
                        </article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal>
    <Modal  size="lg" centered="true"
        show={projecteight} onHide={closeProjecteight} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
            <Col md={12}>
           
                    <div className="align-item-center productlandmark">
                        <img src={landmarkcenter08} alt='' />
                        <article className="p-4">
                            <h4>Nazrul Tirtha</h4>
                            <p>A cultural and educational centre dedicated to Kazi Nazrul Islam that has 'Unnata mama shir' [Head held high] carved on its outer wall. Nuvoco’s free-flowing concrete ‘Agile’ was the ideal choice for getting the desired carving.</p>
                        </article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal>
    <Modal  size="lg" centered="true"
        show={projectnine} onHide={closeProjectnine} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
            <Col md={12}>
           
                    <div className="align-item-center productlandmark">
                        <img src={landmarkcenter09} alt='' />
                        <article className="p-4">
                            <h4>Lodha World One</h4>
                            <p>A residential skyscraper of 117 floors each covering 7, 50,000 sq meter area and offering more than 300 apartments. Nuvoco’s Agile was the perfect fit; its free-flowing property eliminates the need for vibration and allows easy placement offering desired look and shape.</p>
                        </article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal>
    <Modal  size="lg" centered="true"
        show={projectten} onHide={closeProjectten} className="productbob_modal bob_modal1 product_modal our_brand_inner card_round">
         <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
            {/* <Col md={12} className="d-flex align-items-center">
                <h2 className='grad-heading pd-5 m-0'>Our Brands</h2>
            </Col> */}
            <Col md={12}>
           
                    <div className="align-item-center productlandmark">
                        <img src={landmarkcenter10} alt='' />
                        <article className="p-4">
                            <h4>Amritsar entry gate</h4>
                            <p>An entry gate that is 50 meters tall and has the second largest dome in diameter [4000 sq. ft.] after India's entry in the seven wonders of the world at the entrance of the majestic city of Amritsar. Nuvoco’s Xlite – a low density and thermally efficient concrete helped maintain the structural load of the dome at the top and provide a beautiful structural finish to this landmark.</p>
                        </article>
                    </div>
           </Col>
        </Row>

        </Modal.Body>           
    </Modal>
    



      </div >
    )
}

export default OurRMC;