import React from 'react';
import ReactDOM from "react-dom";
import Container from 'react-bootstrap/Container';
import banner from '../assets/images/esgBanner.png'
import bannermobile from '../assets/images/governance_Mobile.jpg'
import aboutBannerLogo from '../assets/images/esgicon.png'
import abouticon1 from '../assets/images/about-icon1.jpg'
import abouticon2 from '../assets/images/abouticon2.jpg'
import abouticon3 from '../assets/images/abouticon3.jpg'
import abouticon4 from '../assets/images/abouticon4.jpg'
import abouticon5 from '../assets/images/abouticon5.jpg'
import buss1 from '../assets/images/business1.jpg'
import buss2 from '../assets/images/business2.jpg'
import buss3 from '../assets/images/business3.jpg'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Iframe from 'react-iframe';


const Governance = () => {
  return (
    <div className="aboutus-pge inner_page">
      <div className="banner text-center position-relative">
        <img className='desktopbannerinner' src={banner} alt="" />
        <img className='mobilebannerinner' src={bannermobile} alt="" />
        <article className='position-absolute text-center'>
          <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
          <h1 className="text-uppercase">Governance</h1>
        </article>
        <div className='breadouter'>
          <div className='container'>
            <Breadcrumb className='mb-0 py-2'>
              <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
              <Breadcrumb.Item active href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
              Governance
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div className='midpart'>
        
        <p>
       
        <Iframe url="https://esg.churchgatepartners.com/login/companyprofile?id=360031003600240024004100530048004F004B0041004E0041004E00590041004100560041004E004900410053004800570049004E00490024002400"
        width="100%"
        height="1500px"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative"/>
        </p>
      
       
      </div>
    </div >
  )
}

export default Governance