import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from "react-helmet";

const JayakumarKrishnaswamy = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Shruta Sanghavi | Non-Executive Director | NU Vista Limited</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Ms. Shruta Sanghavi has been a member of the Board of Directors since July 21, 2020." />
    <link rel="canonical" href="https://nuvoco.com/Shruta-Sanghavi"/>
    </Helmet>

      <div className='midpart bodPages'>
        <Container>
            <Row className="mb-5">
                 <Col md={4}>
                    <img style={{borderRadius:'15px'}} src="https://admin.nuvoco.com/public/BoardOfDirectorsDetails/mgmt2.png" />
                </Col>
                <Col md={8}>
                    <h2 className='grad-heading'>Shruta Sanghavi<br/><small>Non-Executive Director</small></h2>
                    <p className='onetxt'>
                    Ms. Shruta Sanghavi has been a member of the Board of Directors since July 21, 2020. She holds a Post Graduate degree in Commerce from Mumbai University and is a qualified Company Secretary. She has rich experience of over 25 years in Company Secretarial, Corporate Governance, Fund Raising, and Amalgamation and Merger activities, Buyback of Equity Shares, Foreign Collaboration and Joint Ventures. She has previously been associated with Allcargo Logistics Ltd., Tata Motors Ltd, Punjab Chemicals, Bank of Baroda.</p>
                </Col>
            </Row>

        </Container>
      </div>
    </div >
  )
}

export default JayakumarKrishnaswamy