import { ENVIRONMENT, ENVIRONMENTSEARCH } from "./type";

const initialState ={environment: '', environmentsearch: ''};

const environmentR = (state = initialState, action) =>{
    switch(action.type){
        case ENVIRONMENT:
            return {...state, environment: action.payload};
            
        case ENVIRONMENTSEARCH:
            return {...state, environmentsearch: action.payload};

        default:
            return state;
    }
}

export default environmentR;