import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const ChittorCementPlant = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco’s Chittor Cement Plant Marks 10 Years of Success with its Dealer Partners | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco’s Chittor Cement Plant Marks 10 Years of Success with its Dealer Partners
" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/chittor-cement-plant-success"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
            <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Nuvoco’s Chittor Cement Plant Marks 10 Years of Success  with its Dealer Partners.pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco’s Chittor Cement Plant Marks 10 Years of Success with its Dealer Partners</h2>
         
          <p className='onetxt'><strong>Rajasthan, October 16, 2023: </strong><a href="https://www.nuvoco.com">Nuvoco Vistas Corp Ltd, India's fifth-largest cement group</a>, celebrated the 10th Anniversary of its Chittor Cement Plant in Rajasthan. The event brought together 150 Dealer Partners from Rajasthan, West - Madhya Pradesh, and Gujarat</p>

          <p>Nuvoco Leaders, Ms. Madhumita Basu (Chief of Marketing, Investor Relations, and Cement Sales & Business Development – North), Mr. Sharad Shrimali (Chief Logistics Officer), and Mr. Sanjay Joshi (Chief Projects and Manufacturing Officer - North) graced the occasion, engaging with attendees and sharing insights.</p>

          <p>On October 12, 2013, the Chittor Cement Plant dispatched its first truck, commemorating a decade of success. The celebration featured a half-day program, including a plant tour covering mines, the packing unit, and the captive power plant. At the event, the partners were shown a captivating film highlighting the remarkable 10-year journey and the company's growth in the North. The event continued with a productive business meeting, entertainment, and special recognition for 100 steadfast business partners. Each partner received a certificate and an exclusive token of appreciation for their unwavering support.</p>
          
          <p><strong>Ms. Madhumita Basu, Chief of Marketing, Investor Relations, and Cement Sales & Business Development – North at Nuvoco</strong>, expressed, "The North market is of great importance to us, and our determined focus reflects from manifold increase in capacity from 2.6 MT to 6.2 MT over the past decade, with the Chittor Plant at its core. Ongoing expansion initiatives in Bhiwani and Nimbol will not only strengthen our foothold in existing markets but also expand our reach into Haryana, Rajasthan, West MP, and Gujarat aiding in forging relations with premium channel partners. We continue our focus on value over volume, premiumization, and improving margins continues to drive our growth."</p>

          <p><strong>Mr. Sanjay Joshi, Chief Projects and Manufacturing Officer – North at Nuvoco, stated,</strong> "Chittor Cement Plant is a testament to our commitment to efficiency and sustainability. Several external agencies have recognized it for its high safety standards and for being one of the most energy and cost-efficient plants in the North Cluster. With a TSR in excess of 25%, the Plant has consistently set industry benchmarks."</p>

        <p><strong>About Nuvoco Vistas</strong></p>
          <p><a href="https://www.nuvoco.com">Nuvoco Vistas Corporation Limited (“Nuvoco”)</a> is a cement manufacturing company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999, and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest Cement Group in terms of capacity (23.8 MMTPA) with an INR 10,586 crore total revenue from operations in FY23, and is among the leading players in East India. Nuvoco offers a diversified business portfolio under three business segments - Cement, Ready-Mix Concrete (RMX), and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses PAN India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (ready-to-use bagged concrete - the first-ofits-kind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to landmark projects like the Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium, Rourkela; Aquatic Gallery Science City, Ahmedabad; and Metro Railway (Delhi, Jaipur, Noida and Mumbai) among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ and ‘InstaMix’ brands, comprises construction chemicals, wall putty and cover blocks. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements.</p>
          <p>Know more about the Company on <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
          <p>For more information contact the Company at  <a href="mailto:india.communications@nuvoco.com">India.communications@nuvoco.com</a></p>
      </Container>
      </div>
    </div >
  )
}

export default ChittorCementPlant