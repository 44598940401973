import React from 'react';
import Container from 'react-bootstrap/Container';

import { Helmet } from "react-helmet";

const FinancialResultQ1FY23 = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Financial result Q1 FY 24 | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Vistas is one of the leading brand in producing cement, ready mix concrete and modern building materials and rated as fifth largest cement group in India" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/financial-result-q1-fy24"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
          <h2 className='grad-heading'>Press Release: NUVOCO Vistas announces its financial results for Q1 FY24 </h2>
          
          <ul>
            <li>Consolidated revenue improved to Rs. 2,805 crores</li>
            <li>Consolidated EBITDA increased 7% YoY to Rs. 402 crores </li>
          </ul>
          <p className='onetxt'>
            <strong>Mumbai, August 10, 2023: </strong>Nuvoco Vistas Corp. Ltd., a leading building materials Company in India, announced its unaudited financial results for the quarter ended June 30, 2023. With 23.82 MMTPA of combined installed capacity, Nuvoco Vistas Corp. Ltd. is the fifth largest cement group in India and amongst the leading cement players in East India. </p>

          <p>The consolidated cement sales volume for the Company increased by 7% YoY to 5 MMT in Q1 FY24. Consolidated revenue from operations during the same period improved by 6% YoY to Rs. 2,805 crores and consolidated EBITDA also improved by 7% YoY to Rs. 402 crores.</p> 

          <p>Premium products continue to remain a major thrust area for the Company and have contributed significantly, with a 37% share of the company's cement trade volumes in Q1 FY24. The Company extended ‘Duraguard F2F’ - a premium composite cement in West Bengal and launched ‘InstaMix Superior Column Concrete’ and ‘Artiste Flooring Solution’ - specialized Ready-Mix Concrete (RMX) products in Q1 FY24, demonstrating Nuvoco’s continuous focus on product innovation.</p>

          <p>The Company remains committed to its sustainability agenda – <strong>Protect Our Planet.</strong> The Company has amongst the lowest carbon emissions at 462 kg CO2 per ton of cementitious materials1. During the quarter, the Company successfully commissioned an Alternate Fuel Feeding system at Nimbol Cement Plant which will enable it to manage a wide range of fuels while increasing waste recycling tonnage. The Company achieved an alternate fuel rate (AFR) of 11.2% in Q1 FY24, amongst the best in the industry, a significant improvement of 5.2% YoY. The Company also maintained one of the highest cement-to-clinker ratio in the industry at 1.83 during the quarter, thus emphasizing blended cement and furthering its decarbonization initiatives. </p>

          <p>Commenting on the performance of the Company, <strong>Mr. Jayakumar Krishnaswamy,</strong> Managing Director, Nuvoco Vistas Corp. Ltd. stated, “Cement demand continues to witness healthy growth on account of government initiatives in the infrastructure and housing segments. We anticipate an uptick in construction activities post-monsoon. Moreover, we have successfully countered the dynamic cost environment through the optimization of the power and fuel mix between pet coke, linkage coal and AFR supported by reduction in pet coke and coal prices.</p>

         <p>Furthermore, he added, “As for the expansion projects, the debottlenecking at the Risda Cement Plant has been completed during the quarter, while the cement expansion at Haryana Plant and debottlenecking at Nimbol Cement Plant are scheduled to be completed by September 2023 which will help us to establish a stronger presence in the North. The Ready-Mix Concrete business continues to expand its footprint across the country and has commissioned three new plants during this quarter.” </p>

        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a cement manufacturing company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999 and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest Cement Group in terms of capacity (23.8 MMTPA) with an INR 10,586 crore total revenue from operations in FY23 and is among the leading players in East India. Nuvoco offers a diversified business portfolio under three business segments - Cement, Ready-Mix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses PAN India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (ready-to-use bagged concrete - the first-of-its-kind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to the landmark projects like Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium, Rourkela; Aquatic Gallery Science City, Ahmedabad; and Metro Railway (Delhi, Jaipur, Noida and Mumbai) among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ and ‘InstaMix’ brands, comprises construction chemicals, wall putty and cover blocks. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements.  <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
          <p>For more information, please contact the Company at <a href="mailto:investor.relations@nuvoco.com">investor.relations@nuvoco.com</a></p>
        </Container>
      </div>
    </div >
  )
}

export default FinancialResultQ1FY23