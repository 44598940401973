import {React, useEffect, useState}  from "react";
import {Container, Row, Breadcrumb,Button, Form, Alert, Col} from 'react-bootstrap';

import banner from '../assets/images/performance_highlight.png';
import bannermobile from '../assets/images/performace-highlight-mobile.jpg';
import aboutBannerLogo from '../assets/images/performanceicon.png';
import sortby from '../assets/images/sortby.png';
import { performanceApi } from "../redux";
import { performancesearchApi } from "../redux";
import { connect } from "react-redux";
import PdfCard from "../common/pdfCard";
import { imgUrl } from "../redux/config";

let IsData = false;
let status = null;
const PerformanceHighlight = ({performanceApi, performance, performancesearchApi, performancesearch}) => {

    useEffect(() => {
        performanceApi(1);
        performancesearchApi(2, 7, 1, 0);
	}, []);

    const [IsListing, setListingType] = useState(1);
    const [IsCategory, setCategory] = useState(2);
    const [IsYear, setYear] = useState(7);
    const [pdfList, setPdfList] = useState([]);
    const [sortBy, setSort] = useState(1);

    if(Object.keys(performance).length){
        status = performance.data.status;
        if(status === 200){
            const resData = {...performance.data}
            var finCta = JSON.parse(resData.fin_cat)
            var finYear = JSON.parse(resData.fin_year);
        }
    }

    const setListing = (id) =>{
        setListingType(id);
        performanceApi(id);
        performancesearchApi(IsCategory, 7, id, 0);
    }

    
    const change = (type,id) =>{
        setYear(type.target.value);     
        setData(type.target.value);      
        performancesearchApi(IsCategory, type.target.value, IsListing, 0);
    }

    const setCategoryData = (val) =>{
        setCategory(val.id);    
        performancesearchApi(val.id, IsYear, IsListing, 0);
    }

    const setData = (y) =>{
        performancesearchApi(IsCategory, y, IsListing, 0);        
    }
    
    const sortData = (data, t) =>{
        console.log(data)
        let copy
        if(t === 1){
            copy= [...data];
        } else{
            copy= [...pdfList];
        }
        
        sortBy === 1 ? copy.sort((a, b) => (new Date(a.created_at) < new Date(b.created_at)) ? 1 : -1) 
        : copy.sort((a, b) => (new Date(a.created_at) > new Date(b.created_at)) ? 1 : -1);
        setSort(sortBy === 1 ? 2 : 1);
        setPdfList(copy);      
    }
    
    if(performancesearch.IsSuccess ===1){        
        const pdfData = {...performancesearch.data};
        performancesearch.IsSuccess = 0;
        const data = JSON.parse(pdfData.fin_docs);
        console.log(data)
        sortData(data, 1);
        IsData = true;
    } 
  
         
        return(
            <>
            {(IsData && (
            <div className="crop_gov per_hig">
                <div className="banner text-center position-relative">
                <img className='desktopbannerinner' src={banner} alt="" />
               <img className='mobilebannerinner' src={bannermobile} alt="" />
                    <article className='position-absolute text-center'>
                        <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
                        <h1 className="text-uppercase">Performance highlights</h1>
                    </article>
                    <div className='breadouter'>
                        <div className='container'>
                            <Breadcrumb className='mb-0 py-2'>
                                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Performance highlights
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                </div>
                <Container className='py-5 text-center'>
                    <div className="mb-4 prepost_btn">
                        <Button variant="success" onClick={()=> setListing(1)} className={ IsListing === 1 ? 'active btn_lg' : 'btn_lg'}><span>Pre-Listing</span></Button>
                        <Button variant="success" onClick={()=> setListing(2)} className={ IsListing === 2 ? 'active btn_lg' : 'btn_lg'} ><span>Post-Listing</span></Button>
                    </div>

                    {status === 200  && (
                        <>
                            {finCta && finCta.map((val, i) =>{
                                return(<Button variant="success" key={i} onClick={()=> setCategoryData(val)} className={ val.id === IsCategory ? 'active btn_lg' : 'btn_lg'}>{val.category}</Button>)
                            })}

                            <div className='selectbx d-flex align-items-center justify-content-center mb-2 mt-4 pb-4'>
                                <label>Year:</label>
                                {(finYear && (
                                <Form.Select onChange={change} aria-label="Default select example">
                                    {finYear.map((item, index)=>{            
                                        return(                        
                                        <option value={item.id} key={index}>{item.year}</option>
                                        );
                                    })}
                                </Form.Select>
                                ))}
                                {/* <Button variant="success" onClick={setData} className="ml-2 read_more">Submit</Button> */}
                            </div>
                            
                            {console.log(">>>>", pdfList)}
                            {pdfList.length > 0  && (
                                
                                <div className="card_round mb-5">
                                    <div className="sortBy mb-2">
                                    <Button variant="success"  onClick={sortData} className={sortBy === 1 ? "ml-2 read_more active" : "ml-2 read_more"}>
                                        <img src={sortby} alt='' /> Sort By
                                        </Button>
                                    </div>
                                    <Row className="justify-content-center mb-3 pf_h">
                                        {pdfList.map((pdf, i)=>{
                                            return(
                                                <>
                                                    <PdfCard bd={pdf} uri={imgUrl} key={i} ct={'p_' + pdf.year_id + pdf.id + IsListing} />                                                
                                                </>
                                                
                                            )
                                        })}       
                                    </Row>
                                </div>
                            )}
                        </>
                    )}

                    {(status === 404 || pdfList.length ===0) && IsCategory < 8 && (
                        <Alert variant="danger">
                            <Alert.Heading>Data Not Available</Alert.Heading>
                        </Alert>
                    )}
                </Container>
        </div>
        )) || ''}
        </>
        );
    }

    const mapStatetoProps = (state) => {
        return {
            performance: state.performanceR.performance,
            performancesearch:state.performanceR.performancesearch
        };
    };
    const mapDispatchtoProps = (dispatch) => {
        return {
            performanceApi: function (id) {
                dispatch(performanceApi(id));
            },
            performancesearchApi: function (cid, yid,id) {
                dispatch(performancesearchApi(cid, yid,id));
            }
        };
    };

export default connect(mapStatetoProps, mapDispatchtoProps)(PerformanceHighlight);