import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const PlantinCoimbatore = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Plant in Coimbatore | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Expands its Southern Footprint with New Ready-Mix Concrete
Plant in Coimbatore" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/plant-in-coimbatore"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
            <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Nuvoco Expands its Southern Footprint with RMX Plant in Coimbatore (May 2023).pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco Expands its Southern Footprint with New Ready-Mix Concrete Plant in Coimbatore</h2>
          <p className='onetxt'>
            <strong>Coimbatore, April 22, 2023:</strong> Nuvoco Vistas Corp. Ltd., India’s fifth largest cement group in terms of capacity and among the leading playersin East India, today announced the launch of its new Ready-Mix Concrete (RMX) plant in Coimbatore, Tamil Nadu. With a capacity of 60 cubic meters per hour, the plant will cater to construction projects in the area, providing a reliable source of high-quality concrete while also improving efficiency, reducing costs, and promoting sustainability.</p>

          <p>The Coimbatore Plant is Nuvoco's second RMX facility in Tamil Nadu, after Chennai, and is part of the company's strategy to expand its footprint in Southern India. The plant is equipped with advanced technology and equipment that ensure the quality and consistency of the concrete produced and faster delivery, which is the need of the hour for the southern market’s fast-growing infrastructure</p>

          <p><strong>Mr. Prashant Jha, Chief of Ready-Mix (RMX) Concrete and Modern Building Materials business, Nuvoco</strong> said "We are delighted to launch our new RMX plant in Coimbatore, which is a growing market and a strategic location for us. The plant's proximity to our customers highlights our customer-centric approach, and we are committed to providing them with the best-in-class products and services. Our RMX product portfolio includes Concreto (Performance Concrete), Artiste (Decorative Concrete), InstaMix (Ready-to-Use Bagged Concrete), X-Con (M20 to M60), and Ecodure (special Green concrete), which are designed to meet the diverse needs of our customers and promote sustainable construction practices."</p>

         <p>He further added, "Our new RMX plant in Coimbatore will be a game-changer for the construction industry in the region. We look forward to contribute to the growth and development of Coimbatore as a smart city and a textile hub along with the surrounding areas."</p>

        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a cement manufacturing company with a vision to build a safer, smarter and sustainable world. Nuvoco started its operations in 1999, and since then it has emerged as one of the major players in the building materials industry in India. Today, Nuvoco offers a diversified business portfolio under three business segments, namely, Cement, Ready-Mix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco is a leading cement player in India and the largest cement company in East India in terms of capacity, following the acquisition of Nu Vista Limited (formerly Emami Cement Limited), offering various brands of cement such as ‘Concreto’, ‘Duraguard’, ‘Nirmax’, ‘Double Bull’, and ‘Infracem’. The MBM product portfolio, under the ‘Zero M’ and ‘Instamix’ brands, comprises construction chemicals, wall putty and cover blocks. The RMX business has a comprehensive pan-India presence offering value-added products under various brands such as ‘Concreto’, ‘Artiste’, ‘InstaMix’ and ‘X-Con’. Nuvoco is a proud contributor to landmark projects such as World One building at Mumbai, and the metro railway projects at Mumbai, Jaipur and Noida. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet the customer requirements. <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
          <p>For more information, please contact the Company at <a href="mailto:india.communications@nuvoco.com">india.communications@nuvoco.com</a></p>
        </Container>
      </div>
    </div >
  )
}

export default PlantinCoimbatore