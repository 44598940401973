import React from 'react';
import Container from 'react-bootstrap/Container';
import banner from '../../assets/images/aboutus-banner.jpg'
import bannermobile from '../../assets/images/aboutus-banner-mobile.jpg'
import aboutBannerLogo from '../../assets/images/aboutba-logo.png'
import abouticon1 from '../../assets/images/about-icon1.jpg'
import abouticon2 from '../../assets/images/abouticon2.jpg'
import abouticon3 from '../../assets/images/abouticon3.jpg'
import abouticon4 from '../../assets/images/abouticon4.jpg'
import abouticon5 from '../../assets/images/abouticon5.jpg'
import buss1 from '../../assets/images/business1.jpg'
import buss2 from '../../assets/images/business2.jpg'
import buss3 from '../../assets/images/business3.jpg'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Helmet } from "react-helmet";

const WomenInNuvoco = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Women in Nuvoco | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Vistas is one of the leading brand in producing cement, ready mix concrete and modern building materials and rated as fifth largest cement group in India" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/women-in-nuvoco"/>
    </Helmet>

      {/* <div className="banner text-center position-relative">
        <img className='desktopbannerinner' src={banner} alt="" />
        <img className='mobilebannerinner' src={bannermobile} alt="" />
        <article className='position-absolute text-center'>
          <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
          <h1 className="text-uppercase">about us</h1>
        </article>
        <div className='breadouter'>
          <div className='container'>
            <Breadcrumb className='mb-0 py-2'>
              <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
              <Breadcrumb.Item active href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                About Us
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div> */}
      <div className='midpart pressReleases'>
        <Container>
          <h2 className='grad-heading'>Press Release: Women in Nuvoco</h2>
          <p className='onetxt'>
            <strong><em>
            Nuvoco empowers Women Dealers across the value chain of the Cement Industry</em></strong></p>
          <p>
          <strong>Mumbai, 8th March 2022:</strong> Nuvoco Vistas Corp. Ltd., the fifth largest cement player in India and the largest cement company in East India, in terms of capacity, plans to celebrate and commemorate Women’s Day by acknowledging the efforts of their female leaders and associates, women dealers and beneficiaries of sustainability programmes. In a largely male-dominated industry, the company has been constantly encouraging women dealers to build their business. At present the company has 200 plus Women Dealers and the count is rising. The company plans to highlight their achievements across the cement industry with the goal of nurturing a more diverse workforce.
          </p>
          <p>
          <strong>Commenting on this initiative, Madhumita Basu, Chief Strategy & Marketing Officer, Nuvoco Vistas, said,</strong> "The company is proud of its female employees and dealers and acknowledges their continued effort and drive in the building materials industry. Particularly, in the cement industry, female dealers have emerged as some of our most successful partners with highly motivated and driven performances. An inclusive environment will encourage more female aspirants to join the industry workforce and broaden the decision-making cohort. The rise of women dealers in a male-dominated industry also indicates a welcome change in the mindset."
          </p>
          <p>
          <strong>Manisha Kelkar, Chief Human Resources Officer, Nuvoco Vistas, added,</strong> "Over the years, we have seen women take the initiative in various fields. Nuvoco has always encouraged gender parity to ensure more diversity and inclusion in the workplace. This Women’s Day, we celebrate their achievements and those of our remarkable women employees who have played a crucial role in the growth of the company."
          </p>
         <p>
         The company has over 200 women dealers, of which approx. 70 percent operated in the eastern markets while rest are in the northern market. The company has also made a conscious effort to encourage women employees joining the workforce. Currently more than 10% of the leadership positions are represented by female workforce - in an industry that has been over the decades, largely male dominated.
         </p>
          <p><strong>Promoting Women Empowerment:</strong></p>
          <p>Women empowerment is one of the key pillars of Nuvoco’s Corporate Social Responsibility initiatives. The company has implemented several projects under its Saksham Bharat theme, an important pillar in Nuvoco’s CSR commitments. Amongst them, a few leading projects are - <strong>Project Aakriti,</strong> to support women from economically backward families from villages, which started from Chittor and has now expanded to other locations as well; <strong>Project Samriddhi,</strong> to support women farmers from underprivileged villages near its Mejia Cement Plant and <strong>Project Daksh,</strong> to support vocational skill development of girls from economically backward families in Chhattisgarh and Jharkhand. The women’s self-help group, in Project Aakriti, oversee the supply of safety jackets to Nuvoco’s cement plants and other local vendors as well; while in Project Samriddhi, 450 women farmers have federated to form Sanchari Women Farmer Producer Company Limited. Project Daksh has trained more than 200 girls during past 2 years and placed them with reputed organizations. All these projects have empowered women economically and socially and reduced their vulnerability in the society.</p>
          <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a cement manufacturing company with a vision to build a safer, smarter and sustainable world. Nuvoco started its operations in 1999, and since then it has emerged as one of the major players in the building materials industry in India. Today, Nuvoco offers a diversified business portfolio under three business segments, namely, Cement, Ready-Mix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco is a leading cement player in India and the largest cement company in East India in terms of capacity, following the acquisition of Nu Vista Limited (formerly Emami Cement Limited), offering various brands of cement such as ‘Concreto’, ‘Duraguard’, ‘Nirmax’, ‘Double Bull’, and ‘Infracem’. The MBM product portfolio, under the ‘Zero M’ and ‘Instamix’ brands, comprises construction chemicals, wall putty and cover blocks. The RMX business has a comprehensive pan-India presence offering value-added products under various brands such as ‘Concreto’, ‘Artiste’, ‘InstaMix’ and ‘X-Con’. Nuvoco is a proud contributor to landmark projects such as World One building at Mumbai, and the metro railway projects at Mumbai, Jaipur and Noida. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet the customer requirements. <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
        </Container>
      </div>
    </div >
  )
}

export default WomenInNuvoco