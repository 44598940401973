import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";

import productR from "./product/reducer";
import mediaR from "./media/reducer";
import commonR from "./common/reducer";
import commonNewR from "./commonNew/reducer";
import corporateR from "./corporateGov/reducer";
import performanceR from "./performanceHig/reducer";
import investorcalltransR from "./investorCallTrans/reducer";
import presentationR from "./quarterlyPresentation/reducer";
import environmentR from "./environment/reducer";
import cementR from "./cement/reducer";
import stockexchangeR from "./stockexchangeIntimations/reducer";
import performancefinancialR from "./performancefinancialHig/reducer";

const middleware = applyMiddleware(thunk);

const rootReducer = combineReducers({
    productR,
    mediaR,
    commonR,
    commonNewR,
    corporateR,
    performanceR,
    investorcalltransR,
    presentationR,
    environmentR,
    cementR,
    stockexchangeR,
    performancefinancialR
});

const store = createStore(rootReducer, middleware);
export default store;