import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const ReadyMixPlantPatna = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco Vistas Launches Its Second Ready-Mix Plant in Patna | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Vistas Launches Its Second Ready-Mix Plant in Patna" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/ready-mix-plant-Patna"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
            <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/RMX-Patna-II-20Feb24.pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco Vistas Launches Its Second Ready-Mix Plant in Patna</h2>
          
          <p className='onetxt'><strong>Patna, February 21, 2024: </strong>Nuvoco Vistas Corp. Ltd., India’s fifth-largest cement group has widened its operational presence with the inauguration of its second state-of-the-art Ready-mix Concrete Plant (RMX) as <b>Patna-II.</b> Situated strategically at Ranipur Milki Chak, Begampur, bypass, this plant marks Nuvoco's continued commitment for providing top-quality building materials to the Patna region. </p>

          <p>Strategically located approximately 24 kilometers from the first plant, <b>Patna-II</b> is positioned as Nuvoco's regional flagship plant. It capitalizes on untapped market potential by providing quick access to Nuvoco's Cement Plant in the region. Its proximity to nearby Industrial, Commercial, and Residential developments guarantee high-quality concrete solutions on time.</p>

          <p><strong>Mr. Prashant Jha, Chief of the Ready-Mix (RMX) Concrete and Modern Building Materials business at Nuvoco Vistas</strong> expressed his excitement at the inauguration of this latest plant, stating, "The launch of the <b>Patna-II</b> plant underscores Nuvoco's dedication to providing innovative solutions to meet the evolving needs of the construction industry in Bihar. This new facility will not only strengthen our presence in the region but also enable us to serve our customers more efficiently.” </p>
          
          <p>The Patna-II facility has a capacity of 60 cubic meters per hour and features a Twin Shaft Mixer capable of producing all grades of concrete. This includes Value Added Products (VAPs) under the brands of <b>XCON - Xpert</b> concrete with Nuvoco mixes, <b>CONCRETO</b> visualizing Self-Compacting Smart Concrete, Light Weight Concrete (Structural & Non-structural grades), Fibre Enriched Concrete, High Strength Concrete, Water & Crack Resistant Concrete, Foundation Expert Concrete. <b>ECODURE</b> - Range of Green Concrete, <b>ARTISTE</b> - Decorative Concrete Floors, and <b>INSTAMIX</b> - Ready to use Bagged Concrete. </p>

          <p>Patna-II is equipped to meet the construction needs of a wide geographical area, covering Hajipur, Masaurhi, Fatua, Panapur, Sampat Chowk on Gaya Road, and the suburbs of Patna. It is positioned at the crossroads of NH22 and NH31 in eastern Patna that allows for easy access to the central, eastern, and northern areas, reducing travel time and improving market response.</p>

          <p>Nuvoco currently operates 58 Ready-Mix Concrete (RMX) Plants across India including Patna-II, further strengthening its footprints, and aiding in the construction sector's overall development.</p>

        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a cement manufacturing company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999 and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest Cement Group in terms of capacity (23.8 MMTPA) with an INR 10,586 crore total revenue from operations in FY23 and is among the leading players in East India. Nuvoco offers a diversified business portfolio under three business segments - Cement, Ready-Mix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses PAN India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (readyto-use bagged concrete - the first-of-its-kind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to the landmark projects like Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium, Rourkela; Aquatic Gallery Science City, Ahmedabad; and Metro Railway (Delhi, Jaipur, Noida and Mumbai) among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ and ‘InstaMix’ brands, comprises construction chemicals, wall putty and cover blocks. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements.</p>
          <p>Know more about the Company on <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
          <p>For more information contact the Company at  <a href="mailto:india.communications@nuvoco.com">India.communications@nuvoco.com</a></p>
      </Container>
      </div>
    </div >
  )
}

export default ReadyMixPlantPatna