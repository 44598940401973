import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const EcodureThermalInsulatedConcrete = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco Vistas Launches Ecodure Thermal Insulated Concrete on the World Environment Day | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Vistas Launches Ecodure Thermal Insulated Concrete on the World Environment Day" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/launch-ecodure-thermal-insulated-concrete"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
        <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Ecodure-Thermal-Insulated-Concrete-Press-Release.pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco Vistas Launches Ecodure Thermal Insulated Concrete on the World Environment Day</h2>
         
          <p className='onetxt'><strong>Mumbai, 05 June 2024:</strong> - Nuvoco Vistas Corp. Ltd., India's fifth-largest cement group, launches its ground-breaking product, <b>Ecodure Thermal Insulated Concrete, on this World Environment Day</b>. The revolutionary material provides an eco-friendly solution to the challenges of global warming and rising indoor temperatures by enhancing energy efficiency and reducing cooling costs in buildings.</p>

          <p>Ecodure Thermal Insulated Concrete is a technological breakthrough in construction materials, formulated with specialised aggregates that exhibit lower thermal conductivity than conventional concrete. This advanced blend helps reduce indoor temperatures by up to 3°C, providing a sustainable way to combat the rising heat associated with global warming.</p>

          <p>Additionally, it also reduces the cooling load and Energy Use Intensity (EUI) in buildings, resulting in several benefits - a 5% decrease in building EUI, a 6% reduction in space cooling load, and a 7% decrease in cooling capacity requirement. Moreover, its reduced density contributes to a lighter building load, which can lower construction costs. Ecodure also helps reduce buildings' overall carbon footprint through embodied carbon reduction.</p>

          <p>This product is ideal for residential, industrial, and institutional buildings. It will be readily available across India through Nuvoco’s RMX Plants, starting June 5, 2024.</p>

          <p><strong>Mr. Prashant Jha, the Chief of Ready-Mix (RMX) Concrete at Nuvoco Vistas Corp. Ltd, expressed his enthusiasm for the recent launch, stating, </strong> “At Nuvoco, sustainability is paramount, and yet again demonstrated on this World Environment Day by the launch of our innovative product Ecodure Thermal Insulated Concrete. This reflects our commitment to innovation. Ecodure exemplifies our vision of providing solutions that benefit both our clients and the environment by addressing the critical issues of energy consumption and indoor temperature regulation. This launch marks a key milestone in our quest to promote a greener, more energy-efficient future. It is our belief that Ecodure will establish a new industry standard, resulting in more sustainable construction practices and significantly contributing to climate change mitigation.”</p>

        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a building materials company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999 and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest cement group in terms of capacity (25 MMTPA), with INR 10,733 crore in total revenue from operations in FY24. It is among the leading players in East India. Nuvoco offers a diversified business portfolio in three business segments: Cement, Ready-Mix Concrete (RMX), and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes - Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses a pan-India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (ready-to-use bagged concrete - the first-of-its-kind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to landmark projects like the Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium (Rourkela), Aquatic Gallery Science City (Ahmedabad), Metro Railway (Delhi, Jaipur, Noida and Mumbai), and among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ brands, comprises construction chemicals, tile adhesives, wall putty and cover blocks. Through the NABLaccredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements.</p>
          <p>Know more about the Company on to <a href="https://www.nuvoco.com">www.nuvoco.com</a></p>
          <p>For more information, contact the Company at <a href="mailto:india.communications@nuvoco.com">India.communications@nuvoco.com</a></p>
        </Container>
      </div>
    </div >
  )
}

export default EcodureThermalInsulatedConcrete