import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const MasonSkillDevelopmentJamshedpur = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco launched the Nuvo Mason Skill Development Program in Jamshedpur
 | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco launched the Nuvo Mason Skill Development Program in Jamshedpur
" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/mason-skill-development-Jamshedpur"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
            <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Nuvo Mason in JCP - 12Oct23.pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco launched the Nuvo Mason Skill Development Program in Jamshedpur</h2>
          <p><strong>The company offers certified masonry skills training to participants as part of its CSR Initiative </strong></p>
          <p className='onetxt'><strong>Jamshedpur, October 10, 2023: </strong><a href="https://www.nuvoco.com">Nuvoco Vistas Corp Ltd, India's fifth-largest cement group </a>launched the <strong>Nuvo Mason - a Masonry Skill Development Program</strong> as part of its Corporate Social Responsibility initiative to benefit the unorganized masonry sector in Jamshedpur. </p>

          <p>NUVO Mason is a training program designed to improve the masonry skills of workers, empower them, and open new earning opportunities for them. This program consists of approximately four hundred hours of training (theoretical and practical application) on tile masonry (floors and walls). The batch of 25 participants has been selected from the surrounding villages of the company's Jojobera Cement Plant and will undergo training. </p>

          <p><strong>The program was inaugurated by Mr. Uma Suryam Bolla, Senior Vice President - Manufacturing, Nuvoco along with Ms. Jasmika Baskey, District Development Manager, East Singhbhum, NABARD.</strong></p>
          
          <p>Nuvoco has engaged with the Sambhav Foundation as an implementation partner. Sambhav by LabourNet is empowering and improving the lives of the socio-economically marginalized communities and has positively impacted more than 1 million lives across 26 states in India. </p>

          <p><strong>Mr. Uma Suryam Bolla, Senior Vice President - Manufacturing, Nuvoco Vistas Corp. Ltd, commented,</strong> “With this program, Nuvoco intends to provide short-term skills development opportunities for youth and unorganized construction sector workers aligned with the National Skills Qualification Framework to enable them to become financially and socially independent, thus improving their quality of life.”</p>

          <p>NUVO Mason is a flagship skill development program launched by Nuvoco Vistas Corp Ltd in FY2022-23 and spans across the states of Rajasthan, Chhattisgarh, West Bengal, and now Jharkhand. More than 100 youth, including women, have benefited from the program, increasing their incomes, and making their way into mainstream society. </p>

        <p><strong>About Nuvoco Vistas</strong></p>
          <p><a href="https://www.nuvoco.com">Nuvoco Vistas Corporation Limited (“Nuvoco”)</a> is a cement manufacturing company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999, and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest Cement Group in terms of capacity (23.8 MMTPA) with an INR 10,586 crore total revenue from operations in FY23 and is among the leading players in East India. Nuvoco offers a diversified business portfolio under three business segments - Cement, Ready-Mix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses PAN India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (ready-to-use bagged concrete - the first-ofits-kind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to landmark projects like the Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium, Rourkela; Aquatic Gallery Science City, Ahmedabad; and Metro Railway (Delhi, Jaipur, Noida and Mumbai) among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ and ‘InstaMix’ brands, comprises construction chemicals, wall putty and cover blocks. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements.</p>
          <p>Know more about the Company on <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
          <p>For more information contact the Company at  <a href="mailto:india.communications@nuvoco.com">India.communications@nuvoco.com</a></p>
      </Container>
      </div>
    </div >
  )
}

export default MasonSkillDevelopmentJamshedpur