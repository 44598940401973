import React from 'react';
import Container from 'react-bootstrap/Container';

import { Helmet } from "react-helmet";

const ConcretoGlyde = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco launches Concreto Glyde | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Vistas is one of the leading brand in producing cement, ready mix concrete and modern building materials and rated as fifth largest cement group in India" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/financial-result-q4-fy22"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
          <h2 className='grad-heading'>Press Release: Nuvoco launches Concreto Glyde</h2>
          <p>A One-Stop Solution for amazing floor and robust roof in Residentials and Commercials Projects</p>
          
          <p className='onetxt'>
            <strong>Mumbai, July 2022:</strong> Nuvoco Vistas Corp. Ltd., the fifth largest cement group in India and leading cement player in East India in terms of capacity, launched <strong>Concreto Glyde,</strong> a premium quality effective solution designed for interior and exterior flooring underlay in Bhubaneswar, Odisha.</p>

          <p>In addition to its smooth and levelled finish for tile or floor overlays, Concreto Glyde also ensures that projects are completed as per schedule due to its early drying time.</p>

          <p>With the Eastern region levelling back up with the demand for cement, driven by factors and activities at the ground level backed by a seasonally strong period, revival in sand mining and improved labour availability, the market seems ready to take on new advancements in the cement sector.</p>

          <p><strong>Ms. Madhumita Basu, Chief of Marketing, Strategy, and Investor Relations, Nuvoco Vistas Corp Ltd says,</strong> “Our customer-centric approach motivates us to bring innovative solutions to the market. In response to our customer needs, we have developed the Concreto Glyde. Undulating floors, cracked screeds, which reflect through finished floors, have low mechanical strength, crumbly, dusty surfaces, and even poor durability. Concreto Glyde overcomes all these issues by providing a smooth and levelled finish, lightweight, early drying, and self-levelling properties.”</p>

         <p>Speaking about the launch <strong>Mr. Prashant Jha, Chief, Ready-Mix Concrete adds,</strong> “With the launch of Concreto Glyde in Bhubaneshwar, Nuvoco aims to solve the challenges that come with conventional screed and provide solutions needed for the advanced developments. With an increase in the demand for sub-floor, parking area, terrace overlay, we are confident that Concreto Glyde will prevail as one of the best in this sector.”</p>

        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a cement manufacturing company with a vision to build a safer, smarter and sustainable world. Nuvoco started its operations in 1999, and since then it has emerged as one of the major players in the building materials industry in India. Today, Nuvoco offers a diversified business portfolio under three business segments, namely, Cement, Ready-Mix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco is a leading cement player in India and the largest cement company in East India in terms of capacity, following the acquisition of Nu Vista Limited (formerly Emami Cement Limited), offering various brands of cement such as ‘Concreto’, ‘Duraguard’, ‘Nirmax’, ‘Double Bull’, and ‘Infracem’. The MBM product portfolio, under the ‘Zero M’ and ‘Instamix’ brands, comprises construction chemicals, wall putty and cover blocks. The RMX business has a comprehensive pan-India presence offering value-added products under various brands such as ‘Concreto’, ‘Artiste’, ‘InstaMix’ and ‘X-Con’. Nuvoco is a proud contributor to landmark projects such as World One building at Mumbai, and the metro railway projects at Mumbai, Jaipur and Noida. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet the customer requirements. <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
        </Container>
      </div>
    </div >
  )
}

export default ConcretoGlyde