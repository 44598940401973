import React from "react";
import GoogleMap from 'google-map-react';

import {Container, Row, Col, Button, Modal} from 'react-bootstrap';
import PropTypes from 'prop-types';

import footprint01 from '../assets/images/footprint01.png';
import footprint02 from '../assets/images/footprint02.png';
import footprint03 from '../assets/images/footprint03.png';
import footprint04 from '../assets/images/footprint04.png';
import footprint07 from '../assets/images/footprint07.png';
import footprint08 from '../assets/images/footprint08.png';
import footprint09 from '../assets/images/footprint09.png';
import landmark09 from '../assets/images/landmark09.png';
import btnicon from '../assets/images/btnicon.png';
import shouldPureComponentUpdate from 'react-pure-render/function';
import MyGreatPlace from '../helpers/my_great_place';
import { Link } from "react-router-dom";

const importAll = (r) => {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  } 
  
const images  = importAll(require.context('../assets/images', false, /\.(png|jpe?g|svg)$/));

function createMapOptions(maps) {
    return {
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_CENTER,
        style: maps.ZoomControlStyle.SMALL
      },
      mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT
      },
      mapTypeControl: true
    };
  }
const cement = [
    {
        id: 1, 
        lat: 21.9664266552632, 
        lng: 82.3519622704287, 
        title: 'Arasmeta Cement Plant', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Gopalnagar, Dist.:Janjgir-Champa, Chattisgarh - 495663',
        phone: '09893 4971 48/49',
        IsCompany: true,
        ctid:1,
        location:'Chattisgarh',
        link:'https://goo.gl/maps/42XA29ve7rJPBuf9A',
        description:"The integrated unit at Arasmeta in Chhattisgarh has an aggregate cement and clinker production capacity of <b>1.89</b> MMTPA and <b>1.66 MMTPA</b>, respectively. Arasmeta, Kirari and Chilhati mines are associated with it and remain the source of limestone used by the Plant. The Plant remains well connected through road and railway lines for incoming raw material and outgoing clinker to grinding units and dispatch of finished goods. The power requirements for the Plant are met by its <b>18 MW captive power plant, 7 MW of waste heat recovery systems and state electricity grid.</b><br/><br/><p style='margin:0px; height:unset;'><b style='font-size:20px;'><i>Key Differentiator</i></b></p><br/><ul><li>Consuming the lowest pet coke in clinker production among all units.</li></ul>",
        url: 'https://www.google.com/maps/place/Nuvoco+Lafarge+Cement+Factory/@21.8762272,82.1869229,11z/data=!4m5!3m4!1s0x3a27dc823b469c8d:0xc11e16bd60b0d182!8m2!3d21.9663364!4d82.3519704',
        bannerImg: 'arasmeta-cement-plant.jpg'
    },{
        id: 2, 
        lat: 21.730824644448, 
        lng: 82.2141248865066, 
        title: 'Sonadih Cement Plant', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'P.O: Raseda, Dist.: Balodabazar-Bhatapara, Raipur - 493332, Chhattisgarh',
        phone: '09993 0119 92',
        IsCompany: true,
        ctid:1,
        location:'Chattisgarh',
        link:'https://goo.gl/maps/nSVTm6XxPrDmFtVZ8',
        description: 'The integrated unit at Raseda, Sonadih village in Chhattisgarh has an aggregate cement and clinker production capacity of <b>0.65 MMTPA and 3.50 MMTPA</b>, respectively. The limestone is sourced from its mines situated near the plant. The Plant is well connected through road and railway lines for incoming raw material, outgoing clinker to grinding units and dispatch of finished goods. The power requirements are met through its 25 MW captive power plant, 11 MW Waste Heat Recovery systems and the state electricity grid. <br/><br/><p style="margin:0px; height:unset;"><b style="font-size:20px;"><i>Key Differentiator</i></b></p><br/><ul><li>Possessing a clinker production capacity of 3.6 million tonnes with two kilns</li><li>Strengthening mining reserves through ML1 lease</li></ul>',
        url: 'https://www.google.com/maps/place/Sonadih+Cement+Plant+NUVOCO+VISTAS/@21.7306303,82.2140927,17z/data=!3m1!4b1!4m5!3m4!1s0x3a287f7d392186ab:0x9285fb32508925f0!8m2!3d21.7306303!4d82.2140927',
        bannerImg: 'Sonadih-Cement-Plant.png'
    },{
        id: 3, 
        lat: 21.6309455990795, 
        lng: 82.1071178711644 , 
        title: 'Risda Cement Plant', 
        company: 'NU Vista Limited', 
        address: 'Suhela Road, Risda, Dist.: Baloda Bazar, Chhattisgarh – 493332',
        phone: '',
        IsCompany: true,
        ctid:1,
        location:'Chattisgarh',
        link:'https://goo.gl/maps/gjQ91NWerkYzi3vj8',
        description: 'The integrated unit at Risda in Chhattisgarh has an aggregate cement and clinker production capacity of <b>3MMTPA and 4.1 MMTPA</b>, respectively. The limestone is sourced from its mines situated near the plant. The energy requirement for the plant is met through its 30 MW captive coal-based power plant, 15 MW Waste Heat Recovery System and the state electricity grid.<br/><br/><p style="margin:0px; height:unset;"><b style="font-size:20px;"><i>Key Differentiator</i></b></p><br/><ul><li>Utilising Alternate Fuel efficiently through a dedicated AFR pre & co-processing unit</li><li>Consuming the lowest clinkerisation specific heat and specific power</li><li>Holding a robotic XRF/XRD laboratory</li><li>Possessing clinker production capacity of 4 million tonnes</li></ul>', 
        url: 'https://www.google.com/maps/place/NU+Vista+Limited:+Risda+Plant+Chhattisgarh/@21.6307212,82.1071286,17z/data=!3m1!4b1!4m5!3m4!1s0x3a288728ab5329df:0x35a3c392175a2ae2!8m2!3d21.6307212!4d82.1071286',
        bannerImg: 'Risda-Cement-Plant.png'
    },{
        id: 4, 
        lat: 24.7143842876515, 
        lng: 74.6708737134933, 
        title: 'Chittor Cement Plant', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Village: Bhawaliya, P. O: Arniya Joshi, Tehsil: Nimbahera, Dist.: Chhittorgarh - 312620 Rajasthan ',
        phone: '01477 2251 21',
        IsCompany: true,
        ctid:1,
        location:'Rajasthan',
        link:'https://goo.gl/maps/rWQuuhFVz3kjGV5s7',
        description: 'The integrated unit at Bhawaliya, Chittorgarh, in Rajasthan, has an aggregate cement and clinker production capacity of <b>2.60 MMTPA and 1.94 MMTPA</b>, respectively. The Plant sources limestone from the Arniya Joshi mines and Sita Ram Ji Ka Khera mines located near the Plant. The power requirements are met through its 25 MW captive power plant, 7 MW waste heat recovery system, 0.5 MW solar power plant and state electricity grid. The Plant remains well connected by road and railway lines, for incoming raw material and dispatch of finished products.<br/><br/><p style="margin:0px; height:unset;"><b style="font-size:20px;"><i>Key Differentiator</i></b></p><br/><ul><li>Leading the Group in energy efficiency, sustainability and the highest level of safety.</li></ul>',
        url: 'https://www.google.com/maps/place/NUVOCO,+Chittor+Cement+Plant,+Bhawaliya/@24.714165,74.6709059,17z/data=!3m1!4b1!4m5!3m4!1s0x3966199a37cd51a3:0x790825ab961d069!8m2!3d24.714165!4d74.6709059',
        bannerImg: 'Chittor-Cement-Plant1.png'
    },{
        id: 5, 
        lat: 26.3331468141769, 
        lng: 73.8484252495648, 
        title: 'Nimbol Cement Plant', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Village & Post Nimbol,Tehsil: Jaitaran, Dist.: Beawar, Rajasthan - 306308',
        phone: '02939 2261 00',
        IsCompany: true,
        ctid:1,
        location:'Rajasthan',
        link:'https://goo.gl/maps/YP5ZzTx4F8Ak6swd6',
        description: 'The integrated unit at Nimbol in Rajasthan has an aggregate cement and clinker production capacity of <b>2.3 MMTPA and 1.9 MMTPA</b>, respectively. The limestone for this facility is sourced from the Sinla mines and Digrana mines, which are situated near the Plant. The power requirement of the Plant is met through its 25 MW captive power plant, 4.7 MW Waste Heat Recovery system and the state electricity grid.<br/><br/><p style="margin:0px; height:unset;"><b style="font-size:20px;"><i>Key Differentiator</i></b></p><br/><ul><li>Leading the Group in pond ash and drill cut usage</li><li>Achieving zero water usage in raw mill grinding</li><li>Providing residential facilities for contractual staffing & security within the plant vicinity</li></ul>',
        url: 'https://www.google.com/maps/place/Nirma+Cement+Limited+(Cement+Plant)/@26.3322718,73.8440908,16z/data=!4m6!3m5!1s0x396a21fbf1efa59b:0xef167ac79e09c5f6!8m2!3d26.3322718!4d73.8484682!15sChNuaW1ib2wgY2VtZW50IHBsYW50WhUiE25pbWJvbCBjZW1lbnQgcGxhbnSSARNjZW1lbnRfbWFudWZhY3R1cmVymgEkQ2hkRFNVaE5NRzluUzBWSlEwRm5TVU5aTFRWVFQzWlJSUkFC?shorturl=1',
        bannerImg: 'Nimbol-Cement-Plant.png'
    },{
        id: 6, 
        lat: 23.4642073124631, 
        lng: 87.1201241018488, 
        title: 'Mejia Cement Plant', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Village Amdanga, P.O:MTPS (DVC), Dist.: Bankura, West Bengal - 722183',
        phone: '03214 2621 20',
        IsCompany: true,
        ctid:1,
        location:'WestBengal',
        link:'https://goo.gl/maps/wpQsJMGv6NFD4KFp9',
        description: 'The grinding unit at Nandanpur, Mejia, in West Bengal, has an aggregate cement production capacity of <b>1.65 MMTPA</b>. The Plant is supplied with clinker from its integrated units at Sonadih and Arasmeta in Chhattisgarh. The Plant’s power requirement is met through the supply from the DVC plant situated next to the cement plant.<br/><br/><p style="margin:0px; height:unset;"><b style="font-size:20px;"><i>Key Differentiator</i></b></p><br/><ul><li>Consuming the lowest grinding specific power within the group cement ball mills</li><li>Utilising low-cost fly ash from an adjacent power plant.</li></ul>',
        url: 'https://www.google.com/maps/place/Mejia+Cement+Plant,+Laferge+India+Ltd/@23.4640154,87.120049,17z/data=!3m1!4b1!4m5!3m4!1s0x39f70ec7ccee4935:0x779808b97fe3efea!8m2!3d23.4640154!4d87.120049',
        bannerImg: 'Mejia-Cement-Plant.jpg'
    },{
        id: 7, 
        lat: 23.4550417649685, 
        lng: 87.5069388586953, 
        title: 'Panagarh Cement Plant', 
        company: 'NU Vista Limited.', 
        address: 'Plot No. B5A, B6 & B8, Panagarh Industrial Park of WBIDC Block Aushgram - 2, West Bengal 713148',
        phone: '',
        IsCompany: true,
        ctid:1,
        location:'WestBengal',
        link:'https://goo.gl/maps/UsQ4GqGTpc5xBNBk8',
        description: 'The grinding unit at Panagarh in West Bengal has an aggregate cement production capacity of <b>2.5 MMTPA</b>. The Plant receives clinker from the integrated unit at Risda. The Plant has the facility of a railway siding, which was commissioned in September 2018. The power requirement for the Plant is met through the state electricity grid.<br/><br/><p style="margin:0px; height:unset;"><b style="font-size:20px;"><i>Key Differentiator</i></b></p><br/><ul><li>Utilising a dedicated bay for inbound and outbound truck movement</li><li>Handling raw materials via both road and rail through the state-of-art machinery</li></ul>', 
        url: 'https://www.google.com/maps/place/NU+Vista+Limited:+Panagarh+Plant+Kolkata/@23.4383481,87.3716697,11z/data=!4m5!3m4!1s0x39f77be554e98419:0x97d6266c6c846a82!8m2!3d23.4383481!4d87.5117454',
        bannerImg: 'Panagarh-Cement-Plant.png'
    },{
        id: 8, 
        lat: 28.4682053738134, 
        lng: 76.2681151132197, 
        title: 'Haryana Cement Plant', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Village Chiriya, Tehsil: Charkhi Dadri, Dist. Bhiwani - 127306, Haryana',
        phone: '01250 2819 02/03',
        IsCompany: true,
        ctid:1,
        location:'Haryana',
        link:'https://goo.gl/maps/QdKpDmS2vXCYWCcj8',
        description: 'The blending unit at Bhiwani in Haryana has an aggregate cement production capacity of <b>1.20 MMTPA</b>. The plant sources the necessary raw materials from its Chittorgarh and Nimbol Cement Plants and manufactures PPC cement.<br/><br/><p style="margin:0px; height:unset;"><b style="font-size:20px;"><i>Key Differentiator</i></p></b><br/><ul><li>Successfully commissioned a 1.2 MMTPA grinding unit.</li><li>Power requirements were partially met through 1 MW solar power panels installed at the unit.</li></ul>',
        url: 'https://www.google.com/maps/place/Bhiwani+Cement+Plant+NUVOCO+VISTAS+CORP.+LTD/@28.4552268,76.1335326,11z/data=!4m6!3m5!1s0x39129b54c8e0b61d:0xfeda03ff3d520dd0!8m2!3d28.4552268!4d76.2736083!15sChRiaGl3YW5pIGNlbWVudCBwbGFudJIBE2NlbWVudF9tYW51ZmFjdHVyZXI?shorturl=1',
        bannerImg: 'Bhiwani-Cement-Plant.png'
    },/* ,{
        id: 9, 
        lat: 22.7533569019477, 
        lng: 86.2452804342225, 
        title: 'Jojobera Cement Plant', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'P.O: Rahargora, Jamshedpur - 831016',
        phone: '06572 2772 52/53',
        IsCompany: true,
        ctid:1,
        location:'Jharkhand',
        link:'https://goo.gl/maps/uSVdhmbMc6JZccNM6',
    }, */{
        id: 10, 
        lat: 25.2758231259891, 
        lng: 83.4512820795618, 
        title: 'Bihar Cement Plant', 
        company: 'NU Vista Limited.', 
        address: 'Bhabua Cement Works, 1644, Mahmoodpur,Beriya Road, Karmansha, Bhabua,Dist.: Kaimur– 821105. Bihar',
        phone: '',
        IsCompany: true,
        ctid:1,
        location:'Bihar',
        link:'https://goo.gl/maps/FLDaqFYqZ4pFSndB8',
        description: 'The grinding unit at Bhabua in Bihar adds to an aggregate cement production capacity of <b>0.80 MMTPA</b>. The Bihar Cement Plant is supplied with clinker from the integrated unit at Risda, Chhattisgarh. The power requirement for the Plant is met through the state electricity grid.<br/><br/><p style="margin:0px; height:unset;"><b style="font-size:20px;"><i>Key Differentiator</i></b></p><br/><ul><li>Boasting sustainability practice through in-house solar power generation (1.4 MW) for green energy consumption.</li></ul>',
        url: 'https://www.google.com/maps/place/Emami+Cement+Limited/@24.8690725,82.9321781,9z/data=!4m6!3m5!1s0x398e0dd25f17a8d9:0x2fe4d368aac3d129!8m2!3d25.2240293!4d83.461571!15sChNCaGFidWEgQ2VtZW50IFBsYW50WhUiE2JoYWJ1YSBjZW1lbnQgcGxhbnSSARNjZW1lbnRfbWFudWZhY3R1cmVy?shorturl=1',
        bannerImg: 'bhabua-new.png'
        
    },{
        id: 11, 
        lat: 22.3194853126837, 
        lng: 86.0634762360373, 
        title: 'Odisha Cement Plant', 
        company: 'NU Vista Limited.', 
        address: 'Jajpur Cement Works, Kalinga Nagar Industrial Complex (KNIC), At/PO: Manitira, Tehsil - Danagadi, Dist.: Jajpur– 755026. Odisha ',
        phone: '',
        IsCompany: true,
        ctid:1,
        location:'Odisha',
        link:'https://goo.gl/maps/nrPBdptdG2FHDifG9',
        description: 'The grinding unit at Jajpur in Odisha has an aggregate cement production capacity of <b>2 MMTPA</b>. The Jajpur Cement Plant receives clinker by road from its integrated plant at Risda. The power requirement is met through the state electricity grid.<br/><br/><p style="margin:0px; height:unset;"><b style="font-size:20px;"><i>Key Differentiator</i></b></p><br/><ul><li>Leading with the most fuel-efficient HAG operation in the Group</li><li>Utilizing state-of-the-art plant machinery and boasting robust load centers</li></ul>',
        url: 'https://www.google.com/maps/place/NU+Vista+Limited:+Jajpur+Plant+Odisha/@20.148679,64.4423835,4z/data=!4m6!3m5!1s0x3a194edc52d80f6f:0xa28aad0c472cdcd!8m2!3d20.9200217!4d86.0323138!15sChNqYWpwdXIgY2VtZW50IHBsYW50kgETY2VtZW50X21hbnVmYWN0dXJlcg?shorturl=1',
        bannerImg: 'Jajpur-Cement-Plant.png'
    },{
        id: 9, 
        lat: 22.7533569019477, 
        lng: 86.2452804342225, 
        title: 'Jojobera Cement Plant ', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'P.O: Rahargora, Jamshedpur - 831016, Jharkhand',
        phone: '+91-6572-277252 / 53',
        IsCompany: true,
        ctid:1,
        location:'Jharkhand',
        link:'https://goo.gl/maps/uSVdhmbMc6JZccNM6',
        description: 'The grinding unit at Jojobera is situated in the industrial city of Jamshedpur, Jharkhand. It has an aggregate cement production capacity of <b>6.60 MMTPA</b>. The Plant’s power requirement is met through its 27 MW captive power plant and a connection from Tata Power.<br/><br/><p style="margin:0px; height:unset;"><b style="font-size:20px;"><i>Key Differentiator</i></b></p><br/><ul><li>Utilising multiple production lines as an ongoing risk mitigation strategy</li><li>Procuring low-cost BF slag in partnership with leading steel player</li></ul>',
        url: 'https://www.google.com/maps/place/Nuvoco+Vistas+Cement+Plant/@22.7528721,86.2409889,16z/data=!4m6!3m5!1s0x39f5fd453e945c37:0x8f7afbc2d3292124!8m2!3d22.7529138!4d86.2453891!15sChVqb2pvYmVyYSBjZW1lbnQgcGxhbnRaFyIVam9qb2JlcmEgY2VtZW50IHBsYW50kgETY2VtZW50X21hbnVmYWN0dXJlcpoBJENoZERTVWhOTUc5blMwVkpRMEZuU1VSemJHODJOVGgzUlJBQg?shorturl=1',
        bannerImg: 'Jojobera-Cement-Plant.jpg'
    }
    
    
    ,{
        id: 12, 
        lat: 28.4226172, 
        lng: 76.9959861, 
        title: 'Faridabad', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: '14/4 Mile stone, Mathura Road, Faridabad, Haryana – 121003',
        phone: '',
        IsCompany: true,
        ctid:2,
        link: 'https://www.google.com/maps/place/Emami+Cement+Limited/@24.8690725,82.9321781,9z/data=!4m6!3m5!1s0x398e0dd25f17a8d9:0x2fe4d368aac3d129!8m2!3d25.2240293!4d83.461571!15sChNCaGFidWEgQ2VtZW50IFBsYW50WhUiE2JoYWJ1YSBjZW1lbnQgcGxhbnSSARNjZW1lbnRfbWFudWZhY3R1cmVy?shorturl=1'
    },
    
    
    {
        id: 13, 
        lat: 28.4226172, 
        lng: 76.9959861, 
        title: 'Gurgaon', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: '38 Milestone, Bherampur Road, Khandsa, Gurugram ( Haryana) - 122004',
        phone: '',
        IsCompany: true,
        ctid:2,
        link: 'https://www.google.com/maps/place/Emami+Cement+Limited/@24.8690725,82.9321781,9z/data=!4m6!3m5!1s0x398e0dd25f17a8d9:0x2fe4d368aac3d129!8m2!3d25.2240293!4d83.461571!15sChNCaGFidWEgQ2VtZW50IFBsYW50WhUiE2JoYWJ1YSBjZW1lbnQgcGxhbnSSARNjZW1lbnRfbWFudWZhY3R1cmVy?shorturl=1'
    },{
        id: 14, 
        lat: 30.6737641516888, 
        lng: 76.8409481576711, 
        title: 'Panchkula', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'plot no-101, Industrial Area Phase 1, Panchkula - 134113',
        phone: '',
        IsCompany: true,
        ctid:2,
        link: 'https://www.google.com/maps/place/Emami+Cement+Limited/@24.8690725,82.9321781,9z/data=!4m6!3m5!1s0x398e0dd25f17a8d9:0x2fe4d368aac3d129!8m2!3d25.2240293!4d83.461571!15sChNCaGFidWEgQ2VtZW50IFBsYW50WhUiE2JoYWJ1YSBjZW1lbnQgcGxhbnSSARNjZW1lbnRfbWFudWZhY3R1cmVy?shorturl=1'
    },{
        id: 15, 
        lat: 26.9024618, 
        lng: 75.7487774, 
        title: 'Sonepat', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Gold Plus Road, Bahalgarh Chowk, Near Bharat Petrol Pump, Sonepat – Haryana – 131001',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://maps.google.com/maps?cid=6420217397206451636'
    },{
        id: 16, 
        lat: 30.9175999936142, 
        lng: 76.8072495134933, 
        title: 'Baddi', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Khasra 459-462 , Opp.- Hotel Annapurna, Village- Malkumajra, PO-Bhud, Tahsil- Nalagarh Baddi, Solan-HP - 173205',
        phone: '91 (0) 22 6769 2595',
        IsCompany: true,
        ctid:2,
        link:'https://www.google.com/maps?cid=4635808007572265553'
    },{
        id: 17, 
        lat: 20.291566654741, 
        lng: 85.8716836865066, 
        title: 'Bhubaneshwar', 
        company: 'B.L. Auto Industries Ltd.', 
        address: 'Sector 2A, Zone- B, Mancheswar Industrial Estate, Bhubaneswar – 751010',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://maps.google.com/maps?cid=2221014261834131364'
    },{
        id: 18, 
        lat: 25.6101360334187, 
        lng: 85.0426081134933, 
        title: 'Patna', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Mustafapur, Tauzi No. 5276,Thana No. 36, Khata No. 188 and 180, Plot no. 769 and 770, PS: Shahpur, Danapur, Khagul Road, Near St KarensSchool. Bihar - 801503',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://maps.google.com/maps?cid=13516208059414185196'
    },{
        id: 19, 
        lat: 21.2346663083373, 
        lng: 81.7387962288355, 
        title: 'Raipur', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Niraj Kemeliya Pvt Ltd, Khasra No - 467/1,3,4,5,6,7, Vill - Sherikhedi, Near Jindal Steel Plant Raipur, Chhattisgarh - 492101',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://maps.google.com/maps?cid=11987695409573043865'
    },{
        id: 20, 
        lat: 26.9024618, 
        lng: 75.7487774, 
        title: 'Rudrapur', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Vill – Matkotla, Near Chhatarpur Behind – Ashok Leyland, Rudrapur ( U.S Nagar) Uttrakhand – 263153',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://maps.google.com/maps?cid=627801886555965249'
    },{
        id: 21, 
        lat: 22.6080289, 
        lng: 69.8203947, 
        title: 'Harini', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Survey No.688/2, Opp.Darjipura, Airforce Gate, Near KismatKathyavadi, NH-8 Harni, Vadadara',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://goo.gl/maps/3Q3x18c3nyrYcPxk9'
    },{
        id: 22, 
        lat: 21.1827211, 
        lng: 70.4872728, 
        title: 'Haripar', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Survey No.42-1/2, Haripur Industrial Area, Opp.Motal the Village Resort,Kalawad Road, Rajkot',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://goo.gl/maps/7Agyit9udeAbgR23A'
    },{
        id: 23, 
        lat: 23.0942424790196, 
        lng: 72.6653025482562, 
        title: 'Naroda', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Plot No.41, Phase I, Naroda, G.I.D.C, Naroda, Opposite Mother Way Bridge Ahmedabad',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://goo.gl/maps/f5xWcqbQyHpigPys9'
    },{
        id: 24, 
        lat: 22.2966484, 
        lng: 70.7260949, 
        title: 'Rajkot', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'C/o. Raghuvira Industries, Plot No. 3, MadhaparInd.Area, Jamnagar Road, Distt: Rajkot',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://goo.gl/maps/zmHxDT7o4hCDGSUa6'
    },{
        id: 25, 
        lat: 22.9896703604486, 
        lng: 72.4591731576711, 
        title: 'Sanathal', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Plot No. C14, C15 & C16, Behind, Sanghi Cement Godown, Sarkhej-Sanand Rd, Vill. Ulariya, Tal. Sanand, Dist. Ahmedabad',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://maps.google.com/maps?cid=16563935997560771424'
    },{
        id: 26, 
        lat: 21.1829114880503, 
        lng: 72.7285205441777, 
        title: 'Surat', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Plot A-7/1, GIDC Ichhapore, Bhatpore Industrial Estate, Magadalla - Hajira Rd, Surat',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://maps.google.com/maps?cid=9473368759390748847'
    },{
        id: 27, 
        lat: 22.6245149255346, 
        lng: 88.2983430269866, 
        title: 'Dankuni', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'NH 6 Kona More, P.O: Chamrail, P.S: Liluah, Mouza: Khalia, Howrah, West Bengal – 711114',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://maps.google.com/maps?cid=4346046883946019728'
    },
    {
        id: 28, 
        lat: 23.61404865488473, 
        lng: 87.12347564232888, 
        title: 'Durgapur', 
        company: 'Nuvoco Vistas Corp. Ltd.',
        address: 'G/14, Mouza Baktarnagar, J.L Number 30. P.S – Raniganj. At Mangalpur Industrial Estate Raniganj. West Bengal – 713321',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://goo.gl/maps/AiFwBSwU7ZHFat4H7'
    },
    {
        id: 29, 
        lat: 22.5471813386663, 
        lng: 88.5517667, 
        title: 'Hatisala', 
        company: 'Nuvoco Vistas Crop. Ltd. C/O - ABKE Ready-Mix. Ltd.', 
        address: 'AT/PO- Pithapukuria (Near Gabtala Bazaar) PS. Kashipur Dist - 24 Parganas South, West Bengal - 700135',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://goo.gl/maps/NYwhVheCcGDXVEbu8'
    },{
        id: 30, 
        lat: 13.0893937769609, 
        lng: 77.6336501423288, 
        title: 'Hegdenagar', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Survey No : 55 / P 53, Thanisandra Main Road, Land Mark : Near Bellahalli, Cross – 560064 ',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://maps.google.com/maps?cid=6648808131031256256'
    },{
        id: 31, 
        lat: 12.3375889483202, 
        lng: 76.554603129814, 
        title: 'Mysore', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'No. 43/5, Huyilalu Village, YelavalaHobli, Hunsur Bypass Road, Mysore – 571130',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://goo.gl/maps/jjtTTnWyT2MyCYx26'
    },{
        id: 32, 
        lat: 12.98513748779662, 
        lng: 77.70662303456126, 
        title: 'White field ', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'NO.20A , VISVESHWARAIAH INDUSTRIAL AREA, MAHADEVAPURA , BANGALORE -560048',
        phone: '',
        IsCompany: true,
        ctid:2,
        link: 'https://goo.gl/maps/4Vh5NEZCo2zxPzAE7'
    },{
        id: 33, 
        lat: 12.8704826278277, 
        lng: 77.7852801405977, 
        title: 'Sarjapura', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'SY. No .51/1,2 & 3, Sompura Gate, Sarjapura, Bangalore – 562125',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://goo.gl/maps/pZoFFgt3y1p61hkK7'
    },{
        id: 34, 
        lat: 15.4083655358155, 
        lng: 75.0639827999999, 
        title: 'Hubli', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Plot No. 32, Kiadb Industrial Area, Rayapurahubli, Dist. Dharwad – 580009',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://maps.google.com/maps?cid=217634595485315748'
    },{
        id: 35, 
        lat: 12.830416493504249, 
        lng: 77.670211686508, 
        title: 'Anjanapura', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Sy No:32/1, Gollahalli Village, Uttarahalli, Hobli, Anjanapura Post ',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://goo.gl/maps/N168i3ZqJUcauqyL7'
    },{
        id: 36, 
        lat: 17.5218252296681, 
        lng: 78.4497520576711, 
        title: 'Jeedimetla', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Plot No. 8 & 9, Phase Iv, Ida Jeedimetla, Hyderabad – 500055',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://maps.google.com/maps?cid=16494037953585746825'
    },{
        id: 37, 
        lat: 17.5399090657722, 
        lng: 78.2561792576711, 
        title: 'Patencheru', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'SY. No. 808, 811, 812, Plot No.10B, Phase-II, Ida Patancheru, Medak District – 502319',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://maps.google.com/maps?cid=10882357346946821531'
    },{
        id:38, 
        lat: 17.39865443027024, 
        lng: 78.55346820369945, 
        title: 'Uppal', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'B-12/A, IDA Uppal, Hyderabad. – 500039',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://goo.gl/maps/jhiUemVgy5XH13tHA'
    },{
        id:39, 
        lat: 17.531125751000534, 
        lng: 78.3568374134919, 
        title: 'Miyapur', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Survey No:345, Bachupally, Miyapur, Dist-Ranga Reddy, Hyderabad-Telanagana. Near-Volvo service centre.',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://goo.gl/maps/ByWwixm6v6TG2Lq18',
    }/* ,{
        id:40, 
        lat: 22.7532777507268, 
        lng: 86.2454091802492, 
        title: 'Jojobera', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Jojobera Cement Plant, PO - Rahargora, Jamshedpure - East .Singhbhum, Jharkhand - 831016',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://goo.gl/maps/uSVdhmbMc6JZccNM6'
    } */,{
        id:41, 
        lat: 22.7532777507268, 
        lng: 86.2454091802492, 
        title: 'Ranchi', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Vill.- Lal Khatanga, P.O.- Tipudana, P.S.- Hatia, Ranchi - 834003',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://maps.google.com/maps?cid=15773574969723205743'
    },{
        id: 42, 
        lat: 22.8255138186313, 
        lng: 86.0919010747132, 
        title: 'Jamshedpur', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Tata Kandra Main Road, Vill-Pindrabera, Dist-Saraikeklaa, Kharswa, Jharkhand. – 832402',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://goo.gl/maps/Ym7JgCQBDYW1BGpHA'
    },{
        id: 43, 
        lat: 26.6952934826515, 
        lng: 80.9774370865066, 
        title: 'Lucknow', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Mau, Khasra no 94, Old khasra no 1244/7, Tehsil-Mohanlalganj, Lucknow, 226301 ( UP)',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://maps.google.com/maps?cid=13941264937154048735'
    },{
        id: 44, 
        lat: 28.53837896351019, 
        lng: 77.4605413969488, 
        title: 'Noida', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Plot no. 85-90, Industrial area, Toy city, Ecotech-III, Greater noida-201304 (UP)',
        phone: '',
        IsCompany: true,
        ctid:2,
        link: 'https://goo.gl/maps/KsHA68Y6xMN46zNm9'
    },{
        id: 45, 
        lat: 30.8602915646423, 
        lng: 75.9545598612501, 
        title: 'Ludhiana', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Airport Road, Sahnewal, Ludhiana ( Punjab) – 141120',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://goo.gl/maps/SfKXsJE1D6Z17Hb19'
    }, {
        id: 46, 
        lat: 30.720156784344, 
        lng: 76.7070335, 
        title: 'Mohali', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Plot No B-34, industrial Area, Phase- 3, Mohali. - 160055',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://maps.google.com/maps?cid=8069574749506812431'
    },{
        id: 47, 
        lat: 21.056875417349, 
        lng: 79.017807811586, 
        title: 'Nagpur', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Nuvoco Vistas Corp Ltd. Survey No. 78, Mihan, Mouza-Sondapar, Tah-Hingna, Nagpur - 441108',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://goo.gl/maps/5EXDnzZZ1nAnwpua6'
    },{
        id: 48, 
        lat: 18.6752292142229, 
        lng: 73.7335754737571, 
        title: 'Pune', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Nuvoco Vistas Corporation Ltd. Gate No. 23/1/6 Shivar wasti, Akemi business school roads, Near A B Brothers, Marunji, Pune (PIN- 411057)',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://goo.gl/maps/vjLCNA774PUqNdH5A'
    },{
        id: 49, 
        lat: 19.1312401391959, 
        lng: 72.9309991981511, 
        title: 'Runwal Bliss - Project', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Runwal Bliss Project, Crompton Greaves Compound, Station Road, Kanjurmarg (E), Mumbai – 400042',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://g.page/runwal-bliss---official-site?share'
    },{
        id: 50, 
        lat: 19.221974579585503, 
        lng: 72.864309171164, 
        title: 'Oberoi Sky City – Project', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Oberoi Skycity Mall, Datta Pada Road, opp western express Highway, Boriwali East, Mumbai - 400066',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://goo.gl/maps/tev55XkLfwdGaJ8z5',
    },{
        id: 51, 
        lat: 26.789246206970176, 
        lng: 75.8495403950548, 
        title: 'Jaipur', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'PLOT NO. 782 & 783, VILLAGE RAMACHANDRAPURA, TALUK SANGANER, GONER ROAD, SITAPUR INDUSTRIAL ESTATE, JAIPUR- 302022 ',
        phone: '',
        IsCompany: true,
        ctid:2,
        link: 'https://goo.gl/maps/7MEPbniqBjut9MQv9'
    }, {
        id:52, 
        lat: 24.5890869637531, 
        lng: 73.7491661846577, 
        title: 'Udaipur', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Nuvoco Vistas Corp Ltd.204 -A, Mewar industrial area, Road no. 11, near saloni weigh bridge, Madri, Udaipur, Rajasthan - 313003',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://maps.google.com/maps?cid=12810049017441093772'
    },{
        id: 53, 
        lat: 16.45044904043447, 
        lng: 80.5960807120627, 
        title: 'Vijayawada', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Survey No. 1/1, Near Bus Stand, Mangalagiri, Dtdc Courier Vadesswaram Village, Guntur Dist. Vijayawada – 522502',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://goo.gl/maps/w5Ge8bK3x5ZUWPuYA'
    },{
        id: 54, 
        lat: 17.698077798829907, 
        lng: 83.1879910813919, 
        title: 'Vizag ', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Plot No. 235, ‘D’ Block, Auto Nagar, Gajuwaka, Vizag – 530012',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://goo.gl/maps/75QDYZcm2bs9WkRQ6'
    },{
        id: 55, 
        lat: 15.5278832879504, 
        lng: 73.7919210288355, 
        title: 'Pilerne', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'No. 61A, Pilerne Industrial Estate, Pilerne Bardez, North Goa – 403511',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://maps.google.com/maps?cid=15916130720858476861'
    },{
        id: 56, 
        lat: 15.3777359117635, 
        lng: 73.8809105846577, 
        title: 'Vasco', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: 'Plot No 23/26,Chowgule Industries Plots, Zurinagar Sancoale, South Goa – 403726',
        phone: '',
        IsCompany: true,
        ctid:2,
        link:'https://maps.google.com/maps?cid=6666609741862035420'
    },{
        id: 57, 
        lat: 19.07259544506797, 
        lng: 72.87660938763032, 
        title: 'Corporate & Registered Office', 
        address: 'Equinox Business Park, LBS Road, Kurla (West) Mumbai, Maharashtra 400070',
        phone: '+91 (0) 022 6769 2500/525/ 022 6120 2600',
        IsCompany: true,
        ctid:3,
        link:'https://goo.gl/maps/AC1AMrxQBn9tTotm8'
    },
    {
        id: 58, 
        lat: 22.584082650230723, 
        lng: 88.44823655108759, 
        title: 'Eastern Operation Office', 
        company: '', 
        address: 'DLF IT Park-I, Tower C, 10 & 11th Floor, New Town, Rajarhat, Kolkata -700156',
        phone: '+91 (0) 033 4410 2100',
        IsCompany: true,
        ctid:3,
        link:'https://goo.gl/maps/9sPCQ66psAeRUo6Y9'
    },
    {
        id: 59, 
        lat: 22.582735411878204, 
        lng: 88.46141156106951, 
        title: 'Shared Services Centre', 
        company: '', 
        address: 'DLF IT Park - 1, Block C, Tower C , 10th floor, New Town, Rajarhat, Kolkata, West Bengal 700156',
        phone: '+91 (0) 033 4027 1700',
        IsCompany: true,
        ctid:3,
        link:'https://goo.gl/maps/LW89oK5PWY1fDo9e8'
    },
    {
        id: 60, 
        lat: 28.438408605318514, 
        lng: 77.10480447577169, 
        title: 'Northern Operations Office', 
        company: '', 
        address: 'Vatika Tower, 12th floor, Suncity Sector 54, Gurugram, Haryana 122003',
        phone: '+91 (0) 012 4441 2250',
        IsCompany: true,
        ctid:3,
        link:'https://goo.gl/maps/tGB4iSxZsMg9oSDZ6'
    },
    {
        id: 61, 
        lat: 17.42268006312112, 
        lng: 78.46025268120488, 
        title: 'Hyderabad Project Office', 
        company: '', 
        address: 'RVR Tower, Level 4, H.No.6-3-1089/F, Rajbhavan Road, Somajiguda, Hyderabad-500082',
        phone: '+91 (0) 040 2332 0016',
        IsCompany: true,
        ctid:3,
        link:'https://goo.gl/maps/3TTWyASfn8PFMZ7m8'
    },
    
    
    
   {
        id: 63, 
        lat: 17.4223067077299, 
        lng: 78.4622627786428, 
        title: 'Southern Operation Office', 
        company: '', 
        address: 'RVR Tower, 4th Floor, H-no-6-3,1039/F, Raj Bhavan Road, Somajiguda, Hydrabad, Telangana 500082',
        phone: '',
        IsCompany: true,
        ctid:3,
        link:'https://goo.gl/maps/7mp3sRxKSPAn9SDP7'
    },{
        id: 64, 
        lat: 25.6095068456707, 
        lng: 85.141277996906, 
        title: 'Regional Sales Office - Patna', 
        company: '', 
        address: 'Ambition Ram Square, 4th floor, New Dak Bunglow Road, Patna, Bihar 800001',
        phone: '',
        IsCompany: true,
        ctid:3,
        link:'https://goo.gl/maps/7sUFmMQWS9h5yZuh7'
    },{
        id: 65, 
        lat: 21.2405773859793, 
        lng: 81.6841167391533, 
        title: 'Regional Sales Office - Raipur', 
        company: ' ', 
        address: 'Magneto Offizo, 6th Floor, 601, 631, 632 Labhandi, GE Road, Raipur, Chhattisgarh 492001',
        phone: '',
        IsCompany: true,
        ctid:3,
        link:'https://goo.gl/maps/xWHnBpig3mxo8AZ3A'
    },{
        id: 66, 
        lat: 23.027920, 
        lng: 72.496301, 
        title: 'Regional Sales Office - Ahmedabad', 
        company: '', 
        address: 'Block-B-801, Inceptum, B/H. Tranquil, Opp. Planet Land Mark Hotel, Ambli-Bopal Road, Off. SG Highway, Ahemdabad - 380058',
        phone: '',
        IsCompany: true,
        ctid:3,
        link:'https://goo.gl/maps/TS1GTAXc87XV28eb6'
    },{
        id: 67, 
        lat: 23.369363087361, 
        lng: 85.339345269489, 
        title: 'Regional Sales Office - Ranchi', 
        company: '', 
        address: '408-409, Eastern Mall, Circular Road, Ranchi, Jharkhand 834001',
        phone: '',
        IsCompany: true,
        ctid:3,
        link:'https://goo.gl/maps/k3u1SJ3iHXGrgTDe8'
    },{
        id: 68, 
        lat: 22.7538247794548, 
        lng: 75.8975108423109, 
        title: 'Regional Sales Office - Indore', 
        company: '', 
        address: '217/218, Dhan Trident, 2nd floor, Scheme No-54, Vijay Nagar, Near Satya Sai Chouraha, A.B Road, Indore, Madhya Pradesh 452010',
        phone: '',
        IsCompany: true,
        ctid:3,
        link:'https://goo.gl/maps/Ds57VRVK3FX6E8Hj9'
    },{
        id: 69, 
        lat: 21.1489650291474, 
        lng: 79.0520595680612, 
        title: 'Regional Sales Office - Nagpur', 
        company: '', 
        address: '601 Kesava Homes, Amravati Road, Bharatnagar, Nagpur, Maharashtra 440033',
        phone: '',
        IsCompany: true,
        ctid:3,
        link:'https://goo.gl/maps/jEFiqNLUh4kSrPsf7'
    },{
        id: 70, 
        lat: 20.2914797835956, 
        lng: 85.8560145444195, 
        title: 'Regional Sales Office - Bhubaneswar', 
        company: '', 
        address: 'Esplanade Mall, Rasulgarh Industrial Estate, Unit No- 622, 6th Floor, Cuttack Puri Road, Rasulgarh, Bhubanneswar, Odisha 751010',
        phone: '',
        IsCompany: true,
        ctid:3,
        link:'https://goo.gl/maps/LAdBm3ieCD9aYa1E9'
    },{
        id: 71, 
        lat: 26.8829724512559, 
        lng: 75.7994196547452, 
        title: 'Regional Sales Office - Jaipur', 
        company: '', 
        address: 'Signature Tower, DC -2, 9th Floor, Behind Apex Bank, Lal Kothi, Tonk Road, Jaipur, Rajasthan 302015',
        phone: '',
        IsCompany: true,
        ctid:3,
        link:'https://goo.gl/maps/gwhFNp9PFgd2xi2r6'
    },{
        id:72, 
        lat: 24.6012268144055, 
        lng: 73.691548279763, 
        title: 'Regional Sales Office - Udaipur', 
        company: '', 
        address: 'Flat No-4, 14A-1, Near Sukhadia Circle, New Fathepura, Udaipur, Rajasthan 313001',
        phone: '',
        IsCompany: true,
        ctid:3,
        link:'https://goo.gl/maps/akrbmJmN7WWyG2916'
    },{
        id: 73, 
        lat: 25.3177971572038, 
        lng: 82.9882419208358, 
        title: 'Regional Sales Office - Varanasi', 
        company: '', 
        address: 'Shri Prem Complex, C-33/2 Chandua Satti, Opp. Bharat Mata Temple, Chandua Chhittupur, Sigra, Varanasi, Uttar Pradesh 221002',
        phone: '',
        IsCompany: true,
        ctid:3,
        link:'https://goo.gl/maps/4g9xiZdF4rHij5Wy9'
    },


    {
        id: 74, 
        lat: 19.1128421729185, 
        lng: 72.8852717967914, 
        title: 'CDIC Nuvoco', 
        company: 'Nuvoco Vistas Corp. Ltd.', 
        address: '13, Compartment No.42, Marol Cooperative Industrial Estate Rd, Sag Baug, Mumbai, Maharashtra 400059',
        phone: '',
        IsCompany: true,
        ctid:4,
        location:'innovation-center',
        link:'https://www.google.com/maps/place/CDIC+Nuvoco/@19.1126344,72.8830831,17z/data=!4m5!3m4!1s0x3be7c9d45fc2ea81:0x9f3ad9fa83c6dc7a!8m2!3d19.1126293!4d72.8852718'
        
    }

];


const GOOGLE_API_KEY = "AIzaSyCaQm-ZfMkEaCEuN2b-mS6ibXg3F2v5qHg";
class GMapReact extends React.Component {   
    render() {
      const { center, zoom, places} = this.props;
     
      return (
        <div style={{ width: "100%", height: 530 }}>
          <GoogleMap 
            bootstrapURLKeys={{ key: [GOOGLE_API_KEY] }}
            center={center}
            zoom={zoom}
            options={createMapOptions}>
                     {places}
            </GoogleMap>
          
        </div>
      );
    }
  }

class Map extends React.Component{

static propTypes = {
    center: PropTypes.array,
    zoom: PropTypes.number,
    greatPlaceCoords: PropTypes.any,
    greatPlaces: PropTypes.array,
    onCenterChange: PropTypes.func
    };

    static defaultProps = {
        center: [23.84279550796915, 78.505207899788],
        zoom: 5,
        greatPlaces: [...cement]
      };
  shouldComponentUpdate = shouldPureComponentUpdate;

  constructor(props) {
    super();
        this.state = {
          cement: true,
          rmx: false,
          office: false,
          cdic: false,
          center: {
            lat: 23.84279550796915,
            lng: 78.505207899788
          },
          form: {
            lat: 23.84279550796915,
            lng: 78.505207899788
          },
          zoom: 5,
          locationId: 0,
          ctid: 1,
          isOpen: false,
          title: '',
          description: '',
          url: '',
          location: '',
          bannerImg: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.hideComponent = this.hideComponent.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        
  }
  handleChange() {
    this.setState({
      form: {
        lat: Number(this.refs.lat.value),
        lng: Number(this.refs.lng.value)
      }
    });
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  handleClick(slide) {
    this.setState({
      center: {
        lat: slide.lat, 
        lng: slide.lng, 
      },
      zoom: 12,
      locationId: slide.id,
      title: slide.title, description: slide.description, url: slide.url,
      location: slide.location,
      bannerImg: slide.bannerImg
    });
  }

  
  hideComponent(varname) {    
    this.setState({
        center: {
            lat: 23.84279550796915,
            lng: 78.505207899788
        },
        zoom: 5
      });
    switch (varname) {
      case "cement":
        this.setState({ cement: true, rmx:false, office:false,  cdic:false, ctid:1});
        break;
      case "rmx":
        this.setState({ cement: false, rmx:true, office:false,  cdic:false, ctid:2 });
        break;
      case "office":
        this.setState({ cement: false, rmx:false, office:true,  cdic:false, ctid:3 });
        break;
      case "cdic":
        this.setState({ cement: false, rmx:false, office:false,  cdic:true, ctid:4 });
        break;
      default: return;
    }
  }
  
    render(){
        const center = this.state.center;
        const zoom = this.state.zoom;
        const { cement, rmx, office,  cdic} = this.state;
        
        var locations = this.props.greatPlaces.sort((a, b) => (a.title > b.title) ? 1 : -1).filter(x => x.ctid === this.state.ctid);       
        const places = locations
            .map(place => {
                const {id, ...coords} = place;

                return (
                <MyGreatPlace 
                    key={id}
                    {...coords}
                    text={id}
                    hover={this.props.hoverKey === id}
                    locationId={this.state.locationId}
                    id={id}
                     />
                );
            });  
  
        return(         
        <section className="map card_round text-center wow fadeInDown" data-wow-delay="0.5s">
                <img className="a_icon landmark09 wow fadeInUp" data-wow-delay="0.5s" src={landmark09} alt='' />
                <img className="a_icon footprint01 wow fadeInUp" data-wow-delay="0.6s" src={footprint01} alt='' />
                <img className="a_icon footprint02 wow fadeInUp" data-wow-delay="0.7s" src={footprint02} alt='' />
                <img className="a_icon footprint03 wow fadeInUp" data-wow-delay="0.8s" src={footprint03} alt='' />
                <img className="a_icon footprint05 wow fadeInUp" data-wow-delay="0.9s" src={footprint03} alt='' />
                <img className="a_icon footprint04 wow fadeInUp" data-wow-delay="0.10s" src={footprint04} alt='' />
                <img className="a_icon footprint06 wow fadeInUp" data-wow-delay="0.11s" src={footprint04} alt='' />
                <img className="a_icon footprint07 wow fadeInUp" data-wow-delay="0.12s" src={footprint07} alt='' />
                <img className="a_icon footprint08 wow fadeInUp" data-wow-delay="0.13s" src={footprint08} alt='' />
                <img className="a_icon footprint09 wow fadeInUp" data-wow-delay="0.14s" src={footprint09} alt='' />
            <Container> 
                <h1>Our Presence</h1>  
                <div className="button_lt">
                    <Button onClick={() => this.hideComponent("cement")} variant="success" className={cement ? 'active': ''}>Cement</Button>
                    <Button onClick={() => this.hideComponent("rmx")} variant="success" className={rmx ? 'active': ''}>RMX</Button>
                    <Button onClick={() => this.hideComponent("office")} variant="success" className={office ? 'active': ''}>Offices</Button>     
                    <Button onClick={() => this.hideComponent("cdic")} variant="success" className={cdic ? 'active': ''}>CDIC</Button>     
                </div>         
                <Row>
                    <Col>  
                      <div className="map">
                        <GMapReact center={center} zoom={zoom} places={places} />
                      </div>                
                    </Col>
                    <Col md={5}>
                      <div className="mapSidebar">
                            {locations.map((slide, i)=>{
                                return(
                                    <div className={slide.id === this.state.locationId ? 'list_item active' : 'list_item'} key={i} onClick={() => this.handleClick(slide)}>
                                        <h5>{slide.title}</h5>
                                        {slide.IsCompany && <p>{slide.company}</p>}
                                        <p className="read_more">
                                            <span>{slide.address}</span>
                                        </p>
                                        <div className="mapbuttonstyle">
                                        {slide.ctid === 1 ? <Button variant="link" onClick={this.openModal} >Know More</Button> : ''}    
                                        {slide.location && slide.ctid !== 1 ? <Button variant="link" as={Link} to={ this.state.ctid <= 3 ?  'cement-plants/' + slide.location : 'innovation-center/'} >Know More</Button> : ''}
                                            {slide.link ? <a className="locationbtn" href={slide.link} target="_blank" rel="noreferrer">Get Directions</a>: ''}

                                        </div>
                                    </div>
                                )
                            })}
                      </div>
                    </Col> 
                </Row>
                <ul className="notes">
                    <li>*RMX : Ready-Mix Concrete</li>
                    <li>*CDIC : Construction Development & Innovation Centre</li>
                </ul>
            </Container>

            <Modal  show={this.state.isOpen} onHide={this.closeModal} size="xl" centered="true" className="modal_ft">
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        <h1 className="grad-heading">{this.state.location}</h1>
                    </Modal.Title>                
                </Modal.Header>
                <Modal.Body>                    
                    <div className='inbx'>
                    
                    <Row>
                        <Col md={12} lg={6}>
                            <img src={images[this.state.bannerImg]} alt="" className='img-fluid' />
                        </Col>
                        <Col md="12" lg={6}>
                            <article>
                                <h2 className='grad-heading'>{this.state.title}</h2>
                                <div className="popupscroll">
                                     <div dangerouslySetInnerHTML={{ __html: this.state.description }} />
                                </div>
                                <a rel="noreferrer" href={this.state.url} target="_blank"><Button variant="success" className='mt-4'>Get Directions <img src={btnicon} alt="" /></Button></a>{' '}
                            </article>
                        </Col>
                    </Row>              
                
                </div>
                </Modal.Body>           
            </Modal>



            
        </section>
        )
    }
}

export default Map;