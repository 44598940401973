import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const FinancialResultQ4FY23 = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Financial result Q4 FY 23 | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Vistas is one of the leading brand in producing cement, ready mix concrete and modern building materials and rated as fifth largest cement group in India" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/financial-result-q4-fy23"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
          <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/NUVOCO Vistas announces its financial results for Q4 FY23 & FY23.pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: NUVOCO Vistas announces its financial results for Q4 FY23 & FY23</h2>
          
          <ul>
            <li>Consolidated revenue improved 12% QoQ to ₹2,929 crores </li>
            <li>Consolidated cement sales volume improved 17% QoQ to 5.2 MnT</li>
            <li>Consolidated EBITDA improved 41% QoQ to ₹383 crores</li>
            <li>Consolidated Net debt declined by ₹751 crores to ₹4,414 crores from December 31,2022</li>
          </ul>
          <p className='onetxt'>
            <strong>Mumbai, May 09, 2023:</strong>  Nuvoco Vistas Corp. Ltd., a leading building materials Company in India, announced its audited financial results for the quarter and year ended March 31, 2023. With over 23 MTPA of combined installed capacity, Nuvoco Vistas Corp. Ltd. is the fifth largest cement group in India and amongst the leading cement players in East India. </p>

          <p>Consolidated revenue from operations improved by 14% YoY to ₹10,586 for the year ended March 31, 2023. Consolidated cement sales volume improved 5% YoY to 18.8 MnT in FY23. PAT (Profit after tax) for FY23 stands at ₹16 crores.</p>

          <p>The company stays committed to its sustainability initiatives by concentrating on raising the consumption of alternate fuel with an exit rate of 12% TSR (Thermal substitution rate) in FY23. With persistent emphasis on blended cement, Nuvoco has one of the highest cement-to-clinker ratios in the industry (at 1.82 in FY23). More than one-fifth of the company's power consumption comes from clean energy sources, enabling us to minimize our environmental impact and maximize stakeholder value. </p>

          <p>Announcing the results of the quarter, <strong>Mr. Jayakumar Krishnaswamy, Managing Director of Nuvoco Vistas Corp. Ltd.</strong> stated, “The macroeconomic indicators demonstrate a positive outlook for the sector as annual consumer inflation in India eased to 5.7% in March-23 and fuel prices have moderated from their recent highs. Manufacturing and investment activity is expected to increase in the economy due to the Government's emphasis on capital expenditure, better capacity utilization in manufacturing, double-digit credit growth and moderated commodity prices. With the Government's continued focus on developing infrastructure and rural housing, cement demand is expected to remain strong in FY24.” </p>

         <p><strong>He further added,</strong> “We have increased our market share for premium products to 36% on trade volumes in FY23 and this will continue to be a major thrust area for us. Our growth projects in the north, including Bhiwani's 1.2 MTPA cement capacity expansion and debottlenecking at Nimbol, are progressing well and will help us establish a stronger presence in the region. Ready-Mix Concrete (RMX) indicates growth momentum, and we are actively ramping up pan-India plant operations. Our commitment to this business is demonstrated by the back-to-back commissioning of the Guwahati and Coimbatore plants”. </p>

        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a cement manufacturing company with a vision to build a safer, smarter and sustainable world. Nuvoco started its operations in 1999, and since then it has emerged as one of the major players in the building materials industry in India. Today, Nuvoco offers a diversified business portfolio under three business segments, namely, Cement, Ready-Mix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco is a leading cement player in India and the largest cement company in East India in terms of capacity, following the acquisition of Nu Vista Limited (formerly Emami Cement Limited), offering various brands of cement such as ‘Concreto’, ‘Duraguard’, ‘Nirmax’, ‘Double Bull’, and ‘Infracem’. The MBM product portfolio, under the ‘Zero M’ and ‘Instamix’ brands, comprises construction chemicals, wall putty and cover blocks. The RMX business has a comprehensive pan-India presence offering value-added products under various brands such as ‘Concreto’, ‘Artiste’, ‘InstaMix’ and ‘X-Con’. Nuvoco is a proud contributor to landmark projects such as World One building at Mumbai, and the metro railway projects at Mumbai, Jaipur and Noida. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet the customer requirements. <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
          <p>For more information, please contact the Company at <a href="mailto:investor.relations@nuvoco.com">investor.relations@nuvoco.com</a></p>
        </Container>
      </div>
    </div >
  )
}

export default FinancialResultQ4FY23