import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const FibreReinforcedCement = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco Secures Patent for Revolutionary 'Fibre Reinforced Cement Composition' | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Secures Patent for Revolutionary Fibre Reinforced Cement Composition" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/fibre-reinforced-cement"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
            <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Final-PR-Patent-for-Duraguard-Microfibre.pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco Secures Patent for Revolutionary 'Fibre Reinforced Cement Composition'</h2>
          <p>Innovative product offering 'Duraguard Microfiber Cement' with Reinforced Fibre Technology</p>
          <p className='onetxt'><strong>India, August 2023: </strong>Nuvoco Vistas Corp. Ltd., India's fifth-largest cement group in terms of capacity, proudly announces it has been granted a <strong>patent for its revolutionary product, the 'Fibre Reinforced Cement Composition,'</strong> branded in the market as <strong>'Duraguard Microfiber Cement'</strong>. The patent, effective from the date of application - April 4, 2018, cites exclusive rights for the next 20 years.</p>

          <p>The concept of using fibres for concrete reinforcement emerged in the 1960s. Duraguard Microfiber Cement leverages this historical development to revolutionize the Indian cement market. This fibre-based cement significantly enhances the bonding process, improves crack resistance, reduces permeability, improves impact strength and durability, and delivers a smoother surface finish.</p>

          <p>A distinctive feature of Duraguard Microfiber Cement is the visible presence of fibres in the product, which act as micro-reinforcing agents, enabling the cement to withstand seepage, dampness, cracks, shrinkage, and thermal stresses over the years. Manufactured from high-quality clinker with 48-52% Tricalcium Silicate (C3S) and low Tricalcium Aluminate (C3A), the cement contains high-quality electrostatically precipitated dry fly ash, meeting the highest quality standards. </p>

          <p>Duraguard Microfibre Cement finds applications in every stage of construction, from laying foundations to plastering and roof-casting, providing long-lasting protection against micro-cracks. As a result, structures benefit from increased service life and reduced maintenance costs.</p>
          
          <p><strong>On this momentous occasion, Mr. Jayakumar Krishnaswamy, Managing Director, Nuvoco Vistas expressed his delight, stating,</strong> "At Nuvoco, customer experience is paramount to our success, which is why we prioritize innovation. Our Construction Development and Innovation Centre (CDIC) is dedicated to creating sustainable and innovative products that meet the highest global standards. The awarded patent validates our unwavering commitment to innovation and keeps us in the forefront of pioneering advancements in the cement industry delivering exceptional quality and responsible business practices."</p>

        
        <p><strong>About Nuvoco</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a cement manufacturing company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999, and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest Cement Group in terms of capacity (23.8 MMTPA) with an INR 10,586 crore total revenue from operations in FY23 and is among the leading players in East India. Nuvoco offers a diversified business portfolio under three business segments - Cement, ReadyMix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses PAN India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (ready-to-use bagged concrete - the first-of-its-kind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to the landmark projects like Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium, Rourkela; Aquatic Gallery Science City, Ahmedabad; and Metro Railway (Delhi, Jaipur, Noida and Mumbai) among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ and ‘InstaMix’ brands, comprises construction chemicals, wall putty and cover blocks. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements.</p>
          <p>Know more about the Company on <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
          <p>For more information contact the Company at  <a href="mailto:india.communications@nuvoco.com">India.communications@nuvoco.com</a></p>
      </Container>
      </div>
    </div >
  )
}

export default FibreReinforcedCement