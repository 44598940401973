import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const InstaMixSuperiorConcrete = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco Launches InstaMix Superior Column Concrete | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Launches InstaMix Superior Column Concrete | Press Release" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/instaMix-superior-column-concrete"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
            <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Press Release - InstaMix Superior Column Concrete - Final.pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco Launches InstaMix Superior Column Concrete</h2>
          <p>A Revolutionary Solution for Effortless Column Construction </p>
         <p className='onetxt'><strong>India, August 2, 2023 - </strong>Nuvoco Vistas Corp. Ltd., India’s fifth-largest cement group in terms of capacity, introduces the revolutionary product, <strong>InstaMix Superior Column Concrete,</strong> at a customer meet held in Ludhiana. This specialized concrete solution is meticulously designed for column construction, aiming to address the persistent challenges faced by developers and contractors</p>

          <p><strong>Traditionally, column construction has been plagued by problems stemming from delays in pouring concrete, resulting in undesirable honeycombing, and weakened structural integrity</strong> necessitating extensive repairs. However, with InstaMix Superior Column Concrete, these concerns are now a thing of the past. The product’s unique formula provides an extended workability duration of up to four hours, effectively clearing the challenges faced during column construction. </p>

          <p>InstaMix Superior Column Concrete provides a remarkable advantage of early de-shuttering of cast columns. Unlike Ordinary Portland Cement (OPC) concrete with standard mix design, which often demands 7 to 14 days for de-shuttering of cast columns, InstaMix paves the way for early de-shuttering within just 12 to 16 hours, attaining a strength of up to 6MPa, depending on weather conditions. This ensures ease of pouring and sets a new standard for efficiency during construction.</p>

          <p><strong>Speaking about the product, Mr Prashant Jha, Chief of Ready-Mix Concrete and Modern Building Materials Business at Nuvoco, said</strong> “At Nuvoco, quality and customer experience are at the centre stage of our business operations. InstaMix Superior Column Concrete belongs to the renowned InstaMix brand, known for delivering exceptional performance and quality, making it the preferred choice of customers. Furthermore, the ready-to-use concrete and mortar are thoughtfully delivered in 35 kg bags directly to the job site. This benefits the end-user with the convenience of handling, minimal wastage, and faster application.” </p>

          <p>With the introduction of InstaMix Superior Column Concrete, Nuvoco continues to lead the industry by providing innovative solutions that streamline construction processes and enhance overall project efficiency. Contractors and developers across the country can now experience hassle-free column construction with this revolutionary concrete solution.</p>
        
        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a cement manufacturing company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999 and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest Cement Group in terms of capacity (23.8 MMTPA) with an INR 10,586 crore total revenue from operations in FY23 and is among the leading players in East India. Nuvoco offers a diversified business portfolio under three business segments - Cement, Ready-Mix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses PAN India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (ready-to-use bagged concrete - the  first-of-itskind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to the landmark projects like Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium, Rourkela; Aquatic Gallery Science City, Ahmedabad; and Metro Railway (Delhi, Jaipur, Noida and Mumbai) among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ and ‘InstaMix’ brands, comprises construction chemicals, wall putty and cover blocks. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements. </p>
          <p>Know more about the Company on <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
          <p>For more information contact the Company at <a href="mailto: India.communications@nuvoco.com"> India.communications@nuvoco.com</a></p>
        </Container>
      </div>
    </div >
  )
}

export default InstaMixSuperiorConcrete