import React from "react";
import {Breadcrumb} from 'react-bootstrap';

const TopBanner =({title, bannerImg, categoryName}) =>{
    console.log(">><<>>MMMM", categoryName);
    return(
        <div className="banner text-center position-relative">
            <img src={bannerImg} alt="" />
            <div className='breadouter'>
                <div className='container'>
                    <Breadcrumb className='mb-0 py-2'>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="#">{categoryName && categoryName == "1" ? "MBM" : categoryName && categoryName == "2" ? "Cement" : "Ready-Mix Concrete"}</Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            {title}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
        </div>
    )
}

export default TopBanner;