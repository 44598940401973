import { PRODUCT } from "./type";
import { API_URL } from "../config";
import axios from "axios";

const productFn = (data) =>{
    return{
        type: PRODUCT,
        payload: data
    };
}

export const productApi = (page_type) =>{
    let data ={page_url: page_type};
    return (dispatch) =>{
        axios.post(API_URL + 'ProductDetails', data).then((response) =>{
            dispatch(productFn(response));
        });
    };
}