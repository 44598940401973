import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const AFRProjectRisda = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco successfully completes AFR Project at its
Risda Cement Plant, Chhattisgarh and Nimbol Cement Plant, Rajasthan | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco successfully completes AFR Project at its
Risda Cement Plant, Chhattisgarh and Nimbol Cement Plant, Rajasthan | Press Release" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/afr-project-risda"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
            <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Press Release-Commissioning-of-AFR-at-Risda-and-Nimbol.pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco successfully completes AFR Project at its Risda Cement Plant, Chhattisgarh and Nimbol Cement Plant, Rajasthan</h2>
          <p>Reducing fossil fuel dependence and driving sustainability </p>
         <p className='onetxt'><strong>National, June 23, 2023: </strong>Nuvoco Vistas Corp. Ltd., India’s fifth-largest cement group in terms of capacity and among the leading players in East India, successfully announced the completion of its Alternative Fuel Resource (AFR) project at Risda Cement Plant (RCP), Chhattisgarh and Nimbol Cement Plant (NCP), Rajasthan. This milestone achievement illustrates Nuvoco's commitment to reducing dependency on fossil fuels and promoting sustainable practices within the cement industry. With the use of alternative fuels, the company strives to reduce the usage of fossil fuels and minimize its environmental impact</p>

          <p>Alternative fuels are a crucial step towards reducing carbon emissions and mitigating the adverse effects of climate change. Nuvoco has dedicatedly installed a system that is capable of utilizing agricultural waste, RDF (Refused Derived Fuel), plastic waste, municipal waste, biomass, tyre chips and other hazardous waste sources. By incorporating these alternative fuels into its manufacturing process, Nuvoco is taking significant strides towards achieving its sustainability goals.</p>

          <p>Nuvoco has introduced AFR feeding into the Pyro-process system for enabling uniform feeding and incorporating all necessary safety interlocks throughout. This system allows us to consume alternate fuels in an efficient and effective way without impacting the environmental standards prescribed and approved by the Pollution Control Board of India. Although this project is primarily focused on environmental sustainability, it also has several other benefits for clinker production and can offer significant cost savings through its alternative fuels program. </p>

          <p><strong>Mr. Jayakumar Krishnaswamy, Managing Director, Nuvoco </strong>stated. "As part of our PROTECT OUR PLANET agenda, we view the AFR project as an important step towards fulfilling our commitment to sustainable cement manufacturing and reducing the environmental impacts. The successful completion of this project demonstrates our commitment to reducing carbon emissions, maximizing waste consumption, and demonstrating responsible resource management practices.”</p>

          <p><strong>Additionally, he stated,</strong> “The adoption of alternative fuels also aids in resolving issues associated with landfills and unhygienic waste accumulation. For instance, in its cement manufacturing process, Nuvoco uses plastic waste, RDF (Refused Derived Fuel), and municipal waste that is harmful to cattle and other wildlife due to its carcinogenic qualities as an alternative fuel. This along with other themes under PROTECT OUR PLANET agenda are enabling Nuvoco to build robust partnerships with several stakeholders and contribute to society at large.”</p>
        
        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a cement manufacturing company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999 and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest Cement Group in terms of capacity (23.8 MMTPA) with an INR 10,586 crore total revenue from operations in FY23 and is among the leading players in East India. Nuvoco offers a diversified business portfolio under three business segments - Cement, Ready-Mix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses PAN India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (readyto-use bagged concrete - the first-of-its-kind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to the landmark projects like Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium, Rourkela; Aquatic Gallery Science City, Ahmedabad; and Metro Railway (Delhi, Jaipur, Noida and Mumbai) among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ and ‘InstaMix’ brands, comprises construction chemicals, wall putty and cover blocks. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements. </p>
          <p>Know more about the Company on <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
          <p>For more information contact the Company at <a href="mailto: India.communications@nuvoco.com"> India.communications@nuvoco.com</a></p>
        </Container>
      </div>
    </div >
  )
}

export default AFRProjectRisda