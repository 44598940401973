import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Breadcrumb,
  Button,
  Form,
  Alert,
} from "react-bootstrap";

import banner from "../assets/images/investors_corner.png";
import bannermobile from "../assets/images/investor-corner-mobile.jpg";
import aboutBannerLogo from "../assets/images/investericon.png";
import chart from "../assets/images/chart.jpg";
import Iframe from "react-iframe";
import PdfCard from "../common/pdfCard";
import { imgUrl } from "../redux/config";
import {
  investorcalltransApi,
  presentationApi,
  presentationsearchsearchApi,
  audioVideoApi,
} from "../redux";
import { connect } from "react-redux";
import sortby from "../assets/images/sortby.png";
import { data } from "autoprefixer";

let IsData = false;
let status = null;
const InvestorsCorner = ({
  investorcalltransApi,
  investercalltrans,
  presentationApi,
  presentation,
  presentationsearchsearchApi,
  presentationsearch,
  audioVideoApi,
  audiovideo,
}) => {
  useEffect(() => {
    investorcalltransApi(6, 7);
    presentationApi(1);
    presentationsearchsearchApi(6, 7);
  }, []);

  const [IsTab, setTabType] = useState(1);
  const [pdfList, setPdfList] = useState([]);
  const [finDocument, setfinDocument] = useState([]);
  const [sortBy, setSort] = useState(1);
  const [IsTrans, setTrans] = useState(1);
  const [year, setYear] = useState(7);

  const [IsTab1, setTabType1] = useState(5);
  const [IsTab2, setTabType2] = useState(10);
  const [IsA, SetA] = useState(7);

  const setTab = (id) => {
    setTabType(id);
    if (id === 4) {
      presentationsearchsearchApi(6, 7);
    } else if (id === 3) {
      setTrans(1);
      audioVideoApi(year);
    }
  };

  const setTab1 = (id) => {
    setTabType1(id);
  };

  if (Object.keys(presentation).length) {
    status = presentation.data.status;
    if (status === 200) {
      const resData = { ...presentation.data };
      var finYear = JSON.parse(resData.fin_year);
    }
  }
  const change = (type) => {
    if (IsTab === 4) {
      setData(type.target.value);
    } else if (IsTab === 3) {
      audioVideoApi(type.target.value);
    } else if (IsTrans === 3) {
      setDataTrans(type.target.value);
    }
    setYear(parseInt(type.target.value));
  };

  const changeTrans = (type) => {
    if (IsTrans === 1) {
      SetA(type.target.value);
      audioVideoApi(type.target.value);
    } else {
      setDataTrans(type.target.value);
    }
    // setDataTrans(type.target.value);
    // audioVideoApi(type.target.value);
  };

  const setData = (y) => {
    presentationsearchsearchApi(6, y);
  };

  const setDataTrans = (y) => {
    investorcalltransApi(6, y);
  };

  const sortData = (data, t) => {
    let copy;
    if (t > 0) {
      copy = [...data];
    } else {
      copy = [...pdfList];
    }

    sortBy === 1
      ? copy.sort((a, b) =>
          new Date(a.created_at) < new Date(b.created_at) ? 1 : -1
        )
      : copy.sort((a, b) =>
          new Date(a.created_at) > new Date(b.created_at) ? 1 : -1
        );
    setSort(sortBy === 1 ? 2 : 1);
    setPdfList(copy);
  };
  const sortData1 = (data, t) => {
    let copy;
    if (t > 0) {
      copy = [...data];
    } else {
      copy = [...finDocument];
    }

    sortBy === 1
      ? copy.sort((a, b) =>
          new Date(a.created_at) < new Date(b.created_at) ? 1 : -1
        )
      : copy.sort((a, b) =>
          new Date(a.created_at) > new Date(b.created_at) ? 1 : -1
        );
    setSort(sortBy === 1 ? 2 : 1);
    setfinDocument(copy);
  };

  if (investercalltrans.IsSuccess === 1) {
    investercalltrans.IsSuccess = 0;
    const resData = { ...investercalltrans.data };
    sortData1(JSON.parse(resData.fin_docs), 2);
  }

  if (presentationsearch.IsSuccess === 1) {
    presentationsearch.IsSuccess = 0;
    IsData = true;
    const pdfData = { ...presentationsearch.data };
    sortData(JSON.parse(pdfData.fin_docs), 1);
  }

  const changeTranscript = (id) => {
    setTrans(id);
    if (id === 2 || id === 1) {
      audioVideoApi(year);
    }
  };

  if (audiovideo.IsSuccess === 1) {
    audiovideo.IsSuccess = 0;
    IsData = true;
    const data = { ...audiovideo.data };
    var audiovideoList = JSON.parse(data.video);
    var audioList = JSON.parse(data.audio);
    console.log(audioList);
  }

  return (
    <>
      <div className="inv_corn">
        <div className="banner text-center position-relative">
          <img className="desktopbannerinner" src={banner} alt="" />
          <img className="mobilebannerinner" src={bannermobile} alt="" />
          <article className="position-absolute text-center">
            <span className="d-flex justify-content-center align-items-center mx-auto">
              <img src={aboutBannerLogo} alt="" />
            </span>
            <h1 className="text-uppercase">Investors Corner</h1>
          </article>
          <div className="breadouter">
            <div className="container">
              <Breadcrumb className="mb-0 py-2">
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Investor Corner</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
        <Container className="py-5 text-center">
          <Button
            onClick={() => setTab(4)}
            variant="success"
            className={IsTab === 4 ? "active btn_lg" : "btn_lg"}
          >
            Quarterly Presentations
          </Button>
          <Button
            onClick={() => setTab(3)}
            variant="success"
            className={IsTab === 3 ? "active btn_lg" : "btn_lg"}
          >
            Investor Call
          </Button>
          <Button
            onClick={() => setTab(2)}
            variant="success"
            className={IsTab === 2 ? "active btn_lg" : "btn_lg"}
          >
            Credit Ratings
          </Button>
          <Button
            onClick={() => setTab(1)}
            variant="success"
            className={IsTab === 1 ? "active btn_lg" : "btn_lg"}
          >
            Stock Information
          </Button>

          {(IsTab === 1 && (
            <Container>
              <Button
                onClick={() => setTab1(5)}
                variant="success"
                className={IsTab1 === 5 ? "active btn_lg" : "btn_lg"}
              >
                NSE
              </Button>
              <Button
                onClick={() => setTab1(6)}
                variant="success"
                className={IsTab1 === 6 ? "active btn_lg" : "btn_lg"}
              >
                BSE
              </Button>
              {(IsTab1 === 5 && (
                <div className="card_round mb-5">
                  <Row className="justify-content-center">
                    <Col md={8}>
                      <Card
                        className="chart_lt wow fadeInUp"
                        data-wow-delay="0.5s"
                      >
                        {/* <Card.Img variant="top" src={chart} /> */}

                        {/* <Iframe url="http://api.stockdio.com/visualization/financial/charts/v1/HistoricalPrices?app-key=0075232F0BF24DDDA114FA7A94662700&stockExchange=NSE&symbol=NUVOCO&dividends=true&splits=true&palette=Financial-Light=Title"
                                    width="100%"
                                    height="500px"
                                    id="myId"
                                    className="myClassname"
                                    display="initial"
                                    position="relative"/> */}

                        <Iframe
                          frameBorder="0"
                          scrolling="no"
                          width="800"
                          height="420"
                          //old app key: 0075232F0BF24DDDA114FA7A94662700, 01DDB5782D044CD08F291392EFF4C696, New added by Neeraj 
                          src="https://api.stockdio.com/visualization/financial/charts/v1/HistoricalPrices?app-key=907FAF96DAA74AD6B7E77FA1A2AA7F5A&stockExchange=NSE&symbol=NUVOCO&dividends=true&splits=true&palette=Financial-Light&showLogo=No"
                        ></Iframe>
                      </Card>
                    </Col>
                  </Row>
                </div>
              )) ||
                ""}
              {(IsTab1 === 6 && (
                <div className="card_round mb-5">
                  <Row className="justify-content-center">
                    <Col md={8}>
                      <Card
                        className="chart_lt wow fadeInUp"
                        data-wow-delay="0.5s"
                      >
                        <Iframe
                          frameBorder="0"
                          scrolling="no"
                          width="800"
                          height="420"
                          src="https://api.stockdio.com/visualization/financial/charts/v1/HistoricalPrices?app-key=907FAF96DAA74AD6B7E77FA1A2AA7F5A&stockExchange=BSE&symbol=NUVOCO&dividends=true&splits=true&palette=Financial-Light"

                         

                        ></Iframe>
                      </Card>
                    </Col>
                  </Row>
                </div>
              )) ||
                ""}
            </Container>
          )) ||
            ""}

          {(IsTab === 2 && (
            <Container>
              <table className="table table-bordered border-primary">
                <tr>
                  <th>Instrument</th>
                  <th>Rating Agency</th>
                  <th>Rating</th>
                </tr>
                <tr>
                  <td>Non-Convertible Debentures (NCDs)</td>
                  <td>
                    <div className="border-bottom border-1 border-dark py-1">
                      CRISIL
                    </div>
                    <div className="false py-1">
                      India Rating and Research (Ind-Ra)
                    </div>
                  </td>
                  <td>
                    <div className="border-bottom border-1 border-dark py-1">
                      CRISIL AA/Stable
                    </div>
                    <div className="false py-1">IND AA / Positive</div>
                  </td>
                </tr>
                <tr>
                  <td>Perpetual Non-Convertible Debentures</td>
                  <td>
                    <div className="border-bottom border-1 border-dark py-1">
                      CRISIL
                    </div>
                    <div className="false py-1">
                      India Rating and Research (Ind-Ra)
                    </div>
                  </td>
                  <td>
                    <div className="border-bottom border-1 border-dark py-1">
                      CRISIL AA-/Stable
                    </div>
                    <div className="false py-1">IND AA- / Positive</div>
                  </td>
                </tr>
                <tr>
                  <td>Rupee Term Loan</td>
                  <td>
                    <div className="false py-1">CRISIL</div>
                  </td>
                  <td>
                    <div className="false py-1">CRISIL AA/Stable</div>
                  </td>
                </tr>
                <tr>
                  <td>Commercial Paper</td>
                  <td>
                    <div className="border-bottom border-1 border-dark py-1">
                      CRISIL
                    </div>
                    <div className="false py-1">
                      India Rating and Research (Ind-Ra)
                    </div>
                  </td>
                  <td>
                    <div className="border-bottom border-1 border-dark py-1">
                      CRISIL A1+
                    </div>
                    <div className="false py-1">IND A1+</div>
                  </td>
                </tr>
                <tr>
                  <td>Working Capital Limits (Fund Based)</td>
                  <td>
                    <div className="border-bottom border-1 border-dark py-1">
                      CRISIL
                    </div>
                    <div className="false py-1">
                      India Rating and Research (Ind-Ra)
                    </div>
                  </td>
                  <td>
                    <div className="border-bottom border-1 border-dark py-1">
                      CRISIL AA/Stable
                    </div>
                    <div className="false py-1">IND AA / Positive</div>
                  </td>
                </tr>
                <tr>
                  <td>Working Capital Limits (Non Fund Based)</td>
                  <td>
                    <div className="border-bottom border-1 border-dark py-1">
                      CRISIL
                    </div>
                    <div className="false py-1">
                      India Rating and Research (Ind-Ra)
                    </div>
                  </td>
                  <td>
                    <div className="border-bottom border-1 border-dark py-1">
                      CRISIL A1+
                    </div>
                    <div className="false py-1">IND A1+</div>
                  </td>
                </tr>
              </table>
            </Container>
          )) ||
            ""}

          {(IsTab === 3 && (
            <Container>
              <div className="per_hig crop_gov tran_bt">
                <Button
                  onClick={() => changeTranscript(1)}
                  variant="success"
                  className={IsTrans === 1 ? "active btn_lg" : "btn_lg"}
                >
                  Audio
                </Button>
                {/* <Button onClick={()=> changeTranscript(2)} variant="success" className={ IsTrans === 2 ? 'active btn_lg' : 'btn_lg'}>Video</Button> */}
                <Button
                  onClick={() => changeTranscript(3)}
                  variant="success"
                  className={IsTrans === 3 ? "active btn_lg" : "btn_lg"}
                >
                  Transcript
                </Button>
                <div className="selectbx d-flex align-items-center justify-content-center mb-2 mt-4 pb-4">
                  <label>Year:</label>
                  {finYear && (
                    <Form.Select
                      defaultValue={year}
                      onChange={changeTrans}
                      aria-label="Default select example"
                    >
                      {finYear.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.year}
                          </option>
                        );
                      })}
                    </Form.Select>
                  )}
                </div>
                <div className="card_round mb-5">
                  {IsTrans === 3 && year === 7 && (
                    <>
                      <div className="sortBy mb-2">
                        <Button
                          variant="success"
                          onClick={sortData1}
                          className={
                            sortBy === 1
                              ? "ml-2 read_more active"
                              : "ml-2 read_more"
                          }
                        >
                          <img src={sortby} alt="" /> Sort By
                        </Button>
                      </div>
                      {finDocument && (
                        <Row className="justify-content-center mb-3 ">
                          {finDocument.map((doc, i) => {
                            return <PdfCard bd={doc} uri={imgUrl} key={i} />;
                          })}
                        </Row>
                      )}
                    </>
                  )}
                  {IsTrans === 2 && (
                    <Row className="justify-content-center">
                      {audiovideoList &&
                        audiovideoList.map((av, index) => {
                          return (
                            <Col md={6} className="mt-3" key={index}>
                              <Iframe
                                height="340"
                                src={JSON.parse(av.video_url)}
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                              ></Iframe>
                            </Col>
                          );
                        })}
                      {audiovideoList && audiovideoList.length === 0 && (
                        <Col md={6} className="mt-3">
                          <Alert variant="danger">
                            <Alert.Heading>Video Not Available</Alert.Heading>
                          </Alert>
                        </Col>
                      )}
                    </Row>
                  )}
                  {IsTrans === 1 && (
                    <Row className="justify-content-center">
                      {/* {audioList && audioList.map((au, i)=>{
                                    return(
                                        <Col className="col-xl-4" md={5} key={i}>
                                            <Card className="wow fadeInUp" data-wow-delay="0.5s">
                                            <div Classname="row">
                                        
                                            <Col className="col-xl-12">
                                                <audio controls className="row">
                                                    <source src={ imgUrl + au.audio_name} type="audio/mpeg"></source>
                                                </audio>
                                                </Col>
                                                <Col className="col-xl-12 text-center text-break"><strong>{au.title}</strong></Col>
                                                </div>
                                            </Card>
                                        </Col>
                                    )
                                })} */}
                    {IsA == 10 && (
                        <Row className="justify-content-center">
                          <Col className="col-xl-4" md={5}>
                            <Card
                              className="wow fadeInUp"
                              data-wow-delay="0.5s"
                            >
                              <div Classname="row">
                                <Col className="col-xl-12">
                                  <audio controls className="row">
                                    <source
                                      src="http://admin.nuvoco.com/public/Audio_Data/Audio-Recording-Q1-FY25.mp3"
                                      type="audio/mpeg"
                                    ></source>
                                  </audio>
                                </Col>
                                <Col className="col-xl-12 text-center text-break">
                                  <strong>Audio Recording Q1 FY25</strong>
                                </Col>
                              </div>
                            </Card>
                          </Col>
                          <Col className="col-xl-4" md={5}>
                            <Card
                              className="wow fadeInUp"
                              data-wow-delay="0.5s"
                            >
                              <div Classname="row">
                                <Col className="col-xl-12">
                                  <audio controls className="row">
                                    <source
                                      src="http://admin.nuvoco.com/public/Audio_Data/Audio-Recording-Q2-FY25.mp3"
                                      type="audio/mpeg"
                                    ></source>
                                  </audio>
                                </Col>
                                <Col className="col-xl-12 text-center text-break">
                                  <strong>Audio Recording Q2 FY25</strong>
                                </Col>
                              </div>
                            </Card>
                          </Col>
                          
                        </Row>
                      )}

                      {IsA == 9 && (
                        <Row className="justify-content-center">
                          <Col className="col-xl-4" md={5}>
                            <Card
                              className="wow fadeInUp"
                              data-wow-delay="0.5s"
                            >
                              <div Classname="row">
                                <Col className="col-xl-12">
                                  <audio controls className="row">
                                    <source
                                      src="http://admin.nuvoco.com/public/Audio_Data/Audio-Recording_Q1_FY24.mp3"
                                      type="audio/mpeg"
                                    ></source>
                                  </audio>
                                </Col>
                                <Col className="col-xl-12 text-center text-break">
                                  <strong>Audio Recording Q1 FY24</strong>
                                </Col>
                              </div>
                            </Card>
                          </Col>
                          <Col className="col-xl-4" md={5}>
                            <Card
                              className="wow fadeInUp"
                              data-wow-delay="0.5s"
                            >
                              <div Classname="row">
                                <Col className="col-xl-12">
                                  <audio controls className="row">
                                    <source
                                      src="http://admin.nuvoco.com/public/Audio_Data/Audio-Recording-Q2-FY24.mp3"
                                      type="audio/mpeg"
                                    ></source>
                                  </audio>
                                </Col>
                                <Col className="col-xl-12 text-center text-break">
                                  <strong>Audio Recording Q2 FY24</strong>
                                </Col>
                              </div>
                            </Card>
                          </Col>
                          <Col className="col-xl-4" md={5}>
                            <Card
                              className="wow fadeInUp"
                              data-wow-delay="0.5s"
                            >
                              <div Classname="row">
                                <Col className="col-xl-12">
                                  <audio controls className="row">
                                    <source
                                      src="http://admin.nuvoco.com/public/Audio_Data/Audio-Recording-Q3-FY24.mp3"
                                      type="audio/mpeg"
                                    ></source>
                                  </audio>
                                </Col>
                                <Col className="col-xl-12 text-center text-break">
                                  <strong>Audio Recording Q3 FY24</strong>
                                </Col>
                              </div>
                            </Card>
                          </Col>
                          <Col className="col-xl-4" md={5}>
                            <Card
                              className="wow fadeInUp"
                              data-wow-delay="0.5s"
                            >
                              <div Classname="row">
                                <Col className="col-xl-12">
                                  <audio controls className="row">
                                    <source
                                      src="http://admin.nuvoco.com/public/Audio_Data/Audio-Recording-Q4-FY24.mp3"
                                      type="audio/mpeg"
                                    ></source>
                                  </audio>
                                </Col>
                                <Col className="col-xl-12 text-center text-break">
                                  <strong>Audio Recording Q4 FY24</strong>
                                </Col>
                              </div>
                            </Card>
                          </Col>
                        </Row>
                      )}
                      {IsA == 7 && (
                        <Row className="justify-content-center">
                          <Col className="col-xl-4" md={5}>
                            <Card
                              className="wow fadeInUp"
                              data-wow-delay="0.5s"
                            >
                              <div Classname="row">
                                <Col className="col-xl-12">
                                  <audio controls className="row">
                                    <source
                                      src="http://admin.nuvoco.com/public/Audio_Data/Audio_Recording_Q4FY22.mp3"
                                      type="audio/mpeg"
                                    ></source>
                                  </audio>
                                </Col>
                                <Col className="col-xl-12 text-center text-break">
                                  <strong>Audio Recording Q4 FY22</strong>
                                </Col>
                              </div>
                            </Card>
                          </Col>
                        </Row>
                      )}

                      {IsA == 8 && (
                        <Row className="justify-content-center">
                          <Col className="col-xl-4" md={5}>
                            <Card
                              className="wow fadeInUp"
                              data-wow-delay="0.5s"
                            >
                              <div Classname="row">
                                <Col className="col-xl-12">
                                  <audio controls className="row">
                                    <source
                                      src="http://admin.nuvoco.com/public/Audio_Data/Audio_Recording_Q1FY23.mp3"
                                      type="audio/mpeg"
                                    ></source>
                                  </audio>
                                </Col>
                                <Col className="col-xl-12 text-center text-break">
                                  <strong>Audio Recording Q1 FY23</strong>
                                </Col>
                              </div>
                            </Card>
                          </Col>
                          <Col className="col-xl-4" md={5}>
                            <Card
                              className="wow fadeInUp"
                              data-wow-delay="0.5s"
                            >
                              <div Classname="row">
                                <Col className="col-xl-12">
                                  <audio controls className="row">
                                    <source
                                      src="http://admin.nuvoco.com/public/Audio_Data/Audio_Recording_Q2FY23.mp3"
                                      type="audio/mpeg"
                                    ></source>
                                  </audio>
                                </Col>
                                <Col className="col-xl-12 text-center text-break">
                                  <strong>Audio Recording Q2 FY23</strong>
                                </Col>
                              </div>
                            </Card>
                          </Col>
                          <Col className="col-xl-4" md={5}>
                            <Card
                              className="wow fadeInUp"
                              data-wow-delay="0.5s"
                            >
                              <div Classname="row">
                                <Col className="col-xl-12">
                                  <audio controls className="row">
                                    <source
                                      src="http://admin.nuvoco.com/public/Audio_Data/NVC0220230207145447.mp3"
                                      type="audio/mpeg"
                                    ></source>
                                  </audio>
                                </Col>
                                <Col className="col-xl-12 text-center text-break">
                                  <strong>Audio Recording Q3 FY23</strong>
                                </Col>
                              </div>
                            </Card>
                          </Col>
                          <Col className="col-xl-4" md={5}>
                            <Card
                              className="wow fadeInUp"
                              data-wow-delay="0.5s"
                            >
                              <div Classname="row">
                                <Col className="col-xl-12">
                                  <audio controls className="row">
                                    <source
                                      src="http://admin.nuvoco.com/public/Audio_Data/Audio_Recording_Q4_FY23.mp3"
                                      type="audio/mpeg"
                                    ></source>
                                  </audio>
                                </Col>
                                <Col className="col-xl-12 text-center text-break">
                                  <strong>Audio Recording Q4 FY23</strong>
                                </Col>
                              </div>
                            </Card>
                          </Col>
                        </Row>
                      )}

                      {/* {audioList && audioList.length === 0 && (
                        <Col md={6} className="mt-3">
                          <Alert variant="danger">
                            <Alert.Heading>Audio Not Available</Alert.Heading>
                          </Alert>
                        </Col>
                      )} */}
                    </Row>
                  )}
                </div>
              </div>
            </Container>
          )) ||
            ""}

          {(IsTab === 4 && (
            <Container className="text-center crop_gov per_hig">
              <div>
                {status === 200 && (
                  <>
                    <div className="selectbx d-flex align-items-center justify-content-center mb-2 mt-4 pb-4">
                      <label>Year:</label>
                      {finYear && (
                        <Form.Select
                          defaultValue={7}
                          onChange={change}
                          aria-label="Default select example"
                        >
                          {finYear.map((item, index) => {
                            return (
                              <option value={item.id} key={index}>
                                {item.year}
                              </option>
                            );
                          })}
                        </Form.Select>
                      )}
                      {/*  <Button variant="success" onClick={setData} className="ml-2 read_more preinnersubmit">Submit</Button> */}
                    </div>

                    {pdfList.length > 0 && (
                      <div className="card_round mb-5">
                        <div className="sortBy mb-2">
                          <Button
                            variant="success"
                            onClick={sortData}
                            className={
                              sortBy === 1
                                ? "ml-2 read_more active"
                                : "ml-2 read_more"
                            }
                          >
                            <img src={sortby} alt="" /> Sort By
                          </Button>
                        </div>
                        <Row className="justify-content-center mb-3 ">
                          {pdfList.map((pdf, i) => {
                            return <PdfCard bd={pdf} uri={imgUrl} key={i} />;
                          })}
                        </Row>
                      </div>
                    )}
                  </>
                )}
              </div>
            </Container>
          )) ||
            ""}

          {(finDocument.length === 0 || pdfList.length === 0) && (
            <Alert variant="danger">
              <Alert.Heading>Data Not Available</Alert.Heading>
            </Alert>
          )}
        </Container>
      </div>
    </>
  );
};

const mapStatetoProps = (state) => {
  return {
    investercalltrans: state.investorcalltransR.investercalltrans,
    presentation: state.presentationR.presentation,
    presentationsearch: state.presentationR.presentationsearch,
    audiovideo: state.presentationR.audiovideo,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    investorcalltransApi: function (cid, yid) {
      dispatch(investorcalltransApi(cid, yid));
    },
    presentationApi: function (id) {
      dispatch(presentationApi(id));
    },
    presentationsearchsearchApi: function (cid, yid) {
      dispatch(presentationsearchsearchApi(cid, yid));
    },
    audioVideoApi: function (yid) {
      dispatch(audioVideoApi(yid));
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(InvestorsCorner);
