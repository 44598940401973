import React from "react";
import {Card} from 'react-bootstrap';

const BodCard = ({bd, uri}) => {
    return (
        <Card  className="wow fadeInUp" data-wow-delay="0.5s">
            <Card.Img variant="top" src={uri + bd.person_photo} />
            <Card.Body>
                <div>
                    <Card.Title>{bd.person_name}</Card.Title>
                    <p>{bd.person_designation}</p>
                </div>
            </Card.Body>
        </Card>
    )
}

export default BodCard;