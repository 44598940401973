import { ENVIRONMENT, ENVIRONMENTSEARCH } from "./type";
import { API_URL } from "../config";
import axios from "axios";

const environmentFn = (data) =>{
    return{
        type: ENVIRONMENT,
        payload: data
    };
}

export const environmentApi = () =>{
    let data ={};
    return (dispatch) =>{
        axios.post(API_URL + 'Enviornment', data).then((response) =>{
            dispatch(environmentFn(response));
        });
    };
}
const environmentsearchFn = (data) =>{
    return{
        type: ENVIRONMENTSEARCH,
        payload: data
    };
}

export const environmentsearchApi = (year, month, plant) =>{
    let data ={
        year:year,
        month: month,
        plant: plant
    };
    return (dispatch) =>{
        axios.post(API_URL + 'EnviornmentSearch', data).then((response) =>{
            dispatch(environmentsearchFn(response));
        });
    };
}