import { STOCKEXCHANGEINTIMATIONS } from "./type";

const initialState ={stockexchange: ''};

const stockexchangeR = (state = initialState, action) =>{
    switch(action.type){
        case STOCKEXCHANGEINTIMATIONS:
            return {...state, stockexchange: action.payload};

        default:
            return state;
    }
}

export default stockexchangeR;