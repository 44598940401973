import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const UltraModernAluminium = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco adds ultra-modern Aluminium Body Trucks in its Logistics Fleet | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco adds ultra-modern Aluminium Body Trucks in its Logistics Fleet | Press Release" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/ultra-modern-aluminium"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
            <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Press Release - Nuvoco adds ultra-modern Aluminium Body Trucks to its Logistics Fleet (Dec 2022).pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco adds ultra-modern Aluminium Body Trucks in its Logistics Fleet</h2>
          <p>A sustainable initiative to reduce its carbon footprints</p>
         <p className='onetxt'><strong>Mumbai, December 15, 2022:</strong> As part of its <strong>‘Protect Our Planet’</strong> sustainability initiative, Nuvoco Vistas Corp Ltd, India's fifth-largest cement group and leading cement player in the East, in terms of capacity, has added advanced Aluminium Body Trucks to its Logistics Fleet. These trucks are built with aluminium that is sourced from Hindalco Industries Limited, one of the leading aluminium manufacturers in India. This unique model enhances Nuvoco's logistics efficiencies and strengthens its sustainable supply chain</p>

          <p>Aluminium-body Trucks are lighter with better load-bearing capacity which boosts the overall mileage by reducing fuel consumption. This leads to decreased CO2 emissions, leading to reduced vehicular emissions, positively impacting the environment. Aluminium offers excellent resistance to corrosion and rust, resulting in a longer lifespan for vehicles. Moreover, the metal can be recycled repeatedly, making automotive manufacturing highly sustainable. </p>

          <p>In FY22, Nuvoco's direct carbon emissions were 478 kgCO2/t of cementitious products, which is considerably lower than the global average of 580 kgCO2/t and the Indian average of 560 kgCO2/t.</p>

          <p>The introduction of lighter aluminium body trucks for cement dispatches will help Nuvoco save approx. 28 tCO2/vehicle in its life-cycle by reducing fossil fuel consumption leading to natural resource conservation. This initiative will help Nuvoco cut its indirect carbon emissions considerably. </p>
          <p><strong>Mr. Jayakumar Krishnaswamy, Managing Director, Nuvoco Vistas Corp. Ltd, commented,</strong> "A key component of Nuvoco's sustainability roadmap is its approach to decarbonization. As part of our carbon reduction strategy, we focus on several initiatives that will reduce direct emissions as well as indirect emissions. The collaboration between Nuvoco and Hindalco is a major step in reducing indirect emissions. A total of 15 Aluminium trucks will hit the road in Phase-1, and the number will be increased in Phase-2 based on on-road performance. At the end of its lifetime, aluminium is fully recyclable, contributing to the circular economy.” </p>
        
        <p><strong>About Nuvoco</strong></p>
          <p>Nuvoco Vistas Corp. Ltd. is a leading manufacturer and retailer of building materials in India with a vision to build a safer, smarter and more sustainable world. The company started operations in India in 1999 via acquisitions, and since then it has emerged as one of the major players in India. Today it offers a diversified business portfolio under three business segments: Cement, Ready-Mix Concrete (RMX), and Modern Building Materials (MBM). Nuvoco is one of the top cement manufacturers in India, and the leading player in the East following the acquisition of Nu Vista Limited (formerly Emami Cement Limited); offering high-performance, premium, blended cement variants like Concreto, Duraguard, Nirmax and Infracem. Its MBM product range, under the Zero M brand, comprises construction chemicals, wall fill solutions, and cover blocks. Nuvoco's RMX business has a pan-India presence offering specialised products like Artiste and InstaMix being proud contributors to landmark projects like Lodha World One, Amritsar Entry Gate, and the Metros (Delhi, Jaipur, Noida and Mumbai). Through its NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies latent gaps in the industry and offers customised solutions to its customers. Guided by the enduring principles of safety for its employees and responsibility for the community and environment; the company is charting its course to shape a new world. <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
        </Container>
      </div>
    </div >
  )
}

export default UltraModernAluminium