import React, {useEffect} from "react";
import {Container, Row, Col, Card, Breadcrumb, Button, Modal} from 'react-bootstrap';

import banner from '../assets/images/susBanner.jpg'
import bannermobile from '../assets/images/susbanner-mobile.jpg'
import aboutBannerLogo from '../assets/images/susicon.png'
import st01 from '../assets/images/environment-home.jpg';
import st02 from '../assets/images/governance-home.jpg';
import st03 from '../assets/images/social-home.jpg';
import { Link } from "react-router-dom";
const Sustainability = () => {
  return (
   
   
   <div className="SustainabilityPage inner_page">
      <div className="banner text-center position-relative">
        <img className='desktopbannerinner' src={banner} alt="" />
        <img className='mobilebannerinner' src={bannermobile} alt="" />
        <article className='position-absolute text-center'>
          <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
          <h1 className="text-uppercase">Sustainability</h1>
        </article>
        <div className='breadouter'>
          <div className='container'>
            <Breadcrumb className='mb-0 py-2'>
              <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
              <Breadcrumb.Item active href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
              Sustainability
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div className='midpart'>
        <Container>
          <h2 className='grad-heading'>Overview</h2>
          <p className='onetxt'>
          Sustainability has always been a top priority for us. Through our ESG Agenda, we aim to positively impact the environment by implementing the 5R concept (Reduce, Reuse, Recycle, Recharge and Respect) and improving waste and water management across the organization. Some of the measures include,  </p>
<p>
<strong>A:</strong> reducing carbon emissions by optimized fuel mix, adopting energy-saving techniques, maximizing usage of energy generated through Waste Heat Recovery Systems, enhancing renewable energy options,
</p>
<p><strong>B:</strong> reducing freshwater consumption and improving rainwater storage systems</p>
<p><strong>C:</strong>	segregation of the waste at the point of generation and its sustainable utilization, and improving biodiversity in and around our operations.</p>

<p>Our Health, Safety and Environment (HSE) policy and management systems that are evaluated regularly help us manage impacts, risks, and controls, and ensure compliance. Every effort we make in this direction takes us a step closer to our vision of building a Safer, Smarter and a Sustainable World. </p>


        </Container>
      </div>

      <div className="sustainability card_round text-center card_hover row mt-5 mb-5">

          
                <Container>
                    <div className="sus_in">
                        <Row>
                            <Col md={4} className="wow fadeInUp" data-wow-delay="0.5s">
                            <Link to='/environment'>   <Card>
                                    <Card.Img variant="top" src={st01} />
                                    <Card.Body>
                                        <Card.Title className="g_invert">Environment</Card.Title>
                                    </Card.Body>
                                </Card></Link>
                            </Col>

                            <Col md={4} className="wow fadeInUp" data-wow-delay="0.7s">
                            <Link to='/social'>   <Card>
                                    <Card.Img variant="top" src={st02} />
                                    <Card.Body>
                                        <Card.Title className="g_invert">Social </Card.Title>
                                    </Card.Body>
                                </Card></Link>
                            </Col>

                            <Col md={4} className="wow fadeInUp" data-wow-delay="0.8s">
                            <Link to='/governance'>  <Card>
                                    <Card.Img variant="top" src={st03} />
                                    <Card.Body>
                                        <Card.Title className="g_invert">Governance</Card.Title>
                                    </Card.Body>
                                </Card></Link>
                            </Col>
                            
                        </Row>
                    </div>
                </Container>
         
        </div>



    </div >


  )
}

export default Sustainability