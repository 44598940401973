import React, {useState, useEffect}  from "react";
import {Container, Row, Col, Card, Breadcrumb,Button, Form, Alert} from 'react-bootstrap';

import banner from '../assets/images/annualreportBanner.png';
import bannermobile from '../assets/images/performace-highlight-mobile.jpg';
import aboutBannerLogo from '../assets/images/annualIcon.png';
import sortby from '../assets/images/sortby.png';

import {performanceApi, performancesearchApi } from "../redux";
import PdfCard from "../common/pdfCard";
import { imgUrl } from "../redux/config";
import { connect } from "react-redux";

let IsData = false;
let status = null;

const AnnualReports = ({performanceApi, performance, performancesearchApi, performancesearch}) =>{
    useEffect(() => {
        performanceApi(1);
        performancesearchApi(3, 7, 1, 1);
	}, []);

    const [IsListing, setListingType] = useState(1);
    const [IsListingOne, setListingTypeOne] = useState(1);
    const [pdfList, setPdfList] = useState([]);
    const [sortBy, setSort] = useState(1);

    const setListing = (id) =>{
        setListingType(id);
        performanceApi(id);
        performancesearchApi(3, 7, id, IsListingOne);
    }

    const setListingOne = (id) =>{
        setListingTypeOne(id);
        performanceApi(IsListing);
        performancesearchApi(3, 7, IsListing, id);
    }
    
    const sortData = (data, t) =>{
        let copy;
        if(t === 1){
            copy= [...data];
        } else{
            copy= [...pdfList];
        }
        
        sortBy === 1 ? copy.sort((a, b) => (new Date(a.created_at) < new Date(b.created_at)) ? 1 : -1) 
        : copy.sort((a, b) => (new Date(a.created_at) > new Date(b.created_at)) ? 1 : -1);
        setSort(sortBy === 1 ? 2 : 1);
        setPdfList(copy);
    }
    if(Object.keys(performance).length){
        status = performance.data.status;
        if(status === 200){
            const resData = {...performance.data}
            var finCta = JSON.parse(resData.fin_cat)
            var finYear = JSON.parse(resData.fin_year);
        }
    }
    const change = (type,id) =>{
        setData(type.target.value);
    }

    const setData = (y) =>{
        performancesearchApi(3, y, IsListing, IsListingOne);
    }

    if(performancesearch.IsSuccess === 1){
        performancesearch.IsSuccess =0;
        IsData = true;
        const pdfData = {...performancesearch.data};
        sortData(JSON.parse(pdfData.fin_docs), 1);
    }

    return(
        <div className="crop_gov per_hig">
            <div className="banner text-center position-relative">
            <img className='desktopbannerinner' src={banner} alt="" />
            <img className='mobilebannerinner' src={bannermobile} alt="" />
                <article className='position-absolute text-center'>
                    <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
                    <h1 className="text-uppercase">Annual Reports</h1>
                </article>
                <div className='breadouter'>
                    <div className='container'>
                        <Breadcrumb className='mb-0 py-2'>
                            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                Annual Reports
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
            {(IsData && (
                <Container className='py-5 text-center'>
                <div className="prepost_btn">
                    <Button variant="success" onClick={()=> setListing(1)} className={ IsListing === 1 ? 'active btn_lg' : 'btn_lg'}><span>Pre-Listing</span></Button>
                    <Button variant="success" onClick={()=> setListing(2)} className={ IsListing === 2 ? 'active btn_lg' : 'btn_lg'} ><span>Post-Listing</span></Button>
                </div>

                <div className="mb-4">
                    <Button variant="success" onClick={()=> setListingOne(1)} className={ IsListingOne === 1 ? 'active btn_lg' : 'btn_lg'}><span>Nuvoco Vistas Corp Ltd</span></Button>
                    <Button variant="success" onClick={()=> setListingOne(2)} className={ IsListingOne === 2 ? 'active btn_lg' : 'btn_lg'} ><span>NU Vista Ltd</span></Button>
                </div>

                {status === 200 && (
                    <>
                        <div className='selectbx d-flex align-items-center justify-content-center mb-2 mt-4 pb-4'>
                            <label>Year:</label>
                            {(finYear && (
                            <Form.Select onChange={change} aria-label="Default select example">
                                {finYear.map((item, index)=>{            
                                    return(                        
                                    <option value={item.id} key={item.id}>{item.year}</option>
                                    );
                                })}
                            </Form.Select>
                            ))}
                           {/*  <Button variant="success" onClick={setData} className="ml-2 read_more">Submit</Button> */}
                        </div>
                        
                        {pdfList.length > 0 && (
                            <div className="card_round mb-5">
                                <div className="sortBy mb-2">
                                <Button variant="success"  onClick={sortData} className={sortBy === 1 ? "ml-2 read_more active" : "ml-2 read_more"}>
                                        <img src={sortby} alt='' /> Sort By
                                        </Button>
                                </div>
                                <Row className="justify-content-center mb-3 ">
                                    {pdfList.map((pdf, i)=>{
                                        return(
                                            <PdfCard bd={pdf} uri={imgUrl} key={i} />
                                        )
                                    })}
                                </Row>
                            </div>
                        )}
                    </>
                )}

                {(status === 404 || pdfList.length ===0) && (
                    <Alert variant="danger">
                        <Alert.Heading>Data Not Available</Alert.Heading>
                    </Alert>
                )}
            </Container>
            )) || ''}
    </div>
    )
    
}


const mapStatetoProps = (state) => {
    return {
        performance: state.performanceR.performance,
        performancesearch:state.performanceR.performancesearch
    };
};
const mapDispatchtoProps = (dispatch) => {
    return {
        performanceApi: function (id) {
            dispatch(performanceApi(id));
        },
        performancesearchApi: function (cid, yid, id, cpType) {
            dispatch(performancesearchApi(cid, yid, id, cpType));
        }
    };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(AnnualReports);