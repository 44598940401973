import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from "react-helmet";

const MananShah = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Manan Shah | Non-Executive Director | NU Vista Limited</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Mr. Manan Shah has been a member of the Board of Directors since July 21, 2020." />
    <link rel="canonical" href="https://nuvoco.com/Manan-Shah"/>
    </Helmet>

      <div className='midpart bodPages'>
        <Container>
            <Row className="mb-5">
                 <Col md={4}>
                    <img style={{borderRadius:'15px'}} src="https://admin.nuvoco.com/public/BoardOfDirectorsDetails/manan.png" />
                </Col>
                <Col md={8}>
                    <h2 className='grad-heading'>Manan Shah<br/><small>Non-Executive Director</small></h2>
                    <p className='onetxt'>
                    Mr. Manan Shah has been a member of the Board of Directors since July 21, 2020. He is a Commerce graduate and a Chartered Accountant by profession. He has more than 21 years of experience in Investment Banking, Banking and Finance and M&amp;A.</p>
                </Col>
            </Row>

        </Container>
      </div>
    </div >
  )
}

export default MananShah