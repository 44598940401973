import { PAGEDATA } from "./type";
import { API_URL } from "../config";
import axios from "axios";

const commonFn = (data) =>{
    return{
        type: PAGEDATA,
        payload: data
    };
}

export const commonApi = (page_type) =>{   
    return (dispatch) =>{
        axios.get(API_URL + page_type).then((response) =>{
            dispatch(commonFn(response));
        });
    };
}