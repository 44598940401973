import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const AwardForExcellence2022 = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Award for Excellence 2022 | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco wins the Golden Peacock Award for Excellence in Corporate Governance - 2022" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/award-for-excellence-2022"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
            <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Press Release - Nuvoco wins Golden Peacock Award for Governance (Sep2022).pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco wins the Golden Peacock Award for Excellence in Corporate Governance - 2022 </h2>
          <p className='onetxt'>
            <strong>September 26, 2022, Mumbai:</strong> Nuvoco Vistas Corp. Ltd., the fifth largest cement group in India and leading cement player in East India, in terms of capacity, has been conferred with the globally acclaimed <strong>Golden Peacock Award for Excellence in Corporate Governance – 2022.</strong> The achievement is a testament to Nuvoco's growth in the building materials sector as well as its commitment to implementing best-in-class governance and management systems. </p>
          <p>A Certificate and the coveted Golden Peacock Awards Trophy will be presented on November 10, 2022.</p>

          <p>The Golden Peacock Awards have been instituted by the Institute of Directors, India in 1991 and are regarded as a benchmark of corporate excellence throughout the world. With the Golden Peacock Award for Corporate Governance, companies are stimulated and encouraged to accelerate their customer-focused improvement processes. An award is intended to celebrate and honour the best for their unique achievements as well as build their brand equity on Corporate Governance. More than 1000 companies applied in various categories for the awards this year. The jury comprised eminent public figures, known for their independence and impartiality. </p>

          <p>Commenting on the win, <strong>Jayakumar Krishnaswamy, Managing Director, Nuvoco Vistas Corp. Ltd.,</strong> said, "It is a privilege to receive such an award, which is regarded as a benchmark for good corporate governance. Our commitment to Corporate Governance initiatives and practices has earned us this award. It was Nuvoco's ability to focus on inclusive and purpose-driven growth that led to the brand becoming one of the most recognizable in the sector. This is also supported by effective governance frameworks and systems that remain in place. Our robust auditing procedures are guided by trust, responsibility, and accountability so that we remain true to our purpose and drive sustainable, long-term growth."</p>
        
        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a cement manufacturing company with a vision to build a safer, smarter and sustainable world. Nuvoco started its operations in 1999, and since then it has emerged as one of the major players in the building materials industry in India. Today, Nuvoco offers a diversified business portfolio under three business segments, namely, Cement, Ready-Mix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco is a leading cement player in India and the largest cement company in East India in terms of capacity, following the acquisition of Nu Vista Limited (formerly Emami Cement Limited), offering various brands of cement such as ‘Concreto’, ‘Duraguard’, ‘Nirmax’, ‘Double Bull’, and ‘Infracem’. The MBM product portfolio, under the ‘Zero M’ and ‘Instamix’ brands, comprises construction chemicals, wall putty and cover blocks. The RMX business has a comprehensive pan-India presence offering value-added products under various brands such as ‘Concreto’, ‘Artiste’, ‘InstaMix’ and ‘X-Con’. Nuvoco is a proud contributor to landmark projects such as World One building at Mumbai, and the metro railway projects at Mumbai, Jaipur and Noida. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet the customer requirements. <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
      </Container>
      </div>
    </div >
  )
}

export default AwardForExcellence2022