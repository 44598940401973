import React from 'react';
import Container from 'react-bootstrap/Container';
import banner from '../assets/images/vimi-banner.png'
import bannermobile from '../assets/images/vimi-banner-mobile.jpg'
import aboutBannerLogo from '../assets/images/vimiba-logo.png'
import img1 from '../assets/images/vimi1.png'
import img2 from '../assets/images/vimi2.png'
import img3 from '../assets/images/vimi3.png'
import img4 from '../assets/images/vimi4.png'
import img5 from '../assets/images/vimi5.png'
import img6 from '../assets/images/vimi6.png'
import img7 from '../assets/images/vimi7.png'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet';

class VisionMission extends React.Component {
    render(){
    return (
        <div className="vimi-page">
    <Helmet>
    <title>Nuvoco's Vision, Mission & Values | Best ReadyMix Concrete Manufacturer</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco s vision is to enable, empower customers by bringing innovation ensuring sustainability to their construction To build a safer, smarter sustainable world" />
    <link rel="canonical" href="https://nuvoco.com/vision-mission-and-values"/>
    </Helmet>
            <div className="banner text-center position-relative">
                
                <img className='desktopbannerinner' src={banner} alt="" />
               <img className='mobilebannerinner' src={bannermobile} alt="" />

                <article className='position-absolute text-center'>
                    <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
                    <h1 className="text-uppercase">VISION, MISSION & VALUES</h1>
                </article>
                <div className='breadouter'>
                    <div className='container'>
                        <Breadcrumb className='mb-0 py-2'>
                            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                                Vision, Mission & Values
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
            <Container className='py-5'>
                <div className='vmbx'>
                    <h2 className='grad-heading'>Vision</h2>
                    <Row className='align-items-center'>
                        <Col md="6">
                            <div className='imgbx mx-auto d-flex align-items-center justify-content-center'>
                                <img src={img1} alt="" className='img-fluid' />
                            </div>
                        </Col>
                        <Col md="6">
                            <article className='text-center'>Building a Safer, Smarter
                              <br/>  and Sustainable World</article>
                        </Col>
                    </Row>
                </div>
                <div className='vmbx py-5'>
                    <h2 className='grad-heading'>Mission</h2>
                    <Row className='align-items-center flex-row-reverse'>
                        <Col md="6">
                            <div className='imgbx mx-auto d-flex align-items-center justify-content-center'>
                                <img src={img2} alt="" className='img-fluid' />
                            </div>
                        </Col>
                        <Col md="6">
                            <article className='text-center'>Leading Building Materials Company
                                Delivering Superior Performance</article>
                        </Col>
                    </Row>
                </div>

                <div className='valuebx py-4' id="ourValue">
                    <h2 className='grad-heading'>Values</h2>
                 
                </div>

                <div className='vimiicon pb-2 d-flex justify-content-center'>
                <Row className="text-center justify-content-center">
                        <Col md="4">
                    <div className='missionblock'>
                    <div className='imgbx d-flex align-items-center justify-content-center'>
                        <img src={img3} alt="" className='img-fluid' />
                    </div>
                    <div className='missionblocktext'>
                    <h4>Integrity</h4>
                            <p>Honesty. Ethical code of conduct.
                                Honouring commitments.
                                Serving communities, partners
                                and customers.</p>
                                </div>
                   
                   
                    </div>
                    </Col>

                    <Col md="4">
                    <div className='missionblock'>
                    <div className='imgbx d-flex align-items-center justify-content-center'>
                    <img src={img4} alt="" className='img-fluid' />
                    </div>
                    <div className='missionblocktext'>
                    <h4>Entreprenuership</h4>
                            <p>Generating out-of-the-box solutions. Being innovative.
                                Superior customer satisfaction and value creation.
                                Intelligent, inclusive and strategic.
                                Solving problems than simply completing tasks.</p>
                                </div>
                    </div>
                    </Col>

                    <Col md="4">
                    <div className='missionblock'>
                    <div className='imgbx d-flex align-items-center justify-content-center'>
                    <img src={img5} alt="" className='img-fluid' />
                    </div>
                    <div className='missionblocktext'>
                    <h4>Collaboration</h4>
                            <p>Dealing respectfully and fairly with others.
Building mutual trust, transparency and teamwork.
Free-flowing knowledge and information.
Partnership with internal and external stakeholders.</p>
                                </div>
                  
                
                    </div>
                    </Col>
                    <Col md="4">
                    <div className='missionblock'>
                    <div className='imgbx d-flex align-items-center justify-content-center'>
                    <img src={img6} alt="" className='img-fluid' />
                    </div>
                    <div className='missionblocktext'>
                    <h4>Care</h4>
                            <p>Being compassionate and
                                empathising with others. HSE and
                                compliance. Respect and support. Giving
                                importance to people over things.</p>
                                </div>
                    </div>
                    </Col>

                    <Col md="4">
                    <div className='missionblock'>
                    <div className='imgbx d-flex align-items-center justify-content-center'>
                    <img src={img7} alt="" className='img-fluid' />
                    </div>
                    <div className='missionblocktext'>
                    <h4>Operational Excellence</h4>
                            <p>Leveraging knowledge and expertise. Speedy actions; striving for continuous improvement.Best in quality; value for customers. Enabling people around to deliver their best.

</p>
                                </div>
                    
                  
                    </div>
                    </Col>


                    </Row>

                   
                </div>


            </Container>
        </div>
    )
}
}

export default VisionMission