import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname, hash } = useLocation();
  let y = 0; 

  switch(hash){
    case '#landmark':
      y = 2700;
      break;

    case '#ourValue':
      y= 1700;
      break;

    case '#ourPeople':
      y = 680;
      break;

      case '#awardandrecognition':
        y=1400;
        break;
      case '#fake-employment-disclaimer':
        y=2300;
        break;

      default:         
        y=0;     
  }

  useEffect(() => {
    window.scrollTo(0, y); 
  }, [pathname, hash, y]);

  return null;
}