import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const DuraguardXtraJharkhand = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco’s ‘Duraguard Xtra F2F’ Now Available in Jharkhand | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco’s ‘Duraguard Xtra F2F’ Now Available in Jharkhand" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/duraguard-xtra-jharkhand"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
            <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Duraguard-Xtra-F2F-Now-in-Jharkhand.pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco’s ‘Duraguard Xtra F2F’ Now Available in Jharkhand</h2>
          <p>A premium composite cement that ensures high durability and superior finish from foundation to finish (F2F)</p>
          <p className='onetxt'><strong>India, September 2023: </strong>Nuvoco Vistas Corp. Ltd., India's fifth-largest cement group in terms of capacity, launched its <strong>premium composite cement ‘Duraguard Xtra F2F’ as part of its extensive Duraguard range of products in Jharkhand.</strong> The product improves workability, provides a superior finish, and solidifies the construction from foundation to finish (F2F), thus complementing its tagline <strong><i>"Mazbooti Joh Tike, Finishing Jo Dikhe".</i></strong></p>

          <p><strong>Duraguard Xtra F2F</strong> leverages the expertise of Nuvoco’s Construction Development and Innovation Centre (CDIC) that develops the product tailored to the needs of local customers and partners through continual consumer engagement, research, and a thorough understanding of the impact of external elements on building lifespan.</p>

          <p>On the launch, <strong>Mr. Jayakumar Krishnaswamy, Managing Director, Nuvoco</strong> said, “Our customers are the epicenter in driving our innovations, and Duraguard Xtra F2F is one such innovative product that ensures safety, strength, and performance for constructions from foundation to finish. As a leading player in Eastern India, Jharkhand plays a key role in our business and helps in building strong brand equity in the premium category. We continue to remain focused on introducing quality products for ourstakeholders and committed to our vision of Building a safer,smarter sustainable world.”</p>
        
        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a cement manufacturing company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999, and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest Cement Group in terms of capacity (23.8 MMTPA) with an INR 10,586 crore total revenue from operations in FY23 and is among the leading players in East India. Nuvoco offers a diversified business portfolio under three business segments - Cement, Ready-Mix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses PAN India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (ready-to-use bagged concrete - the first-of-its-kind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to the landmark projects like Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium, Rourkela; Aquatic Gallery Science City, Ahmedabad; and Metro Railway (Delhi, Jaipur, Noida and Mumbai) among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ and ‘InstaMix’ brands, comprises construction chemicals, wall putty and cover blocks. Through the NABLaccredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements. </p>
          <p>Know more about the Company on <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
          <p>For more information contact the Company at  <a href="mailto:india.communications@nuvoco.com">India.communications@nuvoco.com</a></p>
      </Container>
      </div>
    </div >
  )
}

export default DuraguardXtraJharkhand