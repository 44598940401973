import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const TechnicalMobileVan = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco Launches Tech Express - Technical Mobile Van for Customer Support | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Launches Tech Express - Technical Mobile Van for Customer Support | Press Release" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/technical-mobile-van"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
            <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Press Release - Nuvoco Launches Tech Express - Technical Mobile Van for Customer Support (Jan 2023).pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco Launches Tech Express - Technical Mobile Van for Customer Support</h2>
          <p>On-Site Technical Assistance for its Customers at No Extra Cost</p>
         <p className='onetxt'><strong>13 January 2023: </strong>Nuvoco Vistas Corp. Ltd., India’s fifth-largest cement group and the leading cement player in East India, in terms of capacity, has launched Tech Express - an on-the-go site service. The company is introducing Fifty Tech Express Vehicles (Technical Mobile Vans) to provide on-site services to its customers in the markets of North, East and Central India for ensuring efficient construction processes.</p>

          <p>One of the most crucial construction operations is pouring concrete, which requires extra care. Tech Express is a value-added service that Nuvoco offers to its customers during concrete placement at no additional cost to ensure consistent and high-quality concrete. This vehicle is equipped with the necessary testing facilities and equipment for on-site evaluation of materials and is manned by Captain Nuvoco, a qualified civil engineer who can offer advice on best practices for construction.</p>

          <p><strong>Commenting on the launch, Mr. Vinit Kumar Tiwari, Chief Sales and Business Development Officer - Cement, Nuvoco said</strong> “Individual Home Builders (IHBs) account for a significant portion of our market and are frequently deprived of institutional technical support during the construction process. Thus, they rely on external advice and rationale. To address this gap and impart knowledge, we launched Tech Express, a free value-added service available only to Nuvoco's customers.”</p>

          <p>Individual Home Builders (IHBs) can contact Nuvoco Dealers from whom they have purchased Nuvoco products to take advantage of this service. The dealer will then arrange for Tech Express to visit the location.</p>
        
        <p><strong>About Nuvoco</strong></p>
          <p>Nuvoco Vistas Corp. Ltd. is a leading manufacturer and retailer of building materials in India with a vision to build a safer, smarter and more sustainable world. The company started operations in India in 1999 via acquisitions, and since then it has emerged as one of the major players in India. Today it offers a diversified business portfolio under three business segments: Cement, Ready-Mix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco is one of the top cement manufacturers in India, and the leading player in the East following the acquisition of Nu Vista Limited (formerly Emami Cement Limited), offering high-performance, premium, blended cement variants like Concreto, Duraguard, Nirmax and Infracem. Its MBM product range, under the Zero M brand, comprises construction chemicals, wall fill solutions, and cover blocks. Nuvoco's RMX business has a pan-India presence offering specialised products like Artiste and InstaMix being proud contributors to landmark projects like Lodha World One, Amritsar Entry Gate, and the Metros (Delhi, Jaipur, Noida and Mumbai). Through its NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies latent gaps in the industry and offers customised solutions to its customers. Guided by the enduring principles of safety for its employees and responsibility for the community and environment; the company is charting its course to shape a new world. <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
        </Container>
      </div>
    </div >
  )
}

export default TechnicalMobileVan