import React from 'react';
import Container from 'react-bootstrap/Container';
import banner from '../assets/images/journey-banner.png'
import bannermobile from '../assets/images/journey-banner-mobile.jpg'
import aboutBannerLogo from '../assets/images/journeyba-logo.png'
import icon1 from '../assets/images/jr1.png'
import icon2 from '../assets/images/jr2.png'
import icon3 from '../assets/images/jr3.png'
import icon4 from '../assets/images/jr4.png'
import icon5 from '../assets/images/jr5.png'
import icon6 from '../assets/images/jr6.png'
import icon7 from '../assets/images/jr7.png'
import icon8 from '../assets/images/jr8.png'
import icon9 from '../assets/images/jr9.png'
import { Helmet } from 'react-helmet';

import Breadcrumb from 'react-bootstrap/Breadcrumb';

const OurJourney = () => {
    return (
        <div className="journey-page">

<Helmet>
    <title>Our Journey | Nuvoco Vistas</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Here is a small glimpse of the Journey of Nuvoco Vistas the milestones which was acheived during the last few decades that empower the overall cement and concrete industry" />
    <link rel="canonical" href="https://nuvoco.com/our-journey"/>
    </Helmet>

            <div className="banner text-center position-relative">
                
                <img className='desktopbannerinner' src={banner} alt="" />
               <img className='mobilebannerinner' src={bannermobile} alt="" />

                <article className='position-absolute text-center'>
                    <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
                    <h1 className="text-uppercase">Our  Journey</h1>
                </article>
                <div className='breadouter'>
                    <div className='container'>
                        <Breadcrumb className='mb-0 py-2'>
                            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                Our  Journey
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
            <Container>
                <div className='midbx my-5'>
                    <div className='bxin bxin-one d-flex align-items-center ourja_22'>
                       
                        <div className='rpart d-flex align-items-center justify-content-end flex-row-reverse'>
                        <div className='imgbx d-flex align-items-center justify-content-center'>
                                <img src={icon1} alt="" />
                            </div>
                            <ul>
                                <li>Upgradation of Bhiwani Blending
                                    Unit to a Griding Unit</li>

                                <li> Sustainability projects at Risda
                                    & Nimbol Cement Plants</li>

                                <li>Green field expansion of Gulbarga</li>
                            </ul>
                        </div>
                        <div className='lpart'>
                            <h2 className='grad-heading'>2022</h2>
                            
                        </div>
                    </div>



                    <div className='bxin bxin-two d-flex align-items-center'>
                        <div className='lpart'>
                            <h2 className='grad-heading'>2021</h2>
                        </div>
                        <div className='rpart d-flex align-items-center justify-content-end'>
                            <div className='imgbx d-flex align-items-center justify-content-center'>
                                <img src={icon2} alt="" />
                            </div>
                            <ul>
                               <li>Received the first-ever Patent Certificate<br />
                                    for ‘Water Resistant Cement Composition’<br />
                                    for Duraguard Waterseal Cement.</li>

                                <li> Equipped with CPPs and WHRs across<br />
                                all integrated units and CPP at <br />Jojobera Cement Plant
                            </li>
                     <li>Expanded the brownfield cement grinding<br /> capacity at Jojobera Cement Plant<br /> by 1.5 MMTPA</li>
                   <li>Launched IPO (Initial Public Offering)<br /> and listed equity shares on BSE and<br /> NSE, successfully</li>                             



                            </ul>
                        </div>
                    </div>





                    <div className='bxin bxin-three d-flex align-items-center flex-row-reverse'>
                        <div className='lpart'>
                            <h2 className='grad-heading'>2020</h2>
                        </div>
                        <div className='rpart d-flex align-items-center justify-content-end flex-row-reverse'>
                            <div className='imgbx d-flex align-items-center justify-content-center'>
                            <img src={icon5} alt="" />
                            </div>
                            <ul>
                                <li> 
                                    Merger of Nimbol Cement undertaking<br />
                                    of Nirma Limited with Nuvoco Vistas <br />Corp. Ltd.
                                </li>
                             
                                <li> Acquired NU Vista Limited<br />
                                (formerly Emami Cement Limited)</li>
                                  
                                <li>Commissioned the first Solar Power<br />
                                Plant at Bhiwani Cement Plant, Haryana</li>
                            </ul>
                        </div>
                    </div>


                  




                    <div className='bxin bxin-two bxin-four d-flex align-items-center'>
                        <div className='lpart'>
                            <h2 className='grad-heading'>2019</h2>
                        </div>
                        <div className='rpart d-flex align-items-center justify-content-end'>
                       <div className='imgbx d-flex align-items-center justify-content-center'>
                                <img src={icon4} alt="" />
                            </div>
                            <ul>
                                <li>Commissioned the first Captive<br />
                              Power Plant (CPP) and<br />
                                Waste Heat Recovery (WHR) in <br />
                                Chittorgarh Cement Plant
                                </li>


                                    


                            </ul>
                        </div>
                    </div>

                    <div className='bxin bxin-three bxin-five d-flex align-items-center flex-row-reverse'>
                        <div className='lpart'>
                            <h2 className='grad-heading'>2017</h2>
                        </div>
                        <div className='rpart d-flex align-items-center justify-content-end flex-row-reverse'>
                            <div className='imgbx d-flex align-items-center justify-content-center'>
                                <img src={icon8} alt="" />
                            </div>
                            <ul>
                                <li> 
                                Rechristened from Lafarge India <br />
                                Limited to Nuvoco Vistas Corp. Ltd.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className='bxin bxin-two bxin-six d-flex align-items-center'>
                    <div className='lpart'>
                        <h2 className='grad-heading'>2016</h2>
                        </div>
                        
                        <div className='rpart d-flex align-items-center justify-content-end'>
                        <div className='imgbx d-flex align-items-center justify-content-center'>
                                <img src={icon9} alt="" />
                            </div>
                            <ul>
                                <li>Nirma group acquired Lafarge India Limited, which entered India in 1999.
                                   </li>

                                     

                            </ul>
                        </div>
                       
                    </div>


                    <div className='bxin bxin-three bxin-seven d-flex align-items-center flex-row-reverse'>
                        <div className='lpart'>
                            <h2 className='grad-heading'>2014</h2>
                        </div>
                        <div className='rpart d-flex align-items-center justify-content-end flex-row-reverse'>
                            <div className='imgbx d-flex align-items-center justify-content-center'>
                                <img src={icon7} alt="" />
                            </div>
                            <ul>
                                <li>
                                Nirma Group entered the Cement<br /> business through 
                                a greenfield cement<br /> plant in Nimbol, Rajasthan
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Container >
        </div >
    )
}

export default OurJourney