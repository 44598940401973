import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, Button, Form, Alert, Card } from 'react-bootstrap';
import banner from '../assets/images/evp.png';
import bannermobile from '../assets/images/evp.png';
import aboutBannerLogo from '../assets/images/socialIcon.png';
import StoryImg1 from '../assets/images/our-brand-story-img-1.png';
import StoryImg2 from '../assets/images/our-brand-story-img-2.jpg';
import slogodescription from '../assets/images/new-logo-description.jpg';
import BrandStoryBanner from '../assets/images/our-brand-story-banner.png';
import { Helmet } from 'react-helmet';

const EVP = () => {
  const [IsTab, setTabType] = useState(1);
  const setTab = (id) => {
    setTabType(id);
  }
  return (
    <div className="socialpage enviromentPage">


<Helmet>
    <title>Our Employee Value Proposition | Nuvoco Vistas</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco's Brand has acheived it's milestones since its inception shaping a new robust world with its wordclass cement and ready mix concrete quality" />
    <link rel="canonical" href="https://nuvoco.com/our-employee-value-proposition"/>
    </Helmet>

      <div className="banner text-center position-relative">
        <img className='desktopbannerinner' src={banner} alt="" />
        <img className='mobilebannerinner' src={bannermobile} alt="" />
      
        <div className='breadouter'>
          <div className='container'>
            <Breadcrumb className='mb-0 py-2'>
              <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>
                 Our Employee Value Proposition
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div className='midpart'>
        <Container>
       <Row>
        <h2 className='grad-heading'>Our Employee Value Proposition</h2>
        </Row>
        
       <Row>
        <Col md={12}>
        <p><br></br>
        <Row>
        <Col md={6} >
          <h2 className="grad-heading mb-4">ENABLING YOU TO BE <br/>FUTURE-READY</h2>  
          <p>At Nuvoco, we don't just aim for excellence; we live it every day. Our dedication to innovation has led to outstanding product quality, establishing us as industry leaders. We strive to build a unique workplace that goes beyond the usual – a place where we understand each other, and share our successes.</p>
          <p><strong>Because at Nuvoco, we don’t just help build structures; WE BUILD THE FUTURE.</strong></p>
        </Col>

        <Col md={6} >
        <iframe style={{width:'100%', height:'400px'}} src="https://www.youtube.com/embed/z7UyjocQQl0?si=RV4FtF4NlbS8QTwR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </Col>
        </Row>
</p>

        </Col>
       </Row>
       <Row className="mb-5">
        <Col md={12}>
          <h2 className="grad-heading">Our EVP Pillars</h2>
          <h3 className="mb-3">Leadership</h3>
          <ul className="mb-4">
            <li><strong>People: </strong>Trust, transparency, and accessibility define our leadership. We commit to fostering your growth through career guidance, internal job postings, and constructive feedback. Striking the right balance between delegation and control is our leadership mantra.</li>
            <li><strong>Product: </strong>Our dedication to innovation is evident, from patented technologies to eco-friendly practices. We take pride in reducing CO2 emissions and incorporating sustainable practices, proving our commitment to the environment.</li>
            <li><strong>Environment: </strong>Our environmental initiatives, from using alternative fuels to green certifications, showcase our responsibility towards a sustainable future.</li>
          </ul>
          <h3 className="mb-3">Learning</h3>
          <ul className="mb-4">
            <li><strong>Growth Mindset: </strong>We embrace challenges as opportunities. A growth mindset empowers you to ask questions, contribute ideas, and fosters innovation, creating a performance-driven culture.</li>
            <li><strong>Learning Culture: </strong>With Nuvoco University we offer a platform for continual learning and holistic development through online and classroom training.</li>
          </ul>
          <h3 className="mb-3">Learning and Development</h3>
          <ul className="mb-4">
            <li>To democratise learning, create a future-ready workforce, and foster a culture of continuous learning, the Company invests in its employees’ professional development through on-the-job and specialised training opportunities. Introductions of digital learning platforms like the Nuvoco University and the Manufacturing and Sales Academy cater to the diverse workforce’s learning needs. Collaborations with esteemed institutions such as IIT Madras and LinkedIn Learning ensure that employees stay abreast of industry trends. The Company is enhancing its capabilities to gamify virtual learning activities to further enrich the employee learning experience. Integration of digital learning platforms with virtual and classroom trainings underscores the Company’s commitment to making learning a USP at Nuvoco. 
            </li>
          </ul>
          <h3 className="mb-3">Care</h3>
          <ul className="mb-4">
            <li><strong>Commitment: </strong>We care for our people, products, customers, and everything we do. This commitment to employee well-being, high-quality products, and outstanding service cultivates trust and integrity.</li>
            <li><strong>Recognition & Rewards: </strong>Our Nuvoco Edge Awards and engaging activities celebrate exceptional contributions. Our employee lifecycle management systems ensure a seamless and positive employee experience.</li>
          </ul>
          <h3 className="mb-3">Career</h3>
          <ul className="mb-4">
            <li><strong>Competency Framework: </strong>Our Talent Management framework represents contemporary leadership competencies, ensuring career growth opportunities for all team members.</li>
            <li><strong>Talent Nurturing: </strong>Assessment and Development Centers coupled with mentorship programs exemplify our dedication to nurturing talent, building high-performance teams, and developing the future-ready leaders.</li>
          </ul>
          <p><strong>At Nuvoco, our EVP is more than words; it's a commitment to success of our fellow Nuvocans.</strong></p>
          <p><strong>Join us in a journey of building a Safer, Smarter and Sustainable World</strong></p>
        </Col>
        </Row>
        </Container>
      </div>
    </div>
  )
}

export default EVP