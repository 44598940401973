import React, {useState} from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import rightIcon from '../assets/images/right_icon.png';
import joinlife from '../assets/images/joinlife.jpg';
import { useForm } from "react-hook-form";
import axios from "axios";
import { API_URL } from "../redux/config";

const CareerForm = () =>{
    const [IsTab, setTab] = useState(1);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [IsRelative, setRelative]=useState('No')

    const changeType = (e) =>{
        console.log(e.target.value)
        setRelative(e.target.value);
    }

    var career = {};
    const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();

    const onSubmit = (data) => {
        career = data;		
        if(IsTab === 4){           

            var formData = new FormData();
            for(let item in career){
                console.log(item, career[item])
                formData.append(item, career[item])
            }

            axios({
				method: "POST",
				url: API_URL + 'CareerFormSubmit',
				data: formData,
			}).then((response) => {
				if (response.status === 200) {
					handleShow();
                    setTab(1);
                    reset();
				} else {
					//
				}
			});
        } else{
            setTab(IsTab + 1);
        }
        
	};

    return(
        <div className="get_right-life mt-5 pb-5">
            <Row>
                <Col md={7}>
                    <h2 className='grad-heading'>Join Our Team</h2>
                    <p>You're invited to join a fun, challenging, and progressive work environment.<br /> Come and see for yourself!</p>
                    <div className="form_sce">
                        <form onSubmit={handleSubmit(onSubmit)} enctype="multipart/form-data">
                            {IsTab === 1 && <Row>
                                <Col md={6}>
                                    <div className="form-group">
                                        <label>Full Name*</label>
                                        <input type="text" {...register("full_name", 
                                        {required: true})} placeholder="Full Name" className="form-control" />
                                        <span className="error">{errors['full_name'] && errors['full_name'].type === "required" && "Required*"}</span>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group">
                                        <label>Email Id*</label>
                                        <input type="text" 
                                        {...register("email", 
                                            {required: true, 
                                                pattern:/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                        })} 
                                        placeholder="Email Id" className="form-control"></input>
                                        <span className="error">{errors['email'] && errors['email'].type === "required" && "Required*"}</span>
                                        <span className="error">{errors['email'] && errors['email'].type === "pattern" && "Invalid Email*"}</span>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group">
                                        <label>Phone Number*</label>
                                        <input type="number" 
                                        {...register("phone", {required: true, maxLength: 10, minLength:10})} 
                                        placeholder="Phone Number" className="form-control"></input>
                                        <span className="error">{errors['phone'] && errors['phone'].type === "required" && "Required*"}</span>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group">
                                        <label>Present Address</label>
                                        <input type="text" 
                                        {...register("present_address")} 
                                        placeholder="Present Address" className="form-control"></input>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group">
                                        <label>Permanent Address*</label>
                                        <input type="text" 
                                        {...register("permanent_address", {required: true})} 
                                        placeholder="Permanent Address*" className="form-control"></input>
                                        <span className="error">{errors['permanent_address'] && errors['permanent_address'].type === "required" && "Required*"}</span>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group">
                                        <label>Languages Known*</label>
                                        <input type="text" 
                                        {...register("languages_known", {required: true})} 
                                        placeholder="Languages Known*" className="form-control"></input>
                                        <span className="error">{errors['languages_known'] && errors['languages_known'].type === "required" && "Required*"}</span>
                                    </div>
                                </Col>
                            </Row>}

                            {IsTab === 2 && 
                                <Row>
                                    <Col md={6}>
                                        <div className="form-group">
                                            <label>Your Industry*</label>
                                            <input type="text" {...register("your_industry", {required: true})} placeholder="Your Industry" className="form-control" />
                                            <span className="error">{errors['your_industry'] && errors['your_industry'].type === "required" && "Required*"}</span>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-group">
                                            <label>Profile Synopsis*</label>
                                            <input type="text" {...register("profile_synopsis", {required: true})}  placeholder="Profile Synopsis" className="form-control" />
                                            <span className="error">{errors['profile_synopsis'] && errors['profile_synopsis'].type === "required" && "Required*"}</span>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="form-group select_b">
                                            <label>Have you applied to Nuvoco Vistas Corp Ltd.before (Yes/No)*</label>
                                            <select {...register("have_you_applied_before", {required: true})} className="form-control">
                                                <option value='No'>No</option>
                                                <option value='Yes'>Yes</option>
                                            </select>
                                            <span className="error">{errors['have_you_applied_before'] && errors['have_you_applied_before'].type === "required" && "Required*"}</span>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="form-group select_b">
                                            <label>Any relatives/friends currently employed in Nuvoco Vistas Corp Ltd? (Yes/No)*</label>
                                            <select {...register("any_relative", {required: true})}  onChange={changeType} className="form-control">
                                                <option value='No'>No</option>
                                                <option value='Yes'>Yes</option>
                                            </select>
                                            <span className="error">{errors['any_relative'] && errors['any_relative'].type === "required" && "Required*"}</span>
                                        </div>
                                    </Col>
                                    {IsRelative === 'Yes' && <>
                                            <Col md={6}>
                                        <div className="form-group">
                                            <label>Name*</label>
                                            <input type="text" {...register("relative_name", {required: true})} placeholder="Name" className="form-control" />
                                            <span className="error">{errors['relative_name'] && errors['relative_name'].type === "required" && "Required*"}</span>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-group">
                                            <label>Designation*</label>
                                            <input type="text" {...register("relative_designation", {required: true})} placeholder="Designation" className="form-control" />
                                            <span className="error">{errors['relative_designation'] && errors['relative_designation'].type === "required" && "Required*"}</span>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="form-group">
                                            <label>Location*</label>
                                            <input type="text" {...register("relative_location", {required: true})} placeholder="Location" className="form-control" />
                                            <span className="error">{errors['relative_location'] && errors['relative_location'].type === "required" && "Required*"}</span>
                                        </div>
                                    </Col>
                                        </>
                                    }
                                    <Col md={6}>
                                        <div className="form-group">
                                            <label>Area of Interest*</label>
                                            <input type="text" {...register("area_of_interest", {required: true})} placeholder="Area of Interest" className="form-control" />
                                            <span className="error">{errors['area_of_interest'] && errors['area_of_interest'].type === "required" && "Required*"}</span>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-group select_b">
                                            <label>Type Fresher/Professional*</label>
                                            <select {...register("type_fp", {required: true})} className="form-control">
                                                <option value='Fresher'>Fresher</option>
                                                <option value='Professional'>Professional</option>
                                            </select>
                                            <span className="error">{errors['type_fp'] && errors['type_fp'].type === "required" && "Required*"}</span>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="form-group">
                                            <label>Educational Qualifications (From 10th Standard to most recent)*</label>
                                            <input type="text" {...register("educational_qaulifications", {required: true})} placeholder="Educational Qualifications (From 10th Standard to most recent)" className="form-control" />
                                            <span className="error">{errors['educational_qaulifications'] && errors['educational_qaulifications'].type === "required" && "Required*"}</span>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="form-group">
                                            <label>Technical courses details (if any)*</label>
                                            <input type="text" {...register("technical_courses_details", {required: true})} placeholder="Technical courses details (if any)" className="form-control" />
                                            <span className="error">{errors['technical_courses_details'] && errors['technical_courses_details'].type === "required" && "Required*"}</span>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {IsTab === 3 && 
                                <Row>
                                    <Col md={12}>
                                        <div className="form-group select_b">
                                            <label>Do you suffer from any health or physical ailment? (Yes/No)*</label>
                                            <select {...register("suffer_from_any_health")} className="form-control">
                                                <option value='No'>No</option>
                                                <option value='Yes'>Yes</option>
                                            </select>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="form-group">
                                            <label>State any serious illness/hospitalization you have had during the last 3 years*</label>
                                            <input type="text" {...register("any_serious_illness", {required: true})} placeholder="State any serious illness/hospitalization you have had during the last 3 years" className="form-control" />
                                            <span className="error">{errors['any_serious_illness'] && errors['any_serious_illness'].type === "required" && "Required*"}</span>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="form-group">
                                            <label>Do you use Spectacles? If so give details:*</label>
                                            <input type="text" {...register("do_you_use_spectacles", {required: true})}  placeholder="Do you use Spectacles? If so give details:" className="form-control" />
                                            <span className="error">{errors['do_you_use_spectacles'] && errors['do_you_use_spectacles'].type === "required" && "Required*"}</span>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {IsTab === 4 && 
                                <Row>
                                    <Col md={12}>
                                        <div className="form-group">
                                            <label>Educational Qualifications (From 10th Standard to most recent)*</label>
                                            <input type="text" {...register("education_last_educational_qualifiactions", {required: true})} placeholder="Educational Qualifications (From 10th Standard to most recent)" className="form-control" />
                                            <span className="error">{errors['education_last_educational_qualifiactions'] && errors['education_last_educational_qualifiactions'].type === "required" && "Required*"}</span>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-group">
                                            <label>Technical courses details (if any)*</label>
                                            <input type="text" {...register("education_technical_course", {required: true})} placeholder="Technical courses details (if any)*" className="form-control" />
                                            <span className="error">{errors['education_technical_course'] && errors['education_technical_course'].type === "required" && "Required*"}</span>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-group">
                                            <label>Last Technical Qualification*</label>
                                            <input type="text" {...register("education_technical_qualifications", {required: true})} placeholder="Last Technical Qualification*" className="form-control" />
                                            <span className="error">{errors['education_technical_qualifications'] && errors['education_technical_qualifications'].type === "required" && "Required*"}</span>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {IsTab >= 2 && (
								<Button variant="success" className="read_more"
									onClick={() => setTab(IsTab - 1)}>
									Back
								</Button>
							)}
                            <Button variant="success" className="read_more ml-2" type="submit">{(IsTab === 4 && "Submit") || "Next"}</Button>
                        </form>
                    </div>
                    
                </Col>
                <Col>
                    <div className="right_im">
                        <img src={joinlife} alt='' />
                        <div></div>
                    </div>
                </Col>
            </Row>   

            <Modal className="success_mb" show={show} onHide={handleClose}>
                <Modal.Body>
                    <h5>Thank you. We have received your details</h5>
                    <Button variant="success" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Body> 
            </Modal>                          
        </div>
    )
}

export default CareerForm;