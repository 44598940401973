import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, Button, Form, Alert, Card } from 'react-bootstrap';

import banner from '../assets/images/socialBanner.jpg';
import bannermobile from '../assets/images/health-and-safety-banner-mobile.jpg';
import aboutBannerLogo from '../assets/images/Health-and-safety-icon.png';
import socialbicon1 from '../assets/images/socialbicon1.png'
import socialbicon2 from '../assets/images/socialbicon2.png'
import socialbicon3 from '../assets/images/socialbicon3.png'
import socialbicon4 from '../assets/images/socialbicon4.png'
import socialbicon5 from '../assets/images/socialbicon5.png'
import Eshiksha from '../assets/images/Eshiksha.jpg'
import ShikshitSunderhattu from '../assets/images/Shikshit-Sunderhattu.jpg'
import AanganTara from '../assets/images/Swasth-Matritva.jpg'
import GirlsHealthGroup from '../assets/images/Girls-Health-Group.jpg'
import JalSanchay from '../assets/images/Jal-Sanchay.jpg'
import akriti from '../assets/images/akriti.jpg'
import daksh from '../assets/images/daksh.jpg'
import nirmaanbahini from '../assets/images/nirmaanbahini.jpg'
import samridhi from '../assets/images/samridhi.jpg'
import HSInitiatives from '../assets/images/H&S-Initiatives-24.png'
import healthpolicy from '../assets/images/Health_and_Safety_Policy.pdf';
import smart1 from '../assets/images/smart.jpg';
import smart2 from '../assets/images/smart1.jpg';

import arroing from '../assets/images/arrofull.png';

import pdfIcon from '../assets/images/pdf_icon.png';
import YogaSocial from '../assets/images/yoga-social.jpg';
import HealthAndSafetyBanner from '../assets/images/health-and-safety-banner.jpg';
import HealthAndSafetyBannerNew from '../assets/images/HealthSafetyBanner.png';
import bannermobileNew from '../assets/images/health-and-safety-banner-mobile.png';
import { Helmet } from "react-helmet";

import Slider from "react-slick";
const settings = {
  //autoplay:true,
  autoplaySpeed: 2000,
  dots: false,
  arrow: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};


const HealthSafety = () => {
  const [IsTab, setTabType] = useState(1);

  const setTab = (id) => {
    setTabType(id);
  }


  return (
    <div className="socialpage enviromentPage">
                <Helmet>
                  <title>Health And Safety Policy | Employees & Workers Safety At Nuvoco</title>
                  <meta name="keywords" description="" />
                 <meta name="description" content="We at Nuvoco Cement concrete manufacturing company consider it our inherent responsibility to provide a safe healthy environment for our stakeholders conduct health benefits programs interventions" />
                </Helmet>
      <div className="banner text-center position-relative">
        <img className='desktopbannerinner' src={HealthAndSafetyBannerNew} alt="" />
        <img className='mobilebannerinner' src={bannermobileNew} alt="" style={{width:'100%'}} />
        <article className='position-absolute text-center'>
          <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
          <h1 className="text-uppercase">Health and Safety</h1>
        </article>
        <div className='breadouter'>
          <div className='container'>
            <Breadcrumb className='mb-0 py-2'>
              <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>
              Health and Safety
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>


      <div className='midpart'>
        <Container>
        <Row className=''>
          <h2 className='grad-heading'>Health and Safety</h2>
          <p>Safety is a non-negotiable tenet of Nuvoco’s vision, and care is one of the core values. Ensuring the well-being of employees is a priority and a commitment from Management.</p>
          <p>The Company has introduced a comprehensive wellness program focusing on physical and mental health, developed in partnership with health experts.</p>
          <p>Furthermore, the ‘ZERO HARM’ philosophy underscores the Company’s commitment to health, safety and the environment. Stringent safety protocols are integrated into operations with on-site resident doctors providing regular check-ups and tailored care.</p>
          <p>Nuvoco extended its extensive medical insurance coverage for employees and their families, supported by annual health assessments and discounted rates for extended family members.</p>
          <p className="text-center">
            <img src={HSInitiatives} alt="H&S Initiatives 24" title="H&S Initiatives 24" style={{maxWidth:'768px', width:'100%', borderRadius:'10px'}}/>
          </p>
</Row>
  </Container>
          <Container>
       {/* <Row className="mt-5">
  <p><strong>Health and Safety Performance for FY-22</strong></p>
                     
<p>
1.	Zero Fatality<br></br>
2.	35% reduction of total injury over FY 21 actual<br></br>
3.	Injury-free commissioning of 4 and dismantling of 5 RMX Plants <br></br>
4.	Launch of 17 Nuvoco Safety Guidelines<br></br> 
</p>



                        <p>As a result of our dedication to efficient Health & Safety, we have received several prestigious awards & accolades - the <b>India Green Manufacturing Challenge (IGMC)</b> Award by IRIM, the <b>Indian Chamber of Commerce (ICC)</b>, the <b>Confederation of Indian Industry (CII)</b>, the <b>Institute of Directors (Golden Peacock Awards)</b>, the <b>Director General of Mines Safety</b>, the <b>OHSSAI Foundation and the Government of Rajasthan</b>. </p>
                      </Row> */}

                      <Row>

                      <div className="per_hig mb-5">
                      <div className="card_round">
                      <div className="justify-content-center mb-3 row">
                        <Col className="col-xl-4" md={5}>

                        <a href={healthpolicy} target="_blank">
                            <Card className="wow fadeInUp" data-wow-delay="0.5s">
                              <Card.Title>Our Policy &amp; Commitment 
                         </Card.Title>
                              <Card.Img src={pdfIcon} />
                            </Card>
                          </a>
                        </Col>
                  
                    
</div></div></div>



                      </Row>

                      </Container>


      </div>



    </div>
  )
}

export default HealthSafety