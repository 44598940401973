import React, {useState} from "react";
import {Button, Row, Col, Modal} from 'react-bootstrap';
import rightIcon from '../assets/images/right_icon.png';
import getIn from '../assets/images/get_in.jpg';
import { API_URL } from "../redux/config";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

function GetRightForm ({data, product}){

    useEffect(() =>{
        if(!product){
            product=""
        }
    }, [product]);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
     const handleShow = () => setShow(true);
    const {
		register,
		handleSubmit,
		formState: { errors },
        reset
	} = useForm();

    const onSubmit = (data) => {
        const {
            email,
            name,
            phone,
            state,
        } = data;

        axios
        .post(API_URL + "AssistanceFormSubmit", {
            name: name,
            email: email,
            phone: phone,
            state: state,
            product: product
        })
        .then((response) => {
            if (response.status === 200) {                
                handleShow();
                reset();
            } else {
                //Something went wrong!
            }
        });
    }

    return(
        <div className="get_right">
            <Row>
                <Col>
                    <h2 className='grad-heading'>Get the right <br/> assistance</h2>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form_sce">
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Full Name*</label>
                                    <input type="text" {...register("name", {required: true})} placeholder="Full Name" className="form-control name"></input>
                                    <span className="error">{errors['name'] && errors['name'].type === "required" && "Required*"}</span>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Email Id*</label>
                                    <input type="text" 
                                        {...register("email", 
                                            {required: true, 
                                                pattern:/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                        })} 
                                        placeholder="Email Id" className="form-control email"></input>
                                        <span className="error">{errors['email'] && errors['email'].type === "required" && "Required*"}</span>
                                        <span className="error">{errors['email'] && errors['email'].type === "pattern" && "Invalid Email*"}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Product</label>
                                    <input type="text" disabled={true} value={product} {...register("product", {required: false})} placeholder="Product" className="form-control name"></input>
                                    <span className="error">{errors['product'] && errors['product'].type === "required" && "Required*"}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Phone Number*</label>
                                    <input type="number" {...register("phone", {required: true, maxLength: 10, minLength:10})} placeholder="Phone Number" className="form-control phone"></input>
                                        <span className="error">{errors['phone'] && errors['phone'].type === "required" && "Required*"}</span>

                                </div>
                            </Col>
                            <Col>
                            <div className="form-group select_b">
                                        <label>State*</label>

                                        <select
                                            className="form-control state"
                                            {...register("state")}
                                        >
                                            <option value="Andhra Pradesh">
                                                Andhra Pradesh
                                            </option>
                                            <option value="Andaman and Nicobar Islands">
                                                Andaman and Nicobar Islands
                                            </option>
                                            <option value="Arunachal Pradesh">
                                                Arunachal Pradesh
                                            </option>
                                            <option value="Assam">Assam</option>
                                            <option value="Bihar">Bihar</option>
                                            <option value="Chandigarh">Chandigarh</option>
                                            <option value="Chhattisgarh">
                                                Chhattisgarh
                                            </option>
                                            <option value="Dadar and Nagar Haveli">
                                                Dadar and Nagar Haveli
                                            </option>
                                            <option value="Daman and Diu">
                                                Daman and Diu
                                            </option>
                                            <option value="Delhi">Delhi</option>
                                            <option value="Lakshadweep">
                                                Lakshadweep
                                            </option>
                                            <option value="Puducherry">Puducherry</option>
                                            <option value="Goa">Goa</option>
                                            <option value="Gujarat">Gujarat</option>
                                            <option value="Haryana">Haryana</option>
                                            <option value="Himachal Pradesh">
                                                Himachal Pradesh
                                            </option>
                                            <option value="Jammu and Kashmir">
                                                Jammu and Kashmir
                                            </option>
                                            <option value="Jharkhand">Jharkhand</option>
                                            <option value="Karnataka">Karnataka</option>
                                            <option value="Kerala">Kerala</option>
                                            <option value="Madhya Pradesh">
                                                Madhya Pradesh
                                            </option>
                                            <option value="Maharashtra">
                                                Maharashtra
                                            </option>
                                            <option value="Manipur">Manipur</option>
                                            <option value="Meghalaya">Meghalaya</option>
                                            <option value="Mizoram">Mizoram</option>
                                            <option value="Nagaland">Nagaland</option>
                                            <option value="Odisha">Odisha</option>
                                            <option value="Punjab">Punjab</option>
                                            <option value="Rajasthan">Rajasthan</option>
                                            <option value="Sikkim">Sikkim</option>
                                            <option value="Tamil Nadu">Tamil Nadu</option>
                                            <option value="Telangana">Telangana</option>
                                            <option value="Tripura">Tripura</option>
                                            <option value="Uttar Pradesh">
                                                Uttar Pradesh
                                            </option>
                                            <option value="Uttarakhand">
                                                Uttarakhand
                                            </option>
                                            <option value="West Bengal">
                                                West Bengal
                                            </option>
                                        </select>
                                    </div>
                            </Col>
                        </Row>
                    </div>

                    <Button variant="success" className="read_more" type="submit">Inquire Now <img src={rightIcon} alt=''/> </Button>
                    </form>                   
                </Col>
                <Col>
                    <div className="right_im">
                        <img src={getIn} alt='' />
                        <div></div>
                    </div>
                </Col>
            </Row>    
            <Modal className="success_mb" show={show} onHide={handleClose}>
                <Modal.Body>
                    <h5>Thank you. We have received your details</h5>
                    <Button variant="success" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Body> 
            </Modal>                         
        </div>
    )
}

export default GetRightForm;