import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const PlantinVizag = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>RMX Plant in Vizag | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Strengthens its Presence in Vizag with New Ready-Mix Concrete Plant" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/plant-in-vizag"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
            <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Press Release - RMX Plant in Vizag - Final - 24May2023.pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco Strengthens its Presence in Vizag with New Ready-Mix Concrete Plant</h2>
          <p className='onetxt'>
            <strong>Vizag, May 23, 2023:</strong> Nuvoco Vistas Corp. Ltd., India’s fifth-largest cement group in terms of capacity and among the leading players in East India, today announced the launch of its new Ready-Mix Concrete (RMX) plant in Vizag, Andhra Pradesh. With a capacity of 60 cubic meters per hour, the plant will cater to construction projects in the area, providing a reliable source of highquality concrete while also improving efficiency, reducing costs, and promoting sustainability. Nuvoco reinforces its commitment to the infrastructure sector with the launch of its second ReadyMix Concrete (RMX) plant in Vizag, Andhra Pradesh. </p>

          <p>Vizag, a rapidly developing city, presents immense opportunities in the infrastructure sector, including projects such as the Greenfield Airport at Bhogapuram, undertaken by the GVMC. Moreover, its proximity to ports and its vibrant industrial belt makes it an attractive market for Nuvoco. The plant’s strategic location underscores its customer-centric approach, ensuring prompt and efficient delivery within a maximum travel time of four hours, thereby preventing concrete solidification in transit.</p>
          <p>Nuvoco's expansion into Vizag demonstrates the changing needs of the construction industry and its commitment to improving customer satisfaction, enhancing efficiency, and reducing costs. By providing reliable high-quality concrete, the new plant supports the realization of superior construction projects and promotes sustainable development.</p>

          <p><strong>Speaking about the Vizag plant, Mr. Prashant Jha, Chief of Ready-Mix (RMX) Concrete and Modern Building Materials business at Nuvoco </strong> expressed his enthusiasm, saying, "We are delighted to launch our second RMX plant in Vizag. This move reinforces our dedication to becoming the preferred choice for construction projects in the region. Our plant is equipped with cutting-edge technology and equipment, ensuring the consistent production of top-notch concrete and enabling swift delivery to meet the demands of the fast-growing southern market's infrastructure needs."</p>

         <p><strong>Mr. Jha further added,</strong> "At Nuvoco, we take pride in our customer-centric approach and have been consistently investing in the RMX business. The Vizag plant's location, close to our valued customers, reaffirms our commitment to their success. We offer a wide range of innovative RMX products, including Concreto (Performance Concrete), Artiste (Decorative Concrete), InstaMix (Ready-to-Use Bagged Concrete), X-Con (M20 to M60), and Ecodure (special green concrete). These products cater to diverse requirements and promote sustainable construction practices, aligning with our vision for the future."</p>

        <p>The establishment of the new RMX plant in Vizag marks a significant milestone for Nuvoco and the construction industry in the region. By contributing to the growth and development of Vizag and surrounding areas, Nuvoco aims to play a pivotal role in transforming the region into a flourishing hub for smart cities and industry.</p>
        
        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a cement manufacturing company with a vision to build a safer, smarter and sustainable world. Nuvoco started its operations in 1999, and since then it has emerged as one of the major players in the building materials industry in India. Today, Nuvoco offers a diversified business portfolio under three business segments, namely, Cement, Ready-Mix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco is a leading cement player in India and the largest cement company in East India in terms of capacity, following the acquisition of Nu Vista Limited (formerly Emami Cement Limited), offering various brands of cement such as ‘Concreto’, ‘Duraguard’, ‘Nirmax’, ‘Double Bull’, and ‘Infracem’. The MBM product portfolio, under the ‘Zero M’ and ‘Instamix’ brands, comprises construction chemicals, wall putty and cover blocks. The RMX business has a comprehensive pan-India presence offering value-added products under various brands such as ‘Concreto’, ‘Artiste’, ‘InstaMix’ and ‘X-Con’. Nuvoco is a proud contributor to landmark projects such as World One building at Mumbai, and the metro railway projects at Mumbai, Jaipur and Noida. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet the customer requirements. <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
          <p>For more information, please contact the Company at <a href="mailto:india.communications@nuvoco.com">india.communications@nuvoco.com</a></p>
        </Container>
      </div>
    </div >
  )
}

export default PlantinVizag