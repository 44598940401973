import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import { Navbar } from 'react-bootstrap';


function MobileMenu() {
    // redirectAnnual(){
    //  window.open('https://annualreport.nuvoco.com', '_blank');
    // }
    return (
        <>
            <div className='mm_menu wow fadeInRight'>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <div className='slide_menu'>
                    <Accordion defaultActiveKey="" flush>
                        <Accordion.Item eventKey="0" className="wow fadeInRight">
                            <Accordion.Header as={Link} to="about-us">About Us</Accordion.Header>
                            <Accordion.Body>
                                <Navbar.Toggle>
                           <Nav.Link as={Link} to="our-journey">Our Journey</Nav.Link>
                          <Nav.Link as={Link} to="our-brand-story">Our Brand Story</Nav.Link>
                          <Nav.Link as={Link} to="vision-mission-and-values">Vision, Mission & Values</Nav.Link>
                          <Nav.Link as={Link} to="board-of-directors">Board of Directors </Nav.Link>
                          <Nav.Link as={Link} to="management-team">Key Management Personnel</Nav.Link>
                          <Nav.Link as={Link} to="quality-and-innovation">Quality and Innovation</Nav.Link>
                                </Navbar.Toggle>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1" className="wow fadeInRight">
                            <Accordion.Header> Our Businesses</Accordion.Header>
                            <Accordion.Body>
                                <Navbar.Toggle>
                                    <Nav.Link as={Link} to="top-cement-company">Cement</Nav.Link>
                                    <Nav.Link as={Link} to="ready-mix-concrete">Ready-Mix Concrete</Nav.Link>
                                    <Nav.Link as={Link} to="modern-building-materials">Modern Building Material</Nav.Link>
                                </Navbar.Toggle>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2" className="wow fadeInRight">
                            <Accordion.Header> Our Brand</Accordion.Header>
                            <Accordion.Body>
                                <Accordion defaultActiveKey="">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Cement</Accordion.Header>
                                        <Accordion.Body>
                                            <Navbar.Toggle>
                                            
                                                <h3>Concreto</h3>
                                                <Nav.Link as={Link} to="products/concreto-cement">Concreto</Nav.Link>
                                                <Nav.Link as={Link} to="products/concreto-uno">Concreto Uno</Nav.Link>
                                                {/* <Nav.Link as={Link} to="products/concreto-green-cement">Concreto Green</Nav.Link>   */} 
                                                <h3 className="mt-3">Double Bull Cement</h3>
                                                <Nav.Link as={Link} to="products/double-bull-master-cement">Master</Nav.Link> 
                                                <Nav.Link as={Link} to="products/double-bull-ppc">PPC</Nav.Link>  
                                                <Nav.Link as={Link} to="products/double-bull-psc">PSC</Nav.Link> 
                                                {/* <Nav.Link as={Link} to="products/double-bull-subh-cement">Subh</Nav.Link>  */}

                                                <h3>Duraguard</h3>
                                                <Nav.Link as={Link} to="products/duraguard-cement">Duraguard</Nav.Link> 
                                                <Nav.Link as={Link} to="products/duraguard-microfiber-cement">Microfiber</Nav.Link> 
                                                <Nav.Link as={Link} to="products/duraguard-rapidx-cement">RapidX</Nav.Link>                                 
                                                {/* <Nav.Link as={Link} to="products/duraguard-waterseal-cement">Waterseal</Nav.Link>  */}
                                                <Nav.Link as={Link} to="products/duraguard-xtra-cement">Xtra</Nav.Link>  
                                                <Nav.Link as={Link} to="products/duraguard-f2f">F2F</Nav.Link>                                
                                                {/* <Nav.Link as={Link} to="products/duraguard-xtra-cement">Xtra Cement</Nav.Link>  */}

                                                <h3>Infracem</h3>
                                                {/* <Nav.Link as={Link} to="products/double-bull-opc-43">Double Bull - OPC 43</Nav.Link> 
                                                <Nav.Link as={Link} to="products/double-bull-opc-53">Double Bull - OPC 53</Nav.Link> */}
                                                <Nav.Link as={Link} to="products/infracem-cement">Infracem</Nav.Link>
                                                {/* <Nav.Link as={Link} to="products/double-bull-procem-cement">Double Bull Procem</Nav.Link> */}                                 
                                                 
                                                <h3>Nirmax</h3>
                                                <Nav.Link as={Link} to="products/nirmax-ppc">Nirmax</Nav.Link>                                
                                                <Nav.Link as={Link} to="products/nirmax-opc">Nirmax 43</Nav.Link> 
                                                <Nav.Link as={Link} to="products/nirmax-opc">Nirmax 53</Nav.Link>

                                                <h3 className="mt-3">Premium Slag</h3>
                                                <Nav.Link as={Link} to="products/p-s-c">PSC</Nav.Link>
                                                
                                            
                                            </Navbar.Toggle>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Ready-Mix Concrete</Accordion.Header>
                                        <Accordion.Body>
                                            <Navbar.Toggle>
                                            <h3>Artiste</h3>
                                            <Nav.Link as={Link} to="products/artiste-bare">Bare</Nav.Link>
                                            <Nav.Link as={Link} to="products/artiste-coarse">Coarse</Nav.Link>
                                            <Nav.Link as={Link} to="products/artiste-engrave">Engrave</Nav.Link>
                                            <Nav.Link as={Link} to="products/artiste-hue">Hue</Nav.Link>
                                            <Nav.Link as={Link} to="products/artiste-lumos">Lumos</Nav.Link>
                                            <Nav.Link as={Link} to="products/artiste-signature">Signature</Nav.Link> 

                                            <h3>Concreto</h3>
                                            <Nav.Link as={Link} to="products/concreto-agile">Agile</Nav.Link>
                                            <Nav.Link as={Link} to="products/concreto-agile-flow">Agile Flow</Nav.Link> 
                                            <Nav.Link as={Link} to="products/concreto-corrosafe-plus">Corrosafe</Nav.Link>
                                            <Nav.Link as={Link} to="products/concreto-cwt-plus">CWT Plus</Nav.Link>
                                            <Nav.Link as={Link} to="products/concreto-easyfille">Easyfille</Nav.Link>
                                            <Nav.Link as={Link} to="products/concreto-endura">Endura</Nav.Link>
                                            <Nav.Link as={Link} to="products/concreto-fluide">Fluide</Nav.Link>
                                            <Nav.Link as={Link} to="products/concreto-glyde">Glyde</Nav.Link>
                                            <Nav.Link as={Link} to="products/concreto-instante">Instante</Nav.Link>
                                            <Nav.Link as={Link} to="products/concreto-lente">Lente</Nav.Link>
                                            <Nav.Link as={Link} to="products/concreto-permadure">Permadure</Nav.Link>
                                            <Nav.Link as={Link} to="products/concreto-polibre">Polibre</Nav.Link>
                                            <Nav.Link as={Link} to="products/concreto-regletherme">Regletherme</Nav.Link>
                                            <Nav.Link as={Link} to="products/concreto-robuste">Robuste</Nav.Link>
                                            <Nav.Link as={Link} to="products/concreto-steelibre">Steelibre</Nav.Link>
                                            <Nav.Link as={Link} to="products/concreto-xlite">Xlite</Nav.Link>
                                            <Nav.Link as={Link} to="products/concreto-xlite-structure">Xlite Structure</Nav.Link>
                                        
                                            <h3>Ecodure</h3>
                                            <Nav.Link as={Link} to="products/ecodure">Ecodure</Nav.Link>
                                            <Nav.Link as={Link} to="products/ecodure-plus">Plus</Nav.Link>
                                            <Nav.Link as={Link} to="products/ecodure-prime">Prime</Nav.Link>

                                            <h3 className="mt-3">InstaMix</h3>
                                            <Nav.Link as={Link} to="products/instamix">InstaMix</Nav.Link>
                                            <Nav.Link as={Link} to="products/instamix-microne">Microne</Nav.Link>
                                            <Nav.Link as={Link} to="products/instamix-mortare">Mortare</Nav.Link>  

                                            <h3 className="mt-3">X-CON</h3>
                                            <Nav.Link as={Link} to="products/x-con">X-CON</Nav.Link>
                                            </Navbar.Toggle>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>MBM</Accordion.Header>
                                        <Accordion.Body>
                                            <Navbar.Toggle>
                                                {/* <h3>InstaMix</h3>
                                                <Nav.Link as={Link} to="products/instamix-bond-aid-joining-mortar">Bond-Aid Joining Mortar</Nav.Link>
                                                <Nav.Link as={Link} to="products/instamix-plastosmart-dry-plaster">Plastosmart Dry Plaster</Nav.Link>
                                                <Nav.Link as={Link} to="products/instamix-xpress-dry-bag-concrete">Xpress Dry Bag Concrete</Nav.Link> */}

                                                
                                                <h3>Zero M</h3>
                                                <h3 className="mt-3 f15">Waterproofing Solutions</h3>
                                                <Nav.Link as={Link} to="products/nuvoco-zero-m-iwc">IWC +</Nav.Link>
                                                <Nav.Link as={Link} to="products/nuvoco-zero-m-2k">2K</Nav.Link>
                                                <Nav.Link as={Link} to="products/nuvoco-zero-m-multipurpose-latex-expert">Multipurpose Latex Expert</Nav.Link>
                                                <Nav.Link as={Link} to="products/nuvoco-zero-m-multipurpose-acrylic-expert">Multipurpose Acrylic Expert</Nav.Link>
                                                <Nav.Link as={Link} to="products/nuvoco-zero-m-roof-shield">Roof shield</Nav.Link>

                                                <h3 className="mt-3 f15">Tile & Stone Fixing Solutions</h3>
                                                <Nav.Link as={Link} to="products/nuvoco-zero-m-tile-adhesive-t1">Tile Adhesive T1</Nav.Link> 
                                                <Nav.Link as={Link} to="products/nuvoco-zero-m-tile-adhesive-t2">Tile Adhesive T2</Nav.Link>
                                                <Nav.Link as={Link} to="products/nuvoco-zero-m-tile-adhesive-t3">Tile Adhesive T3</Nav.Link>
                                                <Nav.Link as={Link} to="products/nuvoco-zero-m-tile-adhesive-t4">Tile Adhesive T4</Nav.Link>
                                                <Nav.Link as={Link} to="products/nuvoco-zero-m-tile-adhesive-t5">Tile Adhesive T5</Nav.Link>
                                                <Nav.Link as={Link} to="products/nuvoco-zero-m-epoxy-tile-grout">Epoxy Tile Grout</Nav.Link>
                                                <Nav.Link as={Link} to="products/nuvoco-zero-m-tile-grout">Tile Grout</Nav.Link>

                                                <h3 className="mt-3 f15">Surface Preparation Solutions</h3>
                                                <Nav.Link as={Link} to="products/nuvoco-zero-m-wall-putty">Wall Putty</Nav.Link> 
                                                <Nav.Link as={Link} to="products/nuvoco-zero-m-dry-plaster">Dry Plaster</Nav.Link>
                                                <Nav.Link as={Link} to="products/nuvoco-zero-m-dry-concrete">Dry Concrete</Nav.Link>
                                                <Nav.Link as={Link} to="products/nuvoco-zero-m-block-jointing-mortar">Block Joining Mortar</Nav.Link>

                                                <h3 className="f15">Repair Solutions</h3>
                                                <Nav.Link as={Link} to="products/nuvoco-zero-m-tile-cleaner">Tile Cleaner</Nav.Link>
                                            
                                                <h3 className="mt-3 f15">Ancillary Solutions</h3>
                                                <Nav.Link as={Link} to="products/nuvoco-zero-m-cover-block">Cover Block</Nav.Link>



                                                
                                            </Navbar.Toggle>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3" className="wow fadeInRight">
                            <Accordion.Header>Sustainability</Accordion.Header>
                            <Accordion.Body>
                                <Navbar.Toggle>
       
                        <Nav.Link as={Link} to="/environment">Environment</Nav.Link>
                        <Nav.Link as={Link} to="/social">Social </Nav.Link>                    
                        <Nav.Link as={Link} to="/governance">Governance - ESG Profile</Nav.Link>                    
                        <Nav.Link as={Link} to="/health-and-safety">Health and Safety</Nav.Link> 



                                </Navbar.Toggle>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4" className="wow fadeInRight">
                            <Accordion.Header>Investor Relations</Accordion.Header>
                            <Accordion.Body>
                                <Navbar.Toggle>
                                    <Nav.Link as={Link} to="performance-highlights"> Performance Highlights</Nav.Link>
                                    <Nav.Link as={Link} to="investors-corner">Investor Corner</Nav.Link>
                                    <Nav.Link as={Link} to="corporate-governance">Corporate Governance</Nav.Link>
                                    <Nav.Link as={Link} to="annual-reports">Annual Reports</Nav.Link>
                                    <a className="nav-link" href="https://annualreport.nuvoco.com" target="_blank">Annual Report FY2023-24 Microsite</a> 
                                </Navbar.Toggle>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5" className="wow fadeInRight">
                            <Accordion.Header>Life at Nuvoco</Accordion.Header>
                            <Accordion.Body>
                                <Navbar.Toggle>
                                    <Nav.Link as={Link} to={{ pathname: "/vision-mission-and-values", hash: "#ourValue" }}>Our Values</Nav.Link>
                                    <Nav.Link as={Link} to={{ pathname: "/life-at-nuvoco", hash: "#ourPeople" }}>Our People</Nav.Link>
                                    <Nav.Link as={Link} to="our-employee-value-proposition">Our Employee Value Proposition</Nav.Link>
                                    <Nav.Link as={Link} to={{ pathname: "/life-at-nuvoco", hash: "#awardandrecognition" }}>Awards and Recognitions </Nav.Link>
                                    <a class="nav-link" href="https://career44.sapsf.com/career?company=nuvocovist" target="_blank" rel="noreferrer">
                                    Join Our Team</a>
                                </Navbar.Toggle>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Navbar.Toggle>
                        <Nav.Link as={Link} to="media/Press-Releases" className="wow fadeInRight">Media</Nav.Link>
                        <Nav.Link as={Link} to="contact-us" className="wow fadeInRight">Contact Us </Nav.Link>
                    </Navbar.Toggle>
                </div>
            </div>
        </>

    );
}

export default MobileMenu;