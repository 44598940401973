import { PRODUCT } from "./type";

const initialState ={product: ''};

const productR = (state = initialState, action) =>{
    switch(action.type){
        case PRODUCT:
            return {...state, product: action.payload};

        default:
            return state;
    }
}

export default productR;