import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from "react-helmet";

const HirenPatel = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Hiren Patel | Chairman and a Non-Executive Director | Nuvoco Vistas Corp. Ltd.</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Mr. Hiren Patel is the Chairman and a Non-Executive Director of the Company." />
    <link rel="canonical" href="https://nuvoco.com/hiren-patel"/>
    </Helmet>

      <div className='midpart bodPages'>
        <Container>
            <Row className="mb-5">
                 <Col md={4}>
                    <img style={{borderRadius:'15px'}} src="https://admin.nuvoco.com/public/BoardOfDirectorsDetails/Hiren.png" />
                </Col>
                <Col md={8}>
                    <h2 className='grad-heading'>Hiren Patel<br/><small>Chairman and a Non-Executive Director</small></h2>
                    <p className='onetxt'>
                    Mr. Hiren Patel is the Chairman and a Non-Executive Director of the Company. He has been a member of the Board of Directors since November 11, 2017. He holds a bachelor's degree in Engineering from Stevens Institute of Technology, New Jersey, USA and a master's degree in business administration from Drexel University, Pennsylvania, USA. He has been associated with the Nirma group since the year 1997. He has experience in the cement, consumer goods, chemicals and health care industry. He is presently the managing director of Nirma Limited. He is also a trustee of Nirma Education and Research Foundation, which runs the Nirma University and Nirma Vidyavihar and a member of the governing board of Nirma University.</p>
                </Col>
            </Row>

        </Container>
      </div>
    </div >
  )
}

export default HirenPatel