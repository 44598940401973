import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const MasonSkillDevelopment = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco launched the ‘Nuvo Mason’ Skill Development Program | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco launched the ‘Nuvo Mason’ Skill Development Program | Press Release" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/mason-skill-development"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
            <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Press Release - Nuvoco launched Nuvo Mason Skill Development Program (Dec 2022).pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco launched the ‘Nuvo Mason’ Skill Development Program</h2>
          <p>The company offers certified masonry skill training as part of its CSR Initiative</p>
         <p className='onetxt'><strong>January 6, 2022:</strong> Nuvoco Vistas Corp Ltd, India's fifth-largest cement company and the largest cement player in the East, in terms of capacity has <strong>launched the Nuvo Mason - a Masonry Skill Development Program in Rajasthan and Chhattisgarh as part of its Corporate Social Responsibility initiative to benefit the unorganised masonry sector.</strong></p>

          <p>Nuvoco has engaged LearNet Skills as an implementation partner. LearNet is one of the country's leading providers of vocational training programs. With this program, Nuvoco intends to provide short-term skills development opportunities for youth and unorganized construction sector workers aligned with the National Skills Qualification Framework (NSQF). Upon successful completion of the program, the National Skill Development Corporation (NSDC) will certify participants.</p>

          <p>Nuvo Mason is a training program designed to improve the masonry skills of workers, empower them, and open new earning opportunities for them. This program consists of approximately three hundred hours of training (theoretical and practical application) on subjects covering brick masonry, plastering (interiors), putty (interiors), tile masonry (floors and walls), and PCC work. The first batch of approximately 25 participants in each state has been selected from the surrounding villages of the company's Plants and is currently undergoing training.</p>

          <p><strong>Mr. Jayakumar Krishnaswamy, Managing Director, Nuvoco Vistas Corp. Ltd, commented</strong> “With a mission to become a leading building materials company delivering superior performance, we strive to meet customer expectations and provide solutions to construct a safe, durable, and aesthetically appealing home while staying abreast of current construction trends. In most cases, Individual Home Builders (IHBs) rely on the skills and knowledge of masons during the construction of a home. In addition to providing training in masonry skills, Nuvo Mason will enable its students to become financially and socially independent, thus improving their quality of life. With the help of this Corporate Social Responsibility initiative, we aim to build self-reliant local communities, thus creating a tangible benefit."</p>
        
        <p><strong>About Nuvoco</strong></p>
          <p>Nuvoco Vistas Corp. Ltd. is a leading manufacturer and retailer of building materials in India with a vision to build a safer, smarter and more sustainable world. The company started operations in India in 1999 via acquisitions, and since then it has emerged as one of the major players in India. Today it offers a diversified business portfolio under three business segments: Cement, Ready-Mix Concrete (RMX), and Modern Building Materials (MBM). Nuvoco is one of the top cement manufacturers in India, and the leading player in the East following the acquisition of Nu Vista Limited (formerly Emami Cement Limited); offering high-performance, premium, blended cement variants like Concreto, Duraguard, Nirmax and Infracem. Its MBM product range, under the Zero M brand, comprises construction chemicals, wall fill solutions, and cover blocks. Nuvoco's RMX business has a pan-India presence offering specialised products like Artiste and InstaMix being proud contributors to landmark projects like Lodha World One, Amritsar Entry Gate, and the Metros (Delhi, Jaipur, Noida and Mumbai). Through its NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies latent gaps in the industry and offers customised solutions to its customers. Guided by the enduring principles of safety for its employees and responsibility for the community and environment; the company is charting its course to shape a new world. <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
        </Container>
      </div>
    </div >
  )
}

export default MasonSkillDevelopment