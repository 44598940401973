import { PERFORMANCE, PERFORMANCESEARCH } from "./type";

const initialState ={performance: '', performancesearch: ''};

const performanceR = (state = initialState, action) =>{
    switch(action.type){
        case PERFORMANCE:
            return {...state, performance: action.payload};
        case PERFORMANCESEARCH:
            return {...state, performancesearch: action.payload};    

        default:
            return state;
    }
}

export default performanceR;