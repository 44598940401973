import { INVESTORCALLTRANSCRIPTS } from "./type";
import { API_URL } from "../config";
import axios from "axios";

const investorcalltransFn = (data) =>{
    const dt = {...data, IsSuccess:1};
    return{
        type: INVESTORCALLTRANSCRIPTS,
        payload: dt
    };
}

export const investorcalltransApi = (cid, yid) =>{
    let data ={category_id:cid, year_id:yid};
    return (dispatch) =>{
        axios.post(API_URL + 'FinancialInformationTranscriptNew', data).then((response) =>{
            dispatch(investorcalltransFn(response));
        });
    };
}