const K_WIDTH = 49;
const K_HEIGHT = 64;

const greatPlaceStyle = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  position: 'absolute',
  width: K_WIDTH,
  height: K_HEIGHT,
  left: -K_WIDTH / 2,
  top: -K_HEIGHT / 2,

 // border: '5px solid #f44336',
  //borderRadius: K_HEIGHT,
 // backgroundColor: 'white',
  textAlign: 'center',
  color: '#3f51b5',
  //fontSize: 16,
  fontWeight: 'bold',
  padding: 4,
 // display: 'flex',
 //   alignItems: 'center',
  //  justifyContent: 'center',
    cursor: 'pointer'
};

const greatPlaceStyleHover = {
    ...greatPlaceStyle,
    border: '5px solid #3f51b5',
    color: '#f44336'
  };
export {greatPlaceStyle, greatPlaceStyleHover};