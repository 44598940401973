import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const FinancialResultQ4FY24 = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Financial result Q4 FY24 | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="NUVOCO Vistas announces its financial results for Q4 FY24" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/financial-result-q4-fy24"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
        <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/financial-results-Q4FY24.pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>NUVOCO Vistas announces its financial results for Q4 FY24</h2>
          
          <ul>
            <li>Consolidated EBITDA at Rs. 1,657 crores and PAT at Rs. 147 crores; achieving highest-ever profitability</li>
            <li>Net debt reduced by Rs. 384 crores YoY to Rs. 4,030 crores</li>
            <li>Successfully commissioned 1.2 MMTPA Grinding unit at Haryana, increasing North share of capacity from 20% to 24%</li>
          </ul>
          <p className='onetxt'>
            <strong>Mumbai, April 30, 2024: </strong>Nuvoco Vistas Corp. Ltd., a leading building materials Company in India, announced its audited financial results for the year ended March 31, 2024. With 25 MMTPA of combined installed capacity, Nuvoco Vistas Corp. Ltd. is the fifth largest cement group in India and amongst the leading cement players in East India.</p>

          <p>The Company's consolidated cement sales volume stood at 18.8 MMT in FY24. Consolidated revenue from operations stood at Rs. 10,733 crores, during the same period. Consolidated EBITDA improved by 35% YoY to Rs. 1,657 crores. Further, the company reduced net debt by Rs. 384 crores YoY to Rs. 4,030 crores, resulting in Net debt / EBITDA of 2.4x.</p>

          <p>This year marked the successful commissioning of a 1.2 MMTPA Grinding unit at Haryana Cement Plant, marking a significant milestone in our expansion. The development has enhanced North share in the total company’s capacity from 20% to 24% and will further solidify our footprint in the region. The company completed debottlenecking projects at Risda and Nimbol Plants, which has resulted in a notable increase in clinker capacity.</p>

          <p>The Company remains dedicated to its strategic imperative in premiumisation and has expanded its premium products Duraguard F2F and Concreto Uno, into the markets of West Bengal and Jharkhand. Concreto Uno's unique water-repelling properties and damp lock formula address the ever-changing needs of the construction industry.</p>

          <p>The Company made significant strides in sustainability efforts under its <i>‘Protect Our Planet’</i> agenda with a reduction in carbon emissions by ~2% YoY to 454 kg CO2 per ton of cementitious materials1, reaffirming our position amongst the industry leaders in low carbon emissions. The Alternate Fuel Rate (AFR mix) saw an impressive improvement, increasing to 13% in FY24 from 9% in FY23, amongst the best in the industry. The Company also increased its solar power capacity to 5.3 MW in FY24 from 1.5 MW in FY23</p>

          <p>Commenting on the performance of the Company, Mr. Jayakumar Krishnaswamy, Managing Director, Nuvoco Vistas Corp. Ltd., stated, “The Company enhanced its operational efficiencies and delivered strong growth in EBITDA and PAT despite a volatile demand environment during FY24, achieving the highest profitability. Our commendable operational outcomes reflect our dynamic and efficient strategy focused on premiumisation and cost optimisation. Moving to FY25, our strategies focus on growth, expanding our market presence, and extracting more volumes from home markets while continuing our thrust on efficiency improvement initiatives.”</p>

         <p>Furthermore, he added, “The additional capacity in Haryana allows us to expand our market presence in the North while maintaining our leadership in the East. We have also seized the opportunity to grow our ReadyMix Concrete business, commissioning seven new plants this fiscal year, bringing the total to 58 plants panIndia.”</p>
        
        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a building materials company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999 and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest cement group in terms of capacity (25 MMTPA), with INR 10,733 crore in total revenue from operations in FY24. It is among the leading players in East India. Nuvoco offers a diversified business portfolio in three business segments: Cement, Ready-Mix Concrete (RMX), and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes - Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses a pan-India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (ready-to-use bagged concrete - the first-of-its-kind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to landmark projects like the Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium (Rourkela), Aquatic Gallery Science City (Ahmedabad), Metro Railway (Delhi, Jaipur, Noida and Mumbai), and among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ brands, comprises construction chemicals, tile adhesives, wall putty and cover blocks. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements. </p> 
          <p>Know more about the Company on <a href="https://www.nuvoco.com">www.nuvoco.com</a></p>
          <p>For more information, contact the Company at <a href="mailto:india.communications@nuvoco.com">India.communications@nuvoco.com</a></p>
        </Container>
      </div>
    </div >
  )
}

export default FinancialResultQ4FY24