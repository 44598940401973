import React, {Component} from 'react';
import shouldPureComponentUpdate from 'react-pure-render/function';
import PropTypes from 'prop-types'
import {greatPlaceStyle, greatPlaceStyleHover} from './my_great_place_styles.js';

export default class MyGreatPlace extends Component {
  static propTypes = {
    text: PropTypes.string,
    hover: PropTypes.bool
  };

  static defaultProps = {};

  shouldComponentUpdate = shouldPureComponentUpdate;

  constructor(props) {
    super(props);
  }

  render() {
    const style = this.props.hover ? greatPlaceStyleHover : greatPlaceStyle;
    return (
        <div className={this.props.id === this.props.locationId ? 'hint hint--html hint--info hint--top isActive' : 'hint hint--html hint--info hint--top'} style={style}>
          <div className='marker'></div>
          <div style={{width: 230}} className="hint__content">
              <h2>{this.props.title}</h2>
            {this.props.IsCompany && (<p className='companyTag'>{this.props.company}</p>)} 
              <p>Address: {this.props.address}</p>
              <p>{this.props.pincode}</p>
              {this.props.phone && <p>Phone: {this.props.phone}</p>}
          </div>
      </div>
    );
  }
}