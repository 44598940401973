import React from "react";
import { Card,Button } from "react-bootstrap";
import blog from '../assets/images/blog.jpg';

const BlogCard = ({item, id, uri}) =>{
    console.log(">>>>-NJ----", item.type)
    let linkUrl = "";
    let prDateCls = "";
    if(item.page_url && item.page_url !== "" && item.page_url !== "media-relation"){
        linkUrl = item.page_url;
    } else{
        linkUrl = uri +item.file_pdf;
    }
    if(item.type == '["1"]'){
        prDateCls = "prDateCls";
    }
    let newdata;
     const dateFormat = (date) =>{
        let data = new Date(date);
        data = data.toGMTString();
        data = data.substring(4, 16);
        newdata = data.split(" ");
        data = newdata[1] + " "+newdata[2] + ", "+newdata[3]
        return data;
    }
    return(
        <>
        {id <= 3 && (
           // uri + item.page_url && item.page_url != "" ? item.page_url : item.file_pdf
            <a href={linkUrl} target="_blank" rel="noreferrer">
                <Card className="mb-5">
                    <Card.Img variant="top" src={id <= 3 ? uri + item.listing_image : blog} />
                    <Card.Body>
                        <div className="new_desc">
                            <Card.Title numberoflines={1}>
                                {id <= 3 ? item.title : item.blog_title} <p>{id <= 3 ? item.description : item.meta_description}</p>
                            </Card.Title> 
                            {(id<=3 &&(
                            <p className={item.type === '["1"]' || item.type === '["2"]' ? 'prDateCls' : ''}>{dateFormat(item.created_at)}</p>
                            )) || ''}
                            <Button variant="link">Read More</Button>                        
                        </div>  
                    </Card.Body>
                </Card>
            </a>
        )}

        {id === 4 && (
            <a href={'/blog/' + item.page_url} rel="noreferrer">
                <Card className="mb-5">
                    <Card.Img variant="top" src={id <= 3 ? uri + item.listing_image : blog} />
                    {/* <Card.Img variant="top" src={id == 4 ? uri + item.banner_img : blog} /> */}
                    <Card.Body>
                        <div className="new_desc">
                            {(id<=3 &&(
                            <p>{dateFormat(item.created_at)}</p>
                            )) || ''}
                            <Card.Title>
                                {id <= 3 ? item.title : item.blog_title} <p>{id <= 3 ? item.description : item.meta_description}</p>
                            </Card.Title> 
                            <Button variant="link">Read More</Button>                        
                        </div>  
                    </Card.Body>
                </Card>
            </a>
        )}
        </>
    )
}
export default BlogCard;