import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const FinancialResultQ2FY24 = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Financial result Q2 FY 24 | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="NUVOCO Vistas announces its financial results for Q2 FY24" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/financial-result-q2-fy24"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
        <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/NUVOCO Vistas announces its financial results for Q2 FY24.pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: NUVOCO Vistas announces its financial results for Q2 FY24</h2>
          
          <ul>
            <li>Consolidated revenue increased 7% YoY to Rs. 2,573 crores </li>
            <li>Consolidated EBITDA increased 73% YoY to Rs. 336 crores</li>
          </ul>
          <p className='onetxt'>
            <strong>Mumbai, October 31, 2023: </strong>Nuvoco Vistas Corp. Ltd., a leading building materials Company in India, announced its unaudited financial results for the quarter ended September 30, 2023. With 23.82 MMTPA of combined installed capacity, Nuvoco Vistas Corp. Ltd. is the fifth largest cement group in India and amongst the leading cement players in East India. </p>

          <p>The consolidated cement sales volume for the Company increased by 1.2% YoY to 4.5 MMT in Q2 FY24. Consolidated revenue from operations during the same period improved by 7% YoY to Rs. 2,573 crores and consolidated EBITDA also improved by 73% YoY to Rs. 336 crores. </p> 

          <p>The Company has completed its debottlenecking projects at the Risda and Nimbol plants, enhancing the clinker production capacity by 2,000 TPD. This will provide headroom for growth and cater to increasing demand.</p>

          <p>In addition, the Premium products continue to remain a key focus area for the Company. The Company secured patent for its revolutionary product <strong>'Fibre Reinforced Cement Composition'</strong> branded in the market as 'Duraguard Microfiber Cement'. The patent, effective from the date of application - April 4, 2018, cites exclusive rights for the next 20 years. The Company also extended premium cement variants ‘Concreto UNO’ and ‘Duraguard F2F’ from Bihar and West Bengal to Jharkhand. </p>

          <p>The Company remains committed to its sustainability agenda – <strong>Protect Our Planet</strong>. The Company has amongst the lowest carbon emissions at 462 kg CO2 per ton of cementitious materials. The Company achieved an alternate fuel rate (AFR) of 14.3% in Q2 FY24, amongst the best in the industry, a significant improvement of 5.2% YoY. </p>

         <p>Commenting on the performance of the Company, <strong>Mr. Jayakumar Krishnaswamy</strong>, Managing Director, Nuvoco Vistas Corp. Ltd. stated, “Our Value over Volume strategy has positively contributed to the Company’s performance. Our trade share has increased from 72% in Q2 FY23 to 74% in Q2 FY24. In addition, the results also demonstrate our commitment to managing the dynamic cost environment through an optimized power and fuel mix between conventional and clean energy sources.”  </p>
        <p>Furthermore, he added, “The expansion at the Haryana Cement Plant is expected to be completed in FY24 which will enable us cater to the strong demand in the Northern region. On the RMX business, we have commissioned four new plants in the current fiscal, bringing our total to 55 plants pan-India.”</p>
        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a cement manufacturing company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999 and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest Cement Group in terms of capacity (23.8 MMTPA) with an INR 10,586 crore total revenue from operations in FY23 and is among the leading players in East India. Nuvoco offers a diversified business portfolio under three business segments ‐ Cement, Ready‐Mix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses PAN India presence and offers value‐added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (ready‐to‐use bagged concrete  ‐ the first‐of‐its‐kind in the industry), X‐Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to the landmark projects like Mumbai‐Ahmedabad Bullet Train; Birsa Munda Hockey Stadium, Rourkela; Aquatic Gallery Science City, Ahmedabad; and Metro Railway (Delhi, Jaipur, Noida and Mumbai) among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ and ‘InstaMix’ brands, comprises construction chemicals, wall putty and cover blocks. Through the NABL‐accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements. </p> 
          <p>Know more about the Company on <a href="https://www.nuvoco.com">www.nuvoco.com</a></p>
          <p>For more information, contact the Company at <a href="mailto:investor.relations@nuvoco.com">investor.relations@nuvoco.com</a></p>
        </Container>
      </div>
    </div >
  )
}

export default FinancialResultQ2FY24