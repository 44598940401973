import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const StrengthensPresence = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco Strengthens Presence in Pune and Mumbai with two new Ready-Mix Concrete Plants | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Strengthens Presence in Pune and Mumbai with two new Ready-Mix Concrete Plants" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/strengthens-presence-in-pune-mumbai"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
            <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/RMX%20Plant%20in%20Maharshatra%20(22Aug23).pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco Strengthens Presence in Pune and Mumbai with two new Ready-Mix Concrete Plants</h2>
          
          <p className='onetxt'><strong>Mumbai, August 23, 2023: </strong>Nuvoco Vistas Corp. Ltd., India’s fifth-largest cement group in terms of capacity and among the leading players in East India, is delighted to announce its expansion in the construction booming markets of Mumbai and Pune. The company has inaugurated two new RMX Plants in Kharadi (Pune-II) and Kandivali (Mumbai) as part of its ongoing efforts to expand its Pan-India footprint. This underscores Nuvoco's commitment to meet these regions' increasing demands for construction materials</p>

          <p>The Kharadi (Pune-II) plant caters to the Pune region's thriving construction market. As a key city in Maharashtra, Pune presents Nuvoco with tremendous opportunities for growth. This plant is strategically located to serve areas such as Kharadi, Hadapsar, Wagholi, Manjari, Nagori and Keshavnagar. Furthermore, the Kandivali Plant in Mumbai is easily accessible from the Western Express Highway and will contribute significantly to ongoing residential and commercial projects within a 15-20 kms radius. It is anticipated that the concrete volumes from these plants will rise gradually, driven by the escalating infrastructure demands in these locations</p>

          <p>With state-of-the-art equipment and facilities, these plants are capable of meeting Maharashtra's growing need for ready-mix concrete (RMX). It is equipped with a batching plant with a capacity of M1 (60 cubic meters per hour) and four 100-metric-ton silos. Consequently, Nuvoco's customers benefit from its advanced technology and quality assurance processes.</p>

          <p><strong>Mr. Prashant Jha, Chief of Ready-Mix Concrete and Modern Building Materials business at Nuvoco Vistas</strong> expressed his excitement about the expansions, stating, “The expansion of our Ready-Mix Concrete business in Maharashtra will play a key role in our overarching business strategy. This will enable us to tap into high-potential markets and strengthen our commitment to providing innovative solutions to our customers. With our proven formula of quality, innovation, and customer satisfaction, Nuvoco solidifies its position to be Leading Building Materials Company Delivering Superior Performance.”</p>
          
          <p>Nuvoco Vistas stays confident of leveraging Mumbai's significant infrastructure transformation and Pune's impressive expansion in the construction domain. With these expansions, the company continues its vision of Building a Safer, Smarter, and Sustainable World while catering to the evolving needs of its customers.</p>

         
        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a cement manufacturing company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999 and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest Cement Group in terms of capacity (23.8 MMTPA) with an INR 10,586 crore total revenue from operations in FY23 and is among the leading players in East India. Nuvoco offers a diversified business portfolio under three business segments - Cement, Ready-Mix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses PAN India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (ready-to-use bagged concrete - the first-of-itskind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to the landmark projects like Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium, Rourkela; Aquatic Gallery Science City, Ahmedabad; and Metro Railway (Delhi, Jaipur, Noida and Mumbai) among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ and ‘InstaMix’ brands, comprises construction chemicals, wall putty and cover blocks. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements</p>
          <p>Know more about the Company on <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
          <p>For more information contact the Company at  <a href="mailto:india.communications@nuvoco.com">India.communications@nuvoco.com</a></p>
      </Container>
      </div>
    </div >
  )
}

export default StrengthensPresence