import { PAGEDATA } from "./type";

const initialState ={comdata: ''};

const commonR = (state = initialState, action) =>{
    switch(action.type){
        case PAGEDATA:
            return {...state, comdata: action.payload};

        default:
            return state;
    }
}

export default commonR;