import React, {useState, useEffect}  from "react";
import {Container, Row, Col, Breadcrumb,Button,Modal} from 'react-bootstrap';

import { commonApi } from "../redux";
import { connect } from "react-redux";
import { imgUrl } from "../redux/config";

import banner from '../assets/images/bord_of_directors.png';
import bannermobile from '../assets/images/bord_of_directors-mobile.jpg';
import aboutBannerLogo from '../assets/images/bod-logo.png';

import BodCard from "../common/bodCard";
import BodSlider from "../common/bodSlide";
import { Helmet } from "react-helmet";

const pageType = 'board-of-directors?lang=en';
let IsData = false;



const BoardOfDirectors = ({commonApi, comdata}) => {
    useEffect(() => {
        IsData = false;
        commonApi(pageType);
	}, []);

    const [IsBOD, setBOD] = useState(1);
    const [bod, setData] = useState([]);
    const [slideList, setSlide] = useState([]);

    if (Object.keys(comdata).length) {        
        var bodData = {...comdata.data};
        if(!IsData)
            setData([...bodData.board_of_director_detail[0].Nuvoco_Vistas_Crop_Ltd]);

        IsData = true;
    }
    
    const changeBOD = (id) =>{
        setBOD(id);
        if(id ===1){
            setData([...bodData.board_of_director_detail[0].Nuvoco_Vistas_Crop_Ltd]);
        } else{
            setData([...bodData.board_of_director_detail[1].NU_Vistas_Limited]);
        }
    }
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (index) =>{
        setShow(true);
        const data = [...bod];
        const showdata = data.splice(index, 1);
        data.unshift(showdata[0]);
        setSlide([...data]);
    } 

    return( 
        <div className="bord_of_directors">
    <Helmet>
    <title>Nuvoco's Board Of Directors | ReadyMix Bulk Cement Supplier Company</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco is managed by a highly experienced board of directors comprising of a chairman, managing directors Find out more about our Board of Directors, CEO committee members" />
    <link rel="canonical" href="https://nuvoco.com/board-of-directors"/>
    </Helmet>
            <div className="banner text-center position-relative">
            <img className='desktopbannerinner' src={banner} alt="" />
               <img className='mobilebannerinner' src={bannermobile} alt="" />
                <article className='position-absolute text-center'>
                    <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
                    <h1 className="text-uppercase">BOARD OF DIRECTORS</h1>
                </article>
                <div className='breadouter'>
                    <div className='container'>
                        <Breadcrumb className='mb-0 py-2'>
                            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                Board of Directors
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
            <Container className='py-5 text-center'>
                <Button variant="success" className={ IsBOD === 1 ? 'active btn_lg' : 'btn_lg'} onClick={() => changeBOD(1)} >Nuvoco Vistas Corp. Ltd.</Button>
                <Button variant="success" className={ IsBOD === 2 ? 'active btn_lg' : 'btn_lg'} onClick={() => changeBOD(2)}  >NU Vista Limited</Button>

                <div className="card_round">
                    {(IsData && (
                        <Row>
                            {bod.map((item, index)=> {
                                return (
                                    <Col onClick={() => handleShow(index)} md={4} key={index}>
                                        <BodCard bd={item} uri={imgUrl} />
                                    </Col>
                                )
                            })}                            
                        </Row>
                    )) }                
                </div>
            </Container>

        <Modal  size="lg" centered="true"
            show={show} onHide={handleClose} className="bob_modal">
            <Modal.Body className="p-0">
            <Modal.Header closeButton></Modal.Header>
                <BodSlider slideList={slideList} uri = {imgUrl} />
            </Modal.Body>           
        </Modal>
    </div>
    )
}

const mapStatetoProps = (state) => {
	return {
		comdata: state.commonR.comdata
	};
};
const mapDispatchtoProps = (dispatch) => {
	return {
		commonApi: function () {
			dispatch(commonApi(pageType));
		},
	};
};

export default connect(mapStatetoProps, mapDispatchtoProps)(BoardOfDirectors);