import React from 'react';
import Container from 'react-bootstrap/Container';
import banner from '../assets/images/disclaimerBanner.jpg'
import bannermobile from '../assets/images/disclaimerBannerMobile.jpg'
import aboutBannerLogo from '../assets/images/aboutba-logo.png'
import abouticon1 from '../assets/images/about-icon1.jpg'
import abouticon2 from '../assets/images/abouticon2.jpg'
import abouticon3 from '../assets/images/abouticon3.jpg'
import abouticon4 from '../assets/images/abouticon4.jpg'
import abouticon5 from '../assets/images/abouticon5.jpg'
import buss1 from '../assets/images/business1.jpg'
import buss2 from '../assets/images/business2.jpg'
import buss3 from '../assets/images/business3.jpg'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const Disclaimer = () => {
  return (
    <div className="aboutus-pge inner_page">
      <div className="banner text-center position-relative">
        <img className='desktopbannerinner' src={banner} alt="" />
        <img className='mobilebannerinner' src={bannermobile} alt="" />
        <article className='position-absolute text-center'>
          <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
          <h1 className="text-uppercase">Disclaimer</h1>
        </article>
        <div className='breadouter'>
          <div className='container'>
            <Breadcrumb className='mb-0 py-2'>
              <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
              <Breadcrumb.Item active href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                Disclaimer
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div className='midpart mb-5'>
        <Container>
          <h2 className='grad-heading'>Disclaimer</h2>
          <p className='onetxt'>
        
            This website (hereafter this “Website”) is made available by Nuvoco Vistas Corp Ltd, a company incorporated in India under the laws of India (hereafter “NVCL”). While NVCL endeavors to ensure that the contents of the Website are accurate and reliable, NVCL makes no representations about the suitability of the information and services contained or obtained through this Website for any purpose, or the results that may be obtained from using this Website. All such information is provided “as is” without warranty of any kind. NVCL does not make any representation or warranty with respect to the veracity or the completeness of the information available on the Website and assumes no liability or responsibility for any omissions or errors in the information available on the Website.</p>
          <p>
          In no event shall NVCL and/or its affiliates be liable for any direct, indirect, punitive, incidental, special or consequential damages arising out of or in any way connected with the use of this Website, or for any information or services obtained through this Website, or otherwise arising out of the use of this Website, whether based on contract, tort, strict liability or otherwise, even if NVCL or any of its affiliates has been advised of the possibility of damages.This Website may contain forward-looking statements about NVCL and/or NVCL’s financial and operating performance, business plans and prospects, in-line products and product development that involve substantial risks and uncertainties. Actual results could differ materially from the expectations and projections set forth in those statements. NVCL undertakes no obligation to publicly update or revise any forward-looking statements.
          </p>
         
          
        </Container>
      </div>

    </div>
  )
}

export default Disclaimer