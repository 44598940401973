import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import {Container, Breadcrumb,Card, Button, Row, Col} from 'react-bootstrap';
import { Helmet } from "react-helmet";
import banner from '../assets/images/mbm-banner.jpg'
import mbmImg from '../assets/images/mbm_img.jpg'
import fountains from '../assets/images/mbm04.png'

import mbm01 from '../assets/images/mbm01.jpg';
import mbm02 from '../assets/images/mbm02.jpg';
import mbm03 from '../assets/images/mbm03.jpg';

import GetRightForm from "../common/get-right";

const settings = {
   autoplay:true,
   autoplaySpeed: 2000,
    dots: false,
    arrow:true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    mode:'center'
  };

class MBM extends React.Component{
    render(){
        return(
            <div className="cement_concreto inner_page">
                <Helmet>
                  <title>Sustainable Building Materials Manufacturer & Supplier In India</title>
                  <meta name="keywords" description="" />
                 <meta name="description" content="Browse a large selection of suatainable building materials, includes construction chemicals compounds to save from cracks, due to a growing demand for environmental friendly products" />
                </Helmet>
                <div className="banner text-center position-relative">
                    <img src={banner} alt="" />
                    <div className='breadouter'>
                        <div className='container'>
                            <Breadcrumb className='mb-0 py-2'>
                                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                                <Breadcrumb.Item href="#">Cement</Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Zero M Speedex Tile Adhesive
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                </div>
                <div className='midpart mt-5'>
                    <Container>
                        <h2 className='grad-heading'>Zero M Speedex Tile Adhesive</h2>
                        <p>
                            Zero M Speedex is a high-performance, non-shrink, and polymer-modified
                             flexible tile adhesive that works on both walls and floors.
                        </p>

                        <h2 className='grad-heading mt-5 pt-4'>Areas of Application</h2>
                        <p className="con_pt">
                            Only water needs to be added to the product for the desired consistency, at the time of application
                        </p>

                        <div className="concreto mbm text-center">
                            <img src={mbmImg} alt='' />
                            <ul>
                                <li>Fixing ceramic, vitrified etc</li>
                                <li>Floors and <br/> walls <img src={fountains} alt='' /></li>
                                <li>Tile on tile <br/> application</li>                                
                            </ul>
                        </div>

                        <h2 className='grad-heading'>Benefits</h2>

                        <div className="slide_cta center_slide card_round">
                            <Slider {...settings}>
                                <div className="wow fadeInLeft" data-wow-delay="0.5s">
                                    <Card>
                                        <Card.Img variant="top" src={mbm01} />
                                        <Card.Body>
                                            <Card.Title>
                                            Outstanding Non -Shrink and Adhesion Properties
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </div>                 
                                <div className="wow fadeInLeft" data-wow-delay="0.7s">
                                    <Card>
                                        <Card.Img variant="top" src={mbm02} />
                                        <Card.Body>
                                            <Card.Title>
                                            Fast Setting and Quick Strength Development 
                                            </Card.Title> 
                                        </Card.Body>
                                    </Card>
                                </div>                 
                                <div className="wow fadeInLeft" data-wow-delay="0.5s">
                                    <Card>
                                        <Card.Img variant="top" src={mbm03} />
                                        <Card.Body>
                                            <Card.Title>
                                                High-Water Resistance
                                            </Card.Title> 
                                        </Card.Body>
                                    </Card>
                                </div>                   
                                <div className="wow fadeInLeft" data-wow-delay="0.5s">
                                    <Card>
                                        <Card.Img variant="top" src={mbm01} />
                                        <Card.Body>
                                            <Card.Title>
                                            Outstanding Non -Shrink and Adhesion Properties
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </div>                 
                                <div className="wow fadeInLeft" data-wow-delay="0.7s">
                                    <Card>
                                        <Card.Img variant="top" src={mbm02} />
                                        <Card.Body>
                                            <Card.Title>
                                            Fast Setting and Quick Strength Development 
                                            </Card.Title> 
                                        </Card.Body>
                                    </Card>
                                </div>                 
                                <div className="wow fadeInLeft" data-wow-delay="0.5s">
                                    <Card>
                                        <Card.Img variant="top" src={mbm03} />
                                        <Card.Body>
                                            <Card.Title>
                                                High-Water Resistance
                                            </Card.Title> 
                                        </Card.Body>
                                    </Card>
                                </div>                   
                            </Slider>
                        </div>

                        <h2 className='grad-heading'>Brochures</h2>
                        <Row className="justify-content-center">
                            <Col md={3}><Button variant="success" className="btn_lg mb-3" href="#">English</Button></Col>
                            <Col md={3}><Button variant="success" className="btn_lg mb-3"  href="#">िहंदी</Button></Col>
                            <Col md={3}><Button variant="success" className="btn_lg mb-3"  href="#">বাংলা</Button></Col>
                        </Row>         
                        <Row className="justify-content-center">
                            <Col md={3}><Button variant="success" className="btn_lg"  href="#">ଓଡିଆ</Button></Col>
                            <Col md={3}><Button variant="success" className="btn_lg" href="#">ગુજરાતી</Button></Col>
                            <Col md={3}><Button variant="success" className="btn_lg"  href="#">తెలుగు</Button></Col>                            
                        </Row>         

                        <GetRightForm/>                       
                    </Container>
                </div>

                
            </div>
        )
    }
}

export default MBM;