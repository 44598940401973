import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";
import img12 from '../../assets/images/durga-puja-press-release.jpg';

const BasantiDurgaPuja = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Shri Basanti Durga Puja, Jamshedpur | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Shri Basanti Durga Puja, Jamshedpur" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/shri-basanti-durga-puja"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
        <Row className="align-center">
          <Col sm={12}>
            <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Shri-Basanti-Durga-Puja-(Jamshedpur).pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Shri Basanti Durga Puja, Jamshedpur</h2>
          </Col>
          <Col sm={7} className="mx-auto">
          <img className="imgbannerdesktop" src={img12} alt="" />

         
        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a building material company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999 and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest Cement Group in terms of capacity (23.8 MMTPA) with an INR 10,586 crore total revenue from operations in FY23 and is among the leading players in East India. Nuvoco offers a diversified business portfolio under three business segments - Cement, ReadyMix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses PAN India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (ready-to-use bagged concrete - the first-of-its-kind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to the landmark projects like Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium, Rourkela; Aquatic Gallery Science City, Ahmedabad; and Metro Railway (Delhi, Jaipur, Noida and Mumbai) among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ and ‘InstaMix’ brands, comprises construction chemicals, wall putty and cover blocks. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements.</p>
          <p>Know more about the Company on <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
          <p>For more information contact the Company at  <a href="mailto:india.communications@nuvoco.com">India.communications@nuvoco.com</a></p>
          </Col>
          </Row>
      </Container>
      </div>
    </div >
  )
}

export default BasantiDurgaPuja