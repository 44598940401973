import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const JojoberaCementPlant = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco’s Jojobera Cement Plant organised Chhath Puja in Jamshedpur | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco’s Jojobera Cement Plant organised Chhath Puja in Jamshedpur" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/Jojobera-Cement-Plant"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
            <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Press Release - Nuvoco’s Jojobera Cement Plant organised Chhath Puja in Jamshedpur (Oct 2022).pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco’s Jojobera Cement Plant organised Chhath Puja in Jamshedpur</h2>
          <p className='onetxt'><strong>Jamshedpur, October 31, 2022: </strong> During the holy festival of Chhath Puja, the Jojobera Cement Plant (JCP) of Nuvoco Vistas Corp. Ltd. facilitated the arrangements for the Chhath Puja at Baridih area including Bhojpur Ghat, Swarnrekha Ghat.</p>

          <p>Chhath is an ancient Hindu festival historically native to the Indian states of Jharkhand, Bihar, Uttar Pradesh, and West Bengal. Chhath Puja is performed to show gratitude and thankfulness to lord Surya (Sun) for bestowing the bounties of life on earth. The rituals are observed over four days including holy baths, vrata (fasting and abstaining from drinking water), standing in water, and offering prasad (prayer offerings) and arghya to the setting and rising sun. Some devotees also perform a prostration march as they head for the riverbanks.</p>

          <p>Nuvoco’s Jojobera Cement Plant supported getting Ghats ready for the Puja by cleaning the ghats, developing the nearby areas, arranging for changing rooms, lights and providing water services for 15 puja ghats of Chhota Gobindpur Area (Govardhan Park, Karpuri Park, Putul Maidan, Shiv Mandir, Vivek Nagar, Tin Tilla, Amalas City, Janta School, Ranchi Road puja Smiti), Jojobera Area (Shiv Mandir Chatth Ghat, Krishna Nagar Basti), Bhojpur Ghat, Barigora Basti, Vijaya Garden and Mithila Colony in collaboration with Mangal Kalindi, MLA; Dr. Paritosh Singh and Kusum Purti, Zilla Parishad Members and other local people</p>
        
        <p><strong>About Nuvoco</strong></p>
          <p>Nuvoco Vistas Corp. Ltd. is a leading manufacturer and retailer of building materials in India with a vision to build a safer, smarter and more sustainable world. The company started operations in India in 1999 via acquisitions, and since then it has emerged as one of the major players in India. Today it offers a diversified business portfolio under three business segments, namely, Cement, Ready-Mix Concrete (RMX), and Modern Building Materials (MBM). Nuvoco is one of the top cement manufacturers in India, and the leading player in the East following the acquisition of Nu Vista Limited (formerly Emami Cement Limited); offering high-performance, premium, blended cement variants like Concreto, Duraguard, Nirmax and Infracem. Its MBM product range, under the Zero M brand, comprises construction chemicals, wall fill solutions, and cover blocks. Nuvoco's RMX business has a pan-India presence offering specialised products like Artiste and InstaMix being proud contributors to landmark projects like Lodha World One, Amritsar Entry Gate, and the Metros (Delhi, Jaipur, Noida and Mumbai). Through its NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies latent gaps in the industry and offers customised solutions to its customers. Guided by the enduring principles of safety for its employees and responsibility for the community and environment; the company is charting its course to shape a new world. <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
      </Container>
      </div>
    </div >
  )
}

export default JojoberaCementPlant