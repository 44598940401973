import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const SuperPremiumCement = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco Launches Concreto Uno – Super Premium Cement Variant | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Launches Concreto Uno – Super Premium Cement Variant | Press Release" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/Super-Premium-Cement"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
            <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Press Release - Nuvoco Launches Concreto Uno - Super Premium Cement Variant in Bihar (Dec 2022).pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco Launches Concreto Uno – Super Premium Cement Variant with unique Water-Repelling Properties and Damp Lock Formula in Bihar</h2>
         <p className='onetxt'><strong>Mumbai, 30 December 2022:</strong> Nuvoco Vistas Corp. Ltd, India’s fifth-largest cement group and the leading cement player in East India, in terms of capacity, has launched a unique and path-breaking product - CONCRETO UNO in Bihar markets. It is the most premium cement variant with unique water-repelling properties and damp lock formula to address the ever-changing needs of the construction industry. </p>

          <p>CONCRETO UNO protects the construction from water ingress, dampness, and efflorescence; resulting in higher resistance and better protection from harmful waterborne environmental pollutants; thereby increasing a structure’s paint life and durability. At the heart of Nuvoco's philosophy is Quality and Innovation; CONCRETO UNO is created in the company's dedicated Construction Development and Innovation Centre (CDIC) using state-of-the-art technology that forms an insoluble coating over steel bars and enhances the structure's longevity.</p>
          <p>CONCRETO UNO's special mix ensures an improved damp-lock process and a faster construction pace without compromising strength. The product can be used both for exterior and interior plastering. When used in kitchens, bathrooms, and cellars, it prevents dampness, thus improving hygiene. The path-breaking product comes in a premium laminated polypropylene bag. </p>

          <p>Speaking about the launch of CONCRETO UNO, <strong>Ms. Madhumita Basu, Chief Strategy and Marketing Officer</strong> commented, “Nuvoco is committed to delivering value for all our stakeholders through innovative, sustainable, and quality products. We enhance our premium offering Concreto franchise, the most preferred choice of the consumer, with product offerings in the super-premium category with CONCRETO UNO. The three guiding principles of Nuvoco—Quality, Innovation, and Trust are reiterated with the launch of CONCRETO UNO."</p>
        
        <p><strong>About Nuvoco</strong></p>
          <p>Nuvoco Vistas Corp. Ltd. is a leading manufacturer and retailer of building materials in India with a vision to build a safer, smarter and more sustainable world. The company started operations in India in 1999 via acquisitions, and since then it has emerged as one of the major players in India. Today it offers a diversified business portfolio under three business segments: Cement, Ready-Mix Concrete (RMX), and Modern Building Materials (MBM). Nuvoco is one of the top cement manufacturers in India, and the leading player in the East following the acquisition of Nu Vista Limited (formerly Emami Cement Limited); offering highperformance, premium, blended cement variants like Concreto, Duraguard, Nirmax and Infracem. Its MBM product range, under the Zero M brand, comprises construction chemicals, wall fill solutions, and cover blocks. Nuvoco's RMX business has a pan-India presence offering specialised products like Artiste and InstaMix being proud contributors to landmark projects like Lodha World One, Amritsar Entry Gate, and the Metros (Delhi, Jaipur, Noida and Mumbai). Through its NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies latent gaps in the industry and offers customised solutions to its customers. Guided by the enduring principles of safety for its employees and responsibility for the community and environment; the company is charting its course to shape a new world.  <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
        </Container>
      </div>
    </div >
  )
}

export default SuperPremiumCement