import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import {Container, Breadcrumb,Card, Button, Row, Col} from 'react-bootstrap';
import banner from '../assets/images/instamix_banner.jpg'
import instamix from '../assets/images/instamix.jpg'
import fountains from '../assets/images/mbm04.png'

import instamix01 from '../assets/images/instamix01.jpg';
import instamix02 from '../assets/images/instamix02.jpg';
import instamix03 from '../assets/images/instamix03.jpg';

import GetRightForm from "../common/get-right";

const settings = {
   // autoplay:true,
   // autoplaySpeed: 2000,
    dots: false,
    arrow:true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    mode:'center'
  };

class InstamixMortare extends React.Component{
    render(){
        return(
            <div className="cement_concreto inner_page">
                <div className="banner text-center position-relative">
                    <img src={banner} alt="" />
                    <div className='breadouter'>
                        <div className='container'>
                            <Breadcrumb className='mb-0 py-2'>
                                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                                <Breadcrumb.Item href="#">Cement</Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Instamix Mortare
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                </div>
                <div className='midpart mt-5'>
                    <Container>
                        <h2 className='grad-heading'>Instamix Mortare</h2>
                        <p>
                        Nuvoco Instamix Mortare is a wet, ready-to-use cement-based bagged mortar especially designed for all kinds of manual plastering applications.
                         It is a high quality Mortar composed of Fibers and Integral Waterproofing Compound.
                        </p>

                        <h2 className='grad-heading mt-5 pt-4'>Areas of Application</h2>
                        <p className="con_pt">
                            Concrete to be placed within 3 hours from batching.
                        </p>

                        <div className="concreto mbm mix text-center">
                            <img src={instamix} alt='' />
                            <ul>
                                <li>Interior wall plastering</li>
                                <li>Brick, blocks, concrete & stone wall plastering <img src={fountains} alt='' /></li>
                                <li>Exterior wall plastering</li>                                
                            </ul>
                        </div>

                        <h2 className='grad-heading'>Benefits</h2>

                        <div className="slide_cta center_slide card_round">
                            <Slider {...settings}>
                                <div className="wow fadeInLeft" data-wow-delay="0.5s">
                                    <Card>
                                        <Card.Img variant="top" src={instamix01} />
                                        <Card.Body>
                                            <Card.Title>
                                                More Cohesive Mix Resulting in Better Durability 
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </div>                 
                                <div className="wow fadeInLeft" data-wow-delay="0.7s">
                                    <Card>
                                        <Card.Img variant="top" src={instamix02} />
                                        <Card.Body>
                                            <Card.Title>
                                            Resistant to Surface Cracks
                                            </Card.Title> 
                                        </Card.Body>
                                    </Card>
                                </div>                 
                                <div className="wow fadeInLeft" data-wow-delay="0.5s">
                                    <Card>
                                        <Card.Img variant="top" src={instamix03} />
                                        <Card.Body>
                                            <Card.Title>
                                            Uniform & Smooth Finish
                                            </Card.Title> 
                                        </Card.Body>
                                    </Card>
                                </div>                  
                                <div className="wow fadeInLeft" data-wow-delay="0.5s">
                                    <Card>
                                        <Card.Img variant="top" src={instamix01} />
                                        <Card.Body>
                                            <Card.Title>
                                                More Cohesive Mix Resulting in Better Durability 
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </div>                 
                                <div className="wow fadeInLeft" data-wow-delay="0.7s">
                                    <Card>
                                        <Card.Img variant="top" src={instamix02} />
                                        <Card.Body>
                                            <Card.Title>
                                            Resistant to Surface Cracks
                                            </Card.Title> 
                                        </Card.Body>
                                    </Card>
                                </div>                 
                                <div className="wow fadeInLeft" data-wow-delay="0.5s">
                                    <Card>
                                        <Card.Img variant="top" src={instamix03} />
                                        <Card.Body>
                                            <Card.Title>
                                            Uniform & Smooth Finish
                                            </Card.Title> 
                                        </Card.Body>
                                    </Card>
                                </div>                  
                            </Slider>
                        </div>

                        <h2 className='grad-heading'>Brochures</h2>
                        <Row className="justify-content-center">
                            <Col md={3}><Button variant="success" className="btn_lg" href="#">English</Button></Col>
                        </Row>            

                        <GetRightForm/>                       
                    </Container>
                </div>

                
            </div>
        )
    }
}

export default InstamixMortare;