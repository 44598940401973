import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const SwarnMahotsavinBihar = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Swarn Mahotsav with its Dealers in Bihar | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Celebrates Swarn Mahotsav with its Dealers in Bihar" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/swarn-mahotsav-in-bihar"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
            <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Press Release - Nuvoco Celebrates Swarn Mahotsav with its Dealers in Bihar.pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco Celebrates Swarn Mahotsav with its Dealers in Bihar</h2>
          <p className='onetxt'>
            <strong>Bihar – October 18, 2022:</strong> India’s fifth-largest cement group and the leading cement company in the East, Nuvoco Vistas Corp. Ltd., celebrated the most prestigious <strong>Annual Gold Distribution event ‘Swarn Mahotsav’ in Bihar.</strong> The event recognizes the efforts of its dealers in key markets of Bihar and felicitates the top 225 dealers by rewarding Gold (a symbol of prosperity, fortune, and wealth) for their target sales achievements for the year for Concreto, Nuvoco's premium offering.</p>

          <p>Concreto is a premium slag cement that is well-suited for all types of low, medium, and heavyduty construction applications and gives a better finish to the exterior and interiors of a building. It can be used for varied applications like roofing, foundations, beams, columns, plastering and brickwork. It is specially formulated to provide a denser concrete mix than normal cement. </p>

          <p><strong>Mr. Vinit Tiwari, Chief Sales Officer, Cement Business </strong>  said, “At Nuvoco, we stay committed to creating value for all its stakeholders. Our dealers play a vital role in Nuvoco's growth story and are an integral part of our network strategy. The Swarn Mahotsav is an initiative to recognize their efforts in promoting our premium cement brand – Concreto, which has seen a 17% Y-o-Y growth in FY22. Concreto continues to be a category leader in slag cement and one of the most trusted cement brands in India.” </p>
        
        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a cement manufacturing company with a vision to build a safer, smarter and sustainable world. Nuvoco started its operations in 1999, and since then it has emerged as one of the major players in the building materials industry in India. Today, Nuvoco offers a diversified business portfolio under three business segments, namely, Cement, Ready-Mix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco is a leading cement player in India and the largest cement company in East India in terms of capacity, following the acquisition of Nu Vista Limited (formerly Emami Cement Limited), offering various brands of cement such as ‘Concreto’, ‘Duraguard’, ‘Nirmax’, ‘Double Bull’, and ‘Infracem’. The MBM product portfolio, under the ‘Zero M’ and ‘Instamix’ brands, comprises construction chemicals, wall putty and cover blocks. The RMX business has a comprehensive pan-India presence offering value-added products under various brands such as ‘Concreto’, ‘Artiste’, ‘InstaMix’ and ‘X-Con’. Nuvoco is a proud contributor to landmark projects such as World One building at Mumbai, and the metro railway projects at Mumbai, Jaipur and Noida. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet the customer requirements. <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
      </Container>
      </div>
    </div >
  )
}

export default SwarnMahotsavinBihar