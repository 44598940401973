import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const NuvoNirmaanApp = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco launches NuvoNirmaan – A Direct-to-Consumer App | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco launches NuvoNirmaan – A Direct-to-Consumer App | Press Release" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/Nuvo-Nirmaan-App"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
            <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Press Release - Nuvoco launches NuvoNirmaan – A Direct-to-Consumer App (Nov 2022).pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco launches NuvoNirmaan – A Direct-to-Consumer App</h2>
          <p>The App offers customized solutions to customers along with knowledge of various stages of construction</p>
          <p className='onetxt'><strong>November 5, 2022, Mumbai:</strong> Building a home is a daunting task involving significant challenges like budget, plot size, house plans, choice of building materials, and many more. To address these tasks and deliver an engaging customer experience, Nuvoco Vistas Corp. Ltd., India's fifthlargest cement group in terms of capacity having over fifty-plus products in cement, ready-mix concrete, and modern building materials, launched <strong>NuvoNirmaan</strong> – the first direct-to-customer home assist app. This is an all-in-one digital platform that covers a wide range of information and points of guidance throughout the home building and construction stages, with minimum turnaround time and dependence. </p>

          <p>In addition to providing knowledge about construction, NuvoNirmaan shares expertise about stages of construction, informs consumers about the latest and most innovative products and offers financial guidance to consumers. Using this app, consumers including Individual Home Builders (IHBs) can view a variety of floor plans, calculate the cost of building materials, and learn about the various stages of construction through articles and videos and products available along with its usage. </p>
          <p>On the App launch, Madhumita Basu, Nuvoco's Chief Strategy and Marketing Officer commented, " According to several reports, the Indian Construction Market is expected to grow more than 10% between 2022 and 2027. A significant portion of this growth will be driven by India's vast homebuilding community. Currently, there are limited digital platforms that cover and assist them in their home-building journey. Through NuvoNirmaan, Nuvoco aims to fill this void by providing all customers with access to up-to-date information and tools about home building at their fingertips. The NuvoNirmaan app aims to engage and simplify the home-building journey and help to plan effectively from execution through completion.”</p>
        
        <p><strong>About Nuvoco</strong></p>
          <p>Nuvoco Vistas Corp. Ltd. is a leading manufacturer and retailer of building materials in India with a vision to build a safer, smarter and more sustainable world. The company started operations in India in 1999 via acquisitions, and since then it has emerged as one of the major players in India. Today it offers a diversified business portfolio under three business segments, namely, Cement, Ready-Mix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco is one of the top cement manufacturers in India, and the leading player in the East following the acquisition of Nu Vista Limited (formerly Emami Cement Limited), offering high performance, premium, blended cement variants like Concreto, Duraguard, Nirmax and Infracem. Its MBM product range, under the Zero M brand, comprises construction chemicals, wall fill solutions, and cover blocks. Nuvoco's RMX business has a pan-India presence offering specialised products like Artiste and InstaMix being proud contributors to landmark projects like Lodha World One, Amritsar Entry Gate, and the Metros (Delhi, Jaipur, Noida and Mumbai). Through its NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies latent gaps in the industry and offers customised solutions to its customers. Guided by the enduring principles of safety for its employees and responsibility for the community and environment; the company is charting its course to shape a new world. <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
        </Container>
      </div>
    </div >
  )
}

export default NuvoNirmaanApp