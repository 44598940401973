import { PERFORMANCEFINANCIAL, PERFORMANCEFINANCIALSEARCH } from "./type";

const initialState ={performancefinancial: '', performanfinancialcesearch: ''};

const performancefinancialR = (state = initialState, action) =>{
    switch(action.type){
        case PERFORMANCEFINANCIAL:
            return {...state, performancefinancial: action.payload};
        case PERFORMANCEFINANCIALSEARCH:
            return {...state, performancefinancialsearch: action.payload};    

        default:
            return state;
    }
}

export default performancefinancialR;