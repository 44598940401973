import React from 'react';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import {Container,Col, Row, Breadcrumb, Card} from 'react-bootstrap';
import banner from '../assets/images/inn-banner.png'
import bannermobile from '../assets/images/inn-banner-mobile.jpg'
import aboutBannerLogo from '../assets/images/innba-logo.png'
import img1 from '../assets/images/infra1.png'
import img2 from '../assets/images/infra2.png'
import img3 from '../assets/images/inn2.png'
import img4 from '../assets/images/inn1.png'
import img5 from '../assets/images/inn3.png'
import img6 from '../assets/images/inn4.png'
import img7 from '../assets/images/inn5.png'
import img8 from '../assets/images/inn6.png'
import img9 from '../assets/images/nabl.jpg'
import pdfIcon from '../assets/images/pdf_icon.png';
import healthpolicy from '../assets/images/Health_and_Safety_Policy.pdf';
import mbm01 from '../assets/images/ingallery01.jpg';
import mbm02 from '../assets/images/ingallery02.jpg';
import mbm03 from '../assets/images/ingallery03.jpg';
import mbm04 from '../assets/images/ingallery04.jpg';
import mbm05 from '../assets/images/ingallery05.jpg';
import mbm06 from '../assets/images/ingallery06.jpg';
import mbm07 from '../assets/images/ingallery07.jpg';
import mbm08 from '../assets/images/ingallery08.jpg';


import f1 from '../assets/images/floors-00.jpg';
import f2 from '../assets/images/floors-01.jpg';
import f3 from '../assets/images/floors-02.jpg';
import f4 from '../assets/images/floors-03.jpg';
import { Helmet } from 'react-helmet';

const settings = {
    autoplay:true,
    autoplaySpeed: 2000,
     dots: false,
     arrow:true,
     infinite: true,
     speed: 500,
     slidesToShow: 3,
     slidesToScroll: 1,
     mode:'center',
     responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            },
        },
    ],
};
const floorSlide = {
     dots: true,
     arrow:false,
     infinite: true,
     speed: 500,
     slidesToShow: 1,
     slidesToScroll: 1,
     mode:'center',
     responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            },
        },
    ],
};
const InnovationCentre = () => {
    return (
        <div className="inncentre-page">

<Helmet>
    <title>Quality and Innovation | Nuvoco Vistas</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco is providing high quality and innovation since its bincepting by producing and delivering value to stakeholders through innovative and qualiy products" />
    <link rel="canonical" href="https://nuvoco.com/quality-and-innovation"/>
    </Helmet>

            <div className="banner text-center position-relative">
                
                <img className='desktopbannerinner' src={banner} alt="" />
               <img className='mobilebannerinner' src={bannermobile} alt="" />

                <article className='position-absolute text-center'>
                    <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
                    <h1 className="text-uppercase">Quality and Innovation</h1>
                </article>
                <div className='breadouter'>
                    <div className='container'>
                        <Breadcrumb className='mb-0 py-2'>
                            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href="#">About Us</Breadcrumb.Item>
                            <Breadcrumb.Item active href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                            Quality and Innovation
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
            <Container className='py-5'>

            <section className='tptextbx py-0'>
                    <h2 className='grad-heading'>Quality</h2>
                    <p>Nuvoco is committed to delivering value for all its stakeholders through innovative and quality products. We offer our loyal customers a variety of concrete and cement solutions that are durable and unique to the industry in addition to our expertise in contemporary building materials. Our team at NABL-accredited CDIC Lab innovates products and processes with a focus on quality to minimize risk against market fluctuations considerably. The product quality monitoring includes daily testing of approved quality parameters; 3-day and 28-day measurement of coefficient of variations, clinker quality assessment; customer satisfaction, product benchmarking, application-oriented product testing, testing of random market samples, mason panel testing and assessment of bag quality index.</p>
              <p>We have a system in place to address customer complaints which can be accessed through a toll-free number printed on all cement bags in addition to informing our Business Development & Technical team present across all our operating states. This helps us in addressing concerns and work closely with home builders, engineers, architects, contractors, and masons to provide technical know-how, technical support and guidance, product demonstration and slab supervision services.</p>


                    {/* <div className="per_hig mb-5">
                      <div className="card_round">
                      <div className="justify-content-center mb-3 row">
                        <Col className="col-xl-4" md={5}>

                        <a href={healthpolicy} target="_blank">
                            <Card className="wow fadeInUp" data-wow-delay="0.5s">
                              <Card.Title>Quality Policy 
                         </Card.Title>
                              <Card.Img src={pdfIcon} />
                            </Card>
                          </a>
                        </Col>
                  
                    
</div></div></div> */}



                </section>


<section className='tptextbx py-0'>
                    <h2 className='grad-heading'>Construction Development & Innovation Centre (CDIC)</h2>
                    <p>At Nuvoco, we are committed to creating value for all our stakeholders through a portfolio of innovative and sustainable products. Our in-house expertise delivers modern building materials, numerous types of concrete and cement variants that offer efficient and cost-effective solutions to our valued patrons. Our team at Construction Development and Innovation Center (CDIC) is constantly assessing changing market needs to steer inquiries that are backed by robust research, which also enables us to understand the products and help improve its performance and develop even greater innovations.</p>
                    <div className="imgbx mx-auto d-flex align-items-center justify-content-center my-4 flex-column">
                        <img src={img9} alt="" className='img-fluid' />
                        <span className='pt-2'>Certificate No.: TC 7607</span>
                    </div>
                    <p>
                        CDIC received accreditation (registration number TC 7607) from the National Accreditation Board for Testing and Calibration Laboratories (NABL) in 2019. With this they can conduct more than 100 mechanical tests, out of which, 55 tests come under the NABL accreditation; covering a range of materials including cement, fly ash, GGBS (Ground Granulated Blast-furnace Slag), concrete, aggregates, bricks, blocks, and construction chemicals.
                    </p>
                    <p>
                        It also offers third party external testing services; customers can be assured that the products and solutions provided have passed the highest standards and are also valid globally.
                    </p>
                </section>
                <section className='process pb-0 pt-5'>
                    <h2 className='grad-heading'>Innovation Process</h2>
                    <p>CDIC follows a tried-and-tested Innovation Process to conceive and develop safe, effective and sustainable solutions.</p>
                    <Row className='mt-5 justify-content-center'>
                    <Col md={9}>
                        <Row>
                            <Col md={4} className="mt-4">
                                 <article className='mx-auto'>
                                    <div className='icnbx'>
                                        <div className='rshadow'></div>
                                        <div className='icnbxin d-flex justify-content-center align-items-center'>
                                            <img src={img4} alt="" className='img-fluid' />
                                            <div className='nbr'>1</div>
                                        </div>
                                    </div>
                                    <h4 className='grad-heading text-center w-100'>Ideation and
                                        Incubation</h4>
                                </article>
                            </Col>
                            <Col md={4} className="mt-4">
                                <article className='mx-auto'>
                                    <div className='icnbx'>
                                        <div className='rshadow'></div>
                                        <div className='icnbxin d-flex justify-content-center align-items-center'>
                                            <img src={img3} alt="" className='img-fluid' />
                                            <div className='nbr'>2</div>
                                        </div>
                                    </div>
                                    <h4 className='grad-heading text-center w-100'>Feasibility and Specification</h4>
                                </article>
                            </Col>
                            <Col md={4} className="mt-4">
                                <article className='mx-auto'>
                                    <div className='icnbx'>
                                        <div className='rshadow'></div>
                                        <div className='icnbxin d-flex justify-content-center align-items-center'>
                                            <img src={img5} alt="" className='img-fluid' />
                                            <div className='nbr'>3</div>
                                        </div>
                                    </div>
                                    <h4 className='grad-heading text-center w-100'>Development</h4>
                                </article>
                            </Col>
                            <Col md={4} className="mt-4">
                                <article className='mx-auto'>
                                    <div className='icnbx'>
                                        <div className='rshadow'></div>
                                        <div className='icnbxin d-flex justify-content-center align-items-center'>
                                            <img src={img6} alt="" className='img-fluid' />
                                            <div className='nbr'>4</div>
                                        </div>
                                    </div>
                                    <h4 className='grad-heading text-center w-100'>Pilot and
                                        Customer User
                                        Test</h4>
                                </article>
                            </Col>
                            <Col md={4} className="mt-4">
                                <article className='mx-auto'>
                                    <div className='icnbx'>
                                        <div className='rshadow'></div>
                                        <div className='icnbxin d-flex justify-content-center align-items-center'>
                                            <img src={img7} alt="" className='img-fluid' />
                                            <div className='nbr'>5</div>
                                        </div>
                                    </div>
                                    <h4 className='grad-heading text-center w-100'>Launch</h4>
                                </article>
                            </Col>
                            <Col md={4} className="mt-4">
                                <article className='mx-auto'>
                                    <div className='icnbx'>
                                        <div className='rshadow'></div>
                                        <div className='icnbxin d-flex justify-content-center align-items-center'>
                                            <img src={img8} alt="" className='img-fluid' />
                                            <div className='nbr'>6</div>
                                        </div>
                                    </div>
                                    <h4 className='grad-heading text-center w-100'>Post Launch
                                        Support</h4>
                                </article>
                            </Col>
                        </Row>
                    </Col>
                    </Row>
                    {/* <Row className='mt-5'>
                        <Col md={12} lg={6}>
                            <div className='topbx d-flex justify-content-center'>
                                <article>
                                    <div className='icnbx'>
                                        <div className='rshadow'></div>
                                        <div className='icnbxin d-flex justify-content-center align-items-center'>
                                            <img src={img3} alt="" className='img-fluid' />
                                            <div className='nbr'>2</div>
                                        </div>
                                    </div>
                                    <h4 className='grad-heading'>Feasibility and Specification</h4>
                                </article>
                            </div>
                            <div className='topbx d-flex justify-content-between'>
                                <article className='arrowone'>
                                    <div className='icnbx'>
                                        <div className='rshadow'></div>
                                        <div className='icnbxin d-flex justify-content-center align-items-center'>
                                            <img src={img4} alt="" className='img-fluid' />
                                            <div className='nbr'>1</div>
                                        </div>
                                    </div>
                                    <h4 className='grad-heading'>Ideation and
                                        Incubation</h4>
                                </article>
                                <article className='arrowtwo'>
                                    <div className='icnbx'>
                                        <div className='rshadow'></div>
                                        <div className='icnbxin d-flex justify-content-center align-items-center'>
                                            <img src={img5} alt="" className='img-fluid' />
                                            <div className='nbr'>3</div>
                                        </div>
                                    </div>
                                    <h4 className='grad-heading'>Development</h4>
                                </article>
                            </div>
                        </Col>

                        <Col md={12} lg={6}>
                            <div className='topbx d-flex justify-content-between'>
                                <article>
                                    <div className='icnbx'>
                                        <div className='rshadow'></div>
                                        <div className='icnbxin d-flex justify-content-center align-items-center'>
                                            <img src={img6} alt="" className='img-fluid' />
                                            <div className='nbr'>4</div>
                                        </div>
                                    </div>
                                    <h4 className='grad-heading'>Pilot and
                                        Customer User
                                        Test</h4>
                                </article>
                                <article>
                                    <div className='icnbx'>
                                        <div className='rshadow'></div>
                                        <div className='icnbxin d-flex justify-content-center align-items-center'>
                                            <img src={img8} alt="" className='img-fluid' />
                                            <div className='nbr'>6</div>
                                        </div>
                                    </div>
                                    <h4 className='grad-heading'>Post Launch
                                        Support</h4>
                                </article>
                            </div>
                            <div className='topbx d-flex justify-content-center'>
                                <article className='arrowtwo'>
                                    <div className='icnbx'>
                                        <div className='rshadow'></div>
                                        <div className='icnbxin d-flex justify-content-center align-items-center'>
                                            <img src={img7} alt="" className='img-fluid' />
                                            <div className='nbr'>5</div>
                                        </div>
                                    </div>
                                    <h4 className='grad-heading'>Launch</h4>
                                </article>
                            </div>
                        </Col>
                    </Row> */}
                </section>
                {/* <section className='infra pt-5'>
                    <h2 className='grad-heading'>Infrastructure</h2>
                    <p>The CDIC operate out of a three-storey facility that is spread across 17,000 square feet, equipped with advanced technology, an in-house quality assurance department, a highly qualified quality and technical team and the latest equipment.</p>
                    <Row className='pt-5'>
                        <Col md="5">
                            <img src={img1} alt="" className='img-fluid floor_img wow fadeInLeft' />
                        </Col>
                        <Col md="7 text-center floor_slide pt-4 wow fadeInRight">
                            <Slider {...floorSlide}>
                                <div>
                                    <img src={f1} alt="" className='img-fluid' />
                                  
                                        <article className='mx-right text-center'>
                                            <h5 className='pb-3'>Ground Floor</h5>
                                            <p>Dedicated to Cement / Concrete/ Other building materials specimen-making and construction prototyping for demonstrating the Company’s product benefits and values.</p>
                                        </article>
                                    </div>
                               
                                <div>
                                    <img src={f2} alt="" className='img-fluid' />
                           
                                        <article className='mx-right text-center'>
                                            <h5 className='pb-3'>First Floor</h5>
                                            <p>Material Analysis Lab with 3500 square feet of space is dedicated to physical testing and concrete formulation designs. The facility has NABL accredited lab for testing & services of various building materials under mechanical scope.</p>
                                        </article>
                                    </div>
                          
                                <div>
                                    <img src={f3} alt="" className='img-fluid' />
                                   
                                        <article className='mx-right text-center'>
                                            <h5 className='pb-3'>Second Floor </h5>
                                            <p>Devoted to interactions with Customers and Partners. Also highlights of innovative products and solutions that developed by Nuvoco. </p>
                                        </article>
                                    </div>
                               
                                <div>
                                    <img src={f4} alt="" className='img-fluid' />
                                   
                                        <article className='mx-right text-center'>
                                            <h5 className='pb-3'>Third Floor </h5>
                                            <p>A mix of Training Center as well as Office Space for employees and relevant stakeholders.</p>
                                        </article>
                                    </div>
                                
                            </Slider>                           
                        </Col>
                    </Row>
                </section> */}
                <h2 className='grad-heading pt-5'>Gallery</h2>
                <div className="slide_cta center_slide card_round">
                            <Slider {...settings}>
                                <div className="wow fadeInLeft" data-wow-delay="0.5s">
                                    <Card>
                                        <Card.Img variant="top" src={mbm01} />
                                    </Card>
                                </div>                 
                                <div className="wow fadeInLeft" data-wow-delay="0.7s">
                                    <Card>
                                        <Card.Img variant="top" src={mbm02} />
                                    </Card>
                                </div>                 
                                <div className="wow fadeInLeft" data-wow-delay="0.5s">
                                    <Card>
                                        <Card.Img variant="top" src={mbm03} />
                                    </Card>
                                </div>                   
                                <div className="wow fadeInLeft" data-wow-delay="0.5s">
                                    <Card>
                                        <Card.Img variant="top" src={mbm04} />
                                    </Card>
                                </div>                 
                                <div className="wow fadeInLeft" data-wow-delay="0.7s">
                                    <Card>
                                        <Card.Img variant="top" src={mbm05} />
                                    </Card>
                                </div>                 
                                <div className="wow fadeInLeft" data-wow-delay="0.5s">
                                    <Card>
                                        <Card.Img variant="top" src={mbm06} />
                                    </Card>
                                </div>   
                                <div className="wow fadeInLeft" data-wow-delay="0.5s">
                                    <Card>
                                        <Card.Img variant="top" src={mbm07} />
                                    </Card>
                                </div>
                                <div className="wow fadeInLeft" data-wow-delay="0.5s">
                                    <Card>
                                        <Card.Img variant="top" src={mbm08} />
                                    </Card>
                                </div>                
                            </Slider>
                        </div>
            </Container>

            
        </div >
    )
}

export default InnovationCentre