import { PRESENTATION, PRESENTATIONSEARCH, AUDIOVIDEO } from "./type";

const initialState ={presentation: '', presentationsearch: '', audiovideo: ''};

const presentationR = (state = initialState, action) =>{
    switch(action.type){
        case PRESENTATION:
            return {...state, presentation: action.payload};
        case PRESENTATIONSEARCH:
            return {...state, presentationsearch: action.payload};    

        case AUDIOVIDEO:
            return {...state, audiovideo: action.payload};    

        default:
            return state;
    }
}

export default presentationR;