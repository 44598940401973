import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from "react-helmet";

const MadhumitaBasu = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Madhumita Basu | Non-Executive Director | NU Vista Limited</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Ms. Madhumita Basu has been a member of the Board of Directors since May 09, 2023." />
    <link rel="canonical" href="https://nuvoco.com/Madhumita-Basu"/>
    </Helmet>

      <div className='midpart bodPages'>
        <Container>
            <Row className="mb-5">
                 <Col md={4}>
                    <img style={{borderRadius:'15px'}} src="https://admin.nuvoco.com/public/BoardOfDirectorsDetails/madhumitabasu.png" />
                </Col>
                <Col md={8}>
                    <h2 className='grad-heading'>Madhumita Basu<br/><small>Non-Executive Director</small></h2>
                    <p className='onetxt'>
                    Ms. Madhumita Basu has been a member of the Board of Directors since May 09, 2023. She holds a bachelors degree in commerce and a masters degree in management studies from the University of Bombay. She has experience in strategic planning, sales, marketing, business development and IT. She has been previously associated with Exide Industries Limited and Eveready Industries India Limited. She is a trained award assessor for the Confederation of Indian Industry (CII) and Export-Import Bank of India for Business Excellence and also recipient of the CII Young Managers trophy. She was also the president of Calcutta Management Association</p>
                </Col>
            </Row>

        </Container>
      </div>
    </div >
  )
}

export default MadhumitaBasu