import React from 'react';
import Container from 'react-bootstrap/Container';

import { Helmet } from "react-helmet";

const FinancialResultQ1FY23 = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Financial result Q1 FY 23 | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Vistas is one of the leading brand in producing cement, ready mix concrete and modern building materials and rated as fifth largest cement group in India" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/financial-result-q1-fy23"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
          <h2 className='grad-heading'>Press Release: NUVOCO Vistas announces its financial results for Q1 FY23</h2>
          
          <ul>
            <li>Consolidated revenue improved 20%YoY to Rs. 2,653 crores</li>
            <li>Consolidated EBITDA stood at Rs. 374 crores</li>
            <li>Consolidated Profit After Tax stood at Rs. 21 crores</li>
          </ul>
          <p className='onetxt'>
            <strong>Mumbai, August 09, 2022:</strong> Nuvoco Vistas Corp. Ltd., a leading building materials Company in India, today announced its unaudited financial results for the quarter ended June 30, 2022. Nuvoco Vistas is the fifth-largest cement group in India, in terms of capacity, with a combined installed capacity of over 23 MMTPA.</p>

          <p>The consolidated cement sales volume for the Company improved by 11%YoY to 4.7 MMT in Q1 FY23. Consolidated revenue from operations during the same period improved by 20%YoY to Rs. 2,653 crores attributed to improved sales volume and higher prices. Consolidated EBITDA for the quarter stood at Rs. 374 crores.</p>

          <p>The Company continues to pursue higher standards of operations through several sustainability initiatives primarily focusing on - enhancing the use of alternative fuel, improving the share of composite cement, and conserving natural resources through efficient use of Waste Heat Recovery systems. The Company achieved highest ever power generation through its Waste Heat Recovery systems during the quarter. Company’s alternate fuel rate also improved by 3 percentage point on YoY basis in Q1 FY23, which furthers its contribution to the circular economy.</p>

          <p>Commenting on the performance of the Company, <strong>Mr. Jayakumar Krishnaswamy,</strong> Managing Director, Nuvoco Vistas Corp. Ltd. said, “As the industry faces continuous headwinds because of soaring fuel prices, we have increased prices to partially offset its impact; more hikes remain essential to offset the full impact. Despite a severe wallop on account of elevated energy prices in the industry, we managed our power and fuel cost-effectively by leveraging internal levers and optimizing the fuel mix. In addition, our finance costs have been declining with debt repayments and effective reductions in the cost of debt over time. Moving forward, this should support profitability.”</p>

         <p>He further added, “While the seasonally weak period and elevated fuel prices will weigh on nearterm profitability, cement demand is expected to show a healthy increase in FY23 due to strong housing demand and government-led infrastructure development projects. Our focus remains on internal levers and operational efficiency while we remain committed to our expansion project at Bhiwani. We also continue to invest in debottlenecking projects at Risda and Nimbol along with alternate fuel material handling facilities as part of our sustainability initiatives.”</p>

        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a cement manufacturing company with a vision to build a safer, smarter and sustainable world. Nuvoco started its operations in 1999, and since then it has emerged as one of the major players in the building materials industry in India. Today, Nuvoco offers a diversified business portfolio under three business segments, namely, Cement, Ready-Mix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco is a leading cement player in India and the largest cement company in East India in terms of capacity, following the acquisition of Nu Vista Limited (formerly Emami Cement Limited), offering various brands of cement such as ‘Concreto’, ‘Duraguard’, ‘Nirmax’, ‘Double Bull’, and ‘Infracem’. The MBM product portfolio, under the ‘Zero M’ and ‘Instamix’ brands, comprises construction chemicals, wall putty and cover blocks. The RMX business has a comprehensive pan-India presence offering value-added products under various brands such as ‘Concreto’, ‘Artiste’, ‘InstaMix’ and ‘X-Con’. Nuvoco is a proud contributor to landmark projects such as World One building at Mumbai, and the metro railway projects at Mumbai, Jaipur and Noida. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet the customer requirements. <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
          <p>For more information, please contact the Company at <a href="mailto:investor.relations@nuvoco.com">investor.relations@nuvoco.com</a></p>
        </Container>
      </div>
    </div >
  )
}

export default FinancialResultQ1FY23