import React from 'react';
import Container from 'react-bootstrap/Container';

import { Helmet } from "react-helmet";

const FinancialResultQ2FY23 = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Financial result Q2 FY 23 | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="NUVOCO Vistas announces its financial results for Q2 FY23" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/financial-result-q2-fy23"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
          <h2 className='grad-heading'>Press Release: NUVOCO Vistas announces its financial results for Q2 FY23</h2>
          
          <ul>
            <li>Consolidated revenue improved 19%YoY to Rs. 2,401 crores</li>
            <li>Consolidated EBITDA stood at Rs. 194 crores</li>
            <li>Consolidated Net debt declined to Rs. 5,283 crores from Jun’22 level</li>
          </ul>
          <p className='onetxt'>
            <strong>Mumbai, November 09, 2022:</strong> Nuvoco Vistas Corp. Ltd., a leading building materials Company in India, announced its unaudited financial results for the quarter ended September 30, 2022. Nuvoco Vistas Corp. Ltd. is the fifth-largest cement group in India and the leading cement player in East India, in terms of capacity, with more than 23 MMTPA of combined installed capacity.</p>

          <p>The consolidated cement sales volume for the Company improved by 15%YoY to 4.4 MMT in Q2 FY23. Consolidated revenue from operations during the same period improved by 19%YoY to Rs. 2,401 crores in line with higher sales volume and improved prices. Consolidated EBITDA for the quarter stood at Rs. 194 crores.</p>

          <p>The Company launched ‘Duraguard Xtra F2F’ - a premium composite cement in Bihar and ‘Concreto Glyde’ – a premium quality Ready-Mix solution designed for interior and exterior flooring underlays, demonstrating Nuvoco’s continuous thrust on environment sustainability and product innovation. Alternate fuel rate improved by 5% YoY in Q2 FY23 and remains a key focus area with the ongoing investment in material handling facilities at Risda and Nimbol Cement plants..</p>

          <p>Announcing the results of the quarter, <strong>Mr. Jayakumar Krishnaswamy, Managing Director of Nuvoco Vistas Corp. Ltd.</strong> stated, "This quarter was one of the challenging periods given that the seasonally weak quarter coincided with peak energy costs. Nonetheless, cement demand continues to grow strongly, especially in the eastern part of India. As we strive to improve our operational standards, we are utilizing alternate fuels and waste heat recovery systems more efficiently, along with effective fuel procurement and consumption strategies. As a result, we achieved one of the most efficient power and fuel consumption rates in the industry during the quarter.”</p>

         <p>He further added, “While we had modest price increase in the eastern markets, high fuel prices will continue to affect near-term profitability. We continue focusing on our customers and operational efficiencies through internal levers; we also remain committed to our expansion and debottlenecking projects and alternative fuel material handling facilities as part of our sustainability initiatives.”</p>

        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a cement manufacturing company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 1999, and since then it has emerged as one of the major players in the building materials industry in India. Today, Nuvoco offers a diversified business portfolio under three business segments, namely, Cement, Ready-Mix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco is a leading cement player in India and the largest cement company in East India in terms of capacity, following the acquisition of Nu Vista Limited (formerly Emami Cement Limited), offering various brands of cement such as ‘Concreto’, ‘Duraguard’, ‘Nirmax’, ‘Double Bull’, and ‘Infracem’. The MBM product portfolio, under the ‘Zero M’ and ‘Instamix’ brands, comprises construction chemicals, wall putty and cover blocks. The RMX business has a comprehensive pan-India presence offering valueadded products under various brands such as ‘Concreto’, ‘Artiste’, ‘InstaMix’ and ‘X-Con’. Nuvoco is a proud contributor to landmark projects such as World One building at Mumbai, and the metro railway projects at Mumbai, Jaipur, and Noida. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet the customer requirements.  <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
          <p>For more information, please contact the Company at <a href="mailto:investor.relations@nuvoco.com">investor.relations@nuvoco.com</a></p>
        </Container>
      </div>
    </div >
  )
}

export default FinancialResultQ2FY23