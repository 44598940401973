import { MEDIARELATION, BLOG, BLOGDETAILS } from "./type";

const initialState ={media: '', blog: '', blogdetails: ''};

const mediaR = (state = initialState, action) =>{
    switch(action.type){
        case MEDIARELATION:
            return {...state, media: action.payload};

        case BLOG:
            return {
                ...state,
                blog: action.payload,
            };
        case BLOGDETAILS:
            return {
                ...state,
                blogdetails: action.payload,
            };

        default:
            return state;
    }
}

export default mediaR;