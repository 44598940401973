import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from "react-helmet";

const BhavnaDoshi = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Bhavna Doshi | Independent Director | Nuvoco Vistas Corp. Ltd.</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Mrs.Bhavna Doshi has been a member of the Board of Directors since January 3, 2017." />
    <link rel="canonical" href="https://nuvoco.com/Bhavna-Doshi"/>
    </Helmet>

      <div className='midpart bodPages'>
        <Container>
            <Row className="mb-5">
                 <Col md={4}>
                    <img style={{borderRadius:'15px'}} src="https://admin.nuvoco.com/public/BoardOfDirectorsDetails/Bhavna.png" />
                </Col>
                <Col md={8}>
                    <h2 className='grad-heading'>Bhavna Doshi<br/><small>Independent Director</small></h2>
                    <p className='onetxt'>
                    Mrs.Bhavna Doshi has been a member of the Board of Directors since January 3, 2017. She is a Chartered Accountant and holds a master's degree in Commerce from the University of Mumbai. Mrs. Doshi was elected in the Institute of Chartered Accountants of Indias (ICAI) western India Regional Council and served as Secretary and Chairperson. She was also elected in the ICAI Council and has served on the Accounting Standards Board of India and the ICAI Research Committee as chairwoman and member. Along with these, she also served as a member on the International Federation of Accountants Compliance Advisory Panel in New York and the Controller and Auditor General of India Government Accounting Standards Advisory Board. Mrs. Doshi has experience in the fields of taxation, accounting, business law and regulatory compliance. She has previously been associated as a partner at chartered accountant firms such as B. S. Mehta and Co., RSM and Co. and Bharat S. Raut and Co. (a KPMG member Company in India). Mrs. Doshi was elected President of the Indian Merchants Chamber and at present, is a member of the Indian Merchants Chambers Presidents Advisory Committee. She is also a member of the CII Corporate Governance Committee and Assochams Management Committee.</p>
                </Col>
            </Row>

        </Container>
      </div>
    </div >
  )
}

export default BhavnaDoshi