import React from 'react';
import Container from 'react-bootstrap/Container';

import { Helmet } from "react-helmet";

const FinancialResultQ4FY22 = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Financial result Q4 FY 22 | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Vistas is one of the leading brand in producing cement, ready mix concrete and modern building materials and rated as fifth largest cement group in India" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/financial-result-q4-fy22"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
          <h2 className='grad-heading'>Press Release: NUVOCO Vistas announces its financial results for Q4 FY22 & FY22</h2>
          
          <ul>
            <li>Consolidated revenues improved 35%QoQ in Q4 FY22 to Rs.2,930 crores</li>
            <li>Consolidated EBITDA in Q4 FY22 increased by 83%QoQ to Rs.440 crores</li>
            <li>Consolidated Profit After Tax stood at Rs.32 crores in FY22</li>
            <li>Consolidated net debt declined by Rs.431 crores in Q4 FY22 and Rs.1,666 crores in FY22</li>
          </ul>
          <p className='onetxt'>
            <strong>Mumbai, May 20, 2022:</strong> Nuvoco Vistas Corp. Ltd., a leading building materials Company in India, today announced its audited financial results for the quarter and year ended March 31, 2022. Nuvoco Vistas is the fifth-largest cement company in India, in terms of capacity, with a combined installed capacity of over 23 MTPA.</p>
          <p>
          The consolidated cement sales volume for the Company improved by 31%QoQ to 5.5 MnT in Q4 FY22. Consolidated revenue from operations during the same period improved by 35%QoQ to Rs.2,930 crores while consolidated EBITDA increased by 83%QoQ to Rs.440 crores.</p>
          <p>Consolidated revenue from operations improved by 24%YoY to Rs.9,318 crores for the year ended March 31, 2022. Consolidated EBITDA and PAT during FY22 stood at Rs.1,539 crores and Rs.32 crores respectively. Consolidated net debt of the Company declined by Rs.431 crores in Q4 FY22 and Rs.1,666 crores in FY22 to Rs.5,064 crores</p>
          <p>
          The Company stays committed to its sustainability initiatives by mainly focusing on increasing the consumption of alternative fuel, healthy share of blended cement, and conserving natural resources through efficient Waste Heat Recovery systems, making a positive impact on the environment and maximizing the value creation for its stakeholders. The Company’s Chittor Cement plant established an Alternate Fuel Rate (AFR) capability of up to 23% in FY22 which furthers its contribution to the circular economy. As India's long-term growth drivers remain intact, the Company is well-positioned for growth to build a safer, smarter and sustainable world.</p>
         <p>Commenting on the financial results, <strong>Mr. Jayakumar Krishnaswamy,</strong> Managing Director, Nuvoco Vistas Corp. Ltd. said, “FY22 was an exceptionally challenging year. Several crises such as – the resurgence of COVID-19 pandemic, sand unavailability and inflationary pressure – have negatively impacted the industry. In the meantime, we continued to focus on internal levers and operational efficiencies. Market share of our premium products on trade volumes improved to 34% and will remain a major thrust area. Our investments in sustainability projects will further our ESG Agenda. We also remain committed to grow our operations on account of the announced clinker expansion plans at Risda & Nimbol; and Grinding Unit at Bhiwani."</p>
        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a cement manufacturing company with a vision to build a safer, smarter and sustainable world. Nuvoco started its operations in 1999, and since then it has emerged as one of the major players in the building materials industry in India. Today, Nuvoco offers a diversified business portfolio under three business segments, namely, Cement, Ready-Mix Concrete (RMX) and Modern Building Materials (MBM). Nuvoco is a leading cement player in India and the largest cement company in East India in terms of capacity, following the acquisition of Nu Vista Limited (formerly Emami Cement Limited), offering various brands of cement such as ‘Concreto’, ‘Duraguard’, ‘Nirmax’, ‘Double Bull’, and ‘Infracem’. The MBM product portfolio, under the ‘Zero M’ and ‘Instamix’ brands, comprises construction chemicals, wall putty and cover blocks. The RMX business has a comprehensive pan-India presence offering value-added products under various brands such as ‘Concreto’, ‘Artiste’, ‘InstaMix’ and ‘X-Con’. Nuvoco is a proud contributor to landmark projects such as World One building at Mumbai, and the metro railway projects at Mumbai, Jaipur and Noida. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet the customer requirements. <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
          <p>For more information, please contact the Company at <a href="mailto:investor.relations@nuvoco.com">investor.relations@nuvoco.com</a></p>
        </Container>
      </div>
    </div >
  )
}

export default FinancialResultQ4FY22