import React, {useState, useEffect}  from "react";
import {Container, Row, Col, Breadcrumb,Modal, Button} from 'react-bootstrap';


import banner from '../assets/images/team_managment.png'
import bannermobile from '../assets/images/team_managment-mobile.jpg'
import aboutBannerLogo from '../assets/images/team_logo.png'

import { commonNewApi } from "../redux";
import { connect } from "react-redux";
import { imgUrl } from "../redux/config";

import BodCard from "../common/bodCard";
import BodSlider from "../common/bodSlide";
import { Helmet } from "react-helmet";


  const pageType = 'management-team?lang=en';
  let IsShow = false;

const ManagementTeam = ({commonNewApi, teamdata}) =>{
    useEffect(() => {
        IsShow = false;
        commonNewApi(pageType);
	}, []);

    const [bod1, setData1] = useState([]);
    const [slideList1, setSlide1] = useState([]);    

    if (Object.keys(teamdata).length) {        
        var teamData1 = {...teamdata.data};
        if(!IsShow || bod1.length === 0)
            setData1([...teamData1.management_team_detail[0].Nuvoco_Vistas_Crop_Ltd, ...teamData1.management_team_detail[1].NU_Vistas_Limited]);
        IsShow = true;
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (index) =>{
        setShow(true);
        const data = [...bod1];
        const showdata = data.splice(index, 1);
        data.unshift(showdata[0]);
        setSlide1([...data]);
    } 
        return(
            <div className="bord_of_directors">
    <Helmet>
    <title>Our Dedicated Management Team | Cement & Concrete Company In India</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco is a leading building materials manufacturer exporter professionally managed by highly renowned professionals management executives dedicated to improving the brand" />
    <link rel="canonical" href="https://nuvoco.com/management-team"/>
    </Helmet>
                <div className="banner text-center position-relative">
                <img className='desktopbannerinner' src={banner} alt="" />
               <img className='mobilebannerinner' src={bannermobile} alt="" />
                    <article className='position-absolute text-center'>
                        <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
                        <h1 className="text-uppercase">KEY MANAGEMENT PERSONNEL</h1>
                    </article>
                    <div className='breadouter'>
                        <div className='container'>
                            <Breadcrumb className='mb-0 py-2'>
                                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Key Management Personnel
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                </div>
                <Container className='py-5 text-center'>
                    <div className="card_round">
                    {(IsShow && (
                        <Row>
                            <Col md={8}>
                                <Row>
                                    {bod1.map((item, index)=> {
                                        return (
                                            <Col onClick={() => handleShow(index)} md={6} key={index}>
                                                <BodCard bd={item} uri={imgUrl} />
                                            </Col>
                                        )
                                    })}   
                                </Row>
                            </Col>                         
                        </Row>
                    )) }
                    </div>
                </Container>


            <Modal  size="lg" centered="true"
                show={show} onHide={handleClose} className="bob_modal">
                <Modal.Body className="p-0">
                <Modal.Header closeButton></Modal.Header>
                    <BodSlider slideList={slideList1} uri = {imgUrl} />
                </Modal.Body>           
            </Modal>
        </div>

        )
}

const mapStatetoProps = (state) => {
	return {
		teamdata: state.commonNewR.teamdata
	};
};
const mapDispatchtoProps = (dispatch) => {
	return {
		commonNewApi: function () {
			dispatch(commonNewApi(pageType));
		},
	};
};

export default connect(mapStatetoProps, mapDispatchtoProps)(ManagementTeam);