import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const NuvoMasonSkillDevelopmentCharkhi = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco launched the Nuvo Mason Skill Development Program in Charkhi Dadri, Haryana | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco launched the Nuvo Mason Skill Development Program in Charkhi Dadri, Haryana" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/nuvo-mason-skill-development-charkhi"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
        <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Nuvoco%20launched%20the%20Nuvo%20Mason%20Skill%20Development%20Program%20in%20Charkhi%20Dadri%20Haryana.pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco launched the Nuvo Mason Skill Development Program in Charkhi Dadri, Haryana</h2>
          <p><strong>The company offers certified masonry skills training to participants as part of its CSR Initiative</strong></p>

          <p className='onetxt'><strong>Charkhi Dadri, Haryana, India – June 12, 2024</strong> - Nuvoco Vistas Corp Ltd, India's fifth-largest cement group in terms of capacity, launched the <b>Nuvo Mason - a Masonry Skill Development Program as part of its Corporate Social Responsibility initiative</b> to benefit the unorganized masonry sector in Charkhi Dadri, Haryana.</p>

          <p>NUVO Mason is a training program designed to improve the masonry skills of workers, empower them, and open new earning opportunities for them. The batch of 50 participants has been selected from the surrounding villages of the Company's Haryana Cement Plant that will undergo training. This program consists of approximately 300 hours of training (theoretical and practical application) on tile masonry (floors and walls). The program content is aligned with the National Skills Qualification Framework (NSQF). Upon successful completion of the program, the National Skill Development Corporation (NSDC) will certify participants.</p>

          <p>Nuvoco has engaged with the National Bank for Agriculture and Rural Development (NABARD), Rohtak Cluster, towards the partial funding of this program and aims to organize skill development training programs and provide better livelihood options for youth. NABARD is the apex development bank in India and has supported this skill development program under the for employment potential enhancement.</p>

          <p>The program was inaugurated by Mr Kuntal Datta, Plant Head of Haryana Cement Plant, Nuvoco, Mr. Dalveer Singh Dalwa, Sarpanch of Gram Panchayat, Chidiya, and Mr Jyoti Kumar Purohit, HR Head of Haryana Cement Plant, Nuvoco, along with other dignitaries from the village.</p>

          <p><strong>Mr Kuntal Datta expressed his gratitude to Mr Mohit Yadav and Mr. Ankit Dahiya from NABARD Rohtak Cluster Office for their faith in Nuvoco’s CSR initiatives and further commented,</strong> “NUVO Mason reflects our dedication to community empowerment through skill development and social responsibility. By providing essential masonry skills to youth and workers in the unorganized sector, we strive to promote financial independence and enhance their quality of life. This initiative advances their technical abilities and creates pathways to sustainable livelihoods, embodying our core value, Care.”</p>

          <p>“NUVO Mason reflects our dedication to community empowerment through skill development and social responsibility. By providing essential masonry skills to youth and workers in the unorganized sector, we strive to promote financial independence and enhance their quality of life. This initiative advances their technical abilities and creates pathways to sustainable livelihoods, embodying our core value, Care.”</p>
        
        <p><strong>About Nuvoco Vistas</strong></p>
          <p>Nuvoco Vistas Corporation Limited (“Nuvoco”) is a building materials company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999 and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest cement group in terms of capacity (25 MMTPA), with INR 10,733 crore in total revenue from operations in FY24. It is among the leading players in East India. Nuvoco offers a diversified business portfolio in three business segments: Cement, Ready-Mix Concrete (RMX), and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes - Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses a pan-India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (ready-to-use bagged concrete - the first-of-its-kind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to landmark projects like the Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium (Rourkela), Aquatic Gallery Science City (Ahmedabad), Metro Railway (Delhi, Jaipur, Noida and Mumbai), and among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ brands, comprises construction chemicals, tile adhesives, wall putty and cover blocks. Through the NABLaccredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements.</p>
          <p>Know more about the Company on to <a href="https://www.nuvoco.com">www.nuvoco.com</a></p>
          <p>For more information, contact the Company at <a href="mailto:india.communications@nuvoco.com">India.communications@nuvoco.com</a></p>
        </Container>
      </div>
    </div >
  )
}

export default NuvoMasonSkillDevelopmentCharkhi