import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const GreenProEcolabel = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco’s Six Ready-Mix Concrete Plants Get GreenPro Ecolabel | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco’s Six Ready-Mix Concrete Plants Get GreenPro Ecolabel | Press Release" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/GreenPro-Ecolabel"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
            <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Press Release - Nuvoco’s Six Ready-Mix Concrete Plants Get GreenPro Ecolabel (Nov 2022).pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco’s Six Ready-Mix Concrete Plants Get GreenPro Ecolabel</h2>
         <p className='onetxt'><strong>Mumbai, November 15, 2022:</strong> Nuvoco Vistas Corp. Ltd India's fifth-largest cement group and the leading cement player in East India, in terms of capacity, recently received the prestigious <strong>Green Pro certification for six of its ready-mix concrete plants.</strong> Patencheru, Miyapur (Hyderabad), Whitefield (Bengaluru), Sanathal (Gujarat), Noida (Uttar Pradesh) and Gurgaon (Haryana) received the Indian Green Building Council (IGBC-CII) “Greenpro  Ecolabel” for producing Ecodure - Green Concrete.</p>

          <p>Green Pro Ecolabel is accredited by Global Ecolabelling Network (GEN) through GENICES (GEN’s Internationally Coordinated Ecolabelling System) to end users in the building and manufacturing sector for choosing sustainable products, materials, and technologies reducing the environmental impact.</p>
          <p>In comparison to conventional OPC mixes, Nuvoco's Ecodure products - Ecodure, Ecodure Prime and Ecodure Plus - reduce carbon emissions by up to 60%. As a result, leading architects and developers consider it the first choice for sustainable and circular construction. Moreover, the company also issues Green Pro-Certificates to end customers who have consciously made an informed choice to buy eco-friendly products with a minimum environmental impact. </p>
          <p>Upon receiving the recognition and certification, <strong>Ms. Madhumita Basu, Chief Strategy & Marketing Officer at Nuvoco Vistas Corp. Ltd.</strong>, said, “This is a testament to our commitment to creating value for our stakeholders by offering innovative and sustainable products from our Construction Development & Innovation Centre. As part of our Protect Our Planet (POP) sustainability agenda, Nuvoco has formulated a detailed sustainability strategy. </p>
          <p>She further added, <strong>"The circular economy is important to us</strong>, and we're committed to taking on challenges such as actively scoping and processing construction and demolition waste.” </p>
        
        <p><strong>About Nuvoco</strong></p>
          <p>Nuvoco Vistas Corp. Ltd. is a leading manufacturer and retailer of building materials in India with a vision to build a safer, smarter and more sustainable world. The company started operations in India in 1999 via acquisitions, and since then it has emerged as one of the major players in India. Today it offers a diversified business portfolio under three business segments: Cement, Ready-Mix Concrete (RMX), and Modern Building Materials (MBM). Nuvoco is one of the top cement manufacturers in India, and the leading player in the East following the acquisition of Nu Vista Limited (formerly Emami Cement Limited); offering highperformance, premium, blended cement variants like Concreto, Duraguard, Nirmax and Infracem. Its MBM product range, under the Zero M brand, comprises construction chemicals, wall fill solutions, and cover blocks. Nuvoco's RMX business has a pan-India presence offering specialised products like Artiste and InstaMix being proud contributors to landmark projects like Lodha World One, Amritsar Entry Gate, and the Metros (Delhi, Jaipur, Noida and Mumbai). Through its NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies latent gaps in the industry and offers customised solutions to its customers. Guided by the enduring principles of safety for its employees and responsibility for the community and environment; the company is charting its course to shape a new world. <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
        </Container>
      </div>
    </div >
  )
}

export default GreenProEcolabel