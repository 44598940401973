import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from "react-helmet";

const KaushikbhaiPatel = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Kaushikbhai Patel | Non-Executive Director | Nuvoco Vistas Corp. Ltd.</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Mr. Kaushikbhai Patel has been a member of the Board of Directors since November 9, 2017." />
    <link rel="canonical" href="https://nuvoco.com/Kaushikbhai-Patel"/>
    </Helmet>

      <div className='midpart bodPages'>
        <Container>
            <Row className="mb-5">
                 <Col md={4}>
                    <img style={{borderRadius:'15px'}} src="https://admin.nuvoco.com/public/BoardOfDirectorsDetails/kaushik bhai.jpg" />
                </Col>
                <Col md={8}>
                    <h2 className='grad-heading'>Kaushikbhai Patel<br/><small>Non-Executive Director</small></h2>
                    <p className='onetxt'>
                    Mr. Kaushikbhai Patel has been a member of the Board of Directors since November 9, 2017. He is a Chartered Accountant with several years of experience and pursued a bachelor&#39;s degree in Commerce from Gujarat university. Mr. Patel has worked in the areas of strategy, financial planning, mergers and acquisitions, direct taxation, and capital markets. He has been a part of Nirma Limited since 2002.</p>
                </Col>
            </Row>

        </Container>
      </div>
    </div >
  )
}

export default KaushikbhaiPatel