import { CEMENT } from "./type";

const initialState ={cement: ''};

const cementR = (state = initialState, action) =>{
    switch(action.type){
        case CEMENT:
            return {...state, cement: action.payload};

        default:
            return state;
    }
}

export default cementR;