import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from "react-helmet";

const BerjisDesai = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Achal Bakeri | Independent Director | Nuvoco Vistas Corp. Ltd.</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Mr. Achal Bakeri has been a member of the Board of Directors since April 7, 2021." />
    <link rel="canonical" href="https://nuvoco.com/Achal-Bakeri"/>
    </Helmet>

      <div className='midpart bodPages'>
        <Container>
            <Row className="mb-5">
                 <Col md={4}>
                    <img style={{borderRadius:'15px'}} src="https://admin.nuvoco.com/public/BoardOfDirectorsDetails/Achal Bakeri.jpeg" />
                </Col>
                <Col md={8}>
                    <h2 className='grad-heading'>Achal Bakeri<br/><small>Independent Director</small></h2>
                    <p className='onetxt'>
                    Mr. Achal Bakeri has been a member of the Board of Directors since April 7, 2021. He has an Architectural Diploma degree from Ahmedabad Centre for Environmental Planning and Technology (CEPT) and a master's degree in Business Administration from the University of Southern California in the United States. He has experience in the Air Cooler industry and serves as Symphony Limited Promoter, Chairman and Managing Director.</p>
                </Col>
            </Row>

        </Container>
      </div>
    </div >
  )
}

export default BerjisDesai