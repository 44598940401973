import React from 'react';
import Container from 'react-bootstrap/Container';
import { imgUrl } from "../../redux/config";
import { Helmet } from "react-helmet";

const ConcretoDawshomAwbotaar = () => {
  return (
    <div className="aboutus-pge inner_page">

    <Helmet>
    <title>Nuvoco Partners with Acclaimed Superstar Prosenjit Chatterjee for a cinematic marvel 'Dawshom Awbotaar' | Press Release</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco Partners with Acclaimed Superstar Prosenjit Chatterjee for a cinematic marvel Dawshom Awbotaar" />
    <link rel="canonical" href="https://nuvoco.com/media/Press-Releases/concreto-with-dawshom-awbotaar"/>
    </Helmet>

      <div className='midpart pressReleases'>
        <Container>
            <div className="text-right">
              <a href={ imgUrl +"public/MediaRalationsDetails/Concreto with Dawshom Awbotaar.pdf"} target="_blank" className="btn_lg active btn btn-success">Download PDF</a>
            </div>
          <h2 className='grad-heading'>Press Release: Nuvoco Partners with Acclaimed Superstar Prosenjit Chatterjee for a cinematic marvel 'Dawshom Awbotaar'</h2>
          <p><strong>Concreto - Naam Tai Joteshto Campaign draws similarities with the enduring legacy of Prosenjit Chatterjee</strong></p>
         
          <p className='onetxt'><strong>Kolkata, 20th October 2023 : </strong>Nuvoco Vistas Corp. Ltd., India's fifth-largest cement group, is thrilled to proud to announce its collaboration with the legendary superstar, Prosenjit Chatterjee, for the latest cinematic marvel, <strong>'Dawshom Awbotaar'</strong>. </p>

          <p>This unique partnership of <strong>Concreto Cement’s Naam Tai Joteshto campaign</strong> and <strong>Prosenjit Chatterjee</strong> highlights the similarities just as his name is synonymous with brilliance in the entertainment world, Concreto's name is synonymous with excellence in the cement industry. </p>

          <p><strong>Commenting on this association, Mr. Chirag Shah, Sr. Vice President - Marketing and Innovation at Nuvoco</strong>, said, "We are thrilled to be associated with 'Dawshom Awbotaar,' a movie that mirrors the premium quality and versatility shared by Nuvoco's Concreto cement and Prosenjit Chatterjee's cinematic charm. This partnership embodies our joint commitment to excellence and aspires to delight our current and potential customers. Together, we hope to create an unforgettable experience for the audience.”</p>
          
          <p>Dawshom Awbotaar is a Bengali-language thriller movie, promising a cinematic spectacle that pushes boundaries. With the combined brilliance of Prosenjit Chatterjee, Srijit Mukherji, Jio Studios, and SVF Entertainment, this movie is set to make waves in Bengali cinema, mirroring superiority and premiumness that Concreto Cement brings to the construction sector.</p>

        <p><strong>About Nuvoco Vistas</strong></p>
          <p><a href="https://www.nuvoco.com">Nuvoco Vistas Corporation Limited (“Nuvoco”)</a> is a cement manufacturing company with a vision to build a safer, smarter, and sustainable world. Nuvoco started its operations in 2014 through a greenfield cement plant in Nimbol, Rajasthan, and further acquired Lafarge India Limited, which entered India in 1999, and Emami Cement Ltd. in 2020. Today, Nuvoco is India’s 5th largest Cement Group in terms of capacity (23.8 MMTPA) with an INR 10,586 crore total revenue from operations in FY23, and is among the leading players in East India. Nuvoco offers a diversified business portfolio under three business segments - Cement, Ready-Mix Concrete (RMX), and Modern Building Materials (MBM). Nuvoco’s Cement product portfolio includes Concreto, Duraguard, Double Bull, PSC, Nirmax and Infracem brands that offer a complete spectrum of Ordinary Portland Cement (OPC), Portland Slag Cement (PSC), Portland Pozzolana Cement (PPC) and Portland Composite Cement (PCC). Nuvoco’s RMX business possesses PAN India presence and offers value-added products under Concreto (Performance concrete), Artiste (Decorative concrete), InstaMix (ready-to-use bagged concrete - the first-ofits-kind in the industry), X-Con (M20 to M60) and Ecodure (Special green concrete) brands. It is also a proud contributor to landmark projects like the Mumbai-Ahmedabad Bullet Train; Birsa Munda Hockey Stadium, Rourkela; Aquatic Gallery Science City, Ahmedabad; and Metro Railway (Delhi, Jaipur, Noida and Mumbai) among many others. Nuvoco’s MBM product portfolio, under the ‘Zero M’ and ‘InstaMix’ brands, comprises construction chemicals, wall putty and cover blocks. Through the NABL-accredited Construction Development and Innovation Centre (CDIC) based in Mumbai, Nuvoco identifies gaps in the marketplace and offers innovative products to meet customer requirements.</p>
          <p>Know more about the Company on <a href="https://www.nuvoco.com">(www.nuvoco.com)</a></p>
          <p>For more information contact the Company at  <a href="mailto:india.communications@nuvoco.com">India.communications@nuvoco.com</a></p>
      </Container>
      </div>
    </div >
  )
}

export default ConcretoDawshomAwbotaar