import React, {useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import banner from '../assets/images/aboutus-banner.jpg'
import aboutBannerLogo from '../assets/images/aboutba-logo.png'
import abouticon1 from '../assets/images/about-icon1.jpg'
import abouticon2 from '../assets/images/abouticon2.jpg'
import abouticon3 from '../assets/images/abouticon3.jpg'
import abouticon4 from '../assets/images/abouticon4.jpg'
import abouticon5 from '../assets/images/abouticon5.jpg'
import buss1 from '../assets/images/business1.jpg'
import buss2 from '../assets/images/business2.jpg'
import buss3 from '../assets/images/business3.jpg'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useLocation } from 'react-router-dom';
import { blogDetailsApi } from '../redux';
import { connect } from 'react-redux';
import { imgUrl } from '../redux/config';
import BlogCard from '../common/blogCard';

let IsData = false;

const Blog = ({blogDetailsApi, blogdetails}) => {
  const location = useLocation();
  useEffect(() => {
    async function fetchData() {
        var x = await location.pathname.split("/");
        blogDetailsApi(`${x[2]}`);
    }

    fetchData();
}, [location]);

if (Object.keys(blogdetails).length) {
  IsData = true;
  var data = { ...blogdetails.data };
  var blog = data.detail[0];

  console.log(data)
 }


  return (
    
    <div className="media inner_page">
      {(IsData && (
        <>
        <div className="banner text-center position-relative">
          <img src={imgUrl + blog.banner_img } alt="" />
          <article className='position-absolute text-center'>
            <Container>
            <h1 className="text-uppercase">{blog.blog_title}</h1>
            </Container>
          </article>
          <div className='breadouter'>
            <div className='container'>
              <Breadcrumb className='mb-0 py-2'>
                <Breadcrumb.Item href="#">Blog</Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {blog.blog_title}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
        <div className='midpart mt-5'>
          <Container>
            <div dangerouslySetInnerHTML={{
												__html: blog.banner_desc,
											}}></div>
            <div className='blog card_round blog_details mt-5'>
              <Row>
                {data.latestBlog.map((slide, index) =>{
                    return (
                      <Col md={4} key={index}>
                        <BlogCard item={slide} id={4} uri ={imgUrl} />
                      </Col>
                    );
                })}
              </Row>
            </div>
          </Container>
        </div>
        </>
      )) || ''}
    </div >
  )
}


const mapStatetoProps = (state) => {
	return {
    blogdetails: state.mediaR.blogdetails
	};
};
const mapDispatchtoProps = (dispatch) => {
	return {
		blogDetailsApi: function (page_url) {
			dispatch(blogDetailsApi(page_url));
		}
	};
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Blog);
