import React from "react";
import { Container, Button, Card} from "react-bootstrap";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import cementLogo from '../assets/images/cement_logo.svg';
import dcement from '../assets/images/dcement.png';
import dbull from '../assets/images/dbull.png';
import brand07 from '../assets/images/brand07.png';
import brand01 from '../assets/images/brand01.png';
import rightIcon from '../assets/images/right_icon.png';
import nirmex from '../assets/images/nirmex.png';
import psc from '../assets/images/psc.png';
import infracem from '../assets/images/infracem.png';
import artiste from '../assets/images/artiste.png';
import concreto from '../assets/images/concreto.png';
import ecodure from '../assets/images/ecodure.png';
import instamix from '../assets/images/instamix.png';
import xcon from '../assets/images/xcon.png';
import zeroM from '../assets/images/zeroM.png';
import { Link } from "react-router-dom";

const settings = {
    autoplay:true,
    autoplaySpeed: 2000,
    dots: false,
    arrow:true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            },
        },
    ],
  };

  const settings1 = {
    autoplay:true,
    autoplaySpeed: 2000,
    dots: false,
    arrow:true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            },
        },
    ],
  };

class HomeOurBarnd extends React.Component{
    constructor() {
        super();
        this.state = {
          cement: true,
          rmx: false,
          mbm: false
        };
        this.hideComponent = this.hideComponent.bind(this);
      }
      hideComponent(varname) {
        switch (varname) {
          case "cement":
            this.setState({ cement: true, rmx:false, mbm:false });
            break;
          case "rmx":
            this.setState({ cement: false, rmx:true, mbm:false });
            break;
          case "mbm":
            this.setState({ cement: false, rmx:false, mbm:true });
            break;
          default: return;
        }
      }

    render(){
        const { cement, rmx, mbm } = this.state;
        return(
            <section className="our_brands text-center card_round">
            <img className="a_icon brand01 wow fadeInUp" data-wow-delay="0.5s" src={brand01} alt='' />
            <img className="a_icon brand07 wow fadeInUp" data-wow-delay="0.7s" src={brand07} alt='' />
            <Container>
                <div className="wow fadeInDown" data-wow-delay="0.5s">
                    
                    <h1><Link to='/our-brands'>Our Brands</Link></h1>
                    <p className="title">Through our continuous innovation and research, Nuvoco has become the best cement company in India and has developed a diverse product portfolio of more than 10 brands and 50 products serving the construction and infrastructure industries.</p>
                    <Button onClick={() => this.hideComponent("cement")} variant="success" className={cement ? 'active': ''}>Cement</Button>
                    <Button onClick={() => this.hideComponent("rmx")} variant="success" className={rmx ? 'active': ''}>RMX</Button>
                    <Button onClick={() => this.hideComponent("mbm")} variant="success" className={mbm ? 'active': ''}>MBM</Button>
                </div>
                    <div className="brand_carousel arrow_only">                   
                        { cement ? 
                            (<Slider {...settings}>
                            <div className="wow fadeInUp" data-wow-delay="0.5s">
                                <Card>
                                    <Card.Img variant="top" src={cementLogo} />
                                </Card>
                            </div>                 
                            <div className="wow fadeInUp" data-wow-delay="0.7s">
                                <Card>
                                    <Card.Img variant="top" src={dcement} />
                                </Card>
                            </div>                 
                            <div className="wow fadeInUp" data-wow-delay="0.9s">
                                <Card>
                                    <Card.Img variant="top" src={dbull} />
                                </Card>
                            </div>                 
                            <div className="wow fadeInUp" data-wow-delay="0.5s">
                                <Card>
                                    <Card.Img variant="top" src={nirmex} />
                                </Card>
                            </div>                 
                            <div className="wow fadeInUp" data-wow-delay="0.7s">
                                <Card>
                                    <Card.Img variant="top" src={psc} />
                                </Card>
                            </div>                 
                            <div className="wow fadeInUp" data-wow-delay="0.9s">
                                <Card>
                                    <Card.Img variant="top" src={infracem} />
                                </Card>
                            </div>                 
                        </Slider>) : ''
                        }
                        { rmx ? 
                            (<Slider {...settings}>
                            <div className="wow fadeInUp" data-wow-delay="0.5s">
                                <Card>
                                    <Card.Img variant="top" src={artiste} />
                                </Card>
                            </div>                 
                            <div className="wow fadeInUp" data-wow-delay="0.7s">
                                <Card>
                                    <Card.Img variant="top" src={concreto} />
                                </Card>
                            </div>                 
                            <div className="wow fadeInUp" data-wow-delay="0.9s">
                                <Card>
                                    <Card.Img variant="top" src={ecodure} />
                                </Card>
                            </div>                 
                            <div className="wow fadeInUp" data-wow-delay="0.5s">
                                <Card>
                                    <Card.Img variant="top" src={instamix} />
                                </Card>
                            </div>                 
                            <div className="wow fadeInUp" data-wow-delay="0.7s">
                                <Card>
                                    <Card.Img variant="top" src={xcon} />
                                </Card>
                            </div>                    
                        </Slider>) : ''
                        }
                        { mbm ? 
                            (<Slider {...settings1}>
                            {/* <div className="wow fadeInUp" data-wow-delay="0.5s">
                                <Card>
                                    <Card.Img variant="top" src={instamix} />
                                </Card>
                            </div>                  */}
                            <div className="wow fadeInUp" data-wow-delay="0.7s">
                                <Card>
                                    <Card.Img variant="top" src={zeroM} />
                                </Card>
                            </div>                
                        </Slider>) : ''
                        }
                    </div>
                <Button variant="success" className="read_more" as={Link} to="/our-brands">View All <img src={rightIcon} alt=''/></Button>
                <ul className='notes'>
                    <li>*RMX : Ready-Mix Concrete</li>
                    <li>*MBM : Modern Building Materials</li>
                </ul>
            </Container>
        </section>
        )
    }
}

export default HomeOurBarnd;